import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import Security from '../../commons/security/Security.js'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
//import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import $ from 'jquery'
import dtConfig from './PanolGrid.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { getPlantillas, createPlantillas, updatePlantillas, deletePlantillas } from './MetodosPanol.js' 
$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class PlantillasAbm extends Component { 

	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			loading: true,
			grid: null,
			create: null,
			update: null,
			delete: null,
		}
	}
	async componentDidMount() {
		
    	let component = this;
    	let filters = null;
		let getGrid = await getPlantillas().then(res=>{
    		let dataR = res.data;
	        this.setState({
	            grid: dataR
	        })
	       
	    });
	    const config = {
    	headers: { 
    		'Content-Type': 'application/json',
			'Authorization-Token': localStorage.getItem("token")
    	 }
		};	    
       let table = $(this.refs.main).DataTable(
			Object.assign({
			//dom: '<"data-table-wrapper">',
			stateSave: true,
			stateSaveCallback: function(settings,data) {
				localStorage.setItem( 'DataTables_PlantillasAbm', JSON.stringify(data) );
			},
			stateLoadCallback: function(settings) {
				return JSON.parse( localStorage.getItem( 'DataTables_PlantillasAbm' ) );
			},

           	ajax: {
					type: 'GET',
					url: Config.get('apiUrlBase') + '/panol/plantillas/grid',
					
					headers: {
						'Content-Type': 'application/json',
						'Authorization-Token': localStorage.getItem("token")
					},
					dataSrc: function(res){
						return component.state.grid
					},
					error: function (xhr, error, thrown) {
					},
					cache: false
				},
			initComplete: function (settings) {
			let stateColumns = JSON.parse(localStorage.getItem('DataTables_PlantillasAbm')).columns;
			var sets = settings;
			var index = 0;
			this.api().columns().every( function () {
				var column = this;
				if(sets.aoColumns[index].filterType){
					if(sets.aoColumns[index].filterType === 'select') {
						var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'plantillasAbm.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
							.appendTo( $(column.footer()).empty() )
							.on( 'change', function () {
								var val = $.fn.dataTable.util.escapeRegex($(this).val());
								column
									.search( val ? val : '', true, false )
									.draw();
							});								
						if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
							filters[sets.aoColumns[index].name].map(e => {
								select.append('<option value="'+e.value+'">'+e.label+'</option>');
								return true;
							});
						} else {
							column.data().unique().sort().each( function ( d, j ) {
								select.append( '<option value="'+d+'">'+d+'</option>' );
							});
						}
						if(stateColumns[index].search.search) select.val(stateColumns[index].search.search);
					}
					if(sets.aoColumns[index].filterType === 'input') {
						var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'plantillasAbm.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
						if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
						input.appendTo($(column.footer()).empty());
						input.on( 'keyup change', function () {
							if ( column.search() !== this.value ) {
								column
								.search( this.value )
								.draw()
								.ajax.reload(null, false);
							}
						});
					}
				}
				index++;
				return '';
			});
			$('tfoot tr').appendTo('thead');
			},
           	columns: [
           		{ 
	            title: component.props.intl.formatMessage({ id: 'plantillasAbm.column_id.label_id', defaultMessage: 'id' }),
	            className: "text-center",
	            visible: false,
	            data: "id"
	            },
	            { 
	            title: component.props.intl.formatMessage({ id: 'plantillasAbm.column_id.label_codigo', defaultMessage: 'Codigo' }),
	            className: "",
	            data: "codigo",
	            filterType: 'input'
	            },
           		{ 
	            title: component.props.intl.formatMessage({ id: 'plantillasAbm.column_id.label_nombre_plantilla', defaultMessage: 'Nombre Plantilla' }),
	            className: "",
	            data: "descripcion",
	            filterType: 'input'
	            },
	            { 
	            title: component.props.intl.formatMessage({ id: 'plantillasAbm.column_id.label_estado', defaultMessage: 'Estado' }),
	            className: "text-center",
	            data: "estado",
	            filterType: 'input'
	            },
	            {
				orderable: false,
				data: null,
				className: "text-center",
				width: '120px',
	            render: function () {
					let html =
						(Security.hasPermission('PANOL_PLANTILLAS_VISUALIZAR') ? `
						<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'plantillasAbm.column_actions.button_title_ver', defaultMessage: 'Ver' })+`">
							<i class="fa fa-search fa-xs"></i>
						</button>`: '') +
                        (Security.hasPermission('PANOL_PLANTILLAS_MODIFICAR') ? `
						<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'plantillasAbm.column_actions.button_title_editar', defaultMessage: 'Editar' })+`" >
							<i class="fa fa-pencil fa-xs"></i>
						</button>`: '') +
                        (Security.hasPermission('PANOL_PLANTILLAS_ELIMINAR') ? `
						<button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'plantillasAbm.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`">
							<i class="fa fa-trash fa-xs"></i>
						</button>` : '');
                    return html;
				}
        	}],
        	sDom: 'lrtip',
			orderCellsTop: true,
        	fixedHeader: true,
        	drawCallback: function () {
						$(this).find('.action').on('click', function () {
							let redirectTo;
							
							if($(this).hasClass('view')) {
								let data = table.row( $(this).parents('tr') ).data();
								let dataId = data.id;
								console.log("redirige a: "+dataId.id + "-" + dataId)
								redirectTo = component.props.match.url + '/' + dataId;
								component.setState({ redirectTo: redirectTo });
								console.log(component.state.redirectTo);
								component.forceUpdate();
							}
								//this.forceUpdate();
							if($(this).hasClass('edit')) {
								let data = table.row( $(this).parents('tr') ).data();
								let dataId = data.id;
								console.log(data);
								$("#editPlantillasModal #editIdPlantilla").val(data.id);
								$("#editPlantillasModal #editNombrePlantilla").val(data.descripcion);
								$("#editPlantillasModal #editCodigoPlantilla").val(data.codigo);
								$('#editPlantillasModal').on('shown.bs.modal', function() {
								    $('#editNombrePlantilla').trigger('focus');
								    $("#editNombrePlantilla").keyup(function(e)
									{
									    if (e.which == 13)
									    {
									        $(this).submit();
									    }
									});
								  });
								$("#editPlantillasModal").modal({show: true});
							}
							if($(this).hasClass('delete')) {
								swal({
								title: component.props.intl.formatMessage({ id: 'plantillasAbm.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
								text: null,
								icon: "warning",
								buttons: {
									confirm: {
										text: component.props.intl.formatMessage({ id: 'plantillasAbm.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
										value: true,
										visible: true,
										className: "btn btn-primary",
										closeModal: false
									},
									cancel: {
										text: component.props.intl.formatMessage({ id: 'plantillasAbm.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
										value: null,
										visible: true,
										className: "btn btn-danger",
										closeModal: true,
									}
								}
							}).then((isConfirm) => {
								if (isConfirm) {
									let data = table.row( $(this).parents('tr') ).data();
									let dataId = data.id;
									console.log("se eliminara el item: "+ data.descripcion);
									deletePlantillas(dataId).then(res => {
										let dataR = res.mensaje;
										swal(component.props.intl.formatMessage({ id: 'plantillasAbm.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
										let getGrid2 = getPlantillas().then(res2=>{
							    		let dataR2 = res2.data;
									        component.setState({
									            grid: dataR2
									        })
										table.ajax.reload();
									    console.log("actualiza grid:");
									    console.log(component.state.grid);
									    })
									})
									.catch(function(error) {
										swal(component.props.intl.formatMessage({ id: 'plantillasAbm.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
									});
								}
							}); //fin accion
							
							}
						});
						
					},

		},dtConfig)
		) //fin dt
		$(document).find("#example thead th").css("height","43px");
		$(document).find("#example thead th").css("padding-left","20px");
		$(document).find("#example tbody td").css("padding-left","20px");
		$('[data-toggle="tooltip"]').tooltip();

		this.setState({ loading: false });

		new $.fn.dataTable.Buttons( table, {
			buttons: [
				{
          extend: 'print',
          text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win)
					{
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						}	else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css( 'font-size', '10pt' )
							.prepend(`<div class="container">
													<div class="row mb-2">
														<div class="col-6">
															<img style="height: 35px" src="`+LogoCompany+`" />
														</div>
														<div class="col-6	text-right">
															<img style="height: 40px" src="`+LogoVecFleet+`" />
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-12" style="border-bottom: 1px solid #000;">
															<h2>`+component.props.intl.formatMessage({ id: 'plantillasAbm.print_content.header_listado_de_regiones', defaultMessage: 'Listado de Regiones' })+`</h2>
														</div>
													</div>
												</div>`);

						$(win.document.body).find( 'table' )
							.addClass( 'compact' )
							.css( 'font-size', 'inherit' );
			 		},
          exportOptions: {
              columns: [ 1,2 ]
          },
					titleAttr: component.props.intl.formatMessage({ id: 'plantillasAbm.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
        },
        {
          extend: 'colvis',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'plantillasAbm.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
        },
        {
          extend: 'collection',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'plantillasAbm.tools_export.title_exportar', defaultMessage: 'Exportar' }),
          buttons: [
          {
            extend: 'copyHtml5',
            exportOptions: {
              columns: ':visible'
            },
						text: component.props.intl.formatMessage({ id: 'plantillasAbm.tools_export.select_text_copiar', defaultMessage: 'Copiar' }),
						titleAttr: component.props.intl.formatMessage({ id: 'plantillasAbm.tools_export.select_copiar', defaultMessage: 'Copiar' })
          },
          {
            extend: 'excelHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'plantillasAbm.tools_export.select_exportar_excel', defaultMessage: 'Exportar a Excel' })
          },
          {
            extend: 'csvHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'plantillasAbm.tools_export.select_exportar_formato_csv', defaultMessage: 'Exportar en formato .csv' })
          },
          {
            extend: 'pdfHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'plantillasAbm.tools_export.select_exportar_PDF', defaultMessage: 'Exportar a PDF' })
					}
					]
				},
				
			]
		});

		table.buttons( 0, null ).container().appendTo('#buttons');
		
		$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
			if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
				$('.dt-button-collection>.dropdown-menu').css('display','block');
		});
    }  
	componentWillUnmount(){
		/*
       $('.data-table-wrapper')
       .find('table')
       .DataTable()
       .destroy(true);
       */
    }
    shouldComponentUpdate() {
        return false;
    }
    onChange = (e) => {
    this.props.history.push(`/panol/config${e.target.value}`);
  }
	onModalEditSubmit = (e) => {
	  		e.preventDefault();  		
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();
	  		updatePlantillas(e).then(res =>{

				$(this).find('form').trigger('reset');
				$("#editPlantillasModal").modal("hide");
				swal(component.props.intl.formatMessage({ id: 'plantillasAbm.modal_edit.information_modificado', defaultMessage: 'Modificado!' }), "", "success");
				let getGrid2 = getPlantillas().then(res2=>{
	    		let dataR2 = res2.data;
			    component.setState({
			            grid: dataR2
			    })
				table.ajax.reload();
			    console.log("actualiza grid:");
			    console.log(component.state.grid);
			    })
			})
	  	}
	  	onModalNewSubmit = (e) => {
	  		e.preventDefault();
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();
	  		createPlantillas(e).then(res =>{

				    $("#newPlantillasModal").find('form').trigger('reset');
				    $("#newPlantillasModal").modal("hide");

				swal(component.props.intl.formatMessage({ id: 'plantillasAbm.modal_add.information_agregado', defaultMessage: 'Agregado!' }), "", "success");
										
				let getGrid2 = getPlantillas().then(res2=>{
	    		let dataR2 = res2.data;
			        component.setState({
			            grid: dataR2
			        })
				table.ajax.reload();
			    console.log("actualiza grid:");
			    console.log(component.state.grid);
			    })
			    
			})
			
	  	}

    render() {
        return (
        	<React.Fragment>
        	{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
    		<div className="row mt-2">
				<div className="col-12">
				{/*ABM Plantillas*/}
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
										<div className="col-6">
                                            {Security.renderIfHasPermission('PANOL_PLANTILLAS_CREAR', (
											<div 
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"  
												data-toggle="tooltip" 
												data-placement="right" 
												title={this.props.intl.formatMessage({ id: 'plantillasAbm.render.button_add_template.title_nueva_plantilla', defaultMessage: 'Nueva Plantilla' })}
												data-toggle="modal" 
												data-target="#newPlantillasModal"
												>
												<i className="ft-plus"></i>
											</div>
											))}
										</div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div> 
				               	<div>
				               		<table id="example" ref="main" className="nowrap table-hover dataTableMovil" width="100%">
										<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													
													<th className=""></th>
													<th className="dt-search-header"><FormattedMessage id="plantillasAbm.render.table_column_codigo" defaultMessage="Codigo"/></th>
													<th className="dt-search-header"><FormattedMessage id="plantillasAbm.render.table_column_nombre_plantilla" defaultMessage="Nombre Plantilla"/></th>
													<th className="dt-search-header"><FormattedMessage id="plantillasAbm.render.table_column_estado" defaultMessage="Estado"/></th>
													<th className=""></th>
												</tr>
											</tfoot>

									</table>
					            </div>

				            </div>
			            </div>
					</div>
				</div>
			{/*---Modal nueva Plantilla---*/}
				<div className="modal fade" id="newPlantillasModal" tabIndex="-1" role="dialog" aria-labelledby="newPlantillasModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="newPlantillasModalLabel"><FormattedMessage id="plantillasAbm.render.modal_add_template.header_nueva_plantilla" defaultMessage="Nueva Plantilla"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <form onSubmit={this.onModalNewSubmit}>
						    <div className="modal-body">
						        <div className="row">
							        <div className="col-md-12">
							          	<div className="form-group">
								           	<label htmlFor="nombrePlantilla" className="col-form-label"><FormattedMessage id="plantillasAbm.render.modal_add_template.label_nombre_de_la_plantilla" defaultMessage="Nombre de la Plantilla:"/></label>
								            <input 
								            	type="text" 
								            	className="form-control" 
								            	name="descripcion" 
								            	id="newNombrePlantilla"
								            	placeholder={this.props.intl.formatMessage({ id: 'plantillasAbm.render.modal_add_template.placeholder_nombre_de_la_plantilla', defaultMessage: 'Ej: Service 10000 km' })}
								            />
							          	</div>
							          </div>
							          <div className="col-md-6">
							          	<div className="form-group">
								           	<label htmlFor="nombrePlantilla" className="col-form-label"><FormattedMessage id="plantillasAbm.render.modal_add_template.label_codigode_plantilla" defaultMessage="Nombre de la Plantilla:"/></label>
								            <input 
								            	type="text" 
								            	className="form-control" 
								            	name="codigo" 
								            	id="newCodigoPlantilla"
								            	placeholder={this.props.intl.formatMessage({ id: 'plantillasAbm.render.modal_add_template.placeholder_codigode_plantilla', defaultMessage: 'Ej: 0023' })}
								            />
							          	</div>
							          </div>
							          <div className="col-md-6">
							          	<div className="form-group">
								           	<label htmlFor="estadoPlantilla" className="col-form-label"><FormattedMessage id="plantillasAbm.render.modal_add_template.label_estado" defaultMessage="Estado:"/></label>
								            <select className="form-control" id="newEstadoPlantilla" name="estado">
												  <option value="ACTIVO">ACTIVO</option>
												  <option value="INACTIVO">INACTIVO</option>
											</select>
							          	</div>
							          </div>
							        </div>
					          	</div>					       
					      <div className="modal-footer">
					        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="plantillasAbm.render.modal_add_template.finish_button.cancelar" defaultMessage="Cancelar"/></button>
					        <button type="submit" className="btn btn-primary"><FormattedMessage id="plantillasAbm.render.modal_add_template.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
					      </div>
				      </form>
				    </div>
				  </div>
				</div>

				{/*---Modal edit Plantilla---*/}
				<div className="modal fade" id="editPlantillasModal" tabIndex="-1" role="dialog" aria-labelledby="editPlantillasModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="editPlantillasModalLabel"><FormattedMessage id="plantillasAbm.render.modal_edit_template.header_editar_plantilla" defaultMessage="Editar Plantilla"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				    	<form onSubmit={this.onModalEditSubmit}>
					      <div className="modal-body">
					        <div className="row">
						        <div className="col-md-12">
						          <div className="form-group">
						          		<input 
							            	type="hidden" 
							            	className="form-control" 
							            	name="id" 
							            	id="editIdPlantilla"
							            />
						           		<label htmlFor="nombrePlantilla" className="col-form-label"><FormattedMessage id="plantillasAbm.render.modal_edit_template.lavel_nombre_de_la_plantilla" defaultMessage="Nombre de la Plantilla:"/></label>
							            <input 
							            	type="text" 
							            	className="form-control" 
							            	name="descripcion" 
							            	id="editNombrePlantilla"
							            />
						          </div>
						        </div>
						        <div className="col-md-6">
							        <div className="form-group">
								           	<label htmlFor="nombrePlantilla" className="col-form-label"><FormattedMessage id="plantillasAbm.render.modal_edit_template.lavel_codigo_de_plantilla" defaultMessage="Codigo de plantilla:"/></label>
								            <input 
								            	type="text" 
								            	className="form-control" 
								            	name="codigo" 
								            	id="editCodigoPlantilla"
								            	placeholder={this.props.intl.formatMessage({ id: 'plantillasAbm.render.modal_edit_template.placeholder_codigo_de_plantilla', defaultMessage: 'Ej: 0023' })}
								            />
							          	</div>
							        </div>
							        <div className="col-md-6">
							          	<div className="form-group">
								           	<label htmlFor="estadoPlantilla" className="col-form-label"><FormattedMessage id="plantillasAbm.render.modal_edit_template.lavel_estado" defaultMessage="Estado:"/></label>
								            <select className="form-control" id="editEstadoPlantilla" name="estado">
												  <option value="ACTIVO">ACTIVO</option>
												  <option value="INACTIVO">INACTIVO</option>
											</select>
							          	</div>
				          			</div>
						      </div>
						      </div>
						      <div className="modal-footer">
						        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="plantillasAbm.render.modal_edit_template.finish_button.cancelar" defaultMessage="Cancelar"/></button>
						        <button type="submit" className="btn btn-primary"><FormattedMessage id="plantillasAbm.render.modal_edit_template.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
						      </div>
				        </form>
				    </div>
				  </div>
				</div>

				
			</div>
		</React.Fragment>
            );
    }
}

export default injectIntl(PlantillasAbm);