/* Imports */
import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import argentinaHigh from "@amcharts/amcharts4-geodata/argentinaHigh";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import '../palette-gradient.css';

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end


class WMapChart extends Component{   
  
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		moment.locale('es');
	
		
		this.state = {
			redirectTo: null,
			props: this.props,	
			valores: null,
			primeraVez: true, 
			unidad: ''		
		};

		this.chart = null;
		this.dateAxis = null;
		this.valueAxis = null;
		this.series = null;
		this.categoryAxis = null;
		this.valueAxisX = null;
	}

	componentWillReceiveProps(nextProps){
		if(nextProps.datos != this.state.valores)
		{ 
			let color = '#'+nextProps.mainColorClass;
			if(this.state.primeraVez){
				this.initData(color, nextProps.id, nextProps.datos, nextProps.unidad);
				this.setState({primeraVez: false, unidad: nextProps.unidad});
			} 
			else{
				if(nextProps.datos != null){

					this.series.stroke = color;
					this.series.fillOpacity = 1;
					this.series.fill = color;
					this.chart.data=nextProps.datos;
					this.setState({valores: nextProps.datos});
				} else{
					this.setState({valores: null});
				}
			}
		}
	}

	initData(color, id, datos, unidad){

		
		let chart = am4core.create("mapchartdiv"+id, am4maps.MapChart);
		chart.width = am4core.percent(100);
		chart.height = 150;
		/* Set map definition */
		chart.geodata = argentinaHigh;
		/* Set projection */
		chart.projection = new am4maps.projections.Miller();
		/* Create map polygon series */
		let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
		/* Make map load polygon (like country names) data from GeoJSON */
		polygonSeries.useGeodata = true;

		/* Configure series */
		let polygonTemplate = polygonSeries.mapPolygons.template;
		polygonTemplate.applyOnClones = true;
		polygonTemplate.togglable = true;
		polygonTemplate.tooltipText = "{name}";
		polygonTemplate.nonScalingStroke = true;
		polygonTemplate.strokeOpacity = 0.5;
		polygonTemplate.fill = chart.colors.getIndex(0);
		let lastSelected;
		polygonTemplate.events.on("hit", function(ev) {
			if (lastSelected) {
				// This line serves multiple purposes:
				// 1. Clicking a country twice actually de-activates, the line below
				//    de-activates it in advance, so the toggle then re-activates, making it
				//    appear as if it was never de-activated to begin with.
				// 2. Previously activated countries should be de-activated.
				lastSelected.isActive = false;
			}
			ev.target.series.chart.zoomToMapObject(ev.target);
			if (lastSelected !== ev.target) {
				lastSelected = ev.target;
			}
		})
	
	
		/* Create selected and hover states and set alternative fill color */
		let ss = polygonTemplate.states.create("active");
		ss.properties.fill = chart.colors.getIndex(2);
	
		let hs = polygonTemplate.states.create("hover");
		hs.properties.fill = chart.colors.getIndex(4);
	
		// Hide Antarctica
		polygonSeries.exclude = ["AQ"];
	
		// Small map
		chart.smallMap = new am4maps.SmallMap();
		// Re-position to top right (it defaults to bottom left)
		chart.smallMap.align = "right";
		chart.smallMap.valign = "top";
		chart.smallMap.series.push(polygonSeries);
	
		// Zoom control
		chart.zoomControl = new am4maps.ZoomControl();
	
		let homeButton = new am4core.Button();
		homeButton.events.on("hit", function(){
		chart.goHome();
		});
	
		homeButton.icon = new am4core.Sprite();
		homeButton.padding(7, 5, 7, 5);
		homeButton.width = 30;
		homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
		homeButton.marginBottom = 10;
		homeButton.parent = chart.zoomControl;
		homeButton.insertBefore(chart.zoomControl.plusButton);
	
		this.chart = chart
		
		//this.chart.data = datos;
		
		return this.chart;
	}
	
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
	
	render() {
		let props = this.props;
  
		return (
		  <div className="card pull-up">
		  
			<div className="card-content">
			  <div className="card-body">
          		<div id={"mapchartdiv"+props.id} style={{ width: "100%", height: "170px" }}></div>
			  </div>
			</div>
			
		  </div>
		);
	}
	
} export default WMapChart;