import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import RopAbm from './RopAbm.js'
import RopView from './RopView.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import RopsGrid from './RopsGrid.js';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'

class Rop extends Component { 
	constructor(props) {
		super(props);
        this.props = props;
		this.ajaxHandler = new AjaxHandler();
    }

    componentDidMount(){
        this.ajaxHandler.subscribe(this);
    }

    componentWillUnmount(){
        this.ajaxHandler.unsubscribe(this);
    }

	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      					<h3 className="content-header-title"><FormattedMessage id="rop.header_administracion_rops" defaultMessage="Administración de ROPs"/></h3>
      				</div>
				</div>
				<div className="content-body">								
					<Switch>
						<Route exact path={`${this.props.match.url}`} render={(props) => <RopsGrid {...this.props} ajaxHandler={this.ajaxHandler} />}/>
						<Route exact path={`${this.props.match.url}/add`} render={(props) => <RopAbm {...props} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <RopAbm {...props} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} render={(props) => <RopView {...props} action="VIEW" />} />
					</Switch>										
				</div>
			</div>
	    );
  	}
}

export default injectIntl(Rop);
