import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
// import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
// import duallistboxConfig from '../../commons/duallistbox/DuallistboxConfig.js'
import Loading from '../ui/Loading.js'
// import Select from 'react-select'
import 'react-select/dist/react-select.css'
// import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import Select from 'react-select'
//import 'react-datepicker/dist/react-datepicker.css'
// import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import SelectWithIcons from '../selectWithIcons/SelectWithIcons.js'
import * as Constants from '../atributoDinamico/constants.js';
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import AtributoDinamico from '../atributoDinamico/AtributoDinamico.js'

class ModeloTiposAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();
    
    moment.locale('es');    		

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        nombre: '',
        observaciones: '',
        plan: null,
        plan_vencimiento: null,
        planPreventivo: null,
        planVencimiento: null,
        unidadMedidorId: 1,
        atributos_dinamicos: [],
      },
      // planesPreventivo: [{ value: '1', label: 'Plan 1'},{ value: '2', label: 'Plan 2'}], //CAMBIAR POR WS
      planesPreventivo: [],
      planesVencimiento: [],
      errors: [],
      mostrarInactivos: true,
			loading: false
    };
    
    this.handleEstadoChange = this.handleEstadoChange.bind(this);
     
    this.handleNotificacionesActivasChange = this.handleNotificacionesActivasChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
    this.handleFormChangeSelectObject = this.handleFormChangeSelectObject.bind(this);
    this.handleUnidadMedidorChange = this.handleUnidadMedidorChange.bind(this);
    this.handleChangeAtributoDinamico = this.handleChangeAtributoDinamico.bind(this);
    this.handleAddAtributoDinamico = this.handleAddAtributoDinamico.bind(this);
    this.handleDeleteAtributoDinamico = this.handleDeleteAtributoDinamico.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
   
    this.formValidation = new FormValidation({
			component: this,			
			validators: {
        'formData.nombre': (value) => Validator.notEmpty(value),
        'formData.unidadMedidorId': (value) => Validator.notEmpty(value)
			}
    });
    
  }

  onDragEnd(result) {
      this.setState({ loading: true });
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      let atributos_dinamicos = formDataCopy.atributos_dinamicos
      let atributoEnMovimiento = atributos_dinamicos[result.source.index]
      atributos_dinamicos.splice(result.source.index, 1);
      atributos_dinamicos.splice(result.destination.index, 0, atributoEnMovimiento);
      this.setState({formData: formDataCopy}, () =>  this.setState({ loading: false }))
  }

  initForm() {
    this.setState({ loading: true });
    let component = this;
    
		Promise.all([
      
      //this.state.props.action !== 'ADD' ? this.getData('modelo-tipos', this.state.props.match.params.id) : null 
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/modelo-tipos/' + this.state.props.match.params.id) : null,
      // this.ajaxHandler.getJson('/plan-mantenimiento-preventivos/simple-search?tipo=PREVENTIVO&unidad_medidor_id='+this.state.formData.unidadMedidorId+'&limit=10'),
      this.ajaxHandler.getJson('/plan-mantenimiento-preventivos/VENCIMIENTO/select'),
		]).then((data) => {
      let formData = data[0];
      if(formData) {
        formData.atributos_dinamicos = formData.atributos_dinamicos.map(atributo => {
          atributo.value = atributo.valorDefault;
          return atributo;
        })
        component.setState({
          formData: formData
        });
      } else
        this.handleAddAtributoDinamico()
      // let planesPreventivo = data[2];
      let planesVencimiento = data[1];
      component.setState({
        // planesPreventivo: planesPreventivo,
        planesVencimiento: planesVencimiento
			});
      this.getPlanesPreventivo(this.state.formData.unidadMedidorId);


    }).catch(function(error) {
			console.log(error);
			//component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });
  }
  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}
	componentWillMount() {
    if ((Security.hasPermission('MODELO_TIPOS_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('MODELO_TIPOS_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('MODELO_TIPOS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }
    
	}
  
  getData(service, param) {
    let serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
		return fetch(serviceURL, {
      method: 'GET', 
			headers: {
				'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
      return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  getPlanesPreventivo(unidadMedidorId) {
    Promise.all([
      this.ajaxHandler.getJson('/plan-mantenimiento-preventivos/simple-search?tipo=PREVENTIVO&unidad_medidor_id='+unidadMedidorId+'&limit=10'),
    ]).then((data) => {
      let planesPreventivo = data[0];
      this.setState({
        planesPreventivo: planesPreventivo
      })
    }).catch(function(error) {
    console.log(error);
  });
  }

  
  handleFormChangeSelectObject(name, object) {
    return new Promise((resolve, reject) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      if (name === 'planPreventivo') {
        formDataCopy[name] = object ? { id: object.id, descripcion: object.descripcion } : null;
      } else {
        formDataCopy[name] = object ? { id: object.value } : null;
      }

      this.setState({
        formData: formDataCopy
      }, () => resolve());
    });
  }

  
  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activo'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }

  

  handleNotificacionesActivasChange(notificacionesActivas) {    
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
		this.setState({
      formData: formDataCopy      
    });    
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }
  
	
  


  handleSelectFormChange(name, object) {
    const value = object === null ? null : object.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy		
		});
  }

   

  handlePaisChange(name, object) {
    Promise.all([
      this.getData('provincias', object.id)
		]).then((data) => {
      let provincias = data[0];
			this.setState({
        provincias: provincias
      });
      this.handleSelectFormChange(name, object);
		}).catch(function(error) {
			// TODO: Manejo de errores
			console.log(error);
    });
  }

  handleUnidadMedidorChange(object) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy['unidadMedidorId'] = object === null ? '' : object.id;
    formDataCopy['planPreventivo'] =  null;
    this.setState({
      formData: formDataCopy
    });
    
    // Actualizo Planes Mantenimiento Preventivos segun UnidadMedidorId

    this.getPlanesPreventivo(object.id);
  }

  handleAddAtributoDinamico() {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    let templateAtributoCopy = JSON.parse(JSON.stringify(Constants.TEMPLATE_ATRIBUTO));
    formDataCopy.atributos_dinamicos.push(templateAtributoCopy);
    this.setState({
      formData: formDataCopy
    });
  }

  handleDeleteAtributoDinamico(index) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.atributos_dinamicos.splice(index, 1)
    this.setState({
      formData: formDataCopy
    });
  }


  handleChangeAtributoDinamico(index, key, value) {
    let atributoDinamico = this.state.formData.atributos_dinamicos[index]
    atributoDinamico[key] = value;
    if (key == 'value')
      atributoDinamico.valorDefault = value;
    this.setState({
      formData: this.state.formData
    });
  }

	handleSubmit(event) {
    this.exit();
    this.setState({ loading: true });
    let component = this
    this.state.formData.atributos_dinamicos.map((atributo, index) => {
      atributo.order = index
      return atributo
    })
    this.ajaxHandler.fetch('/modelo-tipos' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(response.status !== 400) {
        
        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/modeloTipos'
		});
	}

	render() {
    this.formValidation.validate();
    let state = this.state;
    let formData = this.state.formData;
    //let planPreventivo = formData.plan ? state.planesPreventivo.find(e => e.value == formData.plan) : null;
    let planPreventivo = formData.planPreventivo ? state.planesPreventivo.find(e => e.value === formData.planPreventivo.id) : null;
    //let planVencimiento = formData.plan_vencimiento ? state.planesVencimiento.find(e => e.value == formData.plan_vencimiento) : null;
    let planVencimiento = formData.planVencimiento ? state.planesVencimiento.find(e => e.value === formData.planVencimiento.id) : null;
    let validationState = this.formValidation.state;
    
		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}				
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">      
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="modeloTiposAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="modeloTiposAbm.render.general_data.campos_requeridos" defaultMessage=" Datos Generales "/></div>
                      </h4>
                      <div className="row">
                        {/* Tipo de Modelo */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                              <FormattedMessage id="modeloTiposAbm.render.general_data.label_tipo" defaultMessage="Tipo *:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.nombre}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="nombre" name="nombre" placeholder={this.props.intl.formatMessage({ id: 'modeloTiposAbm.render.general_data.placeholder_tipo_modelo', defaultMessage: 'Tipo de Modelo' })} value={formData.nombre} onChange={this.handleInputFormChange} />   
                                <div className="help-block text-danger field-message" hidden={validationState.formData.nombre.pristine || validationState.formData.nombre.valid}>{validationState.formData.nombre.message}</div>
                              </div>
                              )}															
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                      {/* UNIDAD MEDIDOR */}
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="unidadMedidor">
                              <FormattedMessage id="movilesAbm.render.general_data.label_unidad_medidor" defaultMessage="Unidad medidor "/>*:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.unidadMedidorId ? UNIDAD_MEDIDOR[formData.unidadMedidorId]['label'] : ''}</div>
                              ) : (
                              <div>
                                <SelectWithIcons
                                  placeholder="Tipo"
                                  valueKey="id"
                                  labelKey="label"
                                  disabled={this.state.props.action === 'VIEW'}
                                  clearable={false}
                                  onChange={this.handleUnidadMedidorChange}
                                  value={formData.unidadMedidorId}
                                  options={Object.values(UNIDAD_MEDIDOR)}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.unidadMedidorId.pristine || validationState.formData.unidadMedidorId.valid}>{validationState.formData.unidadMedidorId.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                     <div className="row"> 
                      {/* PLAN DE MANTENIMIETO PREVENTIVO */}
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="planPreventivo">
                              <FormattedMessage id="modeloTiposAbm.render.general_data.label_plan_mant_Preventivo" defaultMessage="Plan Mant. Preventivo:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{planPreventivo ? planPreventivo.label : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="planPreventivo"
                                  name="planPreventivo"
                                  placeholder={this.props.intl.formatMessage({ id: 'modeloTiposAbm.render.general_data.placeholder_plan_mant_Preventivo', defaultMessage: 'Seleccione' })}
                                  options={this.state.planesPreventivo}
                                  valueKey='id'
                                  labelKey='descripcion'
                                  value={formData.planPreventivo ? { id: formData.planPreventivo.id, descripcion: formData.planPreventivo.descripcion } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("planPreventivo", e)}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>           

                      
                     <div className="row"> 
                      {/* PLAN DE VENCIMIENTO PREVENTIVO */}
                      <div className="col-md-6">
                          <div className="form-group row" style={{display: ConfigBusiness.get('planesMantenimiento.vencimiento.habilitado') === 'true' ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="planVencimiento">
                              <FormattedMessage id="modeloTiposAbm.render.general_data.label_plan_venc_preventivo" defaultMessage="Plan Venc. Preventivo:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{planVencimiento ? planVencimiento.label : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="planVencimiento"
                                  name="planVencimiento"
                                  placeholder={this.props.intl.formatMessage({ id: 'modeloTiposAbm.render.general_data.placeholder_plan_venc_preventivo', defaultMessage: 'Seleccione' })}
                                  options={this.state.planesVencimiento}
                                  valueKey='value'
                                  labelKey='label'
                                  value={planVencimiento ? { value: planVencimiento.id, label: planVencimiento.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("planVencimiento", e)}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                      {/* Observaciones */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="observaciones">
                              <FormattedMessage id="modeloTiposAbm.render.general_data.label_descripcion" defaultMessage="Descripción:"/>
                            </label>
                            <div className="col-md-9">
                            {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.observaciones}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="observaciones" name="observaciones" placeholder={this.props.intl.formatMessage({ id: 'modeloTiposAbm.render.general_data.placeholder_descripcion', defaultMessage: 'Descripción' })} value={formData.observaciones} onChange={this.handleInputFormChange} />   
                                
                              </div>
                              )}		
                            </div>
                          </div>
                        </div>
                      </div>
                      
                        



                      {/*          
                      <div className="row">
                        
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                              Activo:
                            </label>
                            <div className="col-md-9">
                              <Switch
                                onChange={this.handleEstadoChange}
                                checked={formData.activo}
                                options={this.state.estados}
                                id="activo"
                                name="activo"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      */}
                    </div>
                  </div>
                </div>
                
                {!this.state.loading &&
                  <DragDropContext
                    onDragEnd={this.onDragEnd}
                  >
                    <Droppable droppableId={"1"}>
                      {(provided, snapshot) =>
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={snapshot.isDraggingOver ? {"background" : "lightblue"} : {}}
                          >
                          {this.state.formData.atributos_dinamicos
                            .map((atributoDinamico, index)  => (
                            (atributoDinamico && (atributoDinamico.activo || this.state.mostrarInactivos)) &&
                            <AtributoDinamico
                              mostrarInactivos={this.state.mostrarInactivos}
                              handleDelete={this.handleDeleteAtributoDinamico}
                              key={index}
                              index={index}
                              previsualizacion={true}
                              modulo={'modelo-tipo'}
                              disabled={this.state.props.action === 'VIEW'}
                              draggable={false}
                              handleChange={this.handleChangeAtributoDinamico}
                              formulario={atributoDinamico}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      }
                    </Droppable>
                  </DragDropContext>
                }


                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">     
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <>
                        <button  type="button" className="btn btn-success pull-left mr-1" onClick={this.handleAddAtributoDinamico} >
                          <i className="fa fa-plus"></i><FormattedMessage id="tipoEquipamientoAbm.add_dynamic_form.button" defaultMessage=" Agregar Atributo Dinámico"/>
                        </button>

                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="modeloTiposAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        </>)}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'modeloTiposAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'modeloTiposAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(ModeloTiposAbm);