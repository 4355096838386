import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import { useIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import * as Utils from '../../commons/utils/Utils'
import Security from '../../commons/security/Security.js'
import Loading from '../ui/Loading.js'
import { Redirect } from 'react-router-dom'
import PreventivoVerde from '../../assets/images/calendario/preventivo_verde.svg'
import PreventivoRojo from '../../assets/images/calendario/preventivo_rojo.svg'
import PreventivoAmarillo from '../../assets/images/calendario/preventivo_amarillo.svg'
import PreventivoTicketRojo from '../../assets/images/calendario/preventivostickets-06-rojo.svg'
import VencimientoVerde from '../../assets/images/calendario/vencimiento_verde.svg'
import VencimientoRojo from '../../assets/images/calendario/vencimiento_rojo.svg'
import VencimientoAmarillo from '../../assets/images/calendario/vencimiento_amarillo.svg'
import VencimientoTicketRojo from '../../assets/images/calendario/vencimientostickets-07-rojo.svg'
import CorrectivoTicketRojo from '../../assets/images/calendario/correctivostickets-06-rojo.svg'
import CustomToolbar from "./CustomToolbar";
import '../../assets/css/calendar.css'

const localizer = momentLocalizer(moment)
const FILTER_QUERY_INIT = {
  tipo: [],
  fechaInicio: '',
  fechaFin: '',
  dominio: '',
  modeloTipo: '',
  base: '',
  region: '',
  subregion: '',
  centroCostos: ''
}

const Calendarios = (props) => {
  const userLocale = window.localStorage.getItem('userLocale') || 'es';
  moment.locale(userLocale)
  const localizer = momentLocalizer(moment)
  const intl = useIntl()
  const [redirectTo, setRedirectTo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [eventos, setEventos] = useState([]);
  const msg = {showMore: total => `${total} Más`, date: "Día", event: "Evento", time: "Hora" }
  const views = ['month', 'week', 'day', 'agenda'];
  const icons = {
    preventivoVerde: PreventivoVerde,
    preventivoRojo: PreventivoRojo,
    preventivoAmarillo: PreventivoAmarillo,
    preventivoTicketRojo: PreventivoTicketRojo,
    vencimientoVerde: VencimientoVerde,
    vencimientoRojo: VencimientoRojo,
    vencimientoAmarillo: VencimientoAmarillo,
    vencimientoTicketRojo: VencimientoTicketRojo,
    correctivoTicketRojo: CorrectivoTicketRojo
  }

  //Filtros
  const [searchActive, setSearchActive] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [filterQuery, setFilterQuery] = useState(FILTER_QUERY_INIT);
  const [limpiarFiltros, setLimpiarFiltros] = useState(false);

  //Components
  const components = {
    toolbar: CustomToolbar({eventos, setDropdownActive, dropdownActive, searchActive, setSearchActive, filterQuery, setFilterQuery, limpiarFiltros, setLimpiarFiltros}),
    event: CustomEvent({eventos})
  }

  useEffect(() => {
    if(!Security.hasPermission('CALENDARIO_LISTAR')) {
      setRedirectTo("/error")
    } else {
      if (dropdownActive && eventos.length){
        setSearchActive(true)
        setLimpiarFiltros(false)
      }
      
      if (limpiarFiltros) handleFilterReset()
        
      setLoading(true)
      fetchCalendario()
    }
	}, [filterQuery, dropdownActive, limpiarFiltros])


  const fetchCalendario = () => {
    Utils.getData(`/calendario${getQuery()}`)
      .then((data) => {
        let cal = data.map(item => {
          const evento = {
            ...item,
            start: new Date(item.start),
            end: new Date(item.end),
            icon: icons[item.icono]
          }
          return evento
        });
        setEventos(cal);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
      })
  }

  const getQuery = () => {
		let query = "?" 
		query += "tipo=" + (filterQuery.tipo.length && filterQuery.tipo.map((tipo)=>tipo.value).join(','))
		query += "&fechaInicio=" + (filterQuery.fechaInicio && moment(filterQuery.fechaInicio).unix())
		query += "&fechaFin=" + (filterQuery.fechaFin && moment(filterQuery.fechaFin).unix())
    query += "&dominio=" + (filterQuery.dominio && filterQuery.dominio)
		query += "&base_id=" + (filterQuery.base && filterQuery.base.value ? filterQuery.base.value : '')
    query += "&region_id=" + (filterQuery.region && filterQuery.region.value ? filterQuery.region.value : '')
    query += "&subregion_id=" + (filterQuery.subregion && filterQuery.subregion.value ? filterQuery.subregion.value : '')
    query += "&centroCostos=" + (filterQuery.centroCostos && filterQuery.centroCostos.value ? filterQuery.centroCostos.value : '')
    if(filterQuery.modeloTipo)
      query += "&modeloTipo=" + filterQuery.modeloTipo.value

		return query
	}

  const handleSelectEvent = (evento) => {
    evento.ticketId && setRedirectTo('/tickets/'+evento.ticketId+'/edit/')
	}
  
  const handleFilterReset = () => {
		setSearchActive(false);
		// setDropdownActive(false);
		setFilterQuery(FILTER_QUERY_INIT);
    setLimpiarFiltros(false);
	};

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}
      <Calendar
        localizer={localizer}
        events={eventos}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100vh"}}
        views= {views}
        components={components}
        eventPropGetter={
          (event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: "transparent",
              color: 'black',
              borderRadius: "0px",
              border: "none"
            };
            return {
              className: "",
              style: newStyle
            };
          }
        }
        onSelectEvent={(event) => handleSelectEvent(event)}
        messages= {msg}
      />
    </>
  )
}

const CustomEvent = ({events}) => (props) => {
  return (
    <div>
      {props.event.icon ? <img style={{height: "25px"}} src={props.event.icon}></img> : null}
      {props.title}
    </div>
  )
}

export default Calendarios
