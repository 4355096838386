import React, { Component } from 'react'
import Loading from '../ui/Loading.js'
import moment from 'moment'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import * as Constants from '../atributoDinamico/constants.js'
import $, { type } from 'jquery'
import axios from 'axios'
import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import { castValoresDinamicos } from '../atributoDinamico/helpers.js'
import { FormattedMessage, injectIntl } from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import AsociarTicket from './AsociarTicket.js'
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js'
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'

const configAxios = {
	headers: {
		'Authorization-Token': localStorage.getItem("token")
	}
};


class FormularioDetail extends Component {
	constructor(props) {
		super(props);
		this.ajaxHandler = new AjaxHandler();

		moment.locale('es');

		this.openModalTicket = this.openModalTicket.bind(this);
		this.handleCloseModalTicket = this.handleCloseModalTicket.bind(this);
		this.redireccionarATickets = this.redireccionarATickets.bind(this);
		this.getTicketURL = this.getTicketURL.bind(this);

		this.state = {
			props: this.props,
			formulario: null,
			valores_dinamicos: [],
			loading: true,
			id_map: 'map',
			id_modal_ticket: 'modal_ticket',
			dialogInformarPago: {
				open: false,
				movil: null,
				valorDinamicoId: null,
			},
			unidadMedidorId: 1
		};

	}
	initForm() {
		this.setState({ loading: true });
		let component = this;
		let id_map = 'map_' + this.state.props.formulario_id
		let id_modal_ticket = 'modal_ticket_' + this.state.props.formulario_id
		Promise.all([
			this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/formulario/' + this.state.props.formulario_id) : null,
			ConfigBusiness.get('direccion.latitud'),
			ConfigBusiness.get('direccion.longitud'),
		]).then((data) => {
			let formulario = data[0];
			let valores_dinamicos = castValoresDinamicos(formulario.tipo_formulario.atributos_dinamicos, formulario.valores_dinamicos, false)
			let latitudesPosibles = [
				formulario.latitudMovil,
				formulario.latitudForm,
				data[1],
				-34.6035814
			]
			let longitudesPosibles = [
				formulario.longitudMovil,
				formulario.longitudForm,
				data[2],
				-58.38157119
			]
			let unidadMedidorId = formulario.movil ? formulario.movil.unidadMedidorId : null;
			component.setState({
				id_map: id_map,
				id_modal_ticket: id_modal_ticket,
				formulario: formulario,
				valores_dinamicos: valores_dinamicos,
				latitudCentro: parseFloat(latitudesPosibles.find(x => !!x)),
				longitudCentro: parseFloat(longitudesPosibles.find(x => !!x)),
				unidadMedidorId: unidadMedidorId,
			}, () => {
				this.initMap();
				this.setState({ loading: false });
			});
		}).catch(error => { console.log(error); })

	}
	componentWillMount() {
		this.ajaxHandler.subscribe(this);
		this.initForm();
	}
	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('.popover2').popover('dispose');
	}

	openImage(adjunto_id) {
		let config = Object.assign({}, configAxios, { responseType: 'blob' });
		axios.get(Config.get('apiUrlBase') + '/adjuntos/files/' + adjunto_id, config).then(response => {
			if (response.status === 200) {
				let fileUrl = URL.createObjectURL(response.data);
				window.open(fileUrl)
			}
		}).catch((e) => { console.log(e) });
	}

	ropTitle(valorDinamico){
		return (
			<div className="popover fade show bs-popover-left" style={{position: "absolute", top: "0%", left: "0px", willChange: "transform"}}>
				<h3 class="popover-header">ROP Generado</h3>
				<div class="popover-body">
					<div>
						<span>
							ROP {this.state.formulario.rop_id} - [<span className={valorDinamico.estado == Constants.ID_ESTADO_APROBACION_INVALIDO ? 'danger' : valorDinamico.estado == Constants.ID_ESTADO_APROBACION_VALIDO ? 'success' : ''}>{valorDinamico.value}</span>]
						</span>
						<a href="" class="btn btn-sm btn-icon btn-dt-grid text-success" title="Editar">
							<i class="fa fa-pencil fa-xs"></i>
						</a>
					</div>
				</div>
			</div>
		);
	}

	openModalTicket(valorDinamico) {
		let component = this
		component.setState({
			dialogInformarPago: {
				open: true,
				movil: this.state.formulario.movil,
				valorDinamicoId: valorDinamico.id,
			}
		});
		$('#' + component.state.id_modal_ticket).modal({ backdrop: 'static' });
		$('#' + component.state.id_modal_ticket).on('hidden.bs.modal', function () {
			component.setState({
				dialogInformarPago: {
					open: false,
					movil: null,
					valorDinamicoId: null,
				}
			});
		});
	}

	handleCloseModalTicket() {
		$('#' + this.state.id_modal_ticket).modal('hide');
		this.setState({
			dialogInformarPago: {
				open: false,
				movil: null,
				valorDinamicoId: null,
			}
		});
	}

	redireccionarATickets() {
		$('#' + this.state.id_modal_ticket).modal('hide');
		$('.modal-backdrop').remove()
		let valorDinamicoId = this.state.dialogInformarPago.valorDinamicoId
		let movil = this.state.dialogInformarPago.movil
		this.setState({
			dialogInformarPago: {
				open: false,
				movil: null,
				valorDinamicoId: null,
			}
		},
			(() => {
				$('.popover2').popover('dispose');
				window.location.href = '/correctivos/add/movil/' + movil.id + '/' + movil.dominio + '?valorDinamico=' + valorDinamicoId;
				//this.props.setRedirect('/correctivos/add/movil/' + movil.id + '/' + movil.dominio + '?valorDinamico=' + valorDinamicoId );
			}))
	}


	initMap() {
		return new Promise((resolve, reject) => {
			let interval = setInterval(() => {
				let mapElement = document.getElementById(this.state.id_map)
				if (window.google && mapElement !== null) {
					var centro = {
						lat: this.state.latitudCentro,
						lng: this.state.longitudCentro
					};
					this.map = new window.google.maps.Map(mapElement, {
						zoom: 10,
						center: centro
					});
					// Place a draggable marker on the map
					if (this.state.formulario && this.state.formulario.latitudMovil) {
						let markerMovil = {
							lat: parseFloat(this.state.formulario.latitudMovil),
							lng: parseFloat(this.state.formulario.longitudMovil),
						};
						let car_image = {
							url: "/images/icons/car.svg",
							size: new window.google.maps.Size(71, 71),
							origin: new window.google.maps.Point(0, 0),
							anchor: new window.google.maps.Point(17, 34),
							scaledSize: new window.google.maps.Size(30, 30)
						};
						this.markerMovil = new window.google.maps.Marker({
							position: markerMovil,
							map: this.map,
							draggable: false,
							title: this.props.intl.formatMessage({ id: 'formularioDetail.initMap.title_posicion_movil', defaultMessage: 'Posición del Móvil' }),
							icon: car_image,
						});
					}
					if (this.state.formulario && this.state.formulario.latitudForm) {
						let markerForm = {
							lat: parseFloat(this.state.formulario.latitudForm),
							lng: parseFloat(this.state.formulario.longitudForm),
						};
						let person_image = {
							url: "/images/icons/checklist.svg",
							size: new window.google.maps.Size(71, 71),
							origin: new window.google.maps.Point(0, 0),
							anchor: new window.google.maps.Point(17, 34),
							scaledSize: new window.google.maps.Size(30, 30)
						};
						this.markerForm = new window.google.maps.Marker({
							position: markerForm,
							map: this.map,
							draggable: false,
							icon: person_image,
							title: "Posición del Formulario"
						});
					}
					clearInterval(interval)
					resolve()
				}
			}, 100);
		});
	}
	getTicketURL(ticket_id) {
		return window.location.origin + '/tickets/' + ticket_id + '/edit'
	}

	render() {
		let state = this.state;
		let formulario = this.state.formulario;
		$('.popover2').popover()

		return (
			<React.Fragment>
				<td colSpan="11" style={{ 'backgroundColor': '#f4f5fa' }} >
					<div style={{ minWidth: "72vw", maxWidth: "72vw" }}>
						<div className="card">
							<div className="card-content">
								<div className="card-body" style={{ 'backgroundColor': '#f4f5fa' }}>
									<div className="row">
										<div className="col-md-6">
											<div className="row">
												<div className="col-md-12">
													<h5 className="card-title card-title-costo-total">
														{this.state.formulario &&
															<span className={
																this.state.formulario.estado_gps == Constants.ID_ESTADO_APROBACION_INVALIDO ? 'danger' :
																	this.state.formulario.estado_gps == Constants.ID_ESTADO_APROBACION_VALIDO ? 'success' : 'grey-color'
															}>
																<i className="font-large-2 pr-1 fa fa-map-marker"></i>
															</span>
														}
														Ubicación
													</h5>
													<div id={this.state.id_map} style={{ height: '400px', margin: 'auto' }} />
												</div>

												{(this.state.formulario && this.state.formulario.estado_gps == Constants.ID_ESTADO_APROBACION_INVALIDO) &&
													<div className="col-md-12 danger">
														<p className="text-truncate">
															Inspección realizada a {Math.ceil(this.state.formulario.metros_avl / 1000)} kilómetros de la posición obtenida del vehículo en el AVL
														</p>

													</div>
												}

												{(this.state.formulario && this.state.formulario.movil_id && !this.state.formulario.latitudMovil) &&
													<div className="col-md-12">
														<p className='text-truncate'>
															{this.props.intl.formatMessage({ id: 'formularioDetail.information_no_se_encontraron_posicion_movil', defaultMessage: 'No se encontraron datos sobre la posición del móvil' })}
														</p>
													</div>
												}
												{(this.state.formulario && !this.state.formulario.latitudForm) &&
													<div className="col-md-12">
														<p className='text-truncate'>
															{this.props.intl.formatMessage({ id: 'formularioDetail.information_no_se_encontraron_posicion_donde_completo_formulario', defaultMessage: 'No se encontraron datos sobre la posición donde se completó el formulario' })}
														</p>

													</div>
												}
											</div>
										</div>
										<div className="col-md-6">
											<h5 className="card-title card-title-costo-total">
												<i className="font-large-2 pr-1 fa fa-check"></i>
												Checklist
											</h5>
											{this.state.valores_dinamicos.map((valorDinamico, index) => (
												<div className="row" style={{ minWidth: "30vw" }} key={index}>
													<span className="col-4 text-truncate">
														{valorDinamico.nombre}:
													</span>
													<div className="col-4">
														{Constants.INSTANCIAS_DICT[valorDinamico.tipoValor].conAdjunto ?
															<span>
																{/* FOR LEGACY VALUES (NUMBER) */}
																{valorDinamico.value && typeof(valorDinamico.value) === 'number' && 
																	<i
																	onClick={event => this.openImage(valorDinamico.value)}
																	className="cursor-pointer pr-1 fa fa-2x fa-paperclip">
																	</i>
																}
																{/* FOR NEW VALUES (ARRAY) */}
																{valorDinamico.value && typeof(valorDinamico.value) !== 'number' && valorDinamico.value.map((e, i) => 
																	<i
																	key={i}
																	onClick={event => this.openImage(e)}
																	className="cursor-pointer pr-1 fa fa-2x fa-paperclip">
																	</i>
																)}
																{/* IF THERE IS NO VALUE */}
																{!valorDinamico.value && this.props.intl.formatMessage({ id: 'formularioDetail.information_no_posee_adjunto', defaultMessage: 'No posee adjunto' })}
															</span>
															:
															<span className={
																valorDinamico.estado == Constants.ID_ESTADO_APROBACION_INVALIDO ? 'danger' :
																	valorDinamico.estado == Constants.ID_ESTADO_APROBACION_VALIDO ? 'success' : ''
															}>
																{valorDinamico.value} {(valorDinamico.tipoValor == Constants.ID_MEDIDOR) && ' ' && (UNIDAD_MEDIDOR[this.state.unidadMedidorId]['unidades'])}
															</span>
														}
													</div>
													<div className="col-4">
														{ (this.state.formulario.movil && valorDinamico.tickets.length) ? (
															<span className={
																(valorDinamico.estado && (valorDinamico.estado == Constants.ID_ESTADO_APROBACION_INVALIDO
																	|| valorDinamico.estado == Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION)) ? 'danger' :
																	(valorDinamico.estado && valorDinamico.estado == Constants.ID_ESTADO_APROBACION_VALIDO) ? 'success' :
																		(valorDinamico.estado && valorDinamico.estado == Constants.ID_ESTADO_APROBACION_PENDIENTE) ? 'warning' : ''
															}>
																<i
																	className="mt-auto pr-1 cursor-pointer fa fa-wrench fa-1_4x popover2"
																	data-toggle="popover"
																	data-html="true"
																	data-placement="right"
																	title={this.props.intl.formatMessage({ id: 'formularioDetail.information_title_ticket_generado', defaultMessage: 'Ticket Generado' })}
																	data-content={valorDinamico.tickets.map(ticket => (
																		'<div><span style="padding: 0.75rem">TKT ' + ticket.id + ' - ' + ticket.servicio_detail.nombre + ' - ' + ticket.estado + '</span>' +
																		'<a href="' + this.getTicketURL(ticket.id) + '" style="font-size: 1rem; padding: 0.75rem" class="btn btn-sm btn-icon btn-dt-grid text-success" title="Editar">' +
																		'<i class="fa fa-pencil fa-xs"></i>' +
																		'</a></div>'
																	)).join(' ')}
																/>
															</span>
															) : (valorDinamico.estado && valorDinamico.estado == Constants.ID_ESTADO_APROBACION_INVALIDO) ? (
																<span>
																	{ConfigBusiness.get('rop.habilitado')==='true' ?
																		(this.state.formulario.rop_id && valorDinamico.genera_rop ?
																			<i
																				className="mt-auto pr-1 cursor-pointer fa fa-wrench fa-1_4x popover2"
																				data-toggle="popover"
																				data-html="true"
																				data-placement="right"
																				title={this.props.intl.formatMessage({ id: 'formularioDetail.information_title_rop_generado', defaultMessage: 'ROP Generado' })}
																				data-content={`<div>
																								<span style="padding: 0.75rem">
																									ROP ` + this.state.formulario.rop_id + ` - [<span class="`+ (valorDinamico.estado == Constants.ID_ESTADO_APROBACION_INVALIDO ? 'danger' : (valorDinamico.estado == Constants.ID_ESTADO_APROBACION_VALIDO ? 'success' : '')) + `">` + valorDinamico.value + `</span>]
																								</span> 
																								<a href="/rop/` + this.state.formulario.rop_id + `" style="font-size: 1rem; padding: 0.75rem" class="btn btn-sm btn-icon btn-dt-grid text-success" title="Editar">
																									<i class="fa fa-pencil fa-xs"></i>
																								</a>
																							</div>`}
																			/>
																		: '')
																		:
																		(this.state.formulario.rop_id ?
																			''
																			:<i onClick={event => this.openModalTicket(valorDinamico)} className="mt-auto pr-1 cursor-pointer fa fa-wrench fa-1_4x"></i>
																		)
																	}
																</span>
															) : ''
														
														}

														{/* MAPPING OF ADJUNTOS*/}
														{valorDinamico.adjuntos && valorDinamico.adjuntos.map((a, i) => <i
															key={i}
															onClick={event => this.openImage(a.adjunto_id)}
															className="mt-auto cursor-pointer pr-1 fa fa-1_4x fa-file-image-o">
														</i>)}

														<span className={!valorDinamico.observaciones ? 'hidden' : ''}>
															<i
																className="mt-auto pr-1 cursor-pointer fa fa-1_4x fa-comment-o popover2"
																data-toggle="hover"
																data-html="true"
																data-placement="right"
																title={this.props.intl.formatMessage({ id: 'formularioDetail.information_title_observaciones', defaultMessage: 'Observaciones' })}
																data-content={valorDinamico.observaciones}
															/>
														</span>

													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal fade" tabIndex="-1" id={this.state.id_modal_ticket} role="dialog" aria-labelledby={this.state.id_modal_ticket + '_label'}>
							<div className="modal-dialog modal-lg" role="document">
								<div className="modal-content">
									<div className="modal-header bg-fleet">
										<h4 className="modal-title text-white" id={'h_' + this.state.id_modal_ticket + '_label'}><i className="ft-info align-middle icon-modal-title"></i>Asociar Ticket</h4>
									</div>
									<div className="modal-body modal-grey modal-mh ovf-visible pagarInfranccion_modal_content">
										{this.state.dialogInformarPago.valorDinamicoId &&
											<AsociarTicket data={this.state.dialogInformarPago} redireccionarATickets={this.redireccionarATickets} callbackClose={event => this.handleCloseModalTicket()} />
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</td>
			</React.Fragment>
		);
	}
}

export default FormularioDetail;
