import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import * as Utils from '../../commons/utils/Utils';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  Radio,
  FormControlLabel,
  RadioGroup
} from '@material-ui/core';
import { StyledTableCell } from '../../commons/utils/TableStyles';
import Timezone from '../../commons/timezone/Timezone.js';
import { StyledTooltip } from '../../commons/utils/TableStyles'
import $ from 'jquery'
import moment from 'moment'

const HistoricoDatosGeneralesGrid = (props) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState('estado');
  const [estados,setEstados] = useState(null);
  const [bases,setBases] = useState(null);
  const [dominios,setDominios] = useState(null);

  const handleChangeRadio = (event) => {
    setRadioValue(event.target.value)
  }

  const handleExport = () => {
    let data = null;
    switch (radioValue) {
      case 'estado':
        data = estados;
        break;
      case 'base':
        data = bases;
        break;
      case 'dominio':
        data = dominios;
        break;
      default:
        break;
    }

    let auxDataToExport = JSON.stringify({
      dataToExport: data,
      entidad: radioValue
    })

    Utils.sendDataBlob('/moviles/exportar-historico-datos-generales','POST',auxDataToExport)
      .then((data) => {
        let fileBlob = new File([data],'historico-datos-generales.xlsx')
        let fileUrl = URL.createObjectURL(fileBlob);
        $("<a />", {
          href: fileUrl,
          download: "historico-datos-generales.xlsx"
        }).appendTo("body").get(0).click();
      })
      .catch(() => {
			})
	}
  
  useEffect(() => {
    setLoading(true)
    Utils.getData(`/moviles/historico-datos-generales-grid/${props.movilId}`)
      .then((res) => {
        setEstados(res.estados)
        setBases(res.bases)
        setDominios(res.dominios)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false);
      });

  },[])

  return (
    <div className="card">
      <div className="card-content">
        <div className="card-body">
          <div className="container-fluid mb-1">
            <div className="row dt-icons">
              <div className="col-md-11">
                <RadioGroup row value={radioValue} onChange={handleChangeRadio}>
                  <FormControlLabel
                    value="estado"
                    label={<FormattedMessage id="movilesAbm.datosGenerales_modal.radio.estado.label" defaultMessage="Estado" />}
                    control={<Radio color="default" />}
                  />
                  <FormControlLabel
                    value="base"
                    label={<FormattedMessage id="movilesAbm.datosGenerales_modal.radio.base.label" defaultMessage="Base" />}
                    control={<Radio color="default" />}
                  />
                  <FormControlLabel
                    value="dominio"
                    label={<FormattedMessage id="moviles.dominio" defaultMessage="Dominio" />}
                    control={<Radio color="default" />}
                  />
                </RadioGroup>
              </div>
              <div className="col-md-1">
                <div style={{marginLeft:"85px"}}>
                  <StyledTooltip
                    title={intl.formatMessage({
                      id: 'movilesAbm.datosGenerales_modal.tools_export.title_exportar_a_excel',
                      defaultMessage: 'Exportar a Excel',
                    })}
                    arrow
                    placement="top"
                  >
                    <button onClick={()=>handleExport()} className="btn" type="button">
                      <span>
                        <i className="ft-upload"></i>
                      </span>
                    </button>
                  </StyledTooltip>
                </div>
              </div>
            </div>
          </div>
          {/* Historico Estados */}
          {radioValue === 'estado' && 
            <TableContainer>
              <Table aria-label="clases table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_dominio.label" defaultMessage="Dominio" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_fecha.label" defaultMessage="Fecha y Hora" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_estado_origen.label" defaultMessage="Estado Origen" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_estado_destino.label" defaultMessage="Estado Destino" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_estado_erp_origen.label" defaultMessage="Estado ERP Origen" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_estado_erp_destino.label" defaultMessage="Estado ERP Destino" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_usuario_persona.label" defaultMessage="Persona Modificación" />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading && (
                  <TableRow>
                    <StyledTableCell colSpan={4} align="center">
                      <FormattedMessage
                        id="Cargando"
                        defaultMessage="Cargando"
                      />
                    </StyledTableCell>
                  </TableRow>
                )}
                {!loading &&
                  (estados && estados.map((estado,key) => (
                      <TableRow key={key}>
                        <StyledTableCell align="left">{estado.dominio}</StyledTableCell>
                        <StyledTableCell align="left">{Timezone.getDateForClient(estado.fecha_hora, null, 'DD-MM-YYYY HH:mm')}</StyledTableCell>
                        <StyledTableCell align="left">{estado.estado_origen}</StyledTableCell>
                        <StyledTableCell align="left">{estado.estado_destino}</StyledTableCell>
                        <StyledTableCell align="left">{estado.estado_erp_origen}</StyledTableCell>
                        <StyledTableCell align="left">{estado.estado_erp_destino}</StyledTableCell>
                        <StyledTableCell align="left">{estado.persona_nya}</StyledTableCell>
                      </TableRow>
                    )
                  ))
                }
                </TableBody>
              </Table>
            </TableContainer>
          }
          {/* Historico Bases */}
          {radioValue === 'base' &&
            <TableContainer>
              <Table aria-label="clases table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_dominio.label" defaultMessage="Dominio" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_fecha.label" defaultMessage="Fecha y Hora" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_base_origen.label" defaultMessage="Base Origen" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_base_destino.label" defaultMessage="Base Destino" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.datosGenerales_modal.column_usuario_persona.label" defaultMessage="Persona Modificación" />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading && (
                  <TableRow>
                    <StyledTableCell colSpan={4} align="center">
                      <FormattedMessage
                        id="Cargando"
                        defaultMessage="Cargando"
                       />
                    </StyledTableCell>
                  </TableRow>
                )}
                {!loading && 
                  (bases && bases.map((base,key) => (
                    <TableRow key={key}>
                      <StyledTableCell align="left">{base.dominio}</StyledTableCell>
                      <StyledTableCell align="left">{Timezone.getDateForClient(base.fecha_hora, null, 'DD-MM-YYYY HH:mm')}</StyledTableCell>
                      <StyledTableCell align="left">{base.base_origen}</StyledTableCell>
                      <StyledTableCell align="left">{base.base_destino}</StyledTableCell>
                      <StyledTableCell align="left">{base.persona_nya}</StyledTableCell>
                    </TableRow>
                    )
                  ))
                }
                </TableBody>
              </Table>
            </TableContainer>
          }
          {/* Historico Dominios */}
          {radioValue === 'dominio' &&
            <TableContainer>
              <Table aria-label="clases table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">
                      <FormattedMessage id="Fecha" defaultMessage="Fecha" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="Vehículo_origen" defaultMessage="Vehículo origen" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="Vehículo_destino" defaultMessage="Vehículo destino" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="Persona_modificación" defaultMessage="Persona modificación" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="Razón_de_cambio" defaultMessage="Razón de cambio" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="Comentario" defaultMessage="Comentario" />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading && (
                  <TableRow>
                    <StyledTableCell colSpan={4} align="center">
                      <FormattedMessage
                        id="Cargando"
                        defaultMessage="Cargando"
                       />
                    </StyledTableCell>
                  </TableRow>
                )}
                {!loading && 
                  (dominios && dominios.map((dominio,key) => (
                    <TableRow key={key}>
                      <StyledTableCell align="left">{dominio.fecha}</StyledTableCell>
                      <StyledTableCell align="left">{dominio.dominio_origen}</StyledTableCell>
                      <StyledTableCell align="left">{dominio.dominio_destino}</StyledTableCell>
                      <StyledTableCell align="left">{dominio.fullname}</StyledTableCell>
                      <StyledTableCell align="left">{dominio.razon}</StyledTableCell>
                      <StyledTableCell align="left">{dominio.comentario}</StyledTableCell>
                    </TableRow>
                    )
                  ))
                }
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
      </div>
    </div>
  )
}
export default HistoricoDatosGeneralesGrid