import React, { useEffect, useState } from 'react';
import Security from '../../commons/security/Security';
import { Redirect } from 'react-router-dom';
import ConfigBusiness from '../../commons/config/ConfigBusiness';
import '../../assets/css/bi-iframe.css';

const BISuite = (props) => {
    
    const [redirecTo, setRedirecTo] = useState(null);
    const [iframeUrl, setIframeUrl] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(false)

    useEffect(() => {
        const checkPermissionsAndUrl = async () => {
            const { pathname } = props.location;
    
            const permissionPath = [
                { permission: 'BI_SUITE_1', path: '/BI_1', urlKey: 'BI_1' },
                { permission: 'BI_SUITE_2', path: '/BI_2', urlKey: 'BI_2' },
                { permission: 'BI_SUITE_3', path: '/BI_3', urlKey: 'BI_3' },
                { permission: 'BI_SUITE_4', path: '/BI_4', urlKey: 'BI_4' },
                { permission: 'BI_SUITE_5', path: '/BI_5', urlKey: 'BI_5' }
              ];
            const havePermission = permissionPath.find(permission => permission.path === pathname);
            const habilitado = ConfigBusiness.get(`menu.BISuite.submenu${havePermission.urlKey}.habilitado`);
    
            if(!havePermission || !Security.hasPermission(havePermission.permission) || habilitado === 'false') {
                setRedirecTo('/error');
                return;
            }
    
            const url = ConfigBusiness.get(`menu.BISuite.submenu${havePermission.urlKey}.url`);
            const isValid = await checkUrl(url);

            if (isValid) {
                setIframeUrl(url);
                setIsValidUrl(true);
            } else {
                setRedirecTo('/biUrlError');
            }
        }

        checkPermissionsAndUrl();

    }, [props.location]);

    async function checkUrl(url) {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    }

    return (
        <div className="iframe-container">
            {redirecTo && <Redirect push to={redirecTo} />}
            {isValidUrl && <iframe
                title="BI SUITE VEC - AR"
                src={iframeUrl}
                allowFullScreen={true}
                className="responsive-iframe">
            </iframe>}
        </div>
    )
}

export default BISuite;