import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'


import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
//import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import $ from 'jquery'
import dtConfig from './PanolGrid.js'

import {
	getAlmacenes,
	getMovAlmacenGrid,
	createAlmacenes,
	updateAlmacenes,
	deleteAlmacenes,
	getProdAlmacenGrid
} from './MetodosPanol.js'

import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class MovimientoStock extends Component {

	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			loading: true,
			grid: null,
			almacenid: null,
			almacenes: [],
			create: null,
			update: null,
			delete: null,
		}
	}
	async componentDidMount() {

    	let component = this;
    	let filters = null;
    	let { id } = this.props.match.params;
    	let idAlmacen = Object.values({id}).toString();
    	this.setState({ almacenid : idAlmacen })
    	console.log("almacen: "+idAlmacen+ ' - '+this.state.almacenid);

    	this.setState({isLoading: true});
		let getGrid = await getProdAlmacenGrid(idAlmacen).then(res=>{

    		let dataR = res.data.items;
	        this.setState({
	            grid: dataR,
	        })
	       	return this.state.grid
	    }).then(result => {
	    		console.log(result);
				this.setState({isLoading: false})
				console.log("termina la  carga: "+ this.state.isLoading);
				this.forceUpdate();
	    });

	    let getAlmacene = await getAlmacenes().then(res=>{

    		let dataR = res.data;
	        this.setState({ almacenes: this.state.almacenes.concat(dataR) });
	        return this.state.almacenes
	    }).then(result => {
	    		console.log(result);
				this.setState({isLoading: false})
				console.log("termina la  carga: "+ this.state.isLoading);
				$("#almacenSelect").val(idAlmacen);
				this.forceUpdate();
	    });

	    const config = {
    	headers: {
    		'Content-Type': 'application/json',
			'Authorization-Token': localStorage.getItem("token")
    	 }
		};
       	let table = $(this.refs.main).DataTable(
       		Object.assign({
				//dom: '<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
				stateSave: true,
				stateSaveCallback: function(settings,data) {
					localStorage.setItem( 'DataTables_MovimientoStockProductos', JSON.stringify(data) );
				},
				stateLoadCallback: function(settings) {
					return JSON.parse( localStorage.getItem( 'DataTables_MovimientoStockProductos' ) );
				},
				ajax: {
					type: 'GET',
					url: Config.get('apiUrlBase') + '/panol/almacenes/grid',

					headers: {
						'Content-Type': 'application/json',
						'Authorization-Token': localStorage.getItem("token")
					},
					dataSrc: function(res){
						return component.state.grid
					},
					error: function (xhr, error, thrown) {
					},
					cache: false
				},
				initComplete: function (settings) {
					let stateColumns = JSON.parse(localStorage.getItem('DataTables_MovimientoStockProductos')).columns;
					var sets = settings;
					var index = 0;
					this.api().columns().every( function () {
						var column = this;
						if(sets.aoColumns[index].filterType){
							if(sets.aoColumns[index].filterType === 'select') {
								var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'movimientoStockProductos.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
									.appendTo( $(column.footer()).empty() )
									.on( 'change', function () {
										var val = $.fn.dataTable.util.escapeRegex($(this).val());
										column
											.search( val ? val : '', true, false )
											.draw();
									});
								if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
									filters[sets.aoColumns[index].name].map(e => {
										select.append('<option value="'+e.value+'">'+e.label+'</option>');
										return true;
									});
								} else {
									column.data().unique().sort().each( function ( d, j ) {
										select.append( '<option value="'+d+'">'+d+'</option>' );
									});
								}
								if(stateColumns[index].search.search) select.val(stateColumns[index].search.search);
							}
							if(sets.aoColumns[index].filterType === 'input') {
								var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'movimientoStockProductos.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
								if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
								input.appendTo($(column.footer()).empty());
								input.on( 'keyup change', function () {
									if ( column.search() !== this.value ) {
										column
										.search( this.value )
										.draw()
										.ajax.reload(null, false);
									}
								});
							}
						}
						index++;
						return '';
					});
					$('tfoot tr').appendTo('thead');
				},
	           	columns: [
	           		{
		            title: component.props.intl.formatMessage({ id: 'movimientoStockProductos.column_id.label_id', defaultMessage: 'id' }),
		            className: "text-center",
		            visible: false,
		            data: "id"
		            },
	           		{
		            title: component.props.intl.formatMessage({ id: 'movimientoStockProductos.column_description.label_descripcion', defaultMessage: 'Descripcion' }),
		            className: "panolRow",
		            data: "item.descripcion",
		            filterType: 'input'
		            },
		            {
		            title: component.props.intl.formatMessage({ id: 'movimientoStockProductos.column_cost.label_costo', defaultMessage: 'costo' }),
		            className: "panolRow",
		            data: "item.costo",
		            filterType: 'input'
		            },
		            {
		            title: component.props.intl.formatMessage({ id: 'movimientoStockProductos.column_unit.label_unidad', defaultMessage: 'unidad' }),
		            className: "panolRow",
		            data: "item.unidad.descripcion",
		            filterType: 'input'
		            },
		            {
		            title: component.props.intl.formatMessage({ id: 'movimientoStockProductos.column_type.label_tipo', defaultMessage: 'Tipo' }),
		            className: "panolRow",
		            data: "item.tipo",
		            filterType: 'input'
		            },
		            {
		            title: component.props.intl.formatMessage({ id: 'movimientoStockProductos.column_part_number.label_numero_de_parte', defaultMessage: 'numero de parte' }),
		            className: "panolRow",
		            data: "item.numeroDeParte",
		            filterType: 'input'
		            },
		            {
		            title: component.props.intl.formatMessage({ id: 'movimientoStockProductos.column_quantity.label_cantidad', defaultMessage: 'cantidad' }),
		            className: "panolRow",
		            data: "cantidad",
		            filterType: 'input'
		            }
		            ],
				sDom: 'lrtip',
				orderCellsTop: true,
	        	fixedHeader: true,
	        	drawCallback: function () {
							$(this).find('.action').on('click', function () {
								let redirectTo;

								if($(this).hasClass('view')) {
									let data = table.row( $(this).parents('tr') ).data();
									let dataId = data.id;
									console.log("redirige a: "+dataId.id + "-" + dataId)
									redirectTo = component.props.match.url + '/stock/' + dataId;
									component.setState({ redirectTo: redirectTo });
									console.log(component.state.redirectTo);
									component.forceUpdate();
								}
								if($(this).hasClass('edit')) {
									let data = table.row( $(this).parents('tr') ).data();
									let dataId = data.id;
									console.log(data);
									$("#editAlmacenesModal #editIdAlmacen").val(data.id);
									$("#editAlmacenesModal #editNombreAlmacen").val(data.nombre);
									$('#editAlmacenesModal').on('shown.bs.modal', function() {
									    $('#editNombreAlmacen').trigger('focus');
									    $("#editNombreAlmacen").keyup(function(e)
										{
										    if (e.which == 13)
										    {
										        $(this).submit();
										    }
										});
									  });
									$("#editAlmacenesModal").modal({show: true});
								}
								if($(this).hasClass('delete')) {
									swal({
									title: component.props.intl.formatMessage({ id: 'movimientoStockProductos.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
									text: null,
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'movimientoStockProductos.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'movimientoStockProductos.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								}).then((isConfirm) => {
									if (isConfirm) {
										let data = table.row( $(this).parents('tr') ).data();
										let dataId = data.id;
										console.log("se eliminara el item: "+ data.descripcion);
										deleteAlmacenes(dataId).then(res => {
											let dataR = res.mensaje;
											swal(component.props.intl.formatMessage({ id: 'movimientoStockProductos.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
											let getGrid2 = getAlmacenes().then(res2=>{
								    		let dataR2 = res2.data;
										        component.setState({
										            grid: dataR2
										        })
											table.ajax.reload();
										    console.log("actualiza grid:");
										    console.log(component.state.grid);
										    })
										})
										.catch(function(error) {
											swal(component.props.intl.formatMessage({ id: 'movimientoStockProductos.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
										});
									}
								}); //fin accion

								}
							});

						},

			}, dtConfig)
		)
			$(document).find("#example thead th").css("height","43px");
			$(document).find("#example thead th").css("padding-left","20px");
			$(document).find("#example tbody td").css("padding-left","20px");
			$('[data-toggle="tooltip"]').tooltip();

			this.setState({ loading: false });

			new $.fn.dataTable.Buttons( table, {
			buttons: [
				{
          extend: 'print',
          text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win)
					{
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						}	else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css( 'font-size', '10pt' )
							.prepend(`<div class="container">
													<div class="row mb-2">
														<div class="col-6">
															<img style="height: 35px" src="`+LogoCompany+`" />
														</div>
														<div class="col-6	text-right">
															<img style="height: 40px" src="`+LogoVecFleet+`" />
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-12" style="border-bottom: 1px solid #000;">
															<h2>`+component.props.intl.formatMessage({ id: 'movimientoStockProductos.print_content.header_listado_de_regiones', defaultMessage: 'Listado de Regiones' })+`</h2>
														</div>
													</div>
												</div>`);

						$(win.document.body).find( 'table' )
							.addClass( 'compact' )
							.css( 'font-size', 'inherit' );
			 		},
          exportOptions: {
              columns: [ 3,4,5,6 ]
          },
					titleAttr: component.props.intl.formatMessage({ id: 'movimientoStockProductos.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
        },
        {
          extend: 'colvis',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'movimientoStockProductos.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
        },
        {
          extend: 'collection',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'movimientoStockProductos.tools_export.title_exportar', defaultMessage: 'Exportar' }),
          buttons: [
          {
            extend: 'copyHtml5',
            exportOptions: {
              columns: ':visible'
            },
						text: component.props.intl.formatMessage({ id: 'movimientoStockProductos.tools_export.select_text_copiar', defaultMessage: 'Copiar' }),
						titleAttr: component.props.intl.formatMessage({ id: 'movimientoStockProductos.tools_export.select_copiar', defaultMessage: 'Copiar' })
          },
          {
            extend: 'excelHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'movimientoStockProductos.tools_export.select_exportar_excel', defaultMessage: 'Exportar a Excel' })
          },
          {
            extend: 'csvHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'movimientoStockProductos.tools_export.select_exportar_formato_csv', defaultMessage: 'Exportar en formato .csv' })
          },
          {
            extend: 'pdfHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'movimientoStockProductos.tools_export.select_exportar_PDF', defaultMessage: 'Exportar a PDF' })
					}
					]
				},
				{
					text: '<i class="ft-download"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'movimientoStockProductos.tools_import.title_importar', defaultMessage: 'Importar' })
        },
			]
		});

		table.buttons( 0, null ).container().appendTo('#buttons');
		/*
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
		*/

		$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
			if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
				$('.dt-button-collection>.dropdown-menu').css('display','block');
		});
    }
    componentWillUnmount(){
       $('.data-table-wrapper')
       .find('table')
       .DataTable()
       .destroy(true);
    }
    shouldComponentUpdate() {
        return true;
    }

    onChange = (e) => {
    	this.props.history.push(`/panol${e.target.value}`);
  	}
  	onAlmacenChange = (e) => {
  		let redirectTo;
  		let almacenSelected = $("#almacenSelect").val();
		redirectTo = '/panol/stock/almacen/'+almacenSelected;
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
		//window.location.reload();
  	}
  	onCuadradoClick = (e) => {
  		let redirectTo;
		redirectTo = '/panol/stock/almacen/'+this.state.almacenid+'/operaciones';
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
  	}
  	onIngresoClick = (e) => {
  		let redirectTo;
		redirectTo = '/panol/stock/ingreso';
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
  	}
  	onEgresoClick = (e) => {
  		let redirectTo;
		redirectTo = '/panol/stock/egreso';
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
  	}
  	onTransferClick = (e) => {
  		let redirectTo;
		redirectTo = '/panol/stock/transferencia';
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
  	}
  	onAjusteClick = (e) => {
  		let redirectTo;
		redirectTo = '/panol/stock/ajuste';
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
  	}
		onModalEditSubmit = (e) => {
		  		e.preventDefault();
		  		let component = this;
		  		let table = $(this.refs.main).DataTable();
		  		updateAlmacenes(e).then(res =>{
					$(this).find('form').trigger('reset');
					$("#editAlmacenesModal").modal("hide");
					swal(component.props.intl.formatMessage({ id: 'movimientoStockProductos.modal_edit.information_modificado', defaultMessage: 'Modificado!' }), "", "success");
					getAlmacenes().then(res2=>{
			    		component.setState({almacenes: res2.data})
							table.ajax.reload();
				  })
				})
		}
	  	onModalNewSubmit = (e) => {
	  		e.preventDefault();
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();


	  		createAlmacenes(e).then(res =>{

				    $("#newAlmacenesModal").find('form').trigger('reset');
				    $("#newAlmacenesModal").modal("hide");

				swal(component.props.intl.formatMessage({ id: 'movimientoStockProductos.modal_add.information_agregado', defaultMessage: 'Agregado!' }), "", "success");

				let getGrid2 = getAlmacenes().then(res2=>{
	    		let dataR2 = res2.data;
			        component.setState({
			            grid: dataR2
			        })
				table.ajax.reload();
			    console.log("actualiza grid:");
			    console.log(component.state.grid);
			    })

			})


	  	}

    render() {
         if (this.state.isLoading) return <div>Cargando...</div>;
	      return (
        	<React.Fragment>
        	{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
    		<div className="row mt-2">
				<div className="col-12">
					<div className="card">

						<div className="card-header">
							<div className="row">
								<div className="col-3">
									<label htmlFor="message-text" className="col-form-label"><FormattedMessage id="movimientoStockProductos.render.label_almacen" defaultMessage="Almacen:"/></label>
									<select id="almacenSelect" className="form-control" onChange={this.onAlmacenChange}>
									  	{this.state.almacenes.map((value) => {
					                       return <option key={value.id} value={value.id}>{value.nombre}</option>
					                    })}
									</select>
								</div>
								<div className="col-3" style={{cursor: 'pointer'}} style={{opacity: '0.4'}}>
									<div className="card bg-gradient-directional-preventivo">
										<div className="card-content">
											<div className="card-body">
												<div className="media d-flex">
													<div className="align-self-center">
														<i className="ft-check-circle text-white font-large-2 float-left"></i>
													</div>
													<div className="media-body text-white text-right">
														<h3 className="text-white"></h3>
														<span><FormattedMessage id="movimientoStockProductos.render.card_productos" defaultMessage="Productos"/></span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-3" style={{cursor: 'pointer'}} onClick={this.onCuadradoClick}>
									<div className="card bg-gradient-directional-correctivo">
										<div className="card-content">
											<div className="card-body">
												<div className="media d-flex">
													<div className="align-self-center">
														<i className="icon-wrench text-white font-large-2 float-left"></i>
													</div>
													<div className="media-body text-white text-right">
														<h3 className="text-white"></h3>
														<span><FormattedMessage id="movimientoStockProductos.render.card_operaciones" defaultMessage="Operaciones"/></span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">


				            </div>
			            </div>
					</div>
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
										<div className="col-6">
											<div
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'movimientoStockProductos.render.button_edit_almacen.title_editar_almacen', defaultMessage: 'Editar almacen' })}
												data-toggle="modal"
												data-target="#editAlmacenesModal"
												>
												<i className="ft-edit"></i>
											</div>
											<div
												className="btn btn-secondary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'movimientoStockProductos.render.button_stock_income.title_ingreso_stock', defaultMessage: 'Ingreso Stock' })}
												onClick={this.onIngresoClick}
												>
												<i className="fas fa-file-import"></i>
											</div>
											<div
												className="btn btn-secondary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'movimientoStockProductos.render.button_stock_egress.title_egreso_stock', defaultMessage: 'Egreso Stock' })}
												onClick={this.onEgresoClick}
												>
												<i className="fas fa-file-export"></i>
											</div>
											<div
												className="btn btn-secondary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'movimientoStockProductos.render.button_transfer.title_transferencia', defaultMessage: 'Transferencia' })}
												onClick={this.onTransferClick}
												>
												<i className="fas fa-exchange-alt"></i>
											</div>
											<div
												className="btn btn-secondary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'movimientoStockProductos.render.button_stock_adjustment.title_ajuste_de_stock', defaultMessage: 'Ajuste de stock' })}
												onClick={this.onAjusteClick}
												>
												<i className="fas fa-edit"></i>
											</div>
										</div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div>
				               	<div>
				               		<table id="example" ref="main" className="nowrap table-hover dataTableMovil" width="100%">
										<tfoot>
											<tr style={{backgroundColor: '#fff'}}>
												<th className=""></th>
												<th className="dt-search-header"><FormattedMessage id="movimientoStockProductos.render.table_column_descripcion" defaultMessage="Descripción"/></th>
												<th className="dt-search-header"><FormattedMessage id="movimientoStockProductos.render.table_column_costo" defaultMessage="Costo"/></th>
												<th className="dt-search-header"><FormattedMessage id="movimientoStockProductos.render.table_column_unidad" defaultMessage="Unidad"/></th>
												<th className="dt-search-header"><FormattedMessage id="movimientoStockProductos.render.table_column_tipo" defaultMessage="Tipo"/></th>
												<th className="dt-search-header"><FormattedMessage id="movimientoStockProductos.render.table_column_numero_de_parte" defaultMessage="numero de parte"/></th>
												<th className="dt-search-header"><FormattedMessage id="movimientoStockProductos.render.table_column_cantidad" defaultMessage="Cantidad"/></th>
											</tr>
										</tfoot>
									</table>
					            </div>

				            </div>
			            </div>
					</div>
				</div>
				{/*---Modal edit Almacen---*/}
				<div className="modal fade" id="editAlmacenesModal" tabIndex="-1" role="dialog" aria-labelledby="editAlmacenesModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="editAlmacenesModalLabel"><FormattedMessage id="movimientoStockProductos.render.modal_edit.header_editar_almacen" defaultMessage="Editar Almacen"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				    	<form onSubmit={this.onModalEditSubmit}>
					      <div className="modal-body">
					        <div className="row">
						        <div className="col-md-12">
						          <div className="form-group">
						          		<input
							            	type="hidden"
							            	className="form-control"
							            	name="id"
							            	id="editIdAlmacen"
														value={this.state.almacenid}
							            />
						           		<label htmlFor="nombreAlmacen" className="col-form-label"><FormattedMessage id="movimientoStockProductos.render.modal_edit.lavel_nombre_de_la_almacen" defaultMessage="Nombre de la Almacen:"/></label>
							            <input
							            	type="text"
							            	className="form-control"
							            	name="nombre"
							            	id="editNombreAlmacen"
							            />
						          </div>
						        </div>
				          		</div>
						      </div>
						      <div className="modal-footer">
						        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="movimientoStockProductos.render.modal_edit.finish_button.cancelar" defaultMessage="Cancelar"/></button>
						        <button type="submit" className="btn btn-primary"><FormattedMessage id="movimientoStockProductos.render.modal_edit.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
						      </div>
				        </form>
				    </div>
				  </div>
				</div>

			</div>
		</React.Fragment>
            );
    }
}

export default injectIntl(MovimientoStock);
