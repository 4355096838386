import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import FacturasGrid from './FacturasGrid.js'
import FacturasAbm from './FacturasAbm.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class Facturas extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      					<h3 className="content-header-title"><i className="la la-file-text ml-1 mr-1 align-middle"></i><FormattedMessage id="facturas.label_administracion_facturas" defaultMessage="Administración de Facturas"/></h3>
      				</div>
				</div>
				<div className="content-body">
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={FacturasGrid} />
						<Route exact path={`${this.props.match.url}/add`} component={(props) => <FacturasAbm {...props} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/:id/edit`} component={(props) => <FacturasAbm {...props} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} component={(props) => <FacturasAbm {...props} action="VIEW" />} />
					</Switch>
				</div>
			</div>
	    );
  	}
}

export default injectIntl(Facturas);