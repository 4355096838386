import React, { Component } from 'react'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import moment from 'moment'
import 'moment/min/locales'
import {FormattedMessage, injectIntl} from 'react-intl';

class FormularioTicketTareas extends Component {
	constructor(props) {
    super(props);
    this.props = props;

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

  }

  componentDidMount() {
    this.ajaxHandler.subscribe(this);
}

//   componentDidUpdate(){
//     this.loadFormData();
//   }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

//   GetTareas() {
//     // let component = this;
//     console.log("movil: "+ this.movilId);
//       this.setState({ loading: true });
//       this.ajaxHandler.getJson('/tareas/movil/'+this.movilId )
//       .then(data => {
//         if(data) this.props.handleTareas(data);
//         // this.setState({
//         //   tareas: data
//         // });
//         // console.log(data);
//     }).finally(() => {
//         this.setState({ loading: false });
//         console.log("tareas");
//         console.log(this.state.tareas);
        
//     });
//   }

	render() {
      return (
        <React.Fragment>
          {this.props.tareas ? (
            <div className="card pull-up form-body">
              <div className="card-content">
                <div className="card-body">
                  <h4 className="form-section">
                    <FormattedMessage id="FormularioTicketTareas.render.modal.header" defaultMessage="Se va a generar un ticket con las siguiente tareas"/>
                  </h4>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table">
                        <thead className="thead-fleet">
                          <tr>
                            <th scope="col"><FormattedMessage id="FormularioTicketTareas.render.modal.table_column_tareas" defaultMessage="Tareas"/></th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.tareas.map((tarea, index)=>{
                          return(
                            <tr key={index}>
                              <td className="align-middle">{tarea.nombre}</td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : ( null )}
        </React.Fragment>
      )
  }
}

export default injectIntl(FormularioTicketTareas);