import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import { withRouter } from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
am4core.useTheme(am4themes_animated);

class AdvancedCardInfo extends Component{   
  
    constructor(props) {
        super(props);

        this.ajaxHandler = new AjaxHandler();

        moment.locale('es');


        this.state = {
            redirectTo: null,
            props: this.props,
            backColor: 'white',
            backColor2: 'white',
            backColorDefault: 'white',
            clicked: false,
            filter: null            
        };

        this.clickedColor = '#e2e2e2';
        this.clickedColor2 = '#e5e5e5';
        this.styleBorder = {
          padding: "6px",
          border: "2px solid #bbbbbb",
          borderRadius: "20px"
        }

        this.styleBorderBody = {          
          padding: "6px",
          border: "2px solid #bbbbbb",
          borderRadius: "20px"
        }

        this.styleBorderBodyDefault = {
          padding: "15px"
        }

        this.styleTextDefault = {
          color: "black"
        }

        this.styleText = {
          color: "black"
        }
    }

    componentDidMount(){      
    }

    handleFilter(filter,filterChild){                                                 
      this.props.onClickAlert(filter,filterChild);      
    }

    render() {
      let values = this.props.values;      
      
      return (
        <div className="card pull-up">    
          <div className="card-content" style={{ backgroundColor: this.props.backColor, borderRadius: ".45rem",cursor:values.cursor }} >
            <div
                style={ this.styleBorderBodyDefault } className="card-body"  onClick={values.cursor == 'pointer' ? this.handleFilter.bind(this,values.filter,null): null}
              >
                <div className="text-right">
                    <h5 className="text-muted text-bold-500"><FormattedMessage {...(backendStrings[values.description] ? backendStrings[values.description] : backendStrings['errors.default'])}/></h5>
                </div>
                <div className="media d-flex">                
                  <div className="align-self-center">
                      <img width={ values.iconSize } height={ values.iconSize } src={require('../../assets/images/'+ values.entity +'/'+ values.image)}/>
                  </div>                
                  <div className="media-body text-right">                    
                      <h4 className="text-bold-400">{!values.value ? 0 : values.value }</h4>
                  </div>                
                </div>
                <div className="text-right">
                    <h6 className="text-muted text-bold-400">{!values.extraValue ? "" : values.extraValue }</h6>
                </div>                
            </div>          
            {
              !!values.details &&
              (
                <div className="card-footer" style={{ backgroundColor: this.props.backColor2, cursor:values.cursor }}>
                  <div className="row align-items-center">
                  {
                      values.details.map((data, index)=>{   
                        return (
                          <div 
                            className="col text-center" 
                            id={data.id} 
                            onClick={values.cursor == 'pointer' ? this.handleFilter.bind(this,values.filter,data.filter): null}
                            style={data.id === this.props.seleccionado ? this.styleBorder : null}
                            key={index}
                          >
                            <div className="media d-flex">
                              <div className="container">
                                <div className="row justify-content-center">
                                  <h5 className="text-bold-500" style={this.props.backColor !== "#fff" ? this.styleText : this.styleTextDefault }>{!data.value ? 0 : data.value }</h5 >
                                </div>
                                <div 
                                  className="row justify-content-center"                                                                     
                                  data-bs-toggle="tooltip" data-bs-placement="down" title={data.tooltip ? data.tooltip : ""}
                                > 
                                  <img width={ data.iconSize } height={ data.iconSize } src={require('../../assets/images/'+ values.entity +'/'+ data.icon)}/>                          
                                </div>                                  
                              </div>  
                            </div>
                          </div>
                        )
                      })
                  }
                  </div>
                </div>
              )
            }

          </div>
        </div>
      );
    }

} export default AdvancedCardInfo;
