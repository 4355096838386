import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
// import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
// import duallistboxConfig from '../../commons/duallistbox/DuallistboxConfig.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
// import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
//import 'react-datepicker/dist/react-datepicker.css'
// import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import swal2 from 'sweetalert2'

class CentrosCostosAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();
    
    moment.locale('es');    		

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        nombre: '',
        observaciones: ''
        
      },
      
      errors: [],
      direcciones:[],
      mercados: [],
      gerencias:[],
      areas: [],
      // direcciones: [
      // { id: 'Adm. y Finanzas', nombre: 'Adm. y Finanzas'},
      // { id: 'Marketing', nombre: 'Marketing'},
      // { id: 'Operaciones', nombre: 'Operaciones'},
      // { id: 'Recursos Humano', nombre: 'Recursos Humano'},
      // { id: 'RSE', nombre: 'RSE'},
      // { id: 'Seguridad Corporativa', nombre: 'Seguridad Corporativa'}
      // ],

      // mercados: [
      // { id: 'Bs As', nombre: 'Bs As'},  
      // { id: 'Corporativo', nombre: 'Corporativo'},
      // { id: 'Norte', nombre: 'Norte'},
      // { id: 'Sur', nombre: 'Sur'}
      // ],
     
      // gerencias: [
      //   { id: 'AMBA Norte', nombre: 'AMBA Norte'}, 
      //   { id: 'AMBA Sur', nombre: 'AMBA Sur'},
      //   { id: 'Azafatas', nombre: 'Azafatas'}, 
      // { id: 'Corporativo', nombre: 'Corporativo'},  
      // { id: 'Cuyo', nombre: 'Cuyo'}, 
      
      // { id: 'Dirección BS AS', nombre: 'Dirección BS AS'},  
      // { id: 'Dirección Norte', nombre: 'Dirección Norte'}, 
      // { id: 'Dirección PMO - FS', nombre: 'Dirección PMO - FS'},  
      // { id: 'Dirección PMO - Vtas', nombre: 'Dirección PMO - Vtas'}, 
      // { id: 'Dirección Sur', nombre: 'Dirección Sur'}, 
      // { id: 'Entrenamiento', nombre: 'Entrenamiento'},  
      // { id: 'F.S.- Sop y Calidad', nombre: 'F.S.- Sop y Calidad'},  
      // { id: 'Facilities', nombre: 'Facilities'},  
      // { id: 'FS-SdC Dealers AMBA', nombre: 'FS-SdC Dealers AMBA'},
      // { id: 'Gerencia Canales', nombre: 'Gerencia Canales'},  
      // { id: 'Litoral', nombre: 'Litoral'},  
      // { id: 'Logistica', nombre: 'Logistica'},
      // { id: 'Mediterraneo', nombre: 'Mediterraneo'},  
      // { id: 'NEA', nombre: 'NEA'},  
      // { id: 'NOA', nombre: 'NOA'},
      // { id: 'Patagonia', nombre: 'Patagonia'},  
      // { id: 'PBA', nombre: 'PBA'},  
      // { id: 'RRHH', nombre: 'RRHH'},
      // { id: 'Training & Auditoria', nombre: 'Training & Auditoria'} ,
      // { id: 'Seguridad Corporativa', nombre: 'Seguridad Corporativa'}
      // ],

      // areas: [  
      //   { id: 'Adm y Finanzas', nombre: 'Adm y Finanzas'}, 
      //   { id: 'Base', nombre: 'Base'},  
      //   { id: 'Canales', nombre: 'Canales'},
      //   { id: 'Comercial', nombre: 'Comercial'},
      //   { id: 'Cross', nombre: 'Cross'}, 
      //   { id: 'Facilities', nombre: 'Facilities'}, 
      //   { id: 'Marketing', nombre: 'Marketing'},   
      //   { id: 'RRHH', nombre: 'RRHH'}, 
      //   { id: 'RSE', nombre: 'RSE'},
      //   { id: 'Seguridad Corporativa', nombre: 'Seguridad Corporativa'}
      // ],
			loading: false
    };
    
    this.handleEstadoChange = this.handleEstadoChange.bind(this);
     
    this.handleNotificacionesActivasChange = this.handleNotificacionesActivasChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
    
    this.handleSubmit = this.handleSubmit.bind(this);
    
   
    this.formValidation = new FormValidation({
			component: this,			
			validators: {
        'formData.nombre': (value) => Validator.notEmpty(value)
        
			}
    });
    
  }

  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}
	componentWillMount() {
    if ((Security.hasPermission('CENTRO_COSTOS_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('CENTRO_COSTOS_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('CENTRO_COSTOS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }
  }
  initForm() {
	
    this.setState({ loading: true });
    let component = this;
    
		Promise.all([
      //this.state.props.action !== 'ADD' ? this.getData('centros-costos', this.state.props.match.params.id) : null  
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/centros-costos/' + this.state.props.match.params.id) : null,
      this.ajaxHandler.getJson('/centros-costos-detail/areas'),
      this.ajaxHandler.getJson('/centros-costos-detail/direcciones'),
      this.ajaxHandler.getJson('/centros-costos-detail/gerencias'),
      this.ajaxHandler.getJson('/centros-costos-detail/mercados'),
		]).then((data) => { 
      let centroCosto = data[0]
      component.setState({
        areas:data[1],
        direcciones:data[2],
        gerencias:data[3],
        mercados:data[4],
			});

			if(centroCosto){
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy['id'] = centroCosto.id
        formDataCopy['nombre'] = centroCosto.nombre
        formDataCopy['observaciones'] = centroCosto.observaciones
        formDataCopy['direccion'] = centroCosto.direccion ? centroCosto.direccion.id : null
        formDataCopy['gerencia'] = centroCosto.gerencia ? centroCosto.gerencia.id : null
        formDataCopy['mercado'] = centroCosto.mercado ? centroCosto.mercado.id : null
        formDataCopy['area'] = centroCosto.area ? centroCosto.area.id : null
        component.setState({
          formData: formDataCopy
        });
      }

    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });
    
	}
  
  getData(service, param) {
    let serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
		return fetch(serviceURL, {
      method: 'GET', 
			headers: {
				'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
      return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  
 

  
  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activo'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }

  

  handleNotificacionesActivasChange(notificacionesActivas) {    
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
		this.setState({
      formData: formDataCopy      
    });    
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }
  
	
  


  handleSelectFormChange(name, object) {
    const value = object === null ? null : object.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy		
		});
  }

 

  handlePaisChange(name, object) {
    Promise.all([
      this.getData('provincias', object.id)
		]).then((data) => {
      let provincias = data[0];
			this.setState({
        provincias: provincias
      });
      this.handleSelectFormChange(name, object);
		}).catch(function(error) {
			// TODO: Manejo de errores
			console.log(error);
    });
  }

  handleAddDetail(detail){
    let ajaxHandler = this.ajaxHandler;
    let dataState;
    switch (detail) {
      case 'direccion':
        dataState = this.state.direcciones
        break;
      case 'mercado':
        dataState = this.state.mercados
        break;
      case 'gerencia':
        dataState = this.state.gerencias
        break;
      case 'area':
        dataState = this.state.areas
      break;
    }
    swal2.fire({
      title: this.props.intl.formatMessage({ id: `centrosCotosAbm.modal_add_${detail}.nombre`, defaultMessage: `Ingrese el nombre de ${detail}` }),
      input: 'text',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return this.props.intl.formatMessage({ id: `centrosCotosAbm.modal_add_${detail}.errors_no_puede_estar_vacio_el_nombre`, defaultMessage: `No puede estar vacío el nombre` });
        }
        for (let i = 0; i < dataState.length; i++) {
          if (dataState[i].nombre.toLowerCase() == value.toLowerCase()) {
            return this.props.intl.formatMessage({ id: `centrosCotosAbm.modal_add_${detail}.errors_${detail}_ya_existe`, defaultMessage: `El nombre ya existe` });
          }
        }
      }
    }).then((result) => {
      if (result.value) {
        ajaxHandler.fetch('/centros-costos-detail/'+detail, {
          method: 'POST',
          body: JSON.stringify({
            nombre: result.value
          }),
        }).then(response => response.json()
        .then(dataDetail => {
          let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
          formDataCopy[detail] = dataDetail ? dataDetail.id : null;
          let dataStateCopy = JSON.parse(JSON.stringify(dataState));
          if (dataDetail) {
            dataStateCopy.push({ id: dataDetail.id, nombre: dataDetail.nombre });
          }
          this.setState({
            formData: formDataCopy,
          });
          switch (detail) {
            case 'direccion':
              this.setState({
                direcciones: dataStateCopy,
              });
              break;
            case 'mercado':
              this.setState({
                mercados: dataStateCopy,
              });
              break;
            case 'gerencia':
              this.setState({
                gerencias: dataStateCopy,
              });
              break;
            case 'area':
              this.setState({
                areas: dataStateCopy,
              });
            break;
          }
        })).catch((error) => {
          let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
          formDataCopy[detail] = null;
          this.setState({
            formData: formDataCopy
          });
        })
      }
    })
  }

	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
    this.ajaxHandler.fetch('/centros-costos' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(response.status !== 400) {
        
        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();
    
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/centrosCostos'
		});
	}

	render() {
    this.formValidation.validate();
		let formData = this.state.formData;
    let validationState = this.formValidation.state;

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}				
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">      
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="centrosCostosAbm.datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="centrosCostosAbm.campos_requeridos" defaultMessage="* campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* Centro de Costos */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                              <FormattedMessage id="centrosCostosAbm.datos_generales.centro_costos" defaultMessage="Centro de Costos *:"/>
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.nombre}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="nombre" name="nombre" placeholder={this.props.intl.formatMessage({ id: 'centrosCostosAbm.datos_generales.centro_costos.placeholder', defaultMessage: 'Centro de Costos' })} value={formData.nombre} onChange={this.handleInputFormChange} />   
                                <div className="help-block text-danger field-message" hidden={validationState.formData.nombre.pristine || validationState.formData.nombre.valid}>{validationState.formData.nombre.message}</div>
                              </div>
                              )}															
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row">
                      {/* Observaciones */}
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="observaciones">
                              <FormattedMessage id="centrosCostosAbm.datos_generales.descripcion" defaultMessage="Descripción:"/>
                            </label>
                            <div className="col-md-8">
                            {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.observaciones}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="observaciones" name="observaciones" placeholder={this.props.intl.formatMessage({ id: 'centrosCostosAbm.datos_generales.descripcion.placeholder', defaultMessage: 'Descripción' })} value={formData.observaciones} onChange={this.handleInputFormChange} />   
                                
                              </div>
                              )}		
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row">
                      {/* Dirección */}
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="direccion">
                              <FormattedMessage id="centrosCostosAbm.datos_generales.direccion" defaultMessage="Dirección:"/>
                            </label>
                            <div className="col-md-9">
                              <div>
                                 <div className="col-md-10" style = {{display: "inline-table", padding: 0 + "px"}}>
                                    <Select
                                      {...this.props.action === 'VIEW' && {disabled:true}}
                                      id="direccion"
                                      name="direccion"
                                      placeholder={this.props.intl.formatMessage({ id: 'centrosCostosAbm.datos_generales.direccion.placeholder', defaultMessage: 'Dirección' })}
                                      options={this.state.direcciones}
                                      valueKey='id'
                                      labelKey='nombre'                                  
                                      value={formData.direccion}
                                      onChange={(e) => this.handleSelectFormChange("direccion", e)}
                                    />
                                  </div>
                                  {this.props.action !== 'VIEW' &&
                                    <div className="col-md-1" style = {{display: "inline-table",  'paddingRight': 0 + "px"}}>
                                      <button type="button" className="btn btn-primary" onClick={()=>this.handleAddDetail('direccion')}>+</button>
                                    </div>
                                  }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row">
                      {/* Mercado */}
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="mercado">
                              <FormattedMessage id="centrosCostosAbm.datos_generales.mercado" defaultMessage="Mercado:"/>
                            </label>
                            <div className="col-md-9">
                              <div>
                                <div className="col-md-10" style = {{display: "inline-table", padding: 0 + "px"}}>
                                  <Select
                                    {...this.props.action === 'VIEW' && {disabled:true}}
                                    id="mercado"
                                    name="mercado"
                                    placeholder={this.props.intl.formatMessage({ id: 'centrosCostosAbm.datos_generales.mercado.placeholder', defaultMessage: 'Mercado' })}
                                    options={this.state.mercados}
                                    valueKey='id'
                                    labelKey='nombre'                                  
                                    value={formData.mercado}
                                    onChange={(e) => this.handleSelectFormChange("mercado", e)}
                                  />
                                </div>
                                {this.props.action !== 'VIEW' &&
                                  <div className="col-md-1" style = {{display: "inline-table",  'paddingRight': 0 + "px"}}>
                                    <button type="button" className="btn btn-primary" onClick={()=>this.handleAddDetail('mercado')}>+</button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row">
                      {/* Gerencia */}
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="gerencia">
                              <FormattedMessage id="centrosCostosAbm.datos_generales.gerencia" defaultMessage="Gerencia:"/>
                            </label>
                            <div className="col-md-9">
                              <div>
                                <div className="col-md-10" style = {{display: "inline-table", padding: 0 + "px"}}>
                                  <Select
                                    {...this.props.action === 'VIEW' && {disabled:true}}
                                    id="gerencia"
                                    name="gerencia"
                                    placeholder={this.props.intl.formatMessage({ id: 'centrosCostosAbm.datos_generales.gerencia.placeholder', defaultMessage: 'Gerencia' })}
                                    options={this.state.gerencias}
                                    valueKey='id'
                                    labelKey='nombre'                                  
                                    value={formData.gerencia}
                                    onChange={(e) => this.handleSelectFormChange("gerencia", e)}
                                  />
                                </div>
                                {this.props.action !== 'VIEW' &&
                                  <div className="col-md-1" style = {{display: "inline-table",  'paddingRight': 0 + "px"}}>
                                    <button type="button" className="btn btn-primary" onClick={()=>this.handleAddDetail('gerencia')}>+</button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row">
                      {/* Area */}
                      <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="area">
                              <FormattedMessage id="centrosCostosAbm.datos_generales.area" defaultMessage="Área:"/>
                            </label>
                            <div className="col-md-9">
                              <div>
                                <div className="col-md-10" style = {{display: "inline-table", padding: 0 + "px"}}>
                                  <Select
                                    {...this.props.action === 'VIEW' && {disabled:true}}
                                    id="area"
                                    name="area"
                                    placeholder={this.props.intl.formatMessage({ id: 'centrosCostosAbm.datos_generales.area.placeholder', defaultMessage: 'Área' })}
                                    options={this.state.areas}
                                    valueKey='id'
                                    labelKey='nombre'                                  
                                    value={formData.area}
                                    onChange={(e) => this.handleSelectFormChange("area", e)}          
                                  />
                                </div>
                                {this.props.action !== 'VIEW' &&
                                  <div className="col-md-1" style = {{display: "inline-table",  'paddingRight': 0 + "px"}}>
                                    <button type="button" className="btn btn-primary" onClick={()=>this.handleAddDetail('area')}>+</button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                                



                      {/*          
                      <div className="row">
                        
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                              Activo:
                            </label>
                            <div className="col-md-9">
                              <Switch
                                onChange={this.handleEstadoChange}
                                checked={formData.activo}
                                options={this.state.estados}
                                id="activo"
                                name="activo"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      */}
                    </div>
                  </div>
                </div>
                



                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">     
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="centrosCostosAbm.button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'centrosCostosAbm.button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'centrosCostosAbm.button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(CentrosCostosAbm);