import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import TipoEquipamientoGrid from './TipoEquipamientoGrid.js'
import TipoEquipamientoAbm from './TipoEquipamientoAbm.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class TipoEquipamiento extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      					<h3 className="content-header-title"><FormattedMessage id="tipoEquipamiento.label.administracion_tipos_equipamiento" defaultMessage="Administración de Tipos de Equipamiento"/></h3>
      				</div>
				</div>
				<div className="content-body">
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={TipoEquipamientoGrid} />
						<Route exact path={`${this.props.match.url}/add`} render={(props) => <TipoEquipamientoAbm {...props} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <TipoEquipamientoAbm {...props} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} render={(props) => <TipoEquipamientoAbm {...props} action="VIEW" />} />
					</Switch>
				</div>
			</div>
	    );
  	}
}

export default injectIntl(TipoEquipamiento);
