import {
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import $ from 'jquery';
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import 'xlsx/dist/xlsx.full.min.js';
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";
import Sort from "../../assets/images/llantas/table/sort.svg";
import Security from '../../commons/security/Security.js';
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles.js';
import * as Utils from '../../commons/utils/Utils.js';
import DivisionesGridFiltros from './DivisionesGridFiltros.jsx';
import DivisionesGridRow from './DivisionesGridRow.jsx';

var pdfMake = require('pdfmake/build/pdfmake.js')
var pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

const PAGINATION_INIT ={
	page: 0,
	count: 0,
	perPage: 10,
}

const FILTER_QUERY_INIT = {
	nombre:"",
	descripcion: '',
}

const SORT_QUERY_INIT = {
	nombre:"",
	id: ""
}

const DivisionesGrid = (props) => {
	const intl = useIntl()

	const [loading, setLoading] = useState(false)
	const [redirectTo, setRedirectTo] = useState(null)
	
	const [searchActive, setSearchActive] = useState(false)
	const [pagination, setPagination] = useState({ ...PAGINATION_INIT })
	const [paginationCount, setPaginationCount] = useState(0);

	const [filterResetInputs,setFilterResetInputs] = useState(false)
	const [filterQuery, setFilterQuery] = useState(null)
	
	const [sortQuery, setSortQuery] = useState({ ...SORT_QUERY_INIT })

	const [dropdownActive, setDropdownActive] = useState(false);
	
	const [divisiones, setDivisiones] = useState([])
	//Security Permissions
	const [permissions,setPermissions] = useState({
		divisionesCrear:false,
		divisionesVisualizar:false,
		divisionesModificar:false,
		divisionesEliminar:false,
	})

	useEffect(() => {
		setLoading(true)
		if (Security.hasPermission("DIVISIONES_LISTAR")) {
			securityPermission()
			filterQuery && fetchDivisiones()

		} else {
			setRedirectTo("/error")
		}
		//componentWillUnmount
		return () => { }
	}, [pagination,filterQuery,sortQuery])

	const securityPermission = () => {
		let aux = {...permissions}
			aux.divisionesCrear = Security.hasPermission('DIVISIONES_CREAR')
			aux.divisionesVisualizar = Security.hasPermission('DIVISIONES_VISUALIZAR')
			aux.divisionesModificar = Security.hasPermission('DIVISIONES_MODIFICAR')
			aux.divisionesEliminar = Security.hasPermission('DIVISIONES_ELIMINAR')
		setPermissions(aux)   
	}

	const fetchDivisiones = () => {
		Promise.all([
			Utils.getData(`/divisiones${getQuery()}`),
		])
		.then((data) => {
			setDivisiones(data[0].divisiones)
			setLoading(false)
			setPaginationCount(data[0].pagination.count)
		})
		.catch((err) => {
			setLoading(false)
		})
	};

	const getQuery = () => {
		//pagination
		let myQuery = "?page=" + pagination.page
		myQuery += "&perPage=" + pagination.perPage

		//filter
		myQuery += '&nombre=' + filterQuery.nombre;
		myQuery += '&descripcion=' + filterQuery.descripcion;

		//sort
		myQuery += '&nombreSort=' + sortQuery.nombre;
		myQuery += '&idSort=' + sortQuery.id;

		return myQuery;
	}

	const handleChangePage = (event, newPage) => {
		setPagination({ ...pagination, page: newPage });
	}

	const handleChangeRowsPerPage = (event) => {
		setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
	}

	const requestSort = (key) => {
		let query = { ...sortQuery }
		let aux = sortQuery[key];
		
		query["nombre"] = "";
		
		pagination.page = 0;
		
		if (!aux) query[key] = "asc";
		else if (aux === "desc") query[key] = "asc";
		else query[key] = "desc";
		setSortQuery(query);
	};

	const handleFilterReset = () => {
		setPagination(PAGINATION_INIT);
		setFilterQuery(FILTER_QUERY_INIT);
		setDropdownActive(false);
		setSortQuery(SORT_QUERY_INIT);
		setSearchActive(false);
		setFilterResetInputs(true)
	}

	const handleFilterChange = () => {
		setPagination(prevState => ({ ...prevState, page: 0 }));
	}        
    
    const handleModalExportOpen = () => {        
        Utils.sendDataBlob(`/divisiones/exportar-excel${getQuery()}`, 'POST')
            .then(data => {
                let fileBlob = new File([data], 'fleet-divisiones.xlsx');
                let fileUrl = URL.createObjectURL(fileBlob);
                $("<a />", {
                    href: fileUrl,
                    download: "fleet-divisiones.xlsx"
                }).appendTo("body").get(0).click();
            }).catch((err) => {
                console.error(err);                
            })            
    };    
      
	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			<div className="card">
				<div className="card-content collpase show">
					<div className="card-body card-dashboard">
						<div className="container-fluid mb-1">
							<div className="row dt-icons">
								<div className="col">
									{permissions.divisionesCrear && 
										<StyledTooltip
											title={intl.formatMessage({
												id: 'Nuevo',
												defaultMessage: 'Nuevo',
											})}
											arrow
											placement="right"
										>
											<div
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												style={{ height: 55 }}
												onClick={() => setRedirectTo(props.match.url + '/add')}
											>
												<i className="ft-plus"></i>
											</div>
										</StyledTooltip>
									}
									<div
										className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
										style={{ height: 52 }}
										onClick={() => setDropdownActive(!dropdownActive)}
									>
										{dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
									</div>
								</div>
								<div className="col">
									{searchActive && (
										<div className="btn-dt-main round-icon">
											<div
												className="text-center"
												style={{
													height: '25px',
													borderRadius: '3px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													paddingLeft: '8px',
													backgroundColor: '#5AAC43',
													color: 'white',
												}}
											>
												<div
													className="text-center"
													style={{
														width: '100%',
														fontSize: '12px',
														fontWeight: '500',
													}}
												>
													{divisiones.length} de {paginationCount} resultados de busqueda
												</div>
												<div className="resultados-busqueda-notif">
													<i
														className="las la-times la-xs cursor-pointer"
														onClick={()=>handleFilterReset()}
													></i>
												</div>
											</div>
										</div>
									)}
								</div>
                                <div className="dt-buttons btn-group">
                                    <StyledTooltip
                                        title={intl.formatMessage({
                                            id: 'formularioGrid.tools_export.title_exportar',
                                            defaultMessage: 'Exportar',
                                        })}
                                        arrow
                                        placement="top"
                                    >
                                        <button
                                            onClick={handleModalExportOpen}
                                            className="btn btn-secondary buttons-collection"
                                            type="button"
                                        >
                                            <span>
                                                <i className="ft-upload"></i>
                                            </span>
                                        </button>
                                    </StyledTooltip>
                                </div>
                            </div>
                        </div>
					
						<DivisionesGridFiltros
							dropdownActive={dropdownActive}
							setDropdownActive={setDropdownActive}
							filterResetInputs={filterResetInputs}
							setFilterResetInputs={setFilterResetInputs}
							filterQuery={filterQuery}
							setFilterQuery={setFilterQuery}
							searchActive={searchActive}
							setSearchActive={setSearchActive}
							pagination={pagination}
							handleFilterChange={handleFilterChange}
						/>
						<TableContainer>
							<Table aria-label="clases table" className="table-hover">
								<TableHead>
									<TableRow>
										<StyledTableCell align="left" onClick={()=>requestSort("id")} style={{ width: "50px"}}>
											<FormattedMessage id="Id" defaultMessage="Id" />
											<img className="ml-1"
												src={sortQuery.id === "" ? Sort : sortQuery.id === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>
										<StyledTableCell align="left" onClick={()=>requestSort("nombre")} style={{ cursor: "pointer" }}>
											<FormattedMessage id="División" defaultMessage="División" />
											<img className="ml-1"
												src={sortQuery.nombre === "" ? Sort : sortQuery.nombre === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>
										<StyledTableCell align="left">
											<FormattedMessage id="Descripción" defaultMessage="Descripción" />
										</StyledTableCell>
										<StyledTableCell align="center" style={{ width: "150px" }}>
											<FormattedMessage id="movilesGrid.rennder.column_acciones.label" defaultMessage="Acciones" />
										</StyledTableCell>
										
									</TableRow>
								</TableHead>
								<TableBody>
									{loading && (
										<TableRow>
											<StyledTableCell colSpan={14} align="center">
												<CircularProgress />
											</StyledTableCell>
										</TableRow>
									)}
									{!loading &&
										(divisiones.length > 0 ?
											divisiones.map((division) => (
											<DivisionesGridRow
												intl={intl}
												permissions={permissions}
												key={'divisionGridRow_' + division.id}
												baseUrl = {props.match.url} 
												division={division}
												fetchDivisiones={fetchDivisiones}
											/>
										))
										:
										<TableRow>
											<StyledTableCell colSpan={14} align="center">
												<FormattedMessage id="movilesGrid.rennder.no_data_table" defaultMessage="No se encontraron resultados..." />
											</StyledTableCell>
										</TableRow>
										)}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 50, 100]}
							component="div"
							count={paginationCount}
							rowsPerPage={pagination.perPage}
							page={pagination.page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={intl.formatMessage({ id: 'Mostrar', defaultMessage: 'Mostrar'})}
							labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default DivisionesGrid