import React from "react";
import { Route, Switch } from "react-router-dom";
import ClasesGrid from "./ClasesGrid";
import ClasesAbm from "./ClasesAbm";
import { FormattedMessage, injectIntl } from "react-intl";

const Clases = (props) => {
  return (
    <div className="content-wrapper">
      <div className="content-header row">
        <div className="content-header-left col-md-6 col-12 mb-5">
          <h3 className="content-header-title ">
            <FormattedMessage
              id="modelos.header_administracion_de_clases"
              defaultMessage="Administración de Clases"
            />
          </h3>
        </div>
      </div>
      <div className="content-body">
        <Switch>
          <Route exact path={`${props.match.url}`} component={ClasesGrid} />
          <Route exact path={`${props.match.url}/add`} render={(props) => <ClasesAbm {...props} action="ADD" />} />
          <Route
            exact
            path={`${props.match.url}/:id/edit`}
            render={(props) => <ClasesAbm {...props} action="EDIT" />}
          />
          <Route path={`${props.match.url}/:id`} render={(props) => <ClasesAbm {...props} action="VIEW" />} />
        </Switch>
      </div>
    </div>
  );
};

export default injectIntl(Clases);
