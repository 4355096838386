import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import Security from '../../commons/security/Security.js'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import $ from 'jquery'

import {
	getPlantillas,
	getPlantilla,
	createPlantillas,
	updatePlantillas,
	deletePlantillas,
	getItemsBySubCat,
	getSubCategoriasByCat,
	getCategorias,
	getMedidas,
	getItems,
	getItemPlantillaGrid,
	itemsPlantillas,
	createItemPlantilla,
	updateItemPlantilla,
	deleteItemPlantilla
	 } from './MetodosPanol.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class PlantillasDetalle extends Component {

	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			loading: true,
			grid: null,
			create: null,
			update: null,
			delete: null,
			iplantillaid: null,
			plantilla: null,
			subcategorias: null,
			categorias: null,
			medidas: [],
			subcategorias: [],
			categorias: [],
			itemSelected: null,
			items:[],
			costoTotal: 0
		}
	}
	async componentDidMount() {

    	let component = this;
    	let { id } = this.props.match.params;
    	let idPlantilla = Object.values({id}).toString();
    	this.setState({ plantillaid : idPlantilla })


		let getGrid = await getPlantilla(idPlantilla).then(res=>{
    		let dataR = res.data;
	        this.setState({
	            plantilla: dataR
	        })
	       	return console.log(this.state.plantilla)
	    }).then(res=> {
	    	this.setState({ grid: this.state.plantilla.items });
	    	$("#datosPlantilla #viewIdPlantilla").val(this.state.plantilla.id);
			$("#datosPlantilla #viewNombrePlantilla").val(this.state.plantilla.descripcion);
			$("#datosPlantilla #viewCodigoPlantilla").val(this.state.plantilla.codigo);
			$("#datosPlantilla #viewEstadoPlantilla").val(this.state.plantilla.estado);
			this.forceUpdate();
	    });

	    //plantilla tiee items tengo que mostrar una grilal con solamente los items

	    const fetchLoad = async (idPlantilla) => {

			this.setState({isLoading: true});
			console.log("empieza carga: "+ this.state.isLoading);
			//let idPlantilla = this.state.plantillaid;
			Promise.all([
						await getItemPlantillaGrid(this.state.plantillaid).then(res=>{
			   				let appendState = res.data;
			          this.setState({ grid: appendState });
			       		return this.state.grid
			    	}),
			      await getCategorias().then(res=>{
								if (res.data[0]) {
										getSubCategoriasByCat(res.data[0].id).then(res=>{
												if (res.data[0]) {
														getItemsBySubCat(res.data[0].id).then(res=>{
																let appendState = res.data;
																this.setState({ itemSelected: appendState[0]})
																this.setState({ items: appendState });
																return this.state.items
														})
												}
												let appendState = res.data;
												this.setState({ subcategorias: appendState });
												return this.state.subcategorias
										})
								}
			   				let appendState = res.data;
			          this.setState({ categorias: appendState });
			       		return this.state.categorias
			    	}),
						await getMedidas().then(res=>{
		   					let appendState = res.data;
		          	this.setState({ medidas: appendState });
		       			return this.state.medidas
			    	}),
			])
			.then(allResults => {
				console.log(allResults);
				this.setState({isLoading: false})
				console.log("termina la  carga: "+ this.state.isLoading);

				this.forceUpdate();
			})
			.catch(err => console.log(err))
		}
		fetchLoad();





	    const config = {
    	headers: {
    		'Content-Type': 'application/json',
			'Authorization-Token': localStorage.getItem("token")
    	 }
		};
       	let table = $(this.refs.main).DataTable({

           	dom: '<"data-table-wrapper"t>',
           	//data: this.state.grid,
           	select: true,
           	lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "Todos"]],
           	ajax: {
					type: 'GET',
					url: Config.get('apiUrlBase') + '/panol/plantillas/'+component.state.plantillaid+'/items',

					headers: {
						'Content-Type': 'application/json',
						'Authorization-Token': localStorage.getItem("token")
					},
					dataSrc: function(res){
						return component.state.grid
					},
					error: function (xhr, error, thrown) {
					},
					cache: false
				},

           	columns: [
           		{
	            title: component.props.intl.formatMessage({ id: 'plantillasDetalle.column_id.label_id', defaultMessage: 'id' }),
	            className: "text-center",
	            visible: false,
	            data: "item.id"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'plantillasDetalle.column_quantity.label_cantidad', defaultMessage: 'Cantidad' }),
	            className: "text-center",
	            data: "cantidad"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'plantillasDetalle.column_description.label_descripcion', defaultMessage: 'Descripcion' }),
	            className: "text-center",
	            data: "item.descripcion"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'plantillasDetalle.column_idSubCat.label_idSubCat', defaultMessage: 'idSubCat' }),
	            className: "text-center",
	            visible: false,
	            data: "item.subcategoria.id"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'plantillasDetalle.column_idCat.label_idCat', defaultMessage: 'idCat' }),
	            className: "text-center",
	            visible: false,
	            data: "item.subcategoria.categoria.id"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'plantillasDetalle.column_subcategory.label_subcategoria', defaultMessage: 'id' }),
	            className: "text-center",
	            data: "item.subcategoria.nombre"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'plantillasDetalle.column_unit.label_unidad', defaultMessage: 'Unidad' }),
	            className: "text-center",
	            data: "unidad.descripcion"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'plantillasDetalle.column_type.label_tipo', defaultMessage: 'Tipo' }),
	            className: "text-center",
	            data: "item.tipo"
	            },
	             {
	            title: component.props.intl.formatMessage({ id: 'plantillasDetalle.column_cost.label_Costo', defaultMessage: 'Costo' }),
	            className: "text-center columnCosto",
	            data: "item.costo"
	            },
	            {
				orderable: false,
				data: null,
				className: "text-center",
				width: '120px',
	            render: function () {
					let html =
						(Security.hasPermission('PANOL_PLANTILLAS_DETALLE_ELIMINAR') ? `
						<button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'plantillasDetalle.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`">
							<i class="fa fa-trash fa-xs"></i>
						</button>` : '');
                        return html;
				}
        	}],
			orderCellsTop: true,
        	fixedHeader: true,
        	initComplete: function (settings, json) {
		                let costoTotal = component.state.costoTotal;
						let getCost = $(".dataTable td.columnCosto").each(function(){
							let costoUnitario = $(this).text();
							costoUnitario = parseInt(costoUnitario);
							costoTotal = costoTotal +  costoUnitario;
						})
						console.log("calcula costo total:");
						console.log(costoTotal);
						component.setState({ costoTotal: costoTotal});
		            },
        	drawCallback: function () {
						$(this).find('.action').on('click', function () {

							if($(this).hasClass('edit')) {
								let data = table.row( $(this).parents('tr') ).data();
								let dataId = data.id;
								console.log(data);
								$("#editItemModal #editIdItem").val(data.item.id);
								$("#editPlantillasModal #editCatItem").val(data.item.subcategoria.categoria.id);
								$("#editItemModal #editSubCatItem").val(data.item.subcategoria.id);
								$("#editItemModal #editCantItem").val(data.cantidad);
								$("#editItemModal #editUnidadItem").val(data.item.unidad.id);
								$('#editItemModal').on('shown.bs.modal', function() {
								    $('#editCantItem').trigger('focus');
								    $("#editItemModal").keyup(function(e)
									{
									    if (e.which == 13)
									    {
									        $(this).submit();
									    }
									});
								  });
								$("#editItemModal").modal({show: true});
							}
							if($(this).hasClass('delete')) {
								swal({
								title: component.props.intl.formatMessage({ id: 'plantillasDetalle.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
								text: null,
								icon: "warning",
								buttons: {
									confirm: {
										text: component.props.intl.formatMessage({ id: 'plantillasDetalle.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
										value: true,
										visible: true,
										className: "btn btn-primary",
										closeModal: false
									},
									cancel: {
										text: component.props.intl.formatMessage({ id: 'plantillasDetalle.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
										value: null,
										visible: true,
										className: "btn btn-danger",
										closeModal: true,
									}
								}
							}).then((isConfirm) => {
								if (isConfirm) {
									let data = table.row( $(this).parents('tr') ).data();
									let dataId = data.id;
									console.log("se eliminara el item: "+ data.descripcion);
									deleteItemPlantilla(dataId, idPlantilla).then(res => {
										let dataR = res.mensaje;
										swal(component.props.intl.formatMessage({ id: 'plantillasDetalle.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
										let getGrid2 = getItemPlantillaGrid(idPlantilla).then(res2=>{
							    		let dataR2 = res2.data;
									        component.setState({
									            grid: dataR2
									        })
										table.ajax.reload();
									    console.log("actualiza grid:");
									    console.log(component.state.grid);
									    })
									})
									.catch(function(error) {
										swal(component.props.intl.formatMessage({ id: 'plantillasDetalle.modal_delete.information_eliminado.error', defaultMessage: 'Error' }), "", "error");
									});
								}
							}); //fin accion

							}
						});

					},

		}, datatablesConfig(this.props.intl))
		$(document).find("#example thead th").css("height","43px");
		$(document).find("#example thead th").css("padding-left","20px");
		$(document).find("#example tbody td").css("padding-left","20px");
		$('[data-toggle="tooltip"]').tooltip();
		this.setState({ loading: false });

		/*
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
		*/
    }
	componentWillUnmount(){
		/*
       $('.data-table-wrapper')
       .find('table')
       .DataTable()
       .destroy(true);
       */
    }
    /*
    shouldComponentUpdate() {
        return false;
    }
    */
	onChange = (e) => {
    	this.props.history.push(`/panol/config${e.target.value}`);
  }

  	onCatChange = (e) => {
  		let catId = e.target.value;
  		console.log("categoria id: "+catId);
  		getSubCategoriasByCat(catId).then(res=>{
					getItemsBySubCat(res.data[0].id).then(res=>{
							let appendState = res.data;
							this.setState({ items: appendState });
							return this.state.items
					})
					let appendState = res.data;
					this.setState({ subcategorias: appendState });
					console.log(this.state.subcategorias);

					return this.state.subcategorias
    	});
    	this.forceUpdate();
  	}
  	onSubCatChange = (e) => {
  		let subCatId = e.target.value;
  		console.log("Sub-categoria id: "+subCatId);
  		getItemsBySubCat(subCatId).then(res=>{
   				let appendState = res.data;
							this.setState({ itemSelected: appendState[0] });
            	this.setState({ items: appendState });
            	console.log(this.state.items);

       			return this.state.items
    	});
    	this.forceUpdate();
  	}

		onItemChange = (e) => {
			let itemId = e.target.value;
			for (let item of this.state.items) {
				if (item.id == itemId) {
					this.setState({ itemSelected: item });
					console.log(item);

				}
			}
		}
		filtrarMedidas = () => {
			let medidas = this.state.medidas
			if (this.state.itemSelected) {
				medidas = medidas
					.filter(medida => {
						return medida.tipo.id === this.state.itemSelected.unidad.tipo.id
					})
			}
			return medidas.map((medida) => {
				return <option key={medida.id} value={medida.id}>{medida.descripcion}</option>
			})
		}

  	/*
  	costoTotal = () => {
		let costoTotal = component.state.costoTotal;
		let getCost = $(".dataTable td.columnCosto").each(function(){
			let costoUnitario = $(this).text();
			costoUnitario = parseInt(costoUnitario);
			costoTotal = costoTotal +  costoUnitario;
		})
		console.log(costoTotal);
		component.setState({ costoTotal: costoTotal});
  	}
*/
	onModalEditSubmit = (e) => {
	  		e.preventDefault();
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();
	  		let plantilla = this.state.plantilla;
	  		updateItemPlantilla(e, plantilla).then(res =>{

				$(this).find('form').trigger('reset');
				$("#editPlantillasModal").modal("hide");
				swal(component.props.intl.formatMessage({ id: 'plantillasDetalle.modal_edit.information_modificado', defaultMessage: 'Modificado!' }), "", "success");
				let getGrid2 = getPlantillas().then(res2=>{
	    		let dataR2 = res2.data;
			    component.setState({
			            grid: dataR2
			    })
				table.ajax.reload();
			    console.log("actualiza grid:");
			    console.log(component.state.grid);
			    })
			})
	  	}
	  	onModalNewSubmit = (e) => {
	  		e.preventDefault();
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();
	  		let plantilla = this.state.plantilla;
	  		createItemPlantilla(e, plantilla).then(res =>{

				    $(this).find('form').trigger('reset');
				    $("#newPlantillasModal").modal("hide");

				swal(component.props.intl.formatMessage({ id: 'plantillasDetalle.modal_add.information_agregado', defaultMessage: 'Agregado!' }), "", "success");

				let getGrid2 = getItemPlantillaGrid(plantilla.id).then(res2=>{
	    		let dataR2 = res2.data;
			        component.setState({
			            grid: dataR2
			        })
				table.ajax.reload( function ( json ) {
				    let costoTotal = component.state.costoTotal;
					let getCost = $(".dataTable").find("td.columnCosto:last-child").each(function(){
						let costoUnitario = $(this).text();
						costoUnitario = parseInt(costoUnitario);
						costoTotal = costoTotal +  costoUnitario;
					})
					console.log(costoTotal);
					component.setState({ costoTotal: costoTotal});
				} );
			    console.log("actualiza grid:");
			    console.log(component.state.grid);
			    })
			    //this.costoTotal();
			})

	  	}

    render() {
        return (
        	<React.Fragment>
        	{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
    		<div className="row mt-2">
				<div className="col-12">
				{/*ABM Plantillas*/}
					<div className="card">
						<div className="card-header">
							<h1><FormattedMessage id="plantillasDetalle.template_detail.header_detalle_de_plantilla" defaultMessage="Detalle de Plantilla"/></h1>
						</div>
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div id="datosPlantilla" className="row">
							        <div className="col-md-12">
							          <div className="form-group">
							          		<input
								            	type="hidden"
								            	className="form-control-sm"
								            	name="id"
								            	id="viewIdPlantilla"
								            />
							           		<label htmlFor="nombrePlantilla" className="col-form-label"><FormattedMessage id="plantillasDetalle.template_detail.label_nombre_de_plantilla" defaultMessage="Nombre de Plantilla:"/></label>
								            <input
								            	type="text"
								            	className="form-control"
								            	name="nombre"
								            	id="viewNombrePlantilla"
								            	disabled
								            />
							          </div>
							        </div>
							        <div className="col-md-4">
							          <div className="form-group">
							           		<label htmlFor="estadoPlantilla" className="col-form-label"><FormattedMessage id="plantillasDetalle.template_detail.label_estado" defaultMessage="Estado:"/></label>
								            <input
								            	type="text"
								            	className="form-control"
								            	name="nombre"
								            	id="viewEstadoPlantilla"
								            	disabled
								            />
							          </div>
							        </div>
							        <div className="col-md-4">
							          <div className="form-group">
							           		<label htmlFor="codigoPlantilla" className="col-form-label"><FormattedMessage id="plantillasDetalle.template_detail.label_codigo" defaultMessage="codigo"/></label>
								            <input
								            	type="text"
								            	className="form-control"
								            	name="nombre"
								            	id="viewCodigoPlantilla"
								            	disabled
								            />
							          </div>
							        </div>
							        <div className="col-md-4">
							          <div className="form-group">
							           		<label htmlFor="totalPlantilla" className="col-form-label"><FormattedMessage id="plantillasDetalle.template_detail.label_total" defaultMessage="Total:"/></label>
								            <input
								            	type="text"
								            	className="form-control"
								            	name="nombre"
								            	id="viewTotalPlantilla"
								            	disabled
								            	value={this.state.costoTotal}
								            />
							          </div>
							        </div>
				          		</div>
						      </div>
				            </div>
					</div>
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
										<div className="col-6">
                                        {Security.renderIfHasPermission('PANOL_PLANTILLAS_DETALLE_CREAR', (
											<div
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'plantillasDetalle.render.button_add_item.title_agregar_item', defaultMessage: 'Agregar Item' })}
												data-toggle="modal"
												data-target="#newPlantillasModal"
												>
												<i className="ft-plus"></i>
											</div>))}

										</div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div>
				               	<div>
				               		<table id="example" ref="main" className="nowrap table-hover dataTableMovil" width="100%">
									</table>
					            </div>

				            </div>
			            </div>
					</div>
				</div>
			{/*---Modal Agregar Item---*/}
				<div className="modal fade" id="newPlantillasModal" tabIndex="-1" role="dialog" aria-labelledby="newPlantillasModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="newPlantillasModalLabel"><FormattedMessage id="plantillasDetalle.render.modal_add_item.header_agregar_item" defaultMessage="Agregar Item"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <form onSubmit={this.onModalNewSubmit}>
						    <div className="modal-body">
						        <div className="row">
							        <div className="col-md-6">
							          	<div className="form-group">
								           	<label htmlFor="estadoPlantilla" className="col-form-label"><FormattedMessage id="plantillasDetalle.render.modal_add_item.label_categoria" defaultMessage="Categoria:"/></label>
								           <select className="form-control" id="newCatItem" name="categoria" onChange={this.onCatChange}>
												  {this.state.categorias.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
							          	</div>
							          </div>
							          <div className="col-md-6">
							          	<div className="form-group">
								           	<label htmlFor="estadoPlantilla" className="col-form-label"><FormattedMessage id="plantillasDetalle.render.modal_add_item.label_subcategoria" defaultMessage="Subcategoria:"/></label>
								            <select className="form-control" id="newSubCatItem" name="subcategoria" onChange={this.onSubCatChange}>
												  	{this.state.subcategorias.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
							          	</div>
							          </div>
							          <div className="col-md-6">
							          	<div className="form-group">
								           	<label htmlFor="estadoPlantilla" className="col-form-label"><FormattedMessage id="plantillasDetalle.render.modal_add_item.label_item" defaultMessage="Item:"/></label>
														<select className="form-control" id="newItem" name="item" required onChange={this.onItemChange}>
																{this.state.items.map((value) => {
																	 return <option key={value.id} value={value.id}>{value.descripcion}</option>
																})}
														</select>
							          	</div>
							          </div>
												<div className="col-md-3">
								          	<div className="form-group">
									            	<label htmlFor="estadoPlantilla" className="col-form-label"><FormattedMessage id="plantillasDetalle.render.modal_add_item.label_unidad" defaultMessage="Unidad:"/></label>
																<select className="form-control" id="newMedidaItem" name="unidad" required>
														  				{this.filtrarMedidas()}
																</select>
								          	</div>
								        </div>
							           <div className="col-md-3">
							          	<div className="form-group">
								           		<label htmlFor="newCantidadItem" className="col-form-label"><FormattedMessage id="plantillasDetalle.render.modal_add_item.label_cantidad" defaultMessage="Cantidad:"/></label>
								            	<input
																min="1"
									            	type="number"
									            	className="form-control"
									            	name="cantidad"
									            	id="newCantidadItem"
									            	placeholder={this.props.intl.formatMessage({ id: 'plantillasDetalle.render.modal_add_item.placeholder_cantidad', defaultMessage: 'Ej: 23' })}
									            />
							          	</div>
							          </div>

							        </div>
					          	</div>
					      <div className="modal-footer">
					        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="plantillasDetalle.render.modal_add_item.finish_button.cancelar" defaultMessage="Cancelar"/></button>
					        <button type="submit" className="btn btn-primary"><FormattedMessage id="plantillasDetalle.render.modal_add_item.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
					      </div>
				      </form>
				    </div>
				  </div>
				</div>


			</div>
		</React.Fragment>
            );
    }
}

export default injectIntl(PlantillasDetalle);
