import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import KmMovilGrid from './KmMovilGrid'
import {FormattedMessage, injectIntl} from 'react-intl';

class KmMovil extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
						<h3 className="content-header-title"><FormattedMessage id="kmMovil.header_medidor_por_movil" defaultMessage="Medidor por Móvil"/></h3>
					</div>
				</div>
				<div className="content-body">
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={KmMovilGrid} />
					</Switch>
				</div>
			</div>
	    );
  	}
}

export default KmMovil;