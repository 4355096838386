import React, { useState, useEffect} from "react";
import { Redirect } from 'react-router-dom'
import {
	TableRow,
} from '@material-ui/core'
import 'moment/min/locales'
import Swal from 'sweetalert2'
import * as Utils from '../../commons/utils/Utils'
import backendStrings from '../../lang/backendStrings.js';
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'

const DivisionesGridRow = (props) => {
	const intl = props.intl
	const division = props.division;
	const permissions = props.permissions;
	const baseUrl = props.baseUrl;

	const [redirectTo, setRedirectTo] = useState(null)

	useEffect(()=>{
	},[]);

	const getEstado = (activo) => {
		if (activo === 1) {
		  	return "status-green";
		} else if  (activo === 0){
			return "status-red";
		} else {
		  	return "";
		}
	};

	const eliminarDivision = () => {
		Swal.fire({
			title: intl.formatMessage({ id: '¿Confirma la eliminación?', defaultMessage: '¿Confirma la eliminación?' }),
			html: intl.formatMessage({ id: 'Se_eliminará_la_división', defaultMessage: 'Se eliminará la división' }) + ': ' + "<b>" + division.nombre + "</b>",
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: intl.formatMessage({ id: 'Si', defaultMessage: 'Si' }),
			cancelButtonText: intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
		}).then((result) => {
			if (result.value) {
				Utils.deleteData('/divisiones/' + division.id)
				.then(() => {
					props.fetchDivisiones();
					Swal(intl.formatMessage({ id: 'Eliminado!', defaultMessage: 'Eliminado!' }), "", "success");
				})
				.catch((err) => {
					Swal(
						intl.formatMessage(backendStrings[err.response.data.detalle[0]] ? 
							backendStrings[err.response.data.detalle[0]] 
							: backendStrings['No_se_pudo_eliminar_la_división_tiene_moviles_asignados']
						),
						'',
						'error',
					);
				});
			}
		})
	};
	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			<TableRow>
				<StyledTableCell align="left">
					{division.id}
				</StyledTableCell>
				<StyledTableCell align="left">
					{division.nombre}
				</StyledTableCell>
				<StyledTableCell align="left">
					{division.descripcion ? (division.descripcion.length < 60 ? division.descripcion : (division.descripcion.slice(0,60) + ' ...')) : ''}
				</StyledTableCell>
				<StyledTableCell>
					<div className="d-inline-flex">
						{permissions.divisionesVisualizar &&
							<button
								className="action view btn btn-sm btn-icon btn-dt-grid text-success"
								title="Ver"
								data-togle="tooltip"
								data-placement="top"
								onClick={() => setRedirectTo(baseUrl + '/' + division.id)}
							>
								<i className="fa fa-search fa-xs"/>
							</button>
						}
						{permissions.divisionesModificar &&
							<button
								className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
								title="Editar"
								data-togle="tooltip"
								data-placement="top"
								onClick={()=> setRedirectTo(baseUrl +'/'+ division.id +'/edit')}
								>
									<i className="fa fa-pencil fa-xs"/>
							</button>
						}
						{permissions.divisionesEliminar &&
							<button
								className="action delete btn btn-sm btn-icon btn-dt-grid text-danger"
								title="Eliminar"
								data-togle="tooltip"
								data-placement="top"
								onClick={()=> eliminarDivision()}
								>
									<i className="fa fa-trash fa-xs"/>
							</button>
						}
					</div>
				</StyledTableCell>
			</TableRow>
		</>
	)
}

export default DivisionesGridRow
