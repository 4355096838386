// Traducciones Orden de Reparacion

/**
 * Obtiene las traducciones para los elementos de una orden de reparación.
 * @param {Object} intl - Objeto de internacionalización con métodos para formatear mensajes.
 * @returns {Object} Un objeto con las traducciones para los elementos de una orden de reparación.
 */
export const obtenerTraducciones = (intl) => ({
    orden_reparacion: intl.formatMessage({ id: 'tickets.imprimir.ordenDeReparacion', defaultMessage: 'ORDEN DE REPARACION' }),
    or_num: intl.formatMessage({ id: 'tickets.imprimir.or_num', defaultMessage: 'O.R. Nº' }),
    fecha_ingreso: intl.formatMessage({ id: 'tickets.imprimir.fecha_ingreso', defaultMessage: 'FECHA DE INGRESO' }),
    marca: intl.formatMessage({ id: 'tickets.imprimir.marca', defaultMessage: 'MARCA:' }),
    modelo: intl.formatMessage({ id: 'tickets.imprimir.modelo', defaultMessage: 'MODELO:' }),
    servicio: intl.formatMessage({ id: 'tickets.imprimir.servicio', defaultMessage: 'SERVICIO:' }),
    anio: intl.formatMessage({ id: 'tickets.imprimir.anio', defaultMessage: 'AÑO:' }),
    ubicacion: intl.formatMessage({ id: 'tickets.imprimir.ubicacion', defaultMessage: 'UBICACION:' }),
    kilometraje: intl.formatMessage({ id: 'tickets.imprimir.kilometraje', defaultMessage: 'KILOMETRAJE:' }),
    proveedor: intl.formatMessage({ id: 'tickets.imprimir.proveedor', defaultMessage: 'PROVEEDOR:' }),
    fecha_estimada_entrega: intl.formatMessage({ id: 'tickets.imprimir.fecha_estimada_entrega', defaultMessage: 'FECHA ESTIMADA DE ENTREGA:' }),
    contacto: intl.formatMessage({ id: 'tickets.imprimir.contacto', defaultMessage: 'CONTACTO:' }),
    telefono: intl.formatMessage({ id: 'tickets.imprimir.telefono', defaultMessage: 'TELÉFONO:' }),
    direccion: intl.formatMessage({ id: 'tickets.imprimir.direccion', defaultMessage: 'DIRECCION:' }),
    repuestos: intl.formatMessage({ id: 'tickets.imprimir.repuestos', defaultMessage: 'REPUESTOS' }),
    tareas: intl.formatMessage({ id: 'tickets.imprimir.tareas', defaultMessage: 'TAREAS' }),
    codigo: intl.formatMessage({ id: 'tickets.imprimir.codigo', defaultMessage: 'CÓDIGO' }),
    descripcion: intl.formatMessage({ id: 'tickets.imprimir.descripcion', defaultMessage: 'DESCRIPCIÓN' }),
    cuenta_mayor: intl.formatMessage({ id: 'tickets.imprimir.cuenta_mayor', defaultMessage: 'CUENTA MAYOR' }),
    cantidad: intl.formatMessage({ id: 'tickets.imprimir.cantidad', defaultMessage: 'CANTIDAD' }),
    precio: intl.formatMessage({ id: 'tickets.imprimir.precio', defaultMessage: 'PRECIO' }),
    total: intl.formatMessage({ id: 'tickets.imprimir.total', defaultMessage: 'TOTAL' }),
    reparaciones_realizadas: intl.formatMessage({ id: 'tickets.imprimir.reparaciones_realizadas', defaultMessage: 'REPARACIONES QUE SE HICIERON EN EL TALLER ASIGNADO...:' }),
    cant_combustible: intl.formatMessage({ id: 'tickets.imprimir.cant_combustible', defaultMessage: 'CANT.COMBUSTIBLE:' }), 
    patente: intl.formatMessage({ id: 'tickets.imprimir.patente', defaultMessage: 'Patente:' }),
    dominio: intl.formatMessage({ id: 'tickets.imprimir.dominio', defaultMessage: 'Dominio:' }),
    detalles: intl.formatMessage({ id: 'tickets.imprimir.detalles', defaultMessage: 'DETALLES:' }),
    seSolicitaReparacion: intl.formatMessage({ id: 'tickets.imprimir.se_solicita_reparacion', defaultMessage: 'Se solicita la revisión y/o reparación de: ' }),
    ruedaAuxilio: intl.formatMessage({ id: 'tickets.imprimir.ruedaAuxilio', defaultMessage: 'RUEDA AUXILIO' }),
    llaveDeRueda: intl.formatMessage({ id: 'tickets.imprimir.llaveDeRueda', defaultMessage: 'LLAVE DE RUEDA' }),
    raspon: intl.formatMessage({ id: 'tickets.imprimir.raspon', defaultMessage: 'RASPON' }),
    crique: intl.formatMessage({ id: 'tickets.imprimir.crique', defaultMessage: 'CRIQUE' }),
    baliza: intl.formatMessage({ id: 'tickets.imprimir.baliza', defaultMessage: 'BALIZA' }),
    matafuego: intl.formatMessage({ id: 'tickets.imprimir.matafuego', defaultMessage: 'MATAFUEGO' }),
    botiquin: intl.formatMessage({ id: 'tickets.imprimir.botiquin', defaultMessage: 'BOTIQUIN' }),
    cubreAlfombra: intl.formatMessage({ id: 'tickets.imprimir.cubreAlfombra', defaultMessage: 'CUBRE ALFOMBRA' }),
    tarjetaComb: intl.formatMessage({ id: 'tickets.imprimir.tarjetaComb', defaultMessage: 'TARJETA COMB.' }),
    estereo: intl.formatMessage({ id: 'tickets.imprimir.estereo', defaultMessage: 'ESTEREO' }),
    telepeajeS: intl.formatMessage({ id: 'tickets.imprimir.telepeajeS', defaultMessage: 'TELEPEAJE/S' }),
    presionNeumaticos: intl.formatMessage({ id: 'tickets.imprimir.presionNeumaticos', defaultMessage: 'PRESIÓN NEUMÁTICOS' }),
    delDerecho: intl.formatMessage({ id: 'tickets.imprimir.delDerecho', defaultMessage: 'DEL.DERECHO' }),
    trasDerecho: intl.formatMessage({ id: 'tickets.imprimir.trasDerecho', defaultMessage: 'TRAS.DERECHO' }),
    delIzquierdo: intl.formatMessage({ id: 'tickets.imprimir.delIzquierdo', defaultMessage: 'DEL.IZQUIERDO' }),
    trasIzquierdo: intl.formatMessage({ id: 'tickets.imprimir.trasIzquierdo', defaultMessage: 'TRAS.IZQUIERDO' }),
    fechaTermTrabajo: intl.formatMessage({ id: 'tickets.imprimir.fechaTermTrabajo', defaultMessage: 'Fecha term.trabajo:' }),
    kmSalidaTaller: intl.formatMessage({ id: 'tickets.imprimir.kmSalidaTaller', defaultMessage: 'Kms. salida taller:' }),
    combustibleIngresoTaller: intl.formatMessage({ id: 'tickets.imprimir.combustibleIngresoTaller', defaultMessage: 'Combustible ing.taller:Líq.: GNC:' }),
    observaciones: intl.formatMessage({ id: 'tickets.imprimir.observaciones', defaultMessage: 'OBSERVACIONES:' }),
    firmaAclaracionResponsable: intl.formatMessage({ id: 'tickets.imprimir.firmaAclaracionResponsable', defaultMessage: 'Firma y Aclaración de Responsable' }),
    firmaAclaracion: intl.formatMessage({ id: 'tickets.imprimir.firmaAclaracion', defaultMessage: 'Firma y Aclaración' }),
    ruAdjunto: intl.formatMessage({ id: 'tickets.imprimir.ruAdjunto', defaultMessage: 'R.U. ó Adjunto' }),
    fechaFormato: intl.formatMessage({ id: 'tickets.imprimir.fechaFormato', defaultMessage: 'Fecha: / /' }),
    abollado: intl.formatMessage({ id: 'tickets.imprimir.abollado', defaultMessage: 'ABOLLADO' }),
    // Agrega más según sea necesario
  });
