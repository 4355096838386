import React, { Component } from 'react'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import moment from 'moment'
import 'moment/min/locales'
import {FormattedMessage, injectIntl} from 'react-intl';

class TicketTareas extends Component {
	constructor(props) {
    super(props);
    this.props = props;

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

    if(props.ticket) {
      this.ticket = props.ticket;
    }


    this.state = {
      loading: false,
      tareas: [],
      tareasCierre: []
    }
  }

  componentDidMount(nextProps, nextState) {
    if(Security.hasPermission('TICKETS_VER_TAREAS')) {
        this.ajaxHandler.subscribe(this);
        this.loadFormData();
    } else {
      this.setState({
        redirectTo: '/error'
      });
    }
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  loadFormData() {
    this.ajaxHandler.getJson('/tareas/ticket-has-tareas-cierre/' + this.ticket.id)
    .then(hasTareasCierre => {
      let component = this;
      // let grid = ConfigBusiness.get('tickets.tareasCierre.habilitado') === 'true' ? ( hasTareasCierre ? '/gridCierre' : '/grid' ) : '/grid';
      let gridCierre = hasTareasCierre ? '/gridCierre' : '/grid';
      this.setState({ loading: true });
      return new Promise((resolve, reject)=>{
        Promise.all([
      this.ajaxHandler.getJson('/tareas/ticket/' + this.ticket.id + '/grid' ),
      this.ajaxHandler.getJson('/tareas/ticket/' + this.ticket.id + gridCierre )

        ]).then(data => {
        if(data) component.setState({
          tareas: data[0],
          tareasCierre: data[1]
        });
      })
      .catch(function(error) {
        component.ajaxHandler.handleError(error);
        reject();
    }).finally(() => {
      component.setState({ loading: false });
      resolve();
    });
  });
  });
  }

	render() {
    if(this.ticket) {
      return (
        <React.Fragment>
          {this.state.loading && <Loading />}
          {this.state.tareas ? (
            <div className="card pull-up">
              <div className="card-content">
              { ConfigBusiness.get('tickets.tareasCierre.habilitado') === 'true' && (this.props.ticket.estado === 'LISTO_PARA_RETIRAR' || this.props.ticket.estado === 'CERRADO'  ) ? (
                <div className="card-body">
                  <h4 className="form-section">
                  <i className="la la-tasks"></i><FormattedMessage id="ticketTareas.render.task.header_tareas_cierre" defaultMessage=" Tareas Realizadas (Cierre)"/>
                  </h4>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table">
                        <thead className="thead-fleet">
                          <tr>
                            <th scope="col"><FormattedMessage id="ticketTareas.render.task.table_column_servicio" defaultMessage="Servicio"/></th>
                            <th scope="col"><FormattedMessage id="ticketTareas.render.task.table_column_tareas" defaultMessage="Tareas"/></th>
                            { ConfigBusiness.get('tickets.tareasCierre.montosRepuesto') === 'true' ? (<th scope="col"><FormattedMessage id="ticketTareas.render.task.table_column_monto_total" defaultMessage="Monto Total"/></th>) :''}
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.tareasCierre.map((tareaCierre, index)=>{
                          return(
                            <tr key={index}>
                              <td className="align-middle">{tareaCierre.servicio.nombre}</td>
                              <td className="align-middle">{tareaCierre.nombre}</td>
                              { ConfigBusiness.get('tickets.tareasCierre.montosRepuesto') === 'true' ? (<td className="align-middle">{tareaCierre.montoTotal}</td>) :''}
                            </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> 
                ) : ''}

                <div className="card-body">
                  <h4 className="form-section">
                   <i className="la la-tasks"></i><FormattedMessage id="ticketTareas.render.task.header_tareas_apertura" defaultMessage=" Tareas indicadas al momento de apertura del Ticket"/>
                  </h4>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table">
                        <thead className="thead-fleet">
                          <tr>
                            <th scope="col"><FormattedMessage id="ticketTareas.render.task.table_column_servicio" defaultMessage="Servicio"/></th>
                            <th scope="col"><FormattedMessage id="ticketTareas.render.task.table_column_tareas" defaultMessage="Tareas"/></th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.tareas.map((tarea, index)=>{
                          return(
                            <tr key={index}>
                              <td className="align-middle">{tarea.servicio.nombre}</td>
                              <td className="align-middle">{tarea.nombre}</td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : ( null )}
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(TicketTareas);