import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import Security from '../../commons/security/Security.js'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
//import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal2 from 'sweetalert2'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import $ from 'jquery' 
import Loading from '../ui/Loading.js'
import dtConfig from './PanolGrid.js'


import { getMedidas, createMedidas, updateMedidas, deleteMedidas } from './MetodosPanol.js' 
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class ConfigAbm extends Component { 
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			loading: true,
			grid: null,
			create: null,
			update: null,
			delete: null,
			tipoMedida: "Litro"
		}
	}
	async componentDidMount() {
		
    	let component = this;
    	let filters = null;
		let getGrid = await getMedidas().then(res=>{
    		let dataR = res.data;
	        this.setState({
	            grid: dataR
	        })
	       
	    });
	    const config = {
    	headers: { 
    		'Content-Type': 'application/json',
			'Authorization-Token': localStorage.getItem("token")
    	 }
		};	    
       	let table = $(this.refs.main).DataTable(
       		Object.assign({
				//dom: '<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
				stateSave: true,
				stateSaveCallback: function(settings,data) {
					localStorage.setItem('DataTables_ConfigAbm', JSON.stringify(data) );
				},
				stateLoadCallback: function(settings) {
					return JSON.parse( localStorage.getItem('DataTables_ConfigAbm') );
				},
	           	//dom: '<"data-table-wrapper"t>',
	           	//data: this.state.grid,
	           	ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + '/panol/unidades-medida/grid',
						
						headers: {
							'Content-Type': 'application/json',
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(res){
							return component.state.grid
						},
						error: function (xhr, error, thrown) {
						},
						cache: false
					},
					initComplete: function (settings) {
					let stateColumns = JSON.parse(localStorage.getItem('DataTables_ConfigAbm')).columns;
					var sets = settings;
					var index = 0;
					this.api().columns().every( function () {
						var column = this;
						if(sets.aoColumns[index].filterType){
							if(sets.aoColumns[index].filterType === 'select') {
								var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'configAbm.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
									.appendTo( $(column.footer()).empty() )
									.on( 'change', function () {
										var val = $.fn.dataTable.util.escapeRegex($(this).val());
										column
											.search( val ? val : '', true, false )
											.draw();
									});								
								if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
									filters[sets.aoColumns[index].name].map(e => {
										select.append('<option value="'+e.value+'">'+e.label+'</option>');
										return true;
									});
								} else {
									column.data().unique().sort().each( function ( d, j ) {
										select.append( '<option value="'+d+'">'+d+'</option>' );
									});
								}
								if(stateColumns[index].search.search) select.val(stateColumns[index].search.search);
							}
							if(sets.aoColumns[index].filterType === 'input') {
								var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'configAbm.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
								if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
								input.appendTo($(column.footer()).empty());
								input.on( 'keyup change', function () {
									if ( column.search() !== this.value ) {
										column
										.search( this.value )
										.draw()
										.ajax.reload(null, false);
									}
								});
							}
						}
						index++;
						return '';
					});
					$('tfoot tr').appendTo('thead');
					},
	           	columns: [
	           		{ 
		            title: component.props.intl.formatMessage({ id: 'configAbm.column_id.label_id', defaultMessage: 'id' }),
		            className: "text-center",
		            visible: false,
		            data: "id"
		            },
	           		{ 
		            title: component.props.intl.formatMessage({ id: 'configAbm.column_description.label_descripcion', defaultMessage: 'Descripción' }),
		            className: "text-center",
		            data: "descripcion",
		            filterType: 'input'
		            },
		            { 
		            title: component.props.intl.formatMessage({ id: 'configAbm.column_abbreviation.label_abreviatura', defaultMessage: 'Abreviatura' }),
		            className: "titleStyle",
		            data: "abreviatura",
		            filterType: 'input'
		            },
		            {
		            title: component.props.intl.formatMessage({ id: 'configAbm.column_tipo_medida.label_tipo_medida', defaultMessage: 'Tipo de medida' }),
		            className: "titleStyle",
		            data: "tipo.nombre",
		            filterType: 'input'
		            },
		            {
		            title: component.props.intl.formatMessage({ id: 'configAbm.column_factor_de_conv.label_factor_de_conv.', defaultMessage: 'Factor de Conv.' }),
		            className: "titleStyle",
		            data: "factor",
		            filterType: 'input'
		            },
		            {
					orderable: false,
					data: null,
					className: "text-center",
					width: '120px',
		            render: function () {
						let html =
						(Security.hasPermission('PANOL_CONFIGURACION_MODIFICAR') ? `
                        <button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'configAbm.column_actions.button_title_edit', defaultMessage: 'Editar' })+`" >
                            <i class="fa fa-pencil fa-xs"></i>
                        </button>`: '') +
                        (Security.hasPermission('PANOL_CONFIGURACION_ELIMINAR') ? `
                        <button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'configAbm.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`">
                            <i class="fa fa-trash fa-xs"></i>
                        </button>` : '');
                        return html;
					}
	        	}],
				sDom: 'lrtip',
				orderCellsTop: true,
	        	fixedHeader: true,
	        	drawCallback: function () {
							$(this).find('.action').on('click', function () {
								
								if($(this).hasClass('edit')) {
									let data = table.row( $(this).parents('tr') ).data();
									let dataId = data.id;
									$("#editMedidasModal #editIdMedida").val(data.id);
									$("#editMedidasModal #editDescMedida").val(data.descripcion);
									$("#editMedidasModal #editAbrMedida").val(data.abreviatura);
									$("#editMedidasModal #editTipoMedida").val(data.tipo.id);
									let tipoLabel = () => {
										let editMedidaLabel = data.tipo.id;
										console.log(editMedidaLabel);
										switch (editMedidaLabel) {
								  			case 1:
									  			component.setState({ tipoMedida: 'LITRO' });
									  			break;
									  		case 2:
									  			component.setState({ tipoMedida: 'METRO' });
									  			break;
									  		case 3:
									  			component.setState({ tipoMedida: 'GRAMO' });
									  			break;
									  		case 4:
									  			component.setState({ tipoMedida: 'UNIDAD' });
									  			break;			
								  		}
								  		component.forceUpdate();
									}
									tipoLabel();
									$("#editMedidasModal #editFactorMedida").val(data.factor);
									$('#editMedidasModal').on('shown.bs.modal', function() {
									    $('#editDescMedida').trigger('focus');
									    $("#editDescMedida").keyup(function(e)
										{
										    if (e.which == 13)
										    {
										        $(this).submit();
										    }
										});
									  });
									$("#editMedidasModal").modal({show: true});
								}
								if($(this).hasClass('delete')) {
									swal({
									title: component.props.intl.formatMessage({ id: 'configAbm.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
									text: null,
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'configAbm.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'configAbm.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								}).then((isConfirm) => {
									if (isConfirm) {
										let data = table.row( $(this).parents('tr') ).data();
										let dataId = data.id;
										console.log("se eliminara el item: "+ data.descripcion);
										deleteMedidas(dataId).then(res => {
											let dataR = res.mensaje;
											swal(component.props.intl.formatMessage({ id: 'configAbm.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
											let getGrid2 = getMedidas().then(res2=>{
								    		let dataR2 = res2.data;
										        component.setState({
										            grid: dataR2
										        })
											table.ajax.reload();
										    console.log("actualiza grid:");
										    console.log(component.state.grid);
										    })
										})
										.catch(function(error) {
											swal(component.props.intl.formatMessage({ id: 'configAbm.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
										});
									}
								}); //fin accion
								
								}
							});
							
						},


		}, dtConfig)
		)
		$(document).find("#example thead th").css("height","43px");
		$(document).find("#example thead th").css("padding-left","20px");
		$(document).find("#example tbody td").css("padding-left","20px");
		$('[data-toggle="tooltip"]').tooltip();

		this.setState({ loading: false });

		new $.fn.dataTable.Buttons( table, {
			buttons: [
				{
          extend: 'print',
          text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win)
					{
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						}	else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css( 'font-size', '10pt' )
							.prepend(`<div class="container">
													<div class="row mb-2">
														<div class="col-6">
															<img style="height: 35px" src="`+LogoCompany+`" />
														</div>
														<div class="col-6	text-right">
															<img style="height: 40px" src="`+LogoVecFleet+`" />
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-12" style="border-bottom: 1px solid #000;">
															<h2>`+component.props.intl.formatMessage({ id: 'configAbm.print_content.header_listado_de_regiones', defaultMessage: 'Listado de Regiones' })+`</h2>
														</div>
													</div>
												</div>`);

						$(win.document.body).find( 'table' )
							.addClass( 'compact' )
							.css( 'font-size', 'inherit' );
			 		},
          exportOptions: {
              columns: [ 3,4,5,6 ]
          },
					titleAttr: component.props.intl.formatMessage({ id: 'configAbm.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
        },
        {
          extend: 'colvis',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'configAbm.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
        },
        {
          extend: 'collection',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'configAbm.tools_export.title_exportar', defaultMessage: 'Exportar' }),
          buttons: [
          {
            extend: 'copyHtml5',
            exportOptions: {
              columns: ':visible'
            },
						text: component.props.intl.formatMessage({ id: 'configAbm.tools_export.select_text_copiar', defaultMessage: 'Exportar' }),
						titleAttr: component.props.intl.formatMessage({ id: 'configAbm.tools_export.select_copiar', defaultMessage: 'Exportar' })
          },
          {
            extend: 'excelHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'configAbm.tools_export.select_exportar_excel', defaultMessage: 'Exportar a Excel' })
          },
          {
            extend: 'csvHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'configAbm.tools_export.select_exportar_formato_csv', defaultMessage: 'Exportar en formato .csv' })
          },
          {
            extend: 'pdfHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'configAbm.tools_export.select_exportar_PDF', defaultMessage: 'Exportar a PDF' })
					}
					]
				},
				{
					text: '<i class="ft-download"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'configAbm.tools_import.title_importar', defaultMessage: 'Importar' })
        },
			]
		});

		table.buttons( 0, null ).container().appendTo('#buttons');

		/*
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
		*/

		$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
			if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
				$('.dt-button-collection>.dropdown-menu').css('display','block');
		});
    }  
	componentWillUnmount(){
		/*
       $('.data-table-wrapper')
       .find('table')
       .DataTable()
       .destroy(true);
       */
    }
    shouldComponentUpdate() {
        return true;
    }

    onChange = (e) => {
    	this.props.history.push(`/panol/config${e.target.value}`);
  	}
  	tipoBase = (e) => {
  		let ething = e.target.value;
  		let algo = $(".tipoBase option:selected").text();
  		switch (ething) {
  			case '1':
	  			this.setState({ tipoMedida: 'LITRO' });
	  			break;
	  		case '2':
	  			this.setState({ tipoMedida: 'METRO' });
	  			break;
	  		case '3':
	  			this.setState({ tipoMedida: 'GRAMO' });
	  			break;
	  		case '4':
	  			this.setState({ tipoMedida: 'UNIDAD' });
	  			break;			
  		}
  		this.forceUpdate();
  		
  	}
  	onModalEditSubmit = (e) => {
  		e.preventDefault();  		
  		let component = this;
  		let table = $(this.refs.main).DataTable();
  		updateMedidas(e).then(res =>{

			//$(this).find('form').trigger('reset');
			$("#editMedidasModal").modal("hide");
			swal(component.props.intl.formatMessage({ id: 'configAbm.modal_edit.information_modificado', defaultMessage: 'Modificado!' }), "", "success");
			let getGrid2 = getMedidas().then(res2=>{
    		let dataR2 = res2.data;
		    component.setState({
		            grid: dataR2
		    })
			table.ajax.reload();
		    console.log("actualiza grid:");
		    console.log(component.state.grid);
		    })
		    
		})
  	}
  	onModalNewSubmit = (e) => {
  		e.preventDefault();
  		let component = this;
  		let table = $(this.refs.main).DataTable();
  		createMedidas(e).then(res =>{

			    $("#newMedidasModal").find('form').trigger('reset');
			    $("#newMedidasModal").modal("hide");

			swal(component.props.intl.formatMessage({ id: 'configAbm.modal_add.information_agregado', defaultMessage: 'Agregado!' }), "", "success");
									
			let getGrid2 = getMedidas().then(res2=>{
    		let dataR2 = res2.data;
		        component.setState({
		            grid: dataR2
		        })
			table.ajax.reload();
		    console.log("actualiza grid:");
		    console.log(component.state.grid);
		    })
		    
		})
		
  	}

    render() {
        return (
        	<React.Fragment>
        	{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        	{/*{this.state.loading && <Loading />}*/}
    		<div className="row mt-2">
				<div className="col-12">
					<div className="card">
						<div className="card-header">
							<h1><FormattedMessage id="configAbm.render.select_module.header_seleccione_el_modulo_a_configurar" defaultMessage="Seleccione el módulo a configurar"/></h1>
						</div>
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								
				               <select className="form-control form-control-lg" defaultValue="/" onChange={this.onChange}>
								  <option value="/">Medidas</option>
								  <option value="/marcas">Marcas</option>
								  <option value="/categorias">Categorias</option>
								  <option value="/subcategorias">Sub-Categorias</option>
								  <option value="/items">Items</option>
								</select>
				            </div>
			            </div>
					</div>
				{/*ABM medidas*/}
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
										<div className="col-6">
                                            {Security.renderIfHasPermission('PANOL_CONFIGURACION_CREAR', (
											<div 
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"  
												data-toggle="tooltip" 
												data-placement="right" 
												title={this.props.intl.formatMessage({ id: 'configAbm.render.button_add_category.placeholder_nueva_medida', defaultMessage: 'Nueva medida' })}
												data-toggle="modal" 
												data-target="#newMedidasModal"
												>
												<i className="ft-plus"></i>
											</div>
											))}
										</div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div> 
				               	<div>
				               		<table id="example" ref="main" className="nowrap table-hover dataTableMovil" width="100%">
					               
					                <tfoot>
										<tr style={{backgroundColor: '#fff'}}>
											<th className=""></th>	
											<th className="dt-search-header"><FormattedMessage id="configAbm.render.table_column_nombre_descripcion" defaultMessage="Descripción"/></th>
											<th className="dt-search-header"><FormattedMessage id="configAbm.render.table_column_nombre_abreviatura" defaultMessage="Abreviatura"/></th>
											<th className="dt-search-header"><FormattedMessage id="configAbm.render.table_column_nombre_tipo_de_medida" defaultMessage="Tipo de medida"/></th>
											<th className="dt-search-header"><FormattedMessage id="configAbm.render.table_column_nombre_factor_de_Conv" defaultMessage="Factor de Conv."/></th>
											<th className=""></th>
										</tr>
									</tfoot>
								
								</table>
					            </div>

				            </div>
			            </div>
					</div>
				</div>
			{/*---Modal nueva medida---*/}
				<div className="modal fade" id="newMedidasModal" tabIndex="-1" role="dialog" aria-labelledby="newMedidasModalLabel" aria-hidden="true">
				  	<div className="modal-dialog" role="document">
				    	<div className="modal-content">
				      		<div className="modal-header">
				        		<h5 className="modal-title" id="newMedidasModalLabel"><FormattedMessage id="configAbm.render.modal_add_medida.header_nueva_medida" defaultMessage="Nueva Medida"/></h5>
						        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
						    </div>
				    		<form onSubmit={this.onModalNewSubmit}>
				      			<div className="modal-body">
				        			<div className="row">
					        			<div className="col-md-8">
					          				<div className="form-group">
					            				<label htmlFor="nombreMedida" className="col-form-label"><FormattedMessage id="configAbm.render.modal_add_medida.label_nombre_medida" defaultMessage="Nombre de la medida:"/></label>
									            <input 
									            	type="text" 
									            	className="form-control" 
									            	name="descripcion" 
									            	id="newDescMedida"
									            	placeholder={this.props.intl.formatMessage({ id: 'configAbm.render.modal_add_medida.placeholder_nombre_medida', defaultMessage: 'Ej: bidon 6 litros' })}
									            />
									          </div>
									    </div>
								        <div className="col-md-4">
								          	<div className="form-group">
								            	<label htmlFor="newAbrMedida" className="col-form-label"><FormattedMessage id="configAbm.render.modal_add_medida.label_abreviatura" defaultMessage="Abreviatura:"/></label>
									            <input 
									            	type="text" 
									            	className="form-control" 
									            	name="abreviatura" 
									            	id="newAbrMedida"
									            	placeholder={this.props.intl.formatMessage({ id: 'configAbm.render.modal_add_medida.placeholder_abreviatura', defaultMessage: 'Ej: B6L' })}
									            />
								          	</div>
			          					</div>
								        <div className="col-md-8">
									          <div className="form-group">
									            <label htmlFor="newTipoMedida" className="col-form-label"><FormattedMessage id="configAbm.render.modal_add_medida.select_label_tipo_medida" defaultMessage="Tipo de medida:"/></label>
									            <select defaultValue="1" className="form-control tipoBase" id="newTipoMedida" name="tipo" onChange={this.tipoBase}>
												  <option value="1">Volumen</option>
												  <option value="2">Distancia</option>
												  <option value="3">Peso</option>
												  <option value="4">Cantidad</option>
												</select>
									          </div>
									          <label>Unidad Base: <b>{this.state.tipoMedida}</b></label>
								        </div>
								        <div className="col-md-4">
											<div className="form-group">
					            				<label htmlFor="nombreMedida" className="col-form-label"><FormattedMessage id="configAbm.render.modal_add_medida.label_factor" defaultMessage="Factor:"/></label>
									            <input 
									            	type="text" 
									            	className="form-control" 
									            	name="factor" 
									            	id="newFactorMedida"
									            	placeholder={this.props.intl.formatMessage({ id: 'configAbm.render.modal_add_medida.placeholder_factor', defaultMessage: 'Ej: 1' })}
									            />
									        </div>
					        			</div>
							        </div>
				      			</div>
						      	<div className="modal-footer">
						        	<button type="button" className="btn btn-secondary" data-dismiss="modal" ><FormattedMessage id="configAbm.modal_add_medida.finish_button.cancelar" defaultMessage="Cancelar"/></button>
						        	<button type="submit" className="btn btn-primary" ><FormattedMessage id="configAbm.modal_add_medida.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
						      	</div>
				    		</form>
				  		</div>
					</div>
				</div>
				{/*---Modal edit medida---*/}
				<div className="modal fade" id="editMedidasModal" tabIndex="-1" role="dialog" aria-labelledby="editMedidasModalLabel" aria-hidden="true">
				  	<div className="modal-dialog" role="document">
				    	<div className="modal-content">
				      		<div className="modal-header">
				        		<h5 className="modal-title" id="editMedidasModalLabel"><FormattedMessage id="configAbm.render.modal_edit_medida.header_editar_medida" defaultMessage="Editar Medida"/></h5>
						        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
						    </div>
				    		<form onSubmit={this.onModalEditSubmit}>
				      			<div className="modal-body">
				        			<div className="row">
					        			<div className="col-md-8">
					          				<div className="form-group">
					          				<input 
									            	type="hidden" 
									            	className="form-control" 
									            	name="id" 
									            	id="editIdMedida"
									            />
					            				<label htmlFor="editDescMedida" className="col-form-label"><FormattedMessage id="configAbm.render.modal_edit_medida.label_nombre_medida" defaultMessage="Nombre de la medida:"/></label>
									            <input 
									            	type="text" 
									            	className="form-control" 
									            	name="descripcion" 
									            	id="editDescMedida"
									            	placeholder={this.props.intl.formatMessage({ id: 'configAbm.render.modal_edit_medida.placeholder_nombre_medida', defaultMessage: 'Ej: bidon 6 litros' })}
									            />
									          </div>
									    </div>
								        <div className="col-md-4">
								          	<div className="form-group">
								            	<label htmlFor="editAbrMedida" className="col-form-label"><FormattedMessage id="configAbm.render.modal_edit_medida.label_abreviatura" defaultMessage="Abreviatura:"/></label>
									            <input 
									            	type="text" 
									            	className="form-control" 
									            	name="abreviatura" 
									            	id="editAbrMedida"
									            	placeholder={this.props.intl.formatMessage({ id: 'configAbm.render.modal_edit_medida.placeholder_abreviatura', defaultMessage: 'Ej: B6L' })}
									            />
								          	</div>
			          					</div>
								        <div className="col-md-8">
									          <div className="form-group">
									            <label htmlFor="editTipoMedida" className="col-form-label"><FormattedMessage id="configAbm.render.modal_edit_medida.select_label_tipo_medida" defaultMessage="Tipo de medida:"/></label>
									            <select className="form-control" id="editTipoMedida" name="tipo">
												  <option value="1">Volumen</option>
												  <option value="2">Distancia</option>
												  <option value="3">Peso</option>
												  <option value="4">Cantidad</option>
												</select>
									          </div>
									          <label>Unidad Base: <b>{this.state.tipoMedida}</b></label>

								        </div>
								        <div className="col-md-4">
											<div className="form-group">
					            				<label htmlFor="editFactorMedida" className="col-form-label"><FormattedMessage id="configAbm.render.modal_edit_medida.label_factor" defaultMessage="Factor:"/></label>
									            <input 
									            	type="text" 
									            	className="form-control" 
									            	name="factor" 
									            	id="editFactorMedida"
									            	placeholder={this.props.intl.formatMessage({ id: 'configAbm.render.modal_edit_medida.placeholder_factor', defaultMessage: 'Ej: 1' })}
									            />
									        </div>
					        			</div>
							        </div>
				      			</div>
						      	<div className="modal-footer">
						        	<button type="button" className="btn btn-secondary" data-dismiss="modal" ><FormattedMessage id="configAbm.modal_edit_medida.finish_button.cancelar" defaultMessage="Cancelar"/>Cancelar</button>
						        	<button type="submit" className="btn btn-primary" ><FormattedMessage id="configAbm.modal_edit_medida.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
						      	</div>
				    		</form>
				  		</div>
					</div>
				</div>

				
			</div>
		</React.Fragment>
            );
    }
}

export default injectIntl(ConfigAbm);