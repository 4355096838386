import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import Loading from '../ui/Loading.js'

import moment from 'moment'
import 'moment/min/locales'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import AtributoDinamicoInstancia from '../atributoDinamico/AtributoDinamicoInstancia.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import { atributosDinamicosRequeridosFaltantes, castValoresDinamicos } from '../atributoDinamico/helpers.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class EquipamientoAbm extends Component {
	constructor(props) {
    super(props);
		this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				identificador: '',
				descripcion: '',
				marca: '',
				modelo: '',
        activo: true,
				adjuntos: [],
				valores_dinamicos: [],
				tipo_equipamiento: null,
				movil: null
      },
			valores_dinamicos: [],
			tiposEquipamientos: null,
      estados: [{ id: 1, nombre: this.props.intl.formatMessage({ id: 'equipamientoAbm.general_data.activo_state', defaultMessage: 'Activoaa' })},{ id: 0, nombre: this.props.intl.formatMessage({ id: 'equipamientoAbm.general_data.inactivo_state', defaultMessage: 'Inactivo' })}],
      errors: [],
			loading: false
    };

    this.handleEstadoChange = this.handleEstadoChange.bind(this);
		this.handleChangeAtributoDinamico = this.handleChangeAtributoDinamico.bind(this);
		this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleTipoEquipamientoChange = this.handleTipoEquipamientoChange.bind(this);
		this.handleMovilChange = this.handleMovilChange.bind(this);
		this.getBackendMoviles = this.getBackendMoviles.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);


    this.formValidation = new FormValidation({
			component: this,
			validators: {
				'formData.identificador': (value) => Validator.notEmpty(value),
				'formData.tipo_equipamiento': (value) => Validator.notEmpty(value),
			}
    });

  }
  initForm() {
    this.setState({ loading: true });
    let component = this;

		Promise.all([
			this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/equipamiento/' + this.state.props.match.params.id) : null,
			this.ajaxHandler.getJson('/tipoequipamiento'),
		]).then((data) => {
			let formData = data[0];
      let tiposEquipamientos = data[1];
			let valores_dinamicos = [];
			if(formData) {
				valores_dinamicos = castValoresDinamicos(formData.tipo_equipamiento.atributos_dinamicos, formData.valores_dinamicos)
				component.setState({
					formData: formData,
					tiposEquipamientos: tiposEquipamientos,
					valores_dinamicos: valores_dinamicos
				});
			} else{
				component.setState({
					tiposEquipamientos: tiposEquipamientos
				});
			}
    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });
  }
	componentWillMount() {
			
        if ((Security.hasPermission('EQUIPAMIENTO_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('MOVILES_MODIFICAR_EQUIPAMIENTO') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('EQUIPAMIENTO_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
        }
	}
  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}


  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activo'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }

	handleMovilChange(movil) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['movil'] = movil;
		this.setState({
			formData: formDataCopy
		});
	}


	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

	handleChangeAtributoDinamico(index, key, value) {
		this.state.valores_dinamicos[index][key] = value;
		this.setState({
			valores_dinamicos: this.state.valores_dinamicos
		});

	}

	handleTipoEquipamientoChange(tipo_equipamiento) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		let valores_dinamicos = [];
		formDataCopy.tipo_equipamiento = tipo_equipamiento;
		if (tipo_equipamiento)
			valores_dinamicos = castValoresDinamicos(tipo_equipamiento.atributos_dinamicos, this.state.formData.valores_dinamicos)
		this.setState({
			formData: formDataCopy,
			valores_dinamicos: valores_dinamicos
		});
	}

	getBackendMoviles (searchTerm) {
		return this.ajaxHandler.getJson('/moviles/simple-search?search=' + searchTerm).then(res => {
			return {
				options: res,
				complete: true
			}
		});
	}



	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
		this.state.formData.tipo_equipamiento_id = this.state.formData.tipo_equipamiento.id
		this.state.formData.movil_id = this.state.formData.movil ? this.state.formData.movil.id : null
		this.state.formData.valores_dinamicos = this.state.valores_dinamicos

    this.ajaxHandler.fetch('/equipamiento' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(response.status !== 400) {
        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/equipamiento'
		});
	}

	render() {
    let state = this.state;
    this.formValidation.validate();
		let formData = this.state.formData;
    let validationState = this.formValidation.state;

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="equipamientoAbm.general_data.label.datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>* campos requeridos</div>
                      </h4>


											<div className="row">
												{/* Tipo Equipamiento */}
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-3 label-control col-form-label" htmlFor="identificador">
															<FormattedMessage id="equipamientoAbm.general_data.label_tipo_equipamiento" defaultMessage="Tipo Equipamiento *:"/>
														</label>
														<Select
															id="tipo_equipamiento"
															name="tipo_equipamiento"
															clearable={true}
															options={this.state.tiposEquipamientos}
															disabled={this.props.action === 'VIEW' || this.props.action === 'EDIT'}
															valueKey="id"
															labelKey="nombre"
															className="col-9 select-periodo"
															value={this.state.formData.tipo_equipamiento ? this.state.formData.tipo_equipamiento : null}
															onChange={this.handleTipoEquipamientoChange}

														/>
													</div>
												</div>
											</div>

											<div className="row">
												{/* Identificador */}
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-3 label-control col-form-label" htmlFor="identificador">
															<FormattedMessage id="equipamientoAbm.general_data.label_identificador" defaultMessage="Identificador *:"/>
														</label>
														<div className="col-md-9">
																<input disabled={this.props.action === 'VIEW'} type="text" className="form-control" id="identificador" name="identificador" placeholder={this.props.intl.formatMessage({ id: 'equipamientoAbm.general_data.placeholder_identificador', defaultMessage: 'Identificador' })} value={formData.identificador} onChange={this.handleInputFormChange} />
														</div>
													</div>
												</div>
											</div>


											<div className="row">
                        {/* Marca */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="marca">
                              <FormattedMessage id="equipamientoAbm.general_data.label_marca" defaultMessage="Marca:"/>
                            </label>
                            <div className="col-md-9">
	                              <input disabled={this.props.action === 'VIEW'} type="text" className="form-control" id="marca" name="marca" placeholder={this.props.intl.formatMessage({ id: 'equipamientoAbm.general_data.placeholder_marca', defaultMessage: 'Marca' })} value={formData.marca} onChange={this.handleInputFormChange} />
                            </div>
                          </div>
                        </div>
                      </div>


											<div className="row">
												{/* Modelo */}
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-3 label-control col-form-label" htmlFor="modelo">
															<FormattedMessage id="equipamientoAbm.general_data.label_modelo" defaultMessage="Modelo:"/>
														</label>
														<div className="col-md-9">
																<input disabled={this.props.action === 'VIEW'} type="text" className="form-control" id="modelo" name="modelo" placeholder={this.props.intl.formatMessage({ id: 'equipamientoAbm.general_data.placeholder_modelo', defaultMessage: 'Modelo' })} value={formData.modelo} onChange={this.handleInputFormChange} />
														</div>
													</div>
												</div>
											</div>

											<div className="row">
												{/* Movil */}
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-3 label-control col-form-label" htmlFor="movil">
															<FormattedMessage id="equipamientoAbm.general_data.label_movil" defaultMessage="Móvil:"/>
														</label>
														<div className="col-md-9">
																<Select.Async
																    name="storageTypeId"
																    value={this.state.formData.movil}
																    valueKey="id"
																    labelKey="dominio"
																    loadOptions={this.getBackendMoviles}
																    onChange={this.handleMovilChange}
																		disabled={this.props.action === 'VIEW'}
																/>
														</div>
													</div>
												</div>
											</div>


											<div className="row">
                        {/* Descripción */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                              <FormattedMessage id="equipamientoAbm.general_data.label_descripcion" defaultMessage="Descripción:"/>
                            </label>
                            <div className="col-md-9">
	                              <textarea disabled={this.props.action === 'VIEW'} className="form-control" id="descripcion" name="descripcion" value={formData.descripcion} onChange={this.handleInputFormChange}>
																</textarea>
                            </div>
                          </div>
                        </div>
                      </div>


											<div className="row">
                        {/* ESTADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage id="equipamientoAbm.general_data.label_activo" defaultMessage="Activo:"/>
                            </label>
                            <div className="col-md-9">
                              <Switch
                                onChange={this.handleEstadoChange}
                                checked={formData.activo}
                                options={this.state.estados}
                                id="activo"
                                name="activo"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

											{this.state.valores_dinamicos
												.map((atributoDinamico, index)  => (
												(atributoDinamico && atributoDinamico.activo ) &&

												<div className="row">

	                        <div className="col-md-6">
	                          <div className="form-group row">
																<AtributoDinamicoInstancia
																	movil={formData.movil}
																	className=""
																	disabled={this.props.action === 'VIEW'}
																	handleChange={(name, value) => this.handleChangeAtributoDinamico(index, name, value)}
																	atributo={atributoDinamico}
																/>
														</div>
	                        </div>
	                      </div>
											))}


                    </div>
                  </div>
                </div>




                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
												<>
	                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid || atributosDinamicosRequeridosFaltantes(this.state.valores_dinamicos)} >
	                          <i className="fa fa-check-circle"></i><FormattedMessage id="equipamientoAbm.finish_button.guardar" defaultMessage=" Guardar"/>
	                        </button>
                        </>)}
	                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
	                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'equipamientoAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'equipamientoAbm.finish_button.cancelar', defaultMessage: 'cancelar' })}
	                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(EquipamientoAbm);
