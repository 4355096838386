import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Security from '../../commons/security/Security.js'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import Switch from "react-switch"
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { ID__TE_PERSONA as PERSONA, ID__TE_MOVIL as MOVIL } from '../tipoEntidad/constants.js'

class VencimientosAbm extends Component {
	constructor(props) {
		super(props);

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        servicio: null,
        persona: null,
        movil: null,
        fechaAlta: null,
        fechaVencimiento: null,
        numero: '',
        alertaDiasPrevios: 0,
        emisionDiasPrevios: 0,
        comentario: '',
        activo: true,
        ticket: null,
        fechaHoraBaja: '',
        usuarioBaja: null,
        renovacion: true
      },
      serviceApplyTo: '',
      servicios: [],
      serviciosLoading: false,
      errors: [],
      loading: false
    };

    this.handleActivoChange = this.handleActivoChange.bind(this);
    this.handleRenovacionChange = this.handleRenovacionChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleFormChangeSelectString = this.handleFormChangeSelectString.bind(this);
    this.handleFormChangeSelectObject = this.handleFormChangeSelectObject.bind(this);
    this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
    this.handleMovilChange = this.handleMovilChange.bind(this);
    this.getBackendMoviles = this.getBackendMoviles.bind(this);
    this.handlePersonaChange = this.handlePersonaChange.bind(this);
    this.getBackendPersonas = this.getBackendPersonas.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.servicio': (value) => Validator.notEmpty(value),
        'formData.fechaAlta': (value) => Validator.date(value),
        'formData.fechaVencimiento': (value) => Validator.fechaVencimiento(this.state.formData.fechaAlta, value),
			}
    });
  }

  componentDidMount() {
    if((Security.hasPermission('VENCIMIENTOS_CREAR') && this.state.props.action === 'ADD') ||
       (Security.hasPermission('VENCIMIENTOS_MODIFICAR') && this.state.props.action === 'EDIT') ||
       (Security.hasPermission('VENCIMIENTOS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: '/error'
      });
    }
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  initForm() {
    let component = this;
    if(this.state.props.action === 'VIEW') this.loadFormData();

    if(this.state.props.action === 'ADD' || this.state.props.action === 'EDIT') {
      this.setState({
        serviciosLoading: true,
        loading: this.state.props.action === 'EDIT'
      });

      Promise.all([
        this.ajaxHandler.getJson('/servicios/tipo-ticket/VENCIMIENTO&&GESTORIA/select'),
      ]).then((data) => {
        let servicios = data[0] ? data[0] : [];
        component.setState({
          serviciosLoading: false,
          servicios: servicios
        }, () => {
          if(component.state.props.action === 'EDIT') this.loadFormData();
        });


      }).catch(function(error) {
        component.ajaxHandler.handleError(error);
      });
    }

    window.scrollTo(0, 0);
  }

  loadFormData() {
    let component = this;
    this.setState({ loading: true });
    this.ajaxHandler.getJson('/vencimientos/' + this.state.props.match.params.id)
    .then(data => {
      if(data){
        component.setState({
          formData: Object.assign(this.state.formData, data)
        }, () =>{
          if(this.state.formData.servicio && this.state.formData.servicio.vencimientoAplicaA === PERSONA ){
            this.handlePersonaChange(this.state.formData.persona)
          } else {
            this.handleMovilChange(this.state.formData.movil)
          }
        });
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleFormChangeSelectString(name, object) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = object === null ? '' : object.value;
    this.setState({
      formData: formDataCopy
    });
  }

  handleFormChangeSelectObject(name, object) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = object ? { id: object.value } : null;
    this.setState({
      formData: formDataCopy
    });
  }

  handleMovilChange(movil) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["movil"] = movil;
    this.setState({
      formData: formDataCopy,
    });
  }

  handlePersonaChange(persona) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["persona"] = persona;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleServicioChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.persona = null;
        formDataCopy.movil = null;
        this.setState({
          formData: formDataCopy,
         });
        this.ajaxHandler.getJson('/servicios/' + object.value)
        .then((data) => {
          let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
          formDataCopy.servicio = data; // { id: object.value, label: object.label };
          this.setState({
            formData: formDataCopy
          }, () => {
            if(this.state.formData.servicio && this.state.formData.servicio.vencimientoAplicaA === PERSONA ){
             this.handlePersonaChange(formDataCopy.persona);
            } else {
              this.handleMovilChange(formDataCopy.movil);
            }
          });
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.servicio = null;
        formDataCopy.persona = null;
        formDataCopy.movil = null;
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      }
    });
  }

  handleActivoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.activo = activo;
		this.setState({
			formData: formDataCopy
    });
  }

  handleRenovacionChange(renovacion) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.renovacion = renovacion;
		this.setState({
			formData: formDataCopy
    });
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleDatePickerFormChange(name, event) {
    let date = event ? event.format(): '';
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }

  handleDatePickerFormRawChange(name, date) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }

  handleForzar (){
    this.setState({
      loading: true
    }, () => {
      let component = this
      this.ajaxHandler.fetch('/vencimientos/forzar/' + this.state.formData.id, {
        method: 'POST'
      }).then(response => {
        if(response.status !== 400) {
          component.exit();
        } else {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        }
        window.scrollTo(0,0);
      }).catch((error) => {
        this.ajaxHandler.handleError(error);
      }).finally(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleNumericChange(e) {
    let name = e.target.name;
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = (e.target.validity.valid) ? e.target.value : this.state.formData[name];
    this.setState({
      formData: formDataCopy
    });
  }

  getBackendMoviles(searchTerm) {
    return this.ajaxHandler.getJson("/moviles/simple-search?search=" + searchTerm).then((res) => {
      if (Security.hasPermission('USUARIO_POSEER_MOVIL')){
        this.handleMovilChange(res[0]);
      } 
      return {
        options: res,
        complete: true,
      };
    });
  }

  getBackendPersonas(searchTerm) {
    return this.ajaxHandler.getJson(`/personas/simple-search?filterNotInternal=true&search=${searchTerm}&active=1`).then((res) => {
      if (Security.hasPermission('USUARIO_POSEER_MOVIL')){
        this.handlePersonaChange(res[0]);
      } 
      return {
        options: res,
        complete: true,
      };
    });
  }

	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this;
    this.state.formData.movil_id = this.state.formData.movil ? this.state.formData.movil.id : null;
    this.state.formData.persona_id = this.state.formData.persona ? this.state.formData.persona.id : null;
      this.ajaxHandler
      .fetch('/vencimientos' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
        method: this.props.action === 'ADD' ? 'POST' : 'PUT',
        body: JSON.stringify({
          ...this.state.formData
        }),
      })
      .then(response => {
        if(response.status !== 400) {
          component.exit();
        } else {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        }
        window.scrollTo(0,0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
		event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/vencimientos'
    });
	}

	render() {
    this.formValidation.validate();
    let state = this.state;
		let formData = this.state.formData;
    let validationState = this.formValidation.state;
    let requiredSymbol = state.props.action !== 'VIEW' ? ' *' : '';
    let servicio = formData.servicio ? state.servicios.find(e => e.value === formData.servicio.id) : null;

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-hourglass-2"></i><FormattedMessage id="vencimientosAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>{ this.props.action === 'VIEW' ? '' : this.props.intl.formatMessage({ id: 'vencimientosAbm.render.general_data.campos_requeridos', defaultMessage: '* campos requeridos' }) }</div>
                      </h4>
                      <div className="row">
                        {/* SERVICIO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="servicio">
                              <FormattedMessage id="vencimientosAbm.render.general_data.label_servicio" defaultMessage="Servicio"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.servicio && formData.servicio.nombre ? formData.servicio.nombre : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="servicio"
                                  name="servicio"
                                  placeholder={!state.serviciosLoading ? this.props.intl.formatMessage({ id: 'vencimientosAbm.render.general_data.placeholder_servicio', defaultMessage: 'Seleccione' }) : ''}
                                  options={state.servicios}
                                  valueKey='value'
                                  labelKey='label'
                                  value={servicio ? { value: servicio.id, label: servicio.label } : null}
                                  onChange={(e) => this.handleServicioChange(e)}
                                  isLoading={state.serviciosLoading}
                                  disabled={state.serviciosLoading}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.servicio.pristine || validationState.formData.servicio.valid}>{validationState.formData.servicio.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* NUMERO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="numero">
                              <FormattedMessage id="vencimientosAbm.render.general_data.label_numero" defaultMessage="Número:"/>
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.numero ? formData.numero : '' }</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="numero" name="numero" placeholder="" value={formData.numero ? formData.numero : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* MOVIL */}
                        {formData.servicio && formData.servicio.vencimientoAplicaA === MOVIL ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="movil">
                              <FormattedMessage id="vencimientosAbm.render.general_data.label_movil" defaultMessage="Móvil"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.movil && formData.movil.dominio ? formData.movil.dominio : ''}</div>
                              ) : (
                              <div>
                                 <Select.Async
                                    name="storageTypeId"
                                    value={this.state.formData.movil}
                                    valueKey="id"
                                    labelKey="dominio"
                                    loadOptions={this.getBackendMoviles}
                                    onChange={this.handleMovilChange}
                                    disabled={this.props.action === "VIEW"}
                                  />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : ( null )}
                        {/* PERSONA */}
                        {formData.servicio && formData.servicio.vencimientoAplicaA === PERSONA ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="persona">
                              <FormattedMessage id="vencimientosAbm.render.general_data.label_persona" defaultMessage="Persona"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.persona && formData.persona.nombre ? formData.persona.nombre + '' + formData.persona.apellido + ' (' + formData.persona.documentoTipo.nombre + ' ' + formData.persona.documentoNumero + ')'  : ''}</div>
                              ) : (
                              <div>
                                <Select.Async
                                    name="persona"
                                    value={this.state.formData.persona}
                                    valueKey="id"
                                    labelKey="label"
                                    loadOptions={this.getBackendPersonas}
                                    onChange={this.handlePersonaChange}
                                    disabled={this.props.action === "VIEW"}
                                  />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : ( null )}
                        {/* BASE */}
                        {formData.movil || formData.persona ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label">
                              <FormattedMessage id="vencimientosAbm.render.general_data.label_base" defaultMessage="Base:"/>
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">{formData.movil ? (formData.movil.base ? formData.movil.base.descripcion : '') : (formData.persona.base ? formData.persona.base.descripcion : '')}</div>
                            </div>
                          </div>
                        </div>
                        ): null}
                      </div>
                      <div className="row" style={{display:'none'}}>
                        {/* COMENTARIOS */}
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-md-2 label-control col-form-label" htmlFor="comentario">
                              <FormattedMessage id="vencimientosAbm.render.general_data.label_detalle" defaultMessage="Detalle:"/>
                            </label>
                            <div className="col-md-10">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.comentario ? formData.comentario : ''}</div>
                              ) : (
                              <div>
                                <textarea className="form-control" id="comentario" name="comentario" rows="3" placeholder="" value={formData.comentario ? formData.comentario : ''} onChange={this.handleInputFormChange}></textarea>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* ACTIVO */}
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-8 label-control col-form-label text-nowrap" htmlFor="activo">
                                  <FormattedMessage id="vencimientosAbm.render.general_data.label_activo" defaultMessage="Activo:"/>
                                </label>
                                <div className="col-md-4 mt-auto">
                                  <Switch
                                    onChange={this.handleActivoChange}
                                    checked={formData.activo ? formData.activo : false}
                                    id="activo"
                                    name="activo"
                                    disabled={this.state.props.action === 'VIEW' ? true: false }
                                    offColor="#FF4961"
                                    onColor="#28D094"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* RENOVACION */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-8 label-control col-form-label text-nowrap" htmlFor="renovacion">
                                  <FormattedMessage id="vencimientosAbm.render.general_data.label_renovacion" defaultMessage="Ejecución por única vez:"/>
                                </label>
                                <div className="col-md-4 mt-auto">
                                  <Switch
                                    onChange={e => this.handleRenovacionChange(!e)}
                                    checked={!formData.renovacion}
                                    id="renovacion"
                                    name="renovacion"
                                    disabled={this.state.props.action === 'VIEW' ? true: false }
                                    offColor="#FF4961"
                                    onColor="#28D094"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-calendar"></i><FormattedMessage id="vencimientosAbm.render.vigencia.header_vigencia" defaultMessage=" Vigencia "/><div className="float-right" style={{fontSize:'14px'}}>{ this.props.action === 'VIEW' ? '' : this.props.intl.formatMessage({ id: 'vencimientosAbm.render.vigencia.campos_requeridos', defaultMessage: '* campos requeridos' }) }</div>
                      </h4>
                      <div className="row">
                        {/* FECHA DE EXPEDICIÓN */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="fechaAlta">
                              <FormattedMessage id="vencimientosAbm.render.vigencia.label_fecha_de_expedicion" defaultMessage="Fecha de Expedición"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaAlta === null || formData.fechaAlta === '0000-00-00' ? '' : moment(formData.fechaAlta).format('L')}</div>
                              ) : (
                              <div>
                                <DatePicker
                                  id="fechaAlta"
                                  name="fechaAlta"
                                  className="form-control date-picker-placeholder"
                                  placeholderText={this.props.intl.formatMessage({ id: 'vencimientosAbm.render.general_data.placeholder_fecha_de_expedicion', defaultMessage: 'DD/MM/AAAA' })}
                                  selected={formData.fechaAlta === null || formData.fechaAlta === '0000-00-00' ? null : moment(formData.fechaAlta)}
                                  onChange={(event) => this.handleDatePickerFormChange("fechaAlta", event)}
                                  onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaAlta", event.target.value)}
                                  openToDate={moment()}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  autoComplete="off"
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.fechaAlta.pristine || validationState.formData.fechaAlta.valid}>{validationState.formData.fechaAlta.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* FECHA DE VENCIMIENTO */}
                        {formData.fechaAlta ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="fechaVencimiento">
                              <FormattedMessage id="vencimientosAbm.render.vigencia.label_fecha_de_vencimiento" defaultMessage="Fecha de Vencimiento"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaVencimiento === null || formData.fechaVencimiento === '0000-00-00' ? '' : moment(formData.fechaVencimiento).format('L')}</div>
                              ) : (
                              <div>
                                <DatePicker
                                  id="fechaVencimiento"
                                  name="fechaVencimiento"
                                  className="form-control date-picker-placeholder"
                                  placeholderText={this.props.intl.formatMessage({ id: 'vencimientosAbm.render.general_data.placeholder_fecha_de_vencimiento', defaultMessage: 'DD/MM/AAAA' })}
                                  selected={formData.fechaVencimiento === null || formData.fechaVencimiento === '0000-00-00' ? null : moment(formData.fechaVencimiento)}
                                  onChange={(event) => this.handleDatePickerFormChange("fechaVencimiento", event)}
                                  onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaVencimiento", event.target.value)}
                                  minDate={moment.max([moment(), moment(formData.fechaAlta)])}
                                  openToDate={moment()}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  autoComplete="off"
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.fechaVencimiento.pristine || validationState.formData.fechaVencimiento.valid}>{validationState.formData.fechaVencimiento.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : null }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-bullhorn"></i><FormattedMessage id="vencimientosAbm.render.avisos.header_vigencia" defaultMessage=" Avisos "/><div className="float-right" style={{fontSize:'14px'}}>{ this.props.action === 'VIEW' ? '' : this.props.intl.formatMessage({ id: 'vencimientosAbm.render.avisos.campos_requeridos', defaultMessage: '* campos requeridos' }) }</div>
                      </h4>
                      <div className="row">
                        {/* ALERTA DIAS PREVIOS */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="alertaDiasPrevios">
                              <FormattedMessage id="vencimientosAbm.render.avisos.label_primer_alerta" defaultMessage="Primer Alerta (dias antes):"/>
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.alertaDiasPrevios === 0 ? '': formData.alertaDiasPrevios }</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" pattern="[0-9]*" id="alertaDiasPrevios" name="alertaDiasPrevios" placeholder={this.props.intl.formatMessage({ id: 'vencimientosAbm.render.avisos.placeholder_primer_alerta', defaultMessage: 'Sólo números' })} value={formData.alertaDiasPrevios === 0 ? '': formData.alertaDiasPrevios} onInput={this.handleNumericChange.bind(this)} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* EMISIÓN DIAS PREVIOS */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="emisionDiasPrevios">
                              <FormattedMessage id="vencimientosAbm.render.avisos.label_emision" defaultMessage="Emisión (dias antes):"/>
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.emisionDiasPrevios === 0 ? '': formData.emisionDiasPrevios }</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" pattern="[0-9]*" id="emisionDiasPrevios" name="emisionDiasPrevios" placeholder={this.props.intl.formatMessage({ id: 'vencimientosAbm.render.avisos.placeholder_emision', defaultMessage: 'Sólo números' })} value={formData.emisionDiasPrevios === 0 ? '': formData.emisionDiasPrevios}  onInput={this.handleNumericChange.bind(this)} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {(this.props.action === 'VIEW' || this.props.action === 'EDIT') && !formData.ticket && Security.hasPermission('VENCIMIENTOS_FORZAR')? (
                          <button type="button" className="btn btn-success mr-1" onClick={this.handleForzar.bind(this)}>
                          <i className="la la-send align-middle"></i><FormattedMessage id="vencimientosAbm.finish_button.forzar" defaultMessage=" Forzar"/>
                          </button>
                        ) : (
                        <div style={{display:'none'}}></div>
                        )}
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
                          <i className="fa fa-check-circle"></i><FormattedMessage id="vencimientosAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'vencimientosAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'vencimientosAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(VencimientosAbm);
