import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import * as Utils from '../../commons/utils/Utils';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Radio,
  FormControlLabel,
  RadioGroup
} from '@material-ui/core';
import { StyledTableCell } from '../../commons/utils/TableStyles';
import Timezone from '../../commons/timezone/Timezone.js';
import { StyledTooltip } from '../../commons/utils/TableStyles';
import $ from 'jquery';
import CecosGrid from './CecosGrid.js';

const HistoricoAdmGrid = (props) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState('tjtComb');
  const [tjtComData, setTjtComData] = useState(null);

  const handleChangeRadio = (event) => {
    setRadioValue(event.target.value)
  }

  const handleExport = () => {
    let data = null;
    switch (radioValue) {
      case 'tjtComb':
        data = tjtComData;
        break;
      default:
        break;
    }

    data = data.map(e => {
      e = {...e};
      e.usuario = e.usuario.nombreApellido;
      return e;
    })

    let auxDataToExport = JSON.stringify({
      dataToExport: data,
      entidad: radioValue
    });

    Utils.sendDataBlob(`/moviles/exportar-historico-datos-generales`, 'POST', auxDataToExport)
      .then((data) => {
        let fileBlob = new File([data], 'historico-datos-generales.xlsx')
        let fileUrl = URL.createObjectURL(fileBlob);
        $("<a />", {
          href: fileUrl,
          download: "historico-tjt-combustible.xlsx"
        }).appendTo("body").get(0).click();
      })
      .catch(() => {
      })
  }

  useEffect(() => {
    setLoading(true)
    Utils.getData(`/moviles/${props.movilId}/tarjeta_combustible/grid`)
      .then((res) => {
        setTjtComData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [])

  return (
    <div className="card">
      <div className="card-content">
        <div className="card-body">
          <div className="container-fluid mb-1">
            <div className="row dt-icons">
              <div className="col-md-11">
                <RadioGroup row value={radioValue} onChange={handleChangeRadio}>
                  <FormControlLabel
                    value="tjtComb"
                    label={<FormattedMessage id="movilesAbm.historicoAdmGenerales_modal.radio.tjtCombustible.label" defaultMessage="Tarjeta Combustible" />}
                    control={<Radio color="default" />}
                  />
                  <FormControlLabel
                    value="ceco"
                    label={<FormattedMessage id="movilesAbm.historicoAdmGenerales_modal.radio.cecos.label" defaultMessage="CECOs" />}
                    control={<Radio color="default" />}
                  />
                </RadioGroup>
              </div>
              <div className="col-md-1">
                <div style={{ marginLeft: "85px" }}>
                  <StyledTooltip
                    title={intl.formatMessage({
                      id: 'movilesAbm.historicoAdmGenerales_modal.tools_export.title_exportar_a_excel',
                      defaultMessage: 'Exportar a Excel',
                    })}
                    arrow
                    placement="top"
                  >
                    <button onClick={() => handleExport()} className="btn" type="button">
                      <span>
                        <i className="ft-upload"></i>
                      </span>
                    </button>
                  </StyledTooltip>
                </div>
              </div>
            </div>
          </div>
          {/* Historico Tarjeta Combustible */}
          {radioValue === 'tjtComb' &&
            <TableContainer>
              <Table aria-label="clases table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.historicoAdmGenerales_modal.column_fecha.label" defaultMessage="Fecha" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.historicoAdmGenerales_modal.column_tjtCombustible.label" defaultMessage="Tarjeta de combustible" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.historicoAdmGenerales_modal.column_personaModificacion.label" defaultMessage="Persona Modificación" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.historicoAdmGenerales_modal.column_razonCambio.label" defaultMessage="Razón de cambio" />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormattedMessage id="movilesAbm.historicoAdmGenerales_modal.column_comentario.label" defaultMessage="Comentario" />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <StyledTableCell colSpan={4} align="center">
                        <FormattedMessage
                          id="Cargando"
                          defaultMessage="Cargando"
                        />
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    (tjtComData && tjtComData.map((tjtCom, key) => (
                      <TableRow key={key}>
                        <StyledTableCell align="left">{Timezone.getDateForClient(tjtCom.fecha, null, 'DD-MM-YYYY HH:mm')}</StyledTableCell>
                        <StyledTableCell align="left">{tjtCom.tarjetaCombustible}</StyledTableCell>
                        <StyledTableCell align="left">{tjtCom.usuario.nombreApellido}</StyledTableCell>
                        <StyledTableCell align="left">{tjtCom.razon}</StyledTableCell>
                        <StyledTableCell align="left">{tjtCom.comentario}</StyledTableCell>
                      </TableRow>
                    )
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          }
          {/* Historico CECOS */}
          {radioValue === 'ceco' &&
            <CecosGrid movilId={props.movilId} movilDominio={props.movilDominio} />
          }
        </div>
      </div>
    </div>
  )
}
export default HistoricoAdmGrid;