import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
	Collapse,
	TextField,
	CircularProgress,
	Grid,
	Box,
} from '@material-ui/core';
import Switch from "react-switch";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

const FILTER_STATE_INIT = {
	almacen: "consolidado",
	item: '',
	subcategoria: null,
	cantidad: '',
	costo: '',
	total: '',
	unidad: '',
	tipo: null,
	numeroParte: '',
}

const QUERY_INIT = {
	almacen: null,
	item: '',
	subcategoria: null,
	cantidad: '',
	costo: '',
	total: '',
	unidad: '',
	tipo: null,
	numeroParte: '',
}

const handleInitFilters = () => {
	let filterInit = JSON.parse(JSON.stringify(FILTER_STATE_INIT))
	let storage = JSON.parse(window.localStorage.getItem('MovilesGridFilter'))
	if (storage && storage.activo && storage.filtros) {
		filterInit = storage.filtros
	}
	return filterInit
}

const PanolGridFiltros = ({
	dropdownActive,
	setDropdownActive,
	filterQuery,
	setFilterQuery,
	pagination,
	loading
}) => {
	const intl = useIntl();
	const numberInputEexceptSymbols = ["e", "E", "+", "-", ".", ","]

	const [init, setInit] = useState(false)
	const [filtersLoading, setFiltersLoading] = useState(false)
	const [filtersState, setFiltersState] = useState({ ...FILTER_STATE_INIT })

	//Filters Selects values
	const [almacenes, setAlmacenes] = useState([{ value: "consolidado", label: "Consolidado" }])
	// const [item, setItem] = useState([])
	const [subcategorias, setSubcategorias] = useState([])
	// const [cantidad, setCantidad] = useState([])
	// const [costo, setCosto] = useState([])
	// const [total, setTotal] = useState([])
	// const [unidad, setUnidad] = useState([])
	// const [tipo, setTipo] = useState([])
	// const [numeroParte, setNumeroParte] = useState([])

	useEffect(() => {
		fetchData()
		initFilter()
	}, []);
	useEffect(() => {
		// !dropdownActive && props.setFilterResetInputs(false)
		!dropdownActive && setFiltersLoading(false)
	}, [dropdownActive]);

	const fetchData = () => {
		console.log('fetching Data')
		setFiltersLoading(true)
		Promise.all([
			Utils.getData('/panol2/select/almacenes'),
			Utils.getData('/panol/subcategorias/grid')
		]).then((data) => {
			if (data[0]) {
				setAlmacenes([...almacenes, ...data[0]])
			}
			if (data[1]) {
				const sCats = data[1].map((item) => { return { value: item.id, label: item.nombre } })
				setSubcategorias(sCats)
			}
		}).catch((err) => {
			setFiltersLoading(false)
			console.log(err);
		})
	}

	useEffect(() => {
		// console.log(filtersState)
	}, [filtersState])

	const initFilter = () => {
		console.log('init filter')
		// setFiltersLoading(true);
		// let query = {}

		// if(props.persisteFiltros || validatorNotNull()){
		// 	props.setSearchActive(true);
		// }

		// query = queryTable();

		// if(props.kpi){
		// 	props.handleAlertClick(props.kpi.filter)
		// }
		// setInit(true)

		// window.localStorage.setItem('MovilesGridFilter',JSON.stringify({activo: props.persisteFiltros, filtros: filtersState}));
		// props.setFilterQuery(query);
		// setFiltersLoading(false)
		// return;
	}

	const queryTable = () => {
		console.log('q table')
		// let query = {}
		// query = JSON.parse(JSON.stringify(filtersState));
		// if(filtersState.estado && filtersState.estado.length){
		// 	query['estado'] = filtersState.estado.map((estado)=>estado.value).join(',')
		// }
		// query['marca'] = filtersState.marca ? filtersState.marca.value : ''
		// query['tipo'] = filtersState.tipo ? filtersState.tipo.value : ''
		// query['pais'] = filtersState.pais ? filtersState.pais.value : ''
		// query['region'] = filtersState.region ? filtersState.region.value : ''
		// query['subRegion'] = filtersState.subRegion ? filtersState.subRegion.value : ''
		// query['base'] = filtersState.base ? filtersState.base.value : ''
		// query['titular'] = filtersState.titular ? filtersState.titular.value : ''
		// query['companiaOrigen'] = filtersState.companiaOrigen ? filtersState.companiaOrigen.value : ''
		// query['centroCosto'] = filtersState.centroCosto ? filtersState.centroCosto.value : ''

		// query['unidad'] = filtersState.unidad ? filtersState.unidad : ''
		// query['chasis'] = filtersState.chasis ? filtersState.chasis : ''
		// query['dominio'] = filtersState.dominio ? filtersState.dominio : ''
		// query['modelo'] = filtersState.modelo ? filtersState.modelo : ''
		// query['cecosDireccion'] = filtersState.cecosDireccion ? filtersState.cecosDireccion : ''
		// query['cecosMercado'] = filtersState.cecosMercado ? filtersState.cecosMercado : ''
		// query['cecosGerencia'] = filtersState.cecosGerencia ? filtersState.cecosGerencia : ''
		// query['cecosArea'] = filtersState.cecosArea ? filtersState.cecosArea : ''
		// query['responsable1'] = filtersState.responsable1 ? filtersState.responsable1 : ''
		// query['medidor'] = filtersState.medidor ? filtersState.medidor : ''
		// query['showInactivos'] = filtersState.showInactivos ? filtersState.showInactivos : ''
		// query['medidorDesde'] = filtersState.medidorDesde ? filtersState.medidorDesde : ''
		// query['medidorHasta'] = filtersState.medidorHasta ? filtersState.medidorHasta : ''
		// if(props.kpi){
		// 	query['filtroKpi'] = props.kpi.filter
		// }
		// return query;
	}

	// const handleChange = (name, object) => {
	// 	let currentFilters = JSON.parse(window.localStorage.getItem('MovilesGridFilter'))['filtros'];
	// 	if (JSON.stringify(currentFilters[name]) !== JSON.stringify(object)) {
	// 		filtersState[name] = object;
	// 		let copyFiltersState = JSON.parse(JSON.stringify(filtersState));
	// 		setFiltersState(copyFiltersState);
	// 		window.localStorage.setItem('MovilesGridFilter', JSON.stringify({ activo: persisteFiltros, filtros: copyFiltersState }));
	// 		return;
	// 	}
	// };

	const handleChange = (key, object) => {
		console.log(key, 'ddd', object)

		let query = { ...filterQuery };

		switch (key) {
			// Selects
			case 'almacen':
			case 'subcategoria':
				query[key] = object ? object.value : '';
				break;
			// Inputs
			default:
				query[key] = object;
				break
		}
		setFilterQuery(query);

	}

	const handleOnEnter = (e, name, value) => {
		// e.preventDefault();
		if (e.keyCode === 13) {
			e.preventDefault();
			// handleChange(name, value);
		}
	};

	const handleChangeInactivosSwich = (value) => {
		setFiltersState({ ...filtersState, showInactivos: value });
	};

	// useEffect(() => {
	// 	if (init) {
	// 		if (persisteFiltros) {
	// 			window.localStorage.setItem('MovilesGridFilter', JSON.stringify({ activo: true, filtros: filtersState }));
	// 		}
	// 		else {
	// 			setFiltersState(JSON.parse(JSON.stringify(FILTER_STATE_INIT)));

	// 			setFilterQuery(JSON.parse(JSON.stringify(QUERY_INIT)));
	// 			window.localStorage.setItem('MovilesGridFilter', JSON.stringify({ activo: false, filtros: FILTER_STATE_INIT }));
	// 		}
	// 	}
	// }, [persisteFiltros])

	// useEffect(() => {
	// 	setValidatorSearch(validator());
	// },[filtersState])

	const validator = () => {
		var result = Object.entries(filtersState);
		for (var index in result) {
			if (Array.isArray(result[index][1])) {
				if (result[index][1].length > 0) {
					return false
				}
			} else {
				if (result[index][1]) {
					return false
				}
			}
		}
		return true
	}

	const validatorNotNull = () => {
		var result = Object.entries(filtersState);
		for (var index in result) {
			if (Array.isArray(result[index][1])) {
				if (result[index][1].length > 0) {
					return true
				}
			} else {
				if (result[index][1]) {
					return true
				}
			}
		}
		return false
	}

	// const handleSubmit = (event) => {
	// 	handleFilterChange();
	// 	event.preventDefault();
	// 	setSearchActive(true)
	// 	let query = queryTable();
	// 	setFilterQuery(query);
	// 	return;
	// }

	// const onChangePersistentFilter = (value) => {
	// 	window.localStorage.setItem('MovilesGridFilter', JSON.stringify({ activo: value, filtros: filtersState }));
	// 	setPersisteFiltros(value)
	// }
	const handleSubmit = (event) => {
		event.preventDefault();
		// let copyFiltersState = JSON.parse(JSON.stringify(filtersState));
		// onFilterChange();
		// setSearchActive(true)
		// let query = queryTable();
		// setFilterQuery(query);
		// return;
	}

	const handleSubmitInto = (event) => {
		// if(!loading && !validatorSearch){
		if (!loading) {
			handleSubmit(event);
		}
		event.preventDefault();
	}

	console.log('almavenes', almacenes)
	return (
		<>
			<Collapse in={dropdownActive}>
				<Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }} className="filtros">
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off">
							<Autocomplete
								size="small"
								name="almacen"
								disabled={filtersLoading}
								options={almacenes}
								value={filtersState.almacen}
								getOptionLabel={(option) => (option ? option.label : "")}
								onChange={(e, value) => setFiltersState({ ...filtersState, almacen: value })}
								onKeyDown={(e) => handleOnEnter(e, 'almacen', filtersState.almacen)}
								onBlur={(e) => handleChange('almacen', filtersState.almacen)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({ id: "almacen", defaultMessage: "Almacen" })}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off">
							<Autocomplete
								size="small"
								name="subcategoria"
								disabled={filtersLoading}
								options={subcategorias}
								value={filtersState.subcategoria}
								getOptionLabel={(option) => (option ? option.label : "")}
								onChange={(e, value) => setFiltersState({ ...filtersState, subcategoria: value })}
								onKeyDown={(e) => handleOnEnter(e, 'subcategoria', filtersState.subcategoria)}
								onBlur={(e) => handleChange('subcategoria', filtersState.subcategoria)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({ id: "subcategoria", defaultMessage: "Subcategoria" })}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off">
							<TextField
								label={intl.formatMessage({
									id: 'panol.item',
									defaultMessage: 'Item',
								})}
								name="item"
								value={filtersState.item}
								onChange={(e) => setFiltersState({ ...filtersState, item: e.target.value })}
								// onKeyDown={(e) => handleOnEnter(e, 'item', filtersState.item)}
								onBlur={(e) => handleChange('item', filtersState.item)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
				</Grid>
				{/* <Grid container item xs={12} justifyContent="flex-end" style={{ marginBottom: 20 }}>
					<Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
						<button
							disabled={loading}
							className="btn btn-primary mr-1"
							onClick={(e) => handleSubmit(e)}
						>
							<i className="fa fa-search fa-xs"></i> <FormattedMessage id="Buscar" defaultMessage="Buscar" />
						</button>
					</Grid>
				</Grid> */}
			</Collapse>
		</>
	)
}

export default PanolGridFiltros
