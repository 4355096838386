import {
    Box,
    CircularProgress,
    Collapse,
    Grid,
    TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import Switch from "react-switch";
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import * as Utils from '../../commons/utils/Utils';

const FILTER_STATE_INIT = {
	marca: null,
	estado: [],
	tipo: null,
	pais: null,
	region: null,
	subRegion: '',
	base: null,
	titular: null,
	companiaOrigen: null,
	centroCosto: null,
	unidad:'',
	chasis: '',
	dominio: '',
	modelo: '',
	cecosDireccion: '',
	cecosMercado: '',
	cecosGerencia: '',
	cecosArea: '',
	responsable1: '',
	medidor: '',
	medidorDesde: '',
	medidorHasta: '',
	showInactivos: false,
	filtroKpi: '',
	supervisores: [],
	temporales: [],
}

const QUERY_INIT = {
	marca: '',
	estado: [],
	tipo: '',
	pais: '',
	region: '',
	subRegion: '',
	base: '',
	titular: '',
	companiaOrigen: '',
	centroCosto: '',
	unidad:'',
	chasis: '',
	dominio: '',
	modelo: '',
	cecosDireccion: '',
	cecosMercado: '',
	cecosGerencia: '',
	cecosArea: '',
	responsable1: '',
	medidor: '',
	medidorDesde: '',
	medidorHasta: '',
	showInactivos: false,
	filtroKpi: '',
    supervisores: '',
	temporales: '',
}

const handleInitFilters = () => {
	let filterInit = JSON.parse(JSON.stringify(FILTER_STATE_INIT))
	let storage = JSON.parse(window.localStorage.getItem('MovilesGridFilter'))
	if(storage && storage.activo && storage.filtros){
		filterInit = storage.filtros
	}
	return filterInit
}

const MovilesGridFiltros = (props) => {
	const intl = useIntl();
	const columnsEnabled = props.columnsEnabled
	const numberInputEexceptSymbols = ["e", "E", "+", "-", ".",  ","]

	const [init,setInit] = useState(false)
	const [filtersLoading, setFiltersLoading] = useState(false);
	const [isEachFilterLoading, setIsEachFilterLoading] = useState({
		marcas: true,
		estados: true,
		tipos: true,
		paises: true,
		regiones: true,
		subRegiones: true,
		bases: true,
		cecos: true,
		companiasOrigen: true,
		personas: true,
		titulares: true
	})
	const [filtersState, setFiltersState] = useState(handleInitFilters)

	//Filters Selects values
	const [marcas, setMarcas] = useState([])
	const [estados, setEstados] = useState([])
	const [tipos, setTipos] = useState([])
	const [paises, setPaises] = useState([])
	const [regiones, setRegiones] = useState([])
	const [subRegiones, setSubRegiones] = useState([])
	const [bases, setBases] = useState([])
	const [titulares, setTitulares] = useState([])
	const [companiasOrigen, setCompaniasOrigen] = useState([])
	const [centrosCostos, setCentrosCostos] = useState([])
	const [validatorSearch, setValidatorSearch] = useState(false)
    const [supervisores, setSupervisores] = useState([]);
    const [temporales, setTemporales] = useState([]);    

	useEffect(() => {
		fetchData()
		initFilter()
	}, []);

	useEffect(() => {
		if(props.filterResetInputs){
			setFiltersState(JSON.parse(JSON.stringify(FILTER_STATE_INIT)));
			window.localStorage.setItem('MovilesGridFilter',JSON.stringify({activo: false, filtros: FILTER_STATE_INIT}));
		}
	},[props.filterResetInputs])

	useEffect(() => {
		!props.dropdownActive && props.setFilterResetInputs(false)
		!props.dropdownActive && setFiltersLoading(false)
	}, [props.dropdownActive]);

	useEffect(() => {
		if(props.estadosFiltro.estados){
			let estados = props.estadosFiltro.estados ? props.estadosFiltro.estados.split(',') : []
			let estadoSelect =  [];
			estados.forEach(estado => {
				estadoSelect.push({ value: estado, label: estado });
			});
			filtersState['estado'] = estadoSelect;
			let copyFiltersState = JSON.parse(JSON.stringify(filtersState));

			let currentFilters = JSON.parse(window.localStorage.getItem('MovilesGridFilter'))['filtros'];
			if( JSON.stringify(currentFilters['estado']) !== JSON.stringify(estadoSelect)){
				window.localStorage.setItem('MovilesGridFilter', JSON.stringify({activo: props.persisteFiltros, filtros: copyFiltersState}));
			}

			setFiltersState(copyFiltersState);
			props.handleFilterChange();
			props.setSearchActive(true);
			props.setDropdownActive(true);
			
			let query = queryTable();
			props.setFilterQuery(query);
		}
	}, [props.estadosFiltro]);
	
	const fetchData = () => {
		setFiltersLoading(true)

		const loadFilters = (url, setter, filtro) => {
			Utils.getData(url)
			.then(data => setter(data || []))
			.catch(err => console.log(err))
			.finally(() => setIsEachFilterLoading(prevState => ({...prevState, [filtro]: false})));
		}
		
		loadFilters('/marcas/select', setMarcas, 'marcas');
		loadFilters('/modelo-tipos/select', setTipos, 'tipos');
		loadFilters('/paises/select', setPaises, 'paises');
		loadFilters('/regiones/select', setRegiones, 'regiones');
		loadFilters('/bases/select-filtered-by-user/movil', setBases, 'bases');
		loadFilters('/movil-titulares/select', setTitulares, 'titulares');
		loadFilters('/centros-costos/select', setCentrosCostos, 'cecos');
		loadFilters('/subregiones/select-all', setSubRegiones, 'subRegiones');

		Utils.getData('/personas/simple-search?filterNotInternal=true&search=')
		.then(personas => {
			const formattedPersonas = personas.map(item => ({
				id: item.id,
				label: item.label
			}));
			setSupervisores(formattedPersonas);
			setTemporales(formattedPersonas);
			
		})
		.catch(err => console.log(err))
		.finally(() => setIsEachFilterLoading(prevState => ({...prevState, personas: false})))
		
		Promise.all([
			ConfigBusiness.get('moviles.estados'),
			ConfigBusiness.get('moviles.companiaOrigen.valores'),
		])
		.then(data => {
			let menuEstado = [];
			const estados = data[0] ? data[0].split(',') : null;
			if (estados != null) {
				estados.forEach(estado => {
					menuEstado.push({ value: estado, label: estado });
				});
			}
			setEstados(menuEstado);

			const companiasOrigen = data[1] ? data[1].split(',') : null;
			let menuCompaniasOrigen = [];
			if (companiasOrigen != null) {
				companiasOrigen.forEach(compania => {
					menuCompaniasOrigen.push({ value: compania, label: compania });
				});
			}
			setCompaniasOrigen(menuCompaniasOrigen);
			
		})
		.catch(err => console.log(err))
		.finally(() => {
			setIsEachFilterLoading(prevState => ({...prevState, estados: false}))
			setIsEachFilterLoading(prevState => ({...prevState, companiasOrigen: false}))
		})
	}

	const initFilter = () => {
		setFiltersLoading(true);
		let query = {}

		if(props.persisteFiltros || validatorNotNull()){
			props.setSearchActive(true);
		}
		
		query = queryTable();
		
		if(props.kpi){
			props.handleAlertClick(props.kpi.filter)
		}
		setInit(true)
		
		window.localStorage.setItem('MovilesGridFilter',JSON.stringify({activo: props.persisteFiltros, filtros: filtersState}));
		props.setFilterQuery(query);
		setFiltersLoading(false)
		return;
	}

	const queryTable = () => {
		let query = {}
		query = JSON.parse(JSON.stringify(filtersState));
		if(filtersState.estado && filtersState.estado.length){
			query['estado'] = filtersState.estado.map((estado)=>estado.value).join(',')
		}
		query['marca'] = filtersState.marca ? filtersState.marca.value : ''
		query['tipo'] = filtersState.tipo ? filtersState.tipo.value : ''
		query['pais'] = filtersState.pais ? filtersState.pais.value : ''
		query['region'] = filtersState.region ? filtersState.region.value : ''
		query['subRegion'] = filtersState.subRegion ? filtersState.subRegion.value : ''
		query['base'] = filtersState.base ? filtersState.base.value : ''
		query['titular'] = filtersState.titular ? filtersState.titular.value : ''
		query['companiaOrigen'] = filtersState.companiaOrigen ? filtersState.companiaOrigen.value : ''
		query['centroCosto'] = filtersState.centroCosto ? filtersState.centroCosto.value : ''

		query['unidad'] = filtersState.unidad ? filtersState.unidad : ''
		query['chasis'] = filtersState.chasis ? filtersState.chasis : ''
		query['dominio'] = filtersState.dominio ? filtersState.dominio : ''
		query['modelo'] = filtersState.modelo ? filtersState.modelo : ''
		query['cecosDireccion'] = filtersState.cecosDireccion ? filtersState.cecosDireccion : ''
		query['cecosMercado'] = filtersState.cecosMercado ? filtersState.cecosMercado : ''
		query['cecosGerencia'] = filtersState.cecosGerencia ? filtersState.cecosGerencia : ''
		query['cecosArea'] = filtersState.cecosArea ? filtersState.cecosArea : ''
		query['responsable1'] = filtersState.responsable1 ? filtersState.responsable1 : ''
		query['medidor'] = filtersState.medidor ? filtersState.medidor : ''
		query['showInactivos'] = filtersState.showInactivos ? filtersState.showInactivos : ''
		query['medidorDesde'] = filtersState.medidorDesde ? filtersState.medidorDesde : ''
		query['medidorHasta'] = filtersState.medidorHasta ? filtersState.medidorHasta : ''
		query['supervisores'] = filtersState.supervisores ? filtersState.supervisores : []
		query['temporales'] = filtersState.temporales ? filtersState.temporales : []
		if(props.kpi){
			query['filtroKpi'] = props.kpi.filter
		}
		return query;
	}

	const handleChange = (name, object) => {
        try {
            // Leer el filtro actual del localStorage
            // let storedFilters = JSON.parse(window.localStorage.getItem('MovilesGridFilter')) || { filtros: {} };

            // Actualizar el estado aunque no haya un cambio en el localStorage
            setFiltersState((prevState) => {
                const updatedFiltersState = { ...prevState, [name]: object };                

                // Guardar en localStorage solo si la persistencia está habilitada
                if (props.persisteFiltros) {
                    window.localStorage.setItem(
                        'MovilesGridFilter',
                        JSON.stringify({
                            activo: props.persisteFiltros,
                            filtros: updatedFiltersState
                        })
                    );                    
                }
                return updatedFiltersState; // Retornar el nuevo estado
            });            
        } catch (error) {
            console.error("Error al manejar el cambio de filtros:", error);
        }
    };

	const handleChangeInactivosSwich = (value) => {
		setFiltersState({...filtersState, showInactivos : value});
	};

	useEffect(() => {
		if(init){
			if(props.persisteFiltros){
				window.localStorage.setItem('MovilesGridFilter', JSON.stringify({activo: true, filtros: filtersState}));
			}
			else{
				setFiltersState(JSON.parse(JSON.stringify(FILTER_STATE_INIT)));
				props.setFilterQuery(JSON.parse(JSON.stringify(QUERY_INIT)));
				window.localStorage.setItem('MovilesGridFilter',JSON.stringify({activo: false, filtros: FILTER_STATE_INIT}));
			} 
		}
	},[props.persisteFiltros])

	useEffect(() => {
		setValidatorSearch(validator());
	},[filtersState])

	const validator = () => {
		var result = Object.entries(filtersState);
		for (var index in result){
			if(Array.isArray(result[index][1])){
				if(result[index][1].length > 0 ){
					return false
				}
			}else{
				if(result[index][1]){
					return false
				}
			}
		}
		return true
	}

	const validatorNotNull = () => {
		var result = Object.entries(filtersState);
		for (var index in result){
			if(Array.isArray(result[index][1])){
				if(result[index][1].length > 0){
					return true
				}
			}else{
				if(result[index][1]){
					return true
				}
			}
		}
		return false
	}

	const handleSubmit = (event) => {
		props.handleFilterChange();
		event.preventDefault();
		props.setSearchActive(true)
		let query = queryTable();
		props.setFilterQuery(query);
		return;
	}

	const onChangePersistentFilter = (value) => {
		window.localStorage.setItem('MovilesGridFilter', JSON.stringify({activo: value, filtros: filtersState}));
		props.setPersisteFiltros(value)
	}

	const handleSubmitInto = (event) => {
		if(!props.loading && !validatorSearch){
			handleSubmit(event);
		}
		event.preventDefault();
	}
	return (
		<>
		{init &&
			<Collapse in={props.dropdownActive}>
				<Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="marca"
								disabled={isEachFilterLoading.marcas}
								options={marcas}
								value={filtersState.marca}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, v) => handleChange('marca', v)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_marca.label", defaultMessage: "Marca" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_marca.placeholder", defaultMessage: "Marca" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.marcas ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								multiple={true}
								size="small"
								name="estado"
								disabled={isEachFilterLoading.estados}
								options={estados}
								value={filtersState.estado}
								defaultValue={[]}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, v) => handleChange('estado', v)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({
											id: "movilesGrid.render.filtros_estados.label",
											defaultMessage: "Estados"
										})}
										placeholder={intl.formatMessage({
											id: "movilesGrid.render.filtros_estados.placeholder",
											defaultMessage: "Estados"
										})}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.estados ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.unidadEnabled && {display:"none"}}  component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_unidad.label", defaultMessage: "Unidad" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_unidad.placeholder", defaultMessage: "Unidad" })}
								name="unidad"
								value={filtersState.unidad}
								onChange={(e) => handleChange('unidad', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.chasisEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_chasis.label", defaultMessage: "Chasis" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_chasis.placeholder", defaultMessage: "Chasis" })}
								name="chasis"
								value={filtersState.chasis}
								onChange={(e) => handleChange('chasis', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_dominio.label", defaultMessage: "Dominio" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_dominio.placeholder", defaultMessage: "Dominio" })}
								name="dominio"
								value={filtersState.dominio}
								onChange={(e) => handleChange('dominio', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_modelo.label", defaultMessage: "Modelo" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_modelo.placeholder", defaultMessage: "Modelo" })}
								name="modelo"
								value={filtersState.modelo}
								onChange={(e) => handleChange('modelo', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="tipo"
								disabled={isEachFilterLoading.tipos}
								options={tipos}
								value={filtersState.tipo}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('tipo', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_tipo.label", defaultMessage: "Tipo" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_tipo.placeholder", defaultMessage: "Tipo" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.tipos ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.paisEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="pais"
								options={paises}
								disabled={isEachFilterLoading.paises}
								value={filtersState.pais}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('pais', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_pais.label", defaultMessage: "País" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_pais.placeholder", defaultMessage: "País" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.paises ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.regionEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="region"
								disabled={isEachFilterLoading.regiones}
								options={regiones}
								value={filtersState.region}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('region', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_region.label", defaultMessage: "Región" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_region.placeholder", defaultMessage: "Región" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.regiones ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.subRegionEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="subRegion"
								disabled={isEachFilterLoading.subRegiones}
								options={subRegiones}
								value={filtersState.subRegion}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('subRegion', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_subRegion.label", defaultMessage: "Sub.Región" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_subRegion.placeholder", defaultMessage: "Sub.Región" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.subRegiones ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.baseEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="base"
								disabled={isEachFilterLoading.bases}
								options={bases}
								value={filtersState.base}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('base', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_base.label", defaultMessage: "Base" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_base.placeholder", defaultMessage: "Base" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.bases ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.titularEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="titular"
								disabled={isEachFilterLoading.titulares}
								options={titulares}
								value={filtersState.titular}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('titular', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_titular.label", defaultMessage: "Titular" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_titular.placeholder", defaultMessage: "Titular" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.titulares ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.companiaOrigenEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="companiaOrigen"
								disabled={isEachFilterLoading.companiasOrigen}
								options={companiasOrigen}
								value={filtersState.companiaOrigen}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('companiaOrigen', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_companiaOrigen.label", defaultMessage: "Cía. Origen" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_companiaOrigen.placeholder", defaultMessage: "Cía. Origen" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.companiasOrigen ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.centroCostosEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="centroCosto"
								disabled={isEachFilterLoading.cecos}
								options={centrosCostos}
								value={filtersState.centroCosto}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange('centroCosto', value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto.label", defaultMessage: "Centro de Costos" })}
										placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto.placeholder", defaultMessage: "Centro de Costos" })}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isEachFilterLoading.cecos ? (
														<CircularProgress color="inherit" size={21} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!columnsEnabled.cecosDireccionEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_direccion.label", defaultMessage: "CC.Dirección" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_direccion.placeholder", defaultMessage: "CC.Dirección" })}
								name="cecosDireccion"
								value={filtersState.cecosDireccion}
								onChange={(e) => handleChange('cecosDireccion', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.cecosMercadoEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_mercado.label", defaultMessage: "CC.Mercado" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_mercado.placeholder", defaultMessage: "CC.Mercado" })}
								name="cecosMercado"
								value={filtersState.cecosMercado}
								onChange={(e) => handleChange('cecosMercado', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.cecosGerenciaEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_gerencia.label", defaultMessage: "CC.Gerencia" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_gerencia.placeholder", defaultMessage: "CC.Gerencia" })}
								name="cecosGerencia"
								value={filtersState.cecosGerencia}
								onChange={(e) => handleChange('cecosGerencia', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
					<Grid {...!columnsEnabled.cecosAreaEnabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_area.label", defaultMessage: "CC.Área" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_area.placeholder", defaultMessage: "CC.Área" })}
								name="cecosArea"
								value={filtersState.cecosArea}
								onChange={(e) => handleChange('cecosArea', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>

					<Grid {...!columnsEnabled.responsable1Enabled && {display:"none"}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "movilesGrid.render.filtros_responsable1.label", defaultMessage: "Responsable 1" })}
								placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_responsable1.placeholder", defaultMessage: "Responsable 1" })}
								name="responsable1"
								value={filtersState.responsable1}
								onChange={(e) => handleChange('responsable1', e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>
                    {/* Combo para Supervisor (si está habilitado) */}
                    {columnsEnabled.supervisorEnabled && (
                        <Grid component={Box} item xs={3}>
                            <form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
                                <Autocomplete
                                    multiple
                                    size="small"
                                    name="supervisores"
                                    disabled={isEachFilterLoading.personas}
                                    options={supervisores} 
                                    value={filtersState.supervisores.map(id => supervisores.find(sup => sup.id === id)) || []} // Convertir ids a objetos
                                    getOptionLabel={(option) => option ? option.label : ""} // Mostrar el label en el dropdown
                                    onChange={(e, value) => handleChange('supervisores', value.map(v => v.id))} // Guardar solo los ids
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={intl.formatMessage({ id: "movilesGrid.render.filtros_supervisor.label", defaultMessage: "Supervisor" })}
                                            placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_supervisor.placeholder", defaultMessage: "Supervisor" })}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {isEachFilterLoading.personas ? (
                                                            <CircularProgress color="inherit" size={21} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                    getOptionSelected={(option, value) => option.id === value.id} // Comparar por 'id'
                                />
                            </form>
                        </Grid>
                    )}
                    {/* Combo para Temporal (si está habilitado) */}
                    {columnsEnabled.temporalEnabled && (
                        <Grid component={Box} item xs={3}>
                            <form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
                                <Autocomplete
                                    multiple // Habilitar multiselección como en Supervisores
                                    size="small"
                                    name="temporales"
                                    disabled={isEachFilterLoading.personas}
                                    options={temporales} // Este array tiene objetos con { id, label }
                                    value={filtersState.temporales.map(id => temporales.find(temp => temp.id === id)) || []} // Convertir ids a objetos
                                    getOptionLabel={(option) => option ? option.label : ""} // Mostrar el label en el dropdown
                                    onChange={(e, value) => handleChange('temporales', value.map(v => v.id))} // Guardar solo los ids
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={intl.formatMessage({ id: "movilesGrid.render.filtros_temporal.label", defaultMessage: "Temporal" })}
                                            placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_temporal.placeholder", defaultMessage: "Temporal" })}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {isEachFilterLoading.personas ? (
                                                            <CircularProgress color="inherit" size={21} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                    getOptionSelected={(option, value) => option.id === value.id} // Compara por 'id'
                                />
                            </form>
                        </Grid>
                    )}
					<Grid component={Box} item xs={3}>
						<Grid container item xs={12} spacing={3}>
							<Grid component={Box} item xs={6}>
								<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
									<TextField
										label={intl.formatMessage({ id: "Medidor_desde", defaultMessage: "Medidor desde" })}
										name="medidorDesde"
										type="number"
										value={filtersState.medidorDesde}
										onChange={(e) => handleChange('medidorDesde', e.target.value)}
										disabled={filtersLoading}
										className="col-12"
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</form>
								{ filtersState.medidorHasta !== '' && 
									filtersState.medidorDesde !== filtersState.medidorHasta &&
									Math.min(filtersState.medidorDesde, filtersState.medidorHasta) == filtersState.medidorHasta && (
								<small className="text-danger">< FormattedMessage id="movilesGrid.filters.error_valores_medidor" defaultMessage="Valores incorrectos" /></small>
								)}
							</Grid>
							<Grid component={Box} item xs={6}>
								<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
									<TextField
										label={intl.formatMessage({ id: "Medidor_hasta", defaultMessage: "Medidor hasta" })}
										name="medidorHasta"
										type="number"
										value={filtersState.medidorHasta}
										onChange={(e) => handleChange('medidorHasta', e.target.value)}
										disabled={filtersLoading}
										className="col-12"
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</form>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						xs={12}
						container
						justifyContent="space-between"
						alignItems="center"
						flexDirection={{ xs: 'column', sm: 'row' }}
						sx={{ fontSize: '12px' }}
					>
						<Grid component={Box} item xs={2} className="align-self-end">
							<div className="d-flex inline justify-content-around align-items-center">
								<FormattedMessage
									id="formulariosGridFilters.render.filtros.ver_inactivos"
									defaultMessage="Ver Inactivos"
								/>
								<Switch
									onChange={(value) => handleChangeInactivosSwich(value)}
									checked={filtersState.showInactivos}
									disabled={filtersLoading}
									offColor="#FF4961"
									onColor="#28D094"
								/>
							</div>
						</Grid>
						<Grid component={Box} item xs={2} className="align-self-end">
							<div className="d-flex inline justify-content-around align-items-center">
								<FormattedMessage
									id="movilesGrid.render.filtros.persistir_filtros"
									defaultMessage="Persistir Filtros"
								/>
								<Switch
									onChange={(value) => onChangePersistentFilter(value)}
									checked={props.persisteFiltros}
									disabled={filtersLoading}
									offColor="#FF4961"
									onColor="#28D094"
								/>
							</div>
						</Grid>
						<Grid component={Box} item xs={1} className="align-self-end"
						>
							<button
								disabled={props.loading || validatorSearch}
								className="btn btn-primary mr-1"
								onClick={(e) => handleSubmit(e)}
							>
								<i className="fa fa-search fa-xs"></i>   <FormattedMessage id="Buscar" defaultMessage="Buscar"/>
							</button>
						</Grid>
					</Grid>
				</Grid>
			</Collapse>
	}
		</>
	)
}

export default MovilesGridFiltros
