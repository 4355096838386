import Config from '../../commons/config/Config.js'
const axios = require('axios');
const configMedidas = {
		headers: {
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
		}
};
export const eventToJsonCaster = (event) => {
		event.preventDefault();
		let datosForm = new FormData(event.target);
		let jsonObj = {};
		for (let entry of datosForm.entries()) {
				jsonObj[entry[0]] = entry[1];
		}
 		return jsonObj;
}
export const getMedidas = () => {
		return axios.get(Config.get('apiUrlBase') + '/panol/unidades-medida/grid', configMedidas)
}

export const createMedidas = (event) => {
		let jsonObj = eventToJsonCaster(event);
		return axios.post(Config.get('apiUrlBase') + '/panol/unidades-medida/', jsonObj, configMedidas);
}

export const updateMedidas = (event) => {
		let jsonObj = eventToJsonCaster(event);
		let setId = jsonObj.id;
		delete jsonObj.id;
		return axios.put(Config.get('apiUrlBase') + '/panol/unidades-medida/' + setId, jsonObj, configMedidas);
}

export const deleteMedidas = (item) => {
    return axios.delete(Config.get('apiUrlBase') + '/panol/unidades-medida/'+item, configMedidas)
}

export const getMarcas = () => {
	return axios.get(Config.get('apiUrlBase') + '/panol/marcas/grid', configMedidas)
}

export const createMarcas = (event) => {
		let jsonObj = eventToJsonCaster(event);
		return axios.post(Config.get('apiUrlBase') + '/panol/marcas/', jsonObj, configMedidas);
}

export const updateMarcas = (event) => {
		let jsonObj = eventToJsonCaster(event);
		let setId = jsonObj.id;
		delete jsonObj.id;
		return axios.put(Config.get('apiUrlBase') + '/panol/marcas/' + setId, jsonObj, configMedidas);
}

export const deleteMarcas = (item) => {
    return axios.delete(Config.get('apiUrlBase') + '/panol/marcas/'+item, configMedidas)
}

export const getCategorias = () => {
		return axios.get(Config.get('apiUrlBase') + '/panol/categorias/grid', configMedidas)
}

export const createCategorias = (event) => {
		let jsonObj = eventToJsonCaster(event);
		return axios.post(Config.get('apiUrlBase') + '/panol/categorias/', jsonObj, configMedidas);
}

export const updateCategorias = (event) => {
		let jsonObj = eventToJsonCaster(event);
		let setId = jsonObj.id;
		delete jsonObj.id;
		return axios.put(Config.get('apiUrlBase') + '/panol/categorias/' + setId, jsonObj, configMedidas);
}

export const deleteCategorias = (item) => {
    return axios.delete(Config.get('apiUrlBase') + '/panol/categorias/'+item, configMedidas)
}

export const getSubCategorias = () => {
		return axios.get(Config.get('apiUrlBase') + '/panol/subcategorias/grid', configMedidas)
}

export const getSubCategoriasByCat = (id) => {
		return axios.get(Config.get('apiUrlBase') + '/panol/categorias/'+id+'/subcategorias', configMedidas)
}

export const createSubCategorias = (event) => {
		let jsonObj = eventToJsonCaster(event);
		return axios.post(Config.get('apiUrlBase') + '/panol/subcategorias/', jsonObj, configMedidas);
}

export const updateSubCategorias = (event) => {
		let jsonObj = eventToJsonCaster(event);
		let setId = jsonObj.id;
		delete jsonObj.id;
		return axios.put(Config.get('apiUrlBase') + '/panol/subcategorias/' + setId, jsonObj, configMedidas);
}

export const deleteSubCategorias = (item) => {
    return axios.delete(Config.get('apiUrlBase') + '/panol/subcategorias/'+item, configMedidas)
}

export const getItems = () => {
		return axios.get(Config.get('apiUrlBase') + '/panol/items/grid', configMedidas)
}

export const getItemsBySubCat = (id) => {
		return axios.get(Config.get('apiUrlBase') + '/panol/subcategorias/'+id+'/items', configMedidas)
}

export const createItems = (event) => {
		let jsonObj = eventToJsonCaster(event);
		return axios.post(Config.get('apiUrlBase') + '/panol/items/', jsonObj, configMedidas);
}

export const updateItems = (event) => {
		let jsonObj = eventToJsonCaster(event);
		let setId = jsonObj.id;
		delete jsonObj.id;
		return axios.put(Config.get('apiUrlBase') + '/panol/items/'+setId, jsonObj, configMedidas);
}

export const deleteItems = (item) => {
    return axios.delete(Config.get('apiUrlBase') + '/panol/items/'+item, configMedidas)
}

export const getAlmacenes = () => {
		return axios.get(Config.get('apiUrlBase') + '/panol/almacenes/grid', configMedidas)
}

export const getAlmacenesList = () => {
		return axios.get(Config.get('apiUrlBase') + '/panol/almacenes/', configMedidas)
}

export const getAlmacen = (id) => {
		return axios.get(Config.get('apiUrlBase') + '/panol/almacenes/'+id, configMedidas)
}

export const createAlmacenes = (event) => {
		let jsonObj = eventToJsonCaster(event);
		return axios.post(Config.get('apiUrlBase') + '/panol/almacenes/', jsonObj, configMedidas);
}

export const updateAlmacenes = (event) => {
		let jsonObj = eventToJsonCaster(event);
		let setId = jsonObj.id;
		delete jsonObj.id;
		return axios.put(Config.get('apiUrlBase') + '/panol/almacenes/'+setId, jsonObj, configMedidas);
}

export const deleteAlmacenes = (item) => {
    return axios.delete(Config.get('apiUrlBase') + '/panol/almacenes/'+item, configMedidas)
}

export const getMovAlmacenGrid = (id) => {
		return axios.get(Config.get('apiUrlBase') + '/panol/almacenes/'+id+'/movimientos', configMedidas)
}

export const getProdAlmacenGrid = (id) => {
		return axios.get(Config.get('apiUrlBase') + '/panol/almacenes/'+id, configMedidas)
}


export const createMovimiento = (e, datosOrden, datosTabla) => {
		let jsonObj = {};
		for (let entry of datosOrden.entries()) {
				jsonObj[entry[0]] = entry[1];
		}
		jsonObj.items = datosTabla;
		return axios.post(Config.get('apiUrlBase') + '/panol/movimientos-stock/', jsonObj, configMedidas);
}
export const createMovIngreso = (e, datosOrden, datosTabla) => {
		return createMovimiento(e, datosOrden, datosTabla)
}
export const createMovEgreso = (e, datosOrden, datosTabla) => {
		return createMovimiento(e, datosOrden, datosTabla)
}
export const createMovTransfer = (e, datosOrden, datosTabla) => {
		return createMovimiento(e, datosOrden, datosTabla)
}
export const createMovAjuste = (e, datosOrden, datosTabla) => {
		return createMovimiento(e, datosOrden, datosTabla)
}

export const getMovimiento = (id) => {
		return axios.get(Config.get('apiUrlBase') + '/panol/movimientos-stock/'+id, configMedidas)
}

export const getPlantillas = () => {
		return axios.get(Config.get('apiUrlBase') + '/panol/plantillas/grid', configMedidas)
}

export const getPlantilla = (id) => {
		return axios.get(Config.get('apiUrlBase') + '/panol/plantillas/'+id, configMedidas)
}

export const createPlantillas = (event) => {
		let jsonObj = eventToJsonCaster(event);
		return axios.post(Config.get('apiUrlBase') + '/panol/plantillas/', jsonObj, configMedidas);
}

export const itemsPlantillas = (event, plantilla) => {
	event.preventDefault();
	let idPlantilla = plantilla.id;
	let newItem = event.target.item.value;

	let cantItem = event.target.cantidad.value;
	let pid = plantilla.id;
	let pdescripcion = plantilla.descripcion;
	let pcodigo = plantilla.codigo;
	let pitems = plantilla.items;

	console.log("item:");
	console.log(newItem);
	console.log(cantItem);
	let newJsonItem = [];
	newJsonItem = newJsonItem.push({'item': newItem, 'cantidad': cantItem});
	//newJsonItem = JSON.stringify(newJsonItem);

	//NOTA: esta trayendo todo el detalle de los items, separar y descartar
	// solo se necesita id, item, cantidad,
	//


	console.log("nuevo item; ");
	console.log(JSON.stringify(newJsonItem));
	let jsonObj = {};
		for (let entry of pitems.entries())
		{
			jsonObj[entry[0]] = entry[1];
		}
	//jsonObj = jsonObj.push(newJsonItem);
	jsonObj = JSON.stringify(jsonObj);
	console.log("items final:");
	console.log(jsonObj);

	const item = jsonObj;

	const request = require('request');
	const options = {
	  'method': 'PUT',
	  'url': Config.get('apiUrlBase') + '/panol/plantillas/'+idPlantilla,
	  'headers': {
	    'Content-Type': 'application/json',
	    'Authorization-Token': localStorage.getItem("token")
	  },
	  body: jsonObj,

	};
	return new Promise(resolve => {
	    request(options, function (error, response, body) {
	        if(!error)
	            resolve(body);
	    })
	});
	console.log(jsonObj);

}

export const updatePlantillas = (event) => {
		let jsonObj = eventToJsonCaster(event);
		let setId = jsonObj.id;
		delete jsonObj.id;
		return axios.put(Config.get('apiUrlBase') + '/panol/plantillas/'+setId, jsonObj, configMedidas);
}


export const deletePlantillas = (item) => {
    return axios.delete(Config.get('apiUrlBase') + '/panol/plantillas/'+item, configMedidas)
}
//items plantilla
export const getItemPlantillaGrid = (id) => {
		return axios.get(Config.get('apiUrlBase') + '/panol/plantillas/'+id+'/items', configMedidas)
}
export const createItemPlantilla = (event, plantilla) => {
		event.preventDefault();
		let idPlantilla = plantilla.id;
		let datosForm = new FormData(event.target);
		//let newItem = event.target.item.value;
		//let cantItem = event.target.cantidad.value;
		datosForm.delete('categoria');
		datosForm.delete('subcategoria');
		let jsonObj = {};
		for (let entry of datosForm.entries())
		{
			jsonObj[entry[0]] = entry[1];
		}
		jsonObj = JSON.stringify(jsonObj);

		const item = jsonObj;
    	let request = require('request');
		let options = {
		  'method': 'POST',
		  'url': Config.get('apiUrlBase') + '/panol/plantillas/'+idPlantilla+'/items/',
		  'headers': {
		    'Content-Type': 'application/json',
		    'Authorization-Token': localStorage.getItem("token")
		  },
		  body: jsonObj,
		};
		console.log(localStorage.getItem("token"));
		return new Promise(resolve => {
		    request(options, function (error, response, body) {
		        if(!error)
		            resolve(body);
		    })
		}); //fin request
}
export const updateItemPlantilla = (event, plantilla) => {
		event.preventDefault();
		let idPlantilla = plantilla.id;
		let datosForm = new FormData(event.target);
		let idItem = event.target.id.value;
		console.log("item a editar: "+idItem);
		//let cantItem = event.target.cantidad.value;
		datosForm.delete('categoria');
		datosForm.delete('subcategoria');
		let jsonObj = {};
		for (let entry of datosForm.entries())
		{
			jsonObj[entry[0]] = entry[1];
		}
		jsonObj = JSON.stringify(jsonObj);

		const item = jsonObj;
    	let request = require('request');
		let options = {
		  'method': 'POST',
		  'url': Config.get('apiUrlBase') + '/panol/plantillas/'+idPlantilla+'/items/'+idItem,
		  'headers': {
		    'Content-Type': 'application/json',
		    'Authorization-Token': localStorage.getItem("token")
		  },
		  body: jsonObj,
		};
		console.log(localStorage.getItem("token"));
		return new Promise(resolve => {
		    request(options, function (error, response, body) {
		        if(!error)
		            resolve(body);
		    })
		}); //fin request
}
export const deleteItemPlantilla = (item, plantilla) => {
		//event.preventDefault();
		let idPlantilla = plantilla;
		let idItem = item;
  	return axios.delete(Config.get('apiUrlBase') + '/panol/plantillas/'+idPlantilla+'/items/'+idItem, configMedidas)
}
