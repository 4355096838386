import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import Config from "../../commons/config/Config.js";
import Loading from "../ui/Loading.js";
import $ from "jquery";
import "xlsx/dist/xlsx.full.min.js";
import {Grid} from '@material-ui/core';
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from '../../lang/backendStrings.js';
import swal from 'sweetalert'
import withReactContent from "sweetalert2-react-content";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Utils from '../../commons/utils/Utils';
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'
import TicketPresupuestoEditModal from "./TicketPresupuestoEditModal";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const requiredSymbol = ' *';

function Row(props) {
  const intl = props.intl;
  const [presupuestoTarea, setPresupuestoTarea] = useState(props.presupuestoTarea);
  const [servicioItem, setServicioItem] = useState(props.servicioItem);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "15px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "0px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: "15px",
    },
  }));

  const classEdit = useStyles();

  const updateElement = (data) => {
    props.updatePresupuestoTarea(data, props.index);
    presupuestoTarea.precio = data.costo
    closeModalEdit();
  }

  const closeModalEdit = (event) => {
    setShowModalEdit(false);
  };

  return (
    <React.Fragment>
      <tr key={presupuestoTarea.id}>
        <Modal
          open={showModalEdit}
          onClose={closeModalEdit}
          className={classEdit.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModalEdit}>
            <Box sx={style}>
              <TicketPresupuestoEditModal
                type="presupuestoTarea"
                intl={props.intl}
                element={presupuestoTarea}
                updateElement={updateElement}
                closeModalEdit={closeModalEdit}
              />
            </Box>
          </Fade> 
        </Modal>
        <td className="all custom-lineHeight">
          {presupuestoTarea.external_code}
        </td>
        <td className="all custom-lineHeight">
          {presupuestoTarea.nombre}
        </td>
        <td className="all custom-lineHeight">
          {presupuestoTarea.cuenta_mayor}
        </td>
        <td className="all custom-lineHeight">
          {presupuestoTarea.cuenta_mayor_descripcion}
        </td>
				<td className="all custom-lineHeight">
          {presupuestoTarea.precio}
        </td>
				<td className="all custom-lineHeight">
          <button
            className="action view btn btn-sm btn-icon btn-dt-grid text-danger"
            title={intl.formatMessage({ id: 'Eliminar', defaultMessage: 'Eliminar' })}
            data-togle="tooltip"
            data-placement="top"
            onClick={() => props.deleteTarea(props.index)}
          >
            <i className="fa fa-trash fa-xs"></i>
          </button>
          <button
            className="action edit btn btn-sm btn-icon btn-dt-grid text-warning"
            title={intl.formatMessage({ id: 'Editar', defaultMessage: 'Editar' })}
            data-togle="tooltip"
            data-placement="top"
            onClick={() => setShowModalEdit(true)}
          >
            <i className="fa fa-pencil fa-xs"/>
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
}

const NEW_TAREA_INIT = {
  id_tarea: null,
  external_code: '',
  nombre: '',
  cuenta_mayor: '',
  cuenta_mayor_descripcion: '',
  precio: '',
  servicio_id: null
};

const TicketPresupuestoManoObra = (props) => {
  const intl = props.intl;
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [newPresupuestoTarea, setNewPresupuestoTarea] = useState({ ...NEW_TAREA_INIT })
  const [tareas, setTareas] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [servicio, setServicio] = useState('');

  const setLoadingAndUpdate = (state) => {
    setLoading(state);
  };

  const handleMaterialChange = (name, value) => {
    let copy = JSON.parse(JSON.stringify(newPresupuestoTarea));
    if (name === "precio") {
      if ((value && value >= 0) || !value) {
        copy[name] = value;
      }
    }else{
      copy[name] = value;
    }
    setNewPresupuestoTarea(copy)
  };

  const HandleExternalCode = (e) => {
    let external_code = e.target.value;
    if (external_code) { validateExternalCode(external_code); }
  };

  const validateExternalCode = (external_code) => {
    Utils.getData('/tareas/simple-search?external_code=' + external_code)
    .then((data) => {
      if(data.length > 0){
        let copy = JSON.parse(JSON.stringify(newPresupuestoTarea));
        copy.external_code = data[0].external_code ? data[0].external_code : ''
        copy.nombre = data[0].nombre ? data[0].nombre : ''
        copy.cuenta_mayor = data[0].cuenta_mayor ? data[0].cuenta_mayor : ''
        copy.cuenta_mayor_descripcion = data[0].cuenta_mayor_descripcion ? data[0].cuenta_mayor_descripcion : ''
        copy.precio = data[0].precio ? data[0].precio : "0"
        copy.id_tarea = data[0].id ? data[0].id : ''
        setNewPresupuestoTarea(copy)
        loadServices(data[0].id)
      }else{
        swal(
          intl.formatMessage({id: 'El_código_de_tarea_es_inexistente', defaultMessage: 'El código de tarea es inexistente.' }),
          intl.formatMessage({id: 'Por_favor_ingrese_los_datos_manualmente.', defaultMessage: 'Por favor ingrese los datos manualmente.' }),
          "warning"
        );
        let copy = JSON.parse(JSON.stringify(newPresupuestoTarea));
        copy.external_code = '';
        copy.nombre = '';
        copy.cuenta_mayor = ''
        copy.cuenta_mayor_descripcion = ''
        copy.id_tarea = null
        copy.precio = ''
        setServicio('');
        setServicios([]);
        setNewPresupuestoTarea(copy)
      }
    });
  };

  const handleFormChangeSelectObject = (value) => {
    let copy = JSON.parse(JSON.stringify(newPresupuestoTarea));
    if(value){
      copy.external_code = value.external_code ? value.external_code : ''
      copy.nombre = value.nombre ? value.nombre : ''
      copy.cuenta_mayor = value.cuenta_mayor ? value.cuenta_mayor : ''
      copy.cuenta_mayor_descripcion = value.cuenta_mayor_descripcion ? value.cuenta_mayor_descripcion : ''
      copy.precio = value.precio ? value.precio : "0"
      copy.id_tarea = value.id
      loadServices(value.id)
    }else{
      copy.nombre = ''
      copy.id_tarea = null
    }
    setNewPresupuestoTarea(copy)
  };

  const addTarea = () => {
    props.addTarea(newPresupuestoTarea)
    setNewPresupuestoTarea({ ...NEW_TAREA_INIT })
    setServicio('');
    setServicios([]);
  };

  const handleInputTareaChange = (e) => {
    let value = e.target.value;
    let copy = JSON.parse(JSON.stringify(newPresupuestoTarea));
    copy.nombre = value;
    setNewPresupuestoTarea(copy)
    Utils.getData('/tareas/simple-search?nombre=' + value)
    .then((data) => {
      setTareas(data)
    });
  };

  const validate = () => {
    let result = false
    if(!newPresupuestoTarea.nombre) {result = true}
    if(!newPresupuestoTarea.precio) {result = true}
    if(!newPresupuestoTarea.external_code){
      if(!newPresupuestoTarea.cuenta_mayor) {result = true}
      if(!newPresupuestoTarea.cuenta_mayor_descripcion) {result = true}
    }else{
      if(!newPresupuestoTarea.cuenta_mayor) {result = true}
    }
    return result;
  };

  const handleChangeSelectCuentaMayor = (value) => {
    setServicio(value);
    let copy = JSON.parse(JSON.stringify(newPresupuestoTarea));
    if(value){
      copy['cuenta_mayor'] = value.external_code
      copy['cuenta_mayor_descripcion'] = value.nombre
      copy['servicio_id'] = value.id;
    }else{
      copy['cuenta_mayor'] = ''
      copy['cuenta_mayor_descripcion'] = ''
      copy['servicio_id'] = null;
    }
    setNewPresupuestoTarea(copy)
  };

  const loadServices = (tareaId) => {
    Utils.getData('/servicios/select-dinant/filter?tipo=tarea&ticketTipo=' + props.ticket.ticketTipo + '&tareaId=' + tareaId)
    .then((data) => {
      setServicios(data)
    });
  }

  return (
    <React.Fragment>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}
			<div className="table-responsive" style={{padding: "15px"}}>
        <div className="row" style={{marginRight: "3px", marginLeft: "3px", padding: "6px"}}>
          <Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <TextField
                  id="external_code"
                  InputLabelProps={newPresupuestoTarea.external_code ? { shrink: true} : ""}
                  label={intl.formatMessage({ id: 'Tarea', defaultMessage: 'Tarea' })}
                  placeholder={intl.formatMessage({ id: 'Tarea', defaultMessage: 'Tarea' })}
                  value={newPresupuestoTarea.external_code}
                  onChange={e => handleMaterialChange('external_code', e.target.value)}
                  onBlur={HandleExternalCode}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  disabled={newPresupuestoTarea.external_code ? true : false}
                  name="itemDescripcion"
                  options={tareas}
                  value={newPresupuestoTarea}
                  getOptionLabel={(option) => option.nombre ? option.nombre : ''}
                  onChange={(event, value) => handleFormChangeSelectObject(value)}
                  renderInput={(params) => (
                    <TextField {...params}
                      label={intl.formatMessage({ id: "Descripción", defaultMessage: "Descripción" })}
                      placeholder={intl.formatMessage({id: 'Descripción', defaultMessage: 'Descripción'})}
                      value={newPresupuestoTarea.nombre}
                      onChange={handleInputTareaChange}
                      onBlur={(e) => loadServices(newPresupuestoTarea.id_tarea ? newPresupuestoTarea.id_tarea : '')}
                    />
                  )}
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <TextField
                  disabled={newPresupuestoTarea.external_code || newPresupuestoTarea.nombre ? false : true}
                  id="cuenta_mayor"
                  type="text"
                  select
                  label={intl.formatMessage({ id: 'Cuenta_Mayor', defaultMessage: 'Cuenta Mayor' })}
                  placeholder={intl.formatMessage({ id: 'Cuenta_Mayor', defaultMessage: 'Cuenta Mayor' })}
                  onChange={(e) => handleChangeSelectCuentaMayor(e.target.value)}
                  value={servicio}
                  className="col-12"
                  InputLabelProps={newPresupuestoTarea.cuenta_mayor ? { shrink: true} : ""}
                >
                  {servicios.map(option => (
                    <MenuItem key={option.id} value={option}>
                      {option.external_code}
                    </MenuItem>
                  ))}
                </TextField>
              </form>
            </Grid>
            <Grid component={Box} item xs={3}>
              <form noValidate autoComplete="off">
                <TextField
                  disabled={newPresupuestoTarea.external_code ? true : false}
                  id="cuenta_mayor_descripcion"
                  InputLabelProps={newPresupuestoTarea.cuenta_mayor_descripcion ? { shrink: true} : ""}
                  label={intl.formatMessage({ id: 'descrip._Cuenta_Mayor', defaultMessage: 'Descrip. Cuenta Mayor' })}
                  placeholder={intl.formatMessage({ id: 'descrip._Cuenta_Mayor', defaultMessage: 'Descrip. Cuenta Mayor' })}
                  value={newPresupuestoTarea.cuenta_mayor_descripcion}
                  onChange={e => handleMaterialChange('cuenta_mayor_descripcion', e.target.value)}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <TextField
                  disabled={newPresupuestoTarea.external_code ? true : false}
                  id="precio"
                  type="number"
                  InputLabelProps={newPresupuestoTarea.precio ? { shrink: true} : ""}
                  InputProps={{
                    inputProps: { min: 0 }
                  }}
                  label={intl.formatMessage({ id: 'Precio', defaultMessage: 'Precio' })}
                  placeholder={intl.formatMessage({ id: 'Precio', defaultMessage: 'Precio' })}
                  value={newPresupuestoTarea.precio}
                  onChange={e => handleMaterialChange('precio', e.target.value)}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={1}>
              <form noValidate autoComplete="off">
                <button
                  className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main"
                  style={{ height: 49 }}
                  onClick={() => addTarea()}
                  disabled={validate()}
                >
                  <i className="ft-plus"></i>
                </button>
              </form>
            </Grid>
          </Grid>
        </div>
				<table
					id="dataTable"
					className="dataTable table nowrap server-side table-hover dataTableMovil"
					width="100%"
				>
					<thead>
						<tr>
							{/* <th scope="col"></th> */}
							<th scope="col">
								<FormattedMessage id="Tarea" defaultMessage="Tarea" />
							</th>
							<th scope="col" style={{ maxWidth: "3px" }}>
								<FormattedMessage id="Descripción" defaultMessage="Descripción" />
							</th>
							<th scope="col">
								<FormattedMessage id="Cuenta_Mayor" defaultMessage="Cuenta Mayor" />
							</th>
              <th scope="col">
                <FormattedMessage id="Descripción_Cuenta_Mayor" defaultMessage="Descripción Cuenta Mayor" />
							</th>
							<th scope="col">
								<FormattedMessage id="Precio" defaultMessage="Precio" />
							</th>
							<th scope="col">
							</th>
						</tr>
					</thead>
					<tbody>
						{props.presupuestoTareas ? (
							props.presupuestoTareas.map((presupuestoTarea, index) => (
								<Row
                  index={index}
									intl={props.intl}
									setRedirectTo={setRedirectTo}
									presupuestoTarea={presupuestoTarea}
									loading={(state) => setLoadingAndUpdate(state)}
									key={presupuestoTarea.external_code + presupuestoTarea.nombre + index}
                  deleteTarea={props.deleteTarea}
                  updatePresupuestoTarea={props.updatePresupuestoTarea}
								/>
							))
						) : (
							<tr>
								<td align={"center"} colSpan={14}>
									{loading ? (
										<CircularProgress />
									) : (
										<FormattedMessage
											id="No_hay_datos_disponibles."
											defaultMessage="No hay datos disponibles."
										/>
									)}
								</td>
							</tr>
						)}
					</tbody>
				</table>
        <div className="row p-1" style={{justifyContent: "right"}}>
          <label className="col-md-2 label-control col-form-label" htmlFor="total_mano_de_obra">
            <FormattedMessage id="Total_Mano_de_Obra" defaultMessage="Total Mano de Obra"/> {requiredSymbol}:
          </label>
          <div className="col-md-3">
            <div>
              <input disabled 
                type="number"
                className="form-control text-right pr-1"
                id="total_mano_de_obra"
                name="total_mano_de_obra"
                value={props.totalManoObra}
              />
            </div>
          </div>
        </div>
			</div>
    </React.Fragment>
  );
};

export default injectIntl(TicketPresupuestoManoObra);
