import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import EquipamientoGrid from './EquipamientoGrid.js'
import EquipamientoAbm from './EquipamientoAbm.js'
import {FormattedMessage, injectIntl} from 'react-intl';

class Equipamiento extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      					<h3 className="content-header-title"><FormattedMessage id="equipamientos.label.administracion_equipamientos" defaultMessage="Administración de Equipamientos"/></h3>
      				</div>
				</div>
				<div className="content-body">
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={EquipamientoGrid} />
						<Route exact path={`${this.props.match.url}/add`} render={(props) => <EquipamientoAbm {...props} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <EquipamientoAbm {...props} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} render={(props) => <EquipamientoAbm {...props} action="VIEW" />} />
					</Switch>
				</div>
			</div>
	    );
  	}
}

export default Equipamiento;
