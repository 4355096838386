import React, { Component } from 'react'
// import { Route, Switch } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Iframe from 'react-iframe'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import Security from '../../commons/security/Security.js'
import { Redirect } from 'react-router-dom'
class Videos extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
          redirectTo: null
      }
    }

  componentWillMount() {
      if (!Security.hasPermission('AYUDA_ACCEDER')) {
          this.setState({
              redirectTo: '/error'
          });
      }
  }

	render() {
	    return (
        <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}

        <div class="content-body">
        <section id="video-grid" class="card">
          <div class="card-header">
            <h4 class="card-title"><FormattedMessage id="video.information.video_tutoriales" defaultMessage="Video Tutoriales"/></h4>
          </div>
          <div class="card-content">
            <div class="card-body"></div>
            <div class="card-body">
                <div class="card-deck">
                  <div class="card border-grey border-lighten-2">
                    <div class="card-img-top embed-responsive embed-responsive-item embed-responsive-16by9">
                      <iframe class="gallery-thumbnail" src="https://www.youtube.com/embed/94msk7wNTcE?rel=0&amp;controls=1&amp;showinfo=0"></iframe>
                    </div>
                    <div class="card-body px-0">
                      <h4 class="card-title"><FormattedMessage id="video.information.presentacion_nueva_plataforma" defaultMessage="Presentación de Nueva Plataforma"/></h4>
                      <p class="card-text"><FormattedMessage id="video.information.plataforma_principales_secciones" defaultMessage="Descubrí la nueva plataforma y sus principales secciones."/>
                      </p>
                    </div>
                  </div>
                  <div class="card border-grey border-lighten-2">
                    <div class="card-img-top embed-responsive embed-responsive-item embed-responsive-16by9">
                      <iframe class="gallery-thumbnail" src="https://www.youtube.com/embed/VVsnYOQdqYE?rel=0&amp;controls=1&amp;showinfo=0"></iframe>
                    </div>
                    <div class="card-body px-0">
                      <h4 class="card-title"><FormattedMessage id="video.information.buscar_tickets" defaultMessage="Buscar Tickets"/></h4>
                      <p class="card-text"><FormattedMessage id="video.information.distintas_alternativas_encontrar_ticket" defaultMessage="Te mostramos las distintas alternativas para encontrar un Ticket."/></p>
                      
                    </div>
                  </div>
                  <div class="card border-grey border-lighten-2">
                    <div class="card-img-top embed-responsive embed-responsive-item embed-responsive-16by9">
                      <iframe class="gallery-thumbnail" src="https://www.youtube.com/embed/-KxarBeV0AU?rel=0&amp;controls=1&amp;showinfo=0"></iframe>
                    </div>
                    <div class="card-body px-0">
                      <h4 class="card-title"><FormattedMessage id="video.information.tickets_mantenimiento" defaultMessage="Trabajar con Tickets de Mantenimiento"/></h4>
                      <p class="card-text"><FormattedMessage id="video.information.proceso_completo_ticket_mantenimiento" defaultMessage="Te describimos el proceso completo de un Ticket de Mantenimiento."/></p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
        </div>
        </React.Fragment>
	    );
  	}
}

export default injectIntl(Videos);
