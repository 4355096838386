import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js';

const configAxios = {
  headers: {
    'Authorization-Token': localStorage.getItem("token")
  }
};


class AsociarTicket extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();
    moment.locale('es');
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      props: this.props,
      tickets: [],
      ticketSelected: null,
    };

  }

  initForm() {
    let component = this;
    Promise.all([
      this.ajaxHandler.getJson('/tickets/movil/' + this.props.data.movil.id + '/ticketsActivos'),
    ]).then((data) => {
      let tickets = data[0].map(ticket => {
        return {
          id: ticket.id,
          label: ticket.id + " - " + ticket.estado + " - " + ticket.servicio.nombre + " - Creado el " + ticket.fechaHoraAlta
        }
      })
      component.setState({
        tickets: tickets
      });
    })
  }
  componentWillMount() {
    this.ajaxHandler.subscribe(this);
    this.initForm();
  }
  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  handleClose() {
    this.ajaxHandler.unsubscribe();
    this.props.callbackClose();
  }

  handleSubmit() {
    this.ajaxHandler.fetch('/valordinamico/' + this.props.data.valorDinamicoId + '/ticket', {
      method: 'PUT',
      body: JSON.stringify({
        ticket_id: this.state.ticketSelected.id
      }),
    }).then(response => {
      this.ajaxHandler.unsubscribe();
      // this.props.callbackClose();
      window.location.reload()
    })
  }


  render() {
    let state = this.state;
    return (
      <React.Fragment>
        <div style={{ maxWidth: "72vw" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row" style={{ height: "100px" }}>
                <label className="col-md-2 label-control col-form-label" htmlFor="region">
                  Ticket *:
                </label>
                <div className="col-md-10">
                  <Select
                    options={this.state.tickets}
                    placeholder='Ticket a Asociar'
                    valueKey='id'
                    labelKey='label'
                    value={this.state.ticketSelected}
                    onChange={(ticket) => this.setState({
                      ticketSelected: ticket
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="text-cd text-left">

                <button type="button" className="btn mr-1" onClick={e => this.props.redireccionarATickets()} >
                  <i className="fa fa-check-circle" ></i> Crear Nuevo Ticket y Asociar
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-cd text-right">

                <button type="button" onClick={this.handleSubmit} className="btn btn-primary mr-1" disabled={!this.state.ticketSelected} >
                  <i className="fa fa-check-circle"></i> Asociar
                </button>
                <button type="button" className="btn btn-danger" onClick={e => this.handleClose()} data-dismiss="modal" data-backdrop="false">
                  <i className="fa fa-times-circle"></i> Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AsociarTicket;
