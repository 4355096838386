import RutasAbm from './RutasAbm'
import React, { Component } from 'react'
import RutasGrid from './RutasGrid'
import { Route, Switch } from 'react-router-dom'
import {FormattedMessage, injectIntl} from 'react-intl';

class Rutas extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      					<h3 className="content-header-title"><FormattedMessage id="Administracion_de_Rutas" defaultMessage="Administración de Rutas"/></h3>
      				</div>
				</div>
				<div className="content-body">								
                    <Switch>
						<Route exact path={`${this.props.match.url}`} component={RutasGrid} />
						<Route exact path={`${this.props.match.url}/add`} render={(props) => <RutasAbm {...props} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <RutasAbm {...props} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} render={(props) => <RutasAbm {...props} action="VIEW" />} />
					</Switch>	
				</div>
			</div>
	    );
  	}
}

export default Rutas;
