import React, { useState, useEffect} from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import {
	Collapse,
	TextField,
	Grid,
	Box,
	CircularProgress
} from '@material-ui/core';
import Switch from "react-switch";
import * as Utils from '../../commons/utils/Utils';
import Autocomplete from '@material-ui/lab/Autocomplete';

const FILTER_STATE_INIT = {
	nombre: '',
	base: null,
	showInactivos: false
}

const handleInitFilters = (props) => {
	var filterInit = FILTER_STATE_INIT;
	return filterInit;
}

const RutasGridFiltros = (props) => {
	const intl = useIntl();
	const [init,setInit] = useState(false)
	const [filtersLoading, setFiltersLoading] = useState(false)
	const [filtersState, setFiltersState] = useState(handleInitFilters(props))
	const [bases, setBases] = useState([]);

	useEffect(() => {
		fetchData()
		initFilter()
	}, []);

	useEffect(() => {
		if(props.filterResetInputs){
			setFiltersState(FILTER_STATE_INIT);
		}
	},[props.filterResetInputs])
	
	useEffect(() => {
		!props.dropdownActive && props.setFilterResetInputs(false)
		!props.dropdownActive && setFiltersLoading(false)
	}, [props.dropdownActive]);

	const validator = () => {
		var result = Object.entries(filtersState);
		for (var index in result){
			if(result[index][1]){
				return false
			}
		}
		return true
	}

	const initFilter = () => {
		setFiltersLoading(true);
		let query = queryTable();
		setInit(true)
		props.setFilterQuery(query);
		return;
	}

	const fetchData = () => {
		setFiltersLoading(true)
		Promise.all([
			Utils.getData('/bases/select')
		]).then((data) => {
			setBases(data[0] ? data[0] : [])
		}).catch((err) => {
			setFiltersLoading(false)
		})
	}

	const handleChange = (name, object) => {
		setFiltersState({...filtersState, [name]: object });
	};

	const handleChangeInactivosSwich = (value) => {
		setFiltersState({...filtersState, showInactivos : value});
	};

	const queryTable = () => {
		let query = {}
		query = JSON.parse(JSON.stringify(filtersState));
		query['nombre'] = filtersState.nombre ? filtersState.nombre : ''
		query['base'] = filtersState.base ? filtersState.base.value : ''
		query['showInactivos'] = filtersState.showInactivos ? filtersState.showInactivos : ''
		return query;
	}

	const handleSubmitInto = (event) => {
		if(!props.loading){
			handleSubmit(event);
		}
		event.preventDefault();
	}

	const handleSubmit = (event) => {
		props.handleFilterChange();
		event.preventDefault();
		props.setSearchActive(!validator())
		let query = queryTable();
		props.setFilterQuery(query);
		return;
	}

	return (
		<>
			{init &&
				<Collapse in={props.dropdownActive}>
					<Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
								<TextField
									label={intl.formatMessage({ id: "Numero_de_Ruta", defaultMessage: "Número de Ruta" })}
									placeholder={intl.formatMessage({ id: "Numero_de_Ruta", defaultMessage: "Número de Ruta" })}
									name="nombre"
									value={filtersState.nombre}
									onChange={(e) => setFiltersState({...filtersState, nombre: e.target.value})}
									disabled={filtersLoading}
									className="col-12"
								/>
							</form>
						</Grid>
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
								<Autocomplete
									size="small"
									name="base"
									disabled={filtersLoading}
									options={bases}
									value={filtersState.base}
									defaultValue={null}
									getOptionLabel={(option) => option ? option.label : ""}
									onChange={(e, value) => handleChange('base', value)}
									renderInput={(params) => (
										<TextField {...params} variant="standard"
											label={intl.formatMessage({ id: "Base", defaultMessage: "Base" })}
											placeholder={intl.formatMessage({ id: "Base", defaultMessage: "Base" })}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{filtersLoading ? (
															<CircularProgress color="inherit" size={21} />
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
								
							</form>
						</Grid>
						<Grid
							xs={12}
							container
							justifyContent="space-between"
							alignItems="center"
							flexDirection={{ xs: 'column', sm: 'row' }}
							sx={{ fontSize: '12px' }}
						>
							<Grid component={Box} item xs={2} className="align-self-end mx-1">
								<div className="d-flex inline justify-content-around align-items-center">
									<FormattedMessage
										id="formulariosGridFilters.render.filtros.ver_inactivos"
										defaultMessage="Ver Inactivos"
									/>
									<Switch
										onChange={(value) => handleChangeInactivosSwich(value)}
										checked={filtersState.showInactivos}
										disabled={filtersLoading}
										offColor="#FF4961"
										onColor="#28D094"
									/>
								</div>
							</Grid>
							<Grid component={Box} item xs={1} className="align-self-end">
								<button
									disabled={props.loading}
									className="btn btn-primary mr-1"
									onClick={(e) => handleSubmit(e)}
								>
									<i className="fa fa-search fa-xs"></i>   <FormattedMessage id="Buscar" defaultMessage="Buscar"/>
								</button>
							</Grid>
						</Grid>
					</Grid>
				</Collapse>
			}
		</>
	)
}

export default RutasGridFiltros
