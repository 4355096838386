import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'

import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Security from '../../commons/security/Security.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class ModelosGrid extends Component {
	constructor(props) {
		super(props);
        this.dataService = '/modelos/grid';
		this.apiBaseUrl = process.env.REACT_APP_VEC_FLEET_API_BASE_URL;
		this.ajaxHandler = new AjaxHandler();

		this.state = {
			redirectTo: null
		}
	}

	componentDidMount(nextProps, nextState) {

        if(Security.hasPermission('MODELOS_LISTAR')) {
	  
            this.ajaxHandler.subscribe(this);
            let component = this;
            let filters = null;

            Promise.all([
                this.getData('/marcas/select'),
                this.getData('/modelo-tipos/select')			
            ]).then((data) => {
                let filters = {
                    marca: data[0] ? data[0] : [],
                    tipo: data[1] ? data[1] : [],	
                    tipoUnidadMedidor: Object.keys(UNIDAD_MEDIDOR).map((unidadMedidorId, i) => ({value: UNIDAD_MEDIDOR[unidadMedidorId]['id'], label: UNIDAD_MEDIDOR[unidadMedidorId]['label'] }))			
                };

                this.table = $(this.refs.grid).DataTable(
                    Object.assign({
                        dom: '<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
                        stateSave: true,
                        stateSaveCallback: function(settings,data) {
                            localStorage.setItem( 'DataTables_ModelosGrid', JSON.stringify(data) );
                        },
                        stateLoadCallback: function(settings) {
                            return JSON.parse( localStorage.getItem( 'DataTables_ModelosGrid' ) );
                        },
                        serverSide: true,
                        ajax: {
                            type: 'GET',
                            url: Config.get('apiUrlBase') + this.dataService,
                            
                            headers: {
                                'Authorization-Token': localStorage.getItem("token")
                            },
                            dataSrc: function (response) {
                                return response.data;
                            },
                            error: function (xhr, error, thrown) {
                            },
                            cache: false
                        },
                        initComplete: function (settings) {
                            let stateColumns = JSON.parse(localStorage.getItem('DataTables_ModelosGrid')).columns;

                            var sets = settings;
                            var index = 0;

                            this.api().columns().every( function () {
                                var column = this;
                                if(sets.aoColumns[index].filterType){
                                    if(sets.aoColumns[index].filterType === 'select') {
                                        var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'modelosGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
                                            .appendTo( $(column.footer()).empty() )
                                            .on( 'change', function () {
                                                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                                column
                                                    .search( val ? val : '', true, false )
                                                    .draw();
                                            });								
                                        if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
                                            filters[sets.aoColumns[index].name].map(e => {
                                                select.append('<option value="'+e.value+'">'+e.label+'</option>');
                                                return true;
                                            });
                                        } else {
                                            column.data().unique().sort().each( function ( d, j ) {
                                                select.append( '<option value="'+d+'">'+d+'</option>' );
                                            });
                                        }
                                        if(stateColumns[index].search.search) select.val(stateColumns[index].search.search);
                                    }
                                    if(sets.aoColumns[index].filterType === 'input') {
                                        var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'modelosGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
                                        if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
                                        input.appendTo($(column.footer()).empty());
                                        input.on( 'keyup change', function () {
                                            if ( column.search() !== this.value ) {
                                                column
                                                .search( this.value )
                                                .draw()
                                                .ajax.reload(null, false);
                                            }
                                        });
                                    }
                                }
                                index++;
                                return '';
                            });

						$('tfoot tr').appendTo('thead');
					},
					columns: [
						{

							title: '',
								data: null,
								className: 'all',
								orderable: false,
								render: function(data, type, full, meta) {
									return '<div id="avatar"></div>';
								},
								filterType: 'none'
						},{
								title: component.props.intl.formatMessage({ id: 'facturasGrid.column_model.label_modelo', defaultMessage: 'Modelo' }),
								data: 'nombre',
								filterType: 'input'
						},{
							name: 'tipo',
							title: component.props.intl.formatMessage({ id: 'facturasGrid.column_type.label_tipo', defaultMessage: 'Tipo' }),
							className: 'all',
							data: 'tipo',
							render: function(data, type, row) {
								if (type === 'filter') {
									return data && data.id ? data.id : '';
								} else {
									return data && data.nombre ? data.nombre : '';
								}
							},
							filterType: 'select'
						},{
							name: 'marca',
							title: component.props.intl.formatMessage({ id: 'facturasGrid.column_mark.label_marca', defaultMessage: 'Marca' }),
							className: 'all',
							data: 'marca',
							render: function(data, type, row) {
								if (type === 'filter') {
									return data && data.id ? data.id : '';
								} else {
									return data && data.nombre ? data.nombre : '';
								}
							},
							filterType: 'select'
						},{
							name: 'rendimiento',
							title: component.props.intl.formatMessage({ id: 'facturasGrid.column_expected_performance.label_rend_esperado', defaultMessage: 'Rend. Esperado' }),
							className: 'all',
							data: 'rendimientoEsperado',
							orderable: true,
							filterType: 'input'
						},{
							name: 'capacidadTanque',
							title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_tank_capacity.label_capacidad_tanque', defaultMessage: 'Capacidad del tanque' }),
							className: 'all',
							data: 'capacidadTanque',
							orderable: true,
							filterType: 'input'
						},{
								orderable: false,
								data: null,
								className: "text-center",
								width: '120px',
								render: function (data, type, full, meta) {
                                    let buttons = 
                                    (Security.hasPermission('MODELOS_VISUALIZAR') ? `
                                    <button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'modelosGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`">
                                        <i class="fa fa-search fa-xs"></i>
                                    </button>` : '') +
                                    (Security.hasPermission('MODELOS_MODIFICAR') ? `
                                    <button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'modelosGrid.column_actions.button_title_editar', defaultMessage: 'Editar' })+`">
                                        <i class="fa fa-pencil fa-xs"></i>
                                    </button>` : '') +
                                    (Security.hasPermission('MODELOS_ELIMINAR') ? `
                                    <button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'modelosGrid.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`">
                                        <i class="fa fa-trash fa-xs"></i>
                                    </button>` : '');

                                    return buttons;
								}
						}],
						drawCallback: function () {
							$(this).find('.action').on('click', function () {
								let data = component.table.row($(this).parents('tr')).data();
								let redirectTo;
								if($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
								if($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
								component.setState({
									redirectTo: redirectTo
								});

								if($(this).hasClass('delete')) {
									swal({
									title: component.props.intl.formatMessage({ id: 'modelosGrid.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
									text: null,
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'modelosGrid.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'modelosGrid.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								})
								.then((isConfirm) => {
									if (isConfirm) {
										fetch(Config.get('apiUrlBase') + '/modelos/' + data.id, {
											method: 'DELETE',
											headers: {
												'Accept': 'application/json',
												'Content-Type': 'application/json',
												'Authorization-Token': localStorage.getItem("token")
											}
										})
										.then(function(response) {
											component.table.ajax.reload(null, false);
											swal(component.props.intl.formatMessage({ id: 'modelosGrid.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
										})
										.catch(function(error) {
											swal(component.props.intl.formatMessage({ id: 'modelosGrid.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
										});
									}
								});
								}
							});
						},
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons( this.table, {
				buttons: [
					{
			extend: 'print',
			text: '<i class="ft-printer"></i>',
						title: '',
						customize: function(win)
						{
							var css = '@page { size: landscape; }',
								head = win.document.head || win.document.getElementsByTagName('head')[0],
								style = win.document.createElement('style');

							style.type = 'text/css';
							style.media = 'print';

							if (style.styleSheet) {
								style.styleSheet.cssText = css;
							}	else {
								style.appendChild(win.document.createTextNode(css));
							}

							head.appendChild(style);

							$(win.document.body)
								.css( 'font-size', '10pt' )
								.prepend(`<div class="container">
														<div class="row mb-2">
															<div class="col-6">
																<img style="height: 35px" src="`+LogoCompany+`" />
															</div>
															<div class="col-6	text-right">
																<img style="height: 40px" src="`+LogoVecFleet+`" />
															</div>
														</div>
														<div class="row mb-2">
															<div class="col-12" style="border-bottom: 1px solid #000;">
																<h2>`+component.props.intl.formatMessage({ id: 'modelosGrid.print_content.header_listado_de_regiones', defaultMessage: 'Listado de Regiones' })+`</h2>
															</div>
														</div>
													</div>`);

							$(win.document.body).find( 'table' )
								.addClass( 'compact' )
								.css( 'font-size', 'inherit' );
						},
			exportOptions: {
				columns: [ 3,4 ]
			},
						titleAttr: component.props.intl.formatMessage({ id: 'modelosGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
			},
			{
			extend: 'colvis',
						text: '<i class="la la-columns"></i>',
						titleAttr: component.props.intl.formatMessage({ id: 'modelosGrid.tools_select_columns.title_imprimir', defaultMessage: 'Seleccionar columnas' })
			}, {
				name: 'exportExcel',
				text: '<i class="ft-upload"></i>',
				titleAttr: component.props.intl.formatMessage({ id: 'modelosGrid.tools_export.select_title_exportar_a_excel', defaultMessage: 'Exportar a Excel' }),
				action: function (e, dt, node, config) {
					swal({
						title: component.props.intl.formatMessage({ id: 'modelosGrid.tools_export.modal_information_se_estan_exportando_los_datos', defaultMessage: 'Se están exportando los datos.' }),
						text: component.props.intl.formatMessage({ id: 'modelosGrid.tools_export.modal_information_esta_accion_puede_demorar', defaultMessage: 'Esta acción puede demorar.' }),
						buttons: {}
					});

					component.ajaxHandler.fetch('/modelos/exportar-excel', {
						method: 'GET'
					}).then(response => {
						if (response.status === 200) {
							return response.blob();
						}
					}).then(fileBlob => {
						let fileUrl = URL.createObjectURL(fileBlob);
						$("<a />", {
							href: fileUrl,
							download: "fleet-modelos.xlsx"
						}).appendTo("body").get(0).click();
					}).catch(() => {
					}).finally(() => {
						swal.close();
					});
				}
			},
			{
				text: '<i class="ft-download"></i>',
				titleAttr: component.props.intl.formatMessage({ id: 'modelosGrid.tools_import.title_importar', defaultMessage: 'Importar' })
			},
				]
			});

			this.table.buttons( 0, null ).container().appendTo('#buttons');

			$('#dataTable tfoot th').each( function () {
				var title = $(this).text();
				if(title !== '') $(this).html( '<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'modelosGrid.columns_searh_filter.dataTable_buscar', defaultMessage: 'Buscar ...' })+'" />' );
			});

			this.table.columns([3]).every( function () {
				var that = this;
				$( 'input', this.footer()).on( 'keyup change', function () {
					if ( that.search() !== this.value ) {
						that
							.search( this.value )
							.draw()
							.ajax.reload(null, false);
					}
				});
				return null;
			});

			$('.buttons-collection').on("click",function(e){ e.stopPropagation();
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
    } else {
        this.setState({
            redirectTo: '/error'
        });
    }
	}

	handleNewBase(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		if(this.table) this.table.destroy();
	}

	render() {
	    return (
	    	<React.Fragment>
	    		{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
    			<div className="row mt-2">
						<div className="col-12">
			    		<div className="card">
								<div className="card-content collpase show">
			                        <div className="card-body card-dashboard">
										<div className="container-fluid">
											<div className="row dt-icons">
												<div className="col-6">
                                                {	Security.hasPermission('MODELOS_CREAR') ?
													<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNewBase.bind(this)}>
														<i className="ft-plus"></i>
													</div>
                                                    :
													null
												}
												</div>
												<div className="col-6" id="buttons"></div>
											</div>
										</div>
			    					<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>

													<th className=""></th>

													<th className="dt-search-header"><FormattedMessage id="modelosGrid.render.table_column_modelo" defaultMessage="Modelo"/></th>
													<th className="dt-search-header"><FormattedMessage id="modelosGrid.render.table_column_tipo" defaultMessage="Tipo"/></th>
													<th className="dt-search-header"><FormattedMessage id="modelosGrid.render.table_column_marca" defaultMessage="Marca"/></th>
													<th className="dt-search-header"><FormattedMessage id="modelosGrid.render.table_column_rend_esperado" defaultMessage="Rend. Esperado"/></th>
													<th className="dt-search-header"><FormattedMessage id="modelosGrid.render.table_column_capacida_del_tanque" defaultMessage="Capacida del Tanque"/></th>
													<th className=""></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
	    	</React.Fragment>
	    );
	}

	getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}

}

export default injectIntl(ModelosGrid);
