import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Security from '../../commons/security/Security.js'
import FormValidation from '../../commons/validation/FormValidation.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import Switch from "react-switch"
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import * as Utils from '../../commons/utils/Utils';
import Swal from 'sweetalert2'
import { ID_REAGENDA } from '../../commons/constants/tipoMotivos.js'

class TicketModificarAsignacion extends Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.ajaxHandler = new AjaxHandler();

		moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				id: 0,
				gerenciador: null,
				taller: null,
				fechaHoraTurno: null,
				fechaPrometida: null,
				enTaller: false,
				observacionesTaller: null,
				motivo_cambio_turno_id: "",
				motivo_cambio_turno_comentario: ""
			},
			fechaHoraTurnoPrevio: null,
			errors: [],
			gerenciadores: [],
			talleres: [],
			loading: false,
			gerenciadoresLoading: false,
			talleresLoading: false,
			gerenciadoresDisabled: true,
			talleresDisabled: true,
			inmovilizadoEnabled: false,
			motivos: [],
			motivo: null,
			motivosLoading: false,
			comentario: "",
			fechaPrometidaCambiada: false
		};

		this.handleFormChange = this.handleFormChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleGerenciadorChange = this.handleGerenciadorChange.bind(this);
		this.handleTallerChange = this.handleTallerChange.bind(this);
		this.handleEnTallerChange = this.handleEnTallerChange.bind(this);
		this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleFormChangeSelectObject = this.handleFormChangeSelectObject.bind(this);
		this.handleInputMotivoChange = this.handleInputMotivoChange.bind(this);
		this.sendData = this.sendData.bind(this);

		this.formValidation = new FormValidation({
			component: this/*,
			validators: {
				'formData.manoDeObra': (value) => Validator.floatNunmber(value),
				'formData.repuestos': (value) => Validator.floatNunmber(value)
			}*/
		});
	}

	componentWillMount() {

	}

	componentDidMount() {
		this.ajaxHandler.subscribe(this);
		this.init();
	}

	init() {
		this.props.onRef(this);
		this.initForm();
		this.handleInputMotivoChange('');
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		this.setState({
			loading: false,
			inmovilizadoEnabled: false,
			formData: {
				id: 0,
				gerenciador: null,
				taller: null,
				fechaHoraTurno: null,
				fechaPrometida: null,
				enTaller: false,
				observacionesTaller: null
			}
		 }, ()=>{
			this.formValidation.validate();
			this.props.callbackSave();
		 });
	}

	loadGerenciadores() {
		let base = null
		if (this.props.ticket.movil)
		 	base = this.props.ticket.movil.base
		if (this.props.ticket.persona)
			base = this.props.ticket.persona.base
		if (this.props.ticket.llanta)
			base = this.props.ticket.llanta.base
		if(base) {
			this.ajaxHandler.getJson('/bases/' + base.id)
			.then((data) => {
				let base = data;
				this.setState({
					gerenciadoresLoading: true,
					gerenciadoresDisabled: true
				}, () =>{
					if(base && base.subregion) {
						this.ajaxHandler.getJson('/gerenciadores/tipo/1/subregion/' + base.subregion.id + '/select')
						.then((data) => {
							let gerenciadores = data ? data : [];
							this.setState({
								gerenciadoresLoading: false,
								gerenciadoresDisabled: false,
								gerenciadores: gerenciadores,
							});
						}).catch(function(error) {
							this.ajaxHandler.handleError(error);
						})
					}
				});
			}).catch(function(error) {
				this.ajaxHandler.handleError(error);
			})
		}
	}

	loadTalleres(gerenciador) {
		let component = this;
		component.setState({
			talleresLoading: true,
			talleresDisabled: true
		}, () =>{
			component.ajaxHandler.getJson('/talleres/gerenciador/' + gerenciador.id + '/select')
			.then((data) => {
				component.setState({
					talleres: data,
					talleresLoading: false,
					talleresDisabled: false
				});
			}).catch(function(error) {
				component.ajaxHandler.handleError(error);
			})
		})
	}

	unloadTalleres() {
		let component = this;
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.taller = null;
		component.setState({
			talleresLoading: false,
			talleresDisabled: true,
			formData: formDataCopy,
			talleres: null
		})
	}

	initForm() {
		this.setState({
			formData: {
				id: this.props.ticket.id,
				gerenciador: this.props.ticket.gerenciador,
				taller: this.props.ticket.taller,
				fechaHoraTurno: this.props.ticket.fechaHoraTurno,
				fechaPrometida: this.props.ticket.fechaPrometida,
				enTaller: this.props.ticket.enTaller,
				observacionesTaller: this.props.ticket.observacionesTaller
			},
			loading: false,
			inmovilizadoEnabled: this.props.inmovilizado,
			fechaHoraTurnoPrevio: this.props.ticket.fechaHoraTurno
		}, () => {
			if(this.props.ticket.estado === 'ABIERTO' || Security.hasPermission('TICKETS_MODIFICAR_PRESUPUESTADOS')) {
				this.loadGerenciadores();
				//Si tiene gerenciador
				if(this.props.ticket.gerenciador) {
					this.loadTalleres(this.props.ticket.gerenciador);
				//Si no tiene gerenciador
				} else {
					this.setState({
						talleresLoading: false,
						talleresDisabled: true
					});
				}
			//si no esta ABIERTO,
			}
		});
	}

	handleGerenciadorChange(object) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

		//actualizo los campos de taller y turno
		formDataCopy.taller =null;
		formDataCopy.fechaHoraTurno = null;
		formDataCopy.fechaPrometida = null;
		formDataCopy.enTaller = false;
		formDataCopy.observacionesTaller = null;

    if(object){
			let component = this;

			formDataCopy.gerenciador = {id: object.value, razonSocial: object.label};

      component.setState({
				formData: formDataCopy,
				talleresDisabled: true,
				talleresLoading: true
      }, () => {
				this.state.formData.gerenciador ? component.loadTalleres(this.state.formData.gerenciador) : component.unloadTalleres();
			});
		} else {

			formDataCopy.gerenciador = null;
      this.setState({
        formData: formDataCopy,
				talleresDisabled: true
      });
    }
	}

	handleTallerChange(object) {
    if(object){
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.taller = {id: object.value, razonSocial: object.label};
      this.setState({
        formData: formDataCopy
      });
    } else {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.taller = null;
      this.setState({
        formData: formDataCopy
      });
    }
	}

	handleEnTallerChange (enTaller) {

    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.enTaller = enTaller;
		this.setState({
			formData: formDataCopy
    });
	}

	handleDatePickerFormChange(name, event) {
		let date = event ? event.format(): '';
		if(date === "")
			date = null;
		if(name === "fechaPrometida" && date !== this.state.formData.fechaPrometida){
			this.setState({
				fechaPrometidaCambiada: true
			});
		}

		if(name === "fechaHoraTurno"){
			this.setState({
				fechaPrometidaCambiada: false
			});
		}
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }

  handleDatePickerFormRawChange(name, date,event) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		if(date === "")
			date = null;
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

	handleFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
	}

	handleSubmit(event) {
		this.state.formData.fechaHoraTurno = this.state.formData.fechaHoraTurno ? moment(this.state.formData.fechaHoraTurno).format('YYYY/MM/DD HH:mm') : null;
		this.state.formData.fechaPrometida = this.state.formData.fechaPrometida ? moment(this.state.formData.fechaPrometida).format('YYYY/MM/DD HH:mm') : null;
		var fechaHoraTurnoPrevio = this.state.fechaHoraTurnoPrevio ? moment(this.state.fechaHoraTurnoPrevio).format('YYYY/MM/DD HH:mm') : null;

		if(fechaHoraTurnoPrevio && this.state.formData.fechaHoraTurno !== fechaHoraTurnoPrevio){
			this.state.formData.motivo_cambio_turno_id = this.state.motivo.id;
			this.state.formData.motivo_cambio_turno_comentario = this.state.comentario;
			if(!this.state.fechaPrometidaCambiada){
				Swal.fire({
					title: this.props.intl.formatMessage({ 
						id: 'Fecha_Prometida',
						defaultMessage: 'Fecha Prometida'
					}),
					text: this.props.intl.formatMessage({ 
						id: '¿La_fecha_de_turno_fue_modificada,_Desea_modificar_la_fecha_prometida?',
						defaultMessage: '¿La fecha de turno fue modificada, Desea modificar la fecha prometida?'
					}),
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: this.props.intl.formatMessage({ 
						id: 'Modificar',
						defaultMessage: 'Modificar'
					}),
					cancelButtonText: this.props.intl.formatMessage({ 
						id: 'No',
						defaultMessage: 'No'
					}),
					reverseButtons: true
				}).then((result) => {
					if (result.value) {
						this.datepickerFechaPrometida.input.focus();
					}else if (result.dismiss && result.dismiss === "cancel") {
						this.sendData(this.state.formData);
					}
				})
			}else{
				this.sendData(this.state.formData);
			}
		}else{
			this.sendData(this.state.formData);
		}
		event.preventDefault();
	}

	sendData(formData){
		this.setState({ loading: true});
		this.ajaxHandler.fetch( '/tickets/asignacion/' + this.props.ticket.id, {
			method: 'PATCH',
			body: JSON.stringify({
				...formData
			})
		}).then(response => {
			if(response.status === 400) {
				response.json()
				.then(data => {
					this.setState({
						errors: data.detalle
					});
				});
			} else {
				this.ajaxHandler.unsubscribe();
				this.setState({
					loading: false,
					formData: {
						id: null,
						manoDeObra: 0,
						repuestos: 0,
						adjunto: null
					}
				}, ()=>{
					this.props.callbackSave();
				});
			}
		}).catch((error) => {
			this.ajaxHandler.handleError(error);
		}).finally(() => {
		});
	}

	handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.setState({
			loading: false,
			formData: {
				id: null,
				manoDeObra: 0,
				repuestos: 0,
				adjunto: null
			}
		 }, ()=>{
			this.formValidation.validate();
			this.props.callbackClose();
		 });
	}

	handleFormChangeSelectObject(motivo){
		this.setState({
			motivo: motivo
		});
	};

	handleInputMotivoChange(motivo){
		this.setState({
			motivosLoading: true
		});
		Utils.getData('/motivos/simple-search?nombre=' + motivo + '&tipo_motivo=' + ID_REAGENDA).then((res) => {
			res.forEach(element => {
				if (element.multilenguaje_id) {
				  	element.nombre = this.props.intl.formatMessage({...(backendStrings[element.multilenguaje_id] ? backendStrings[element.multilenguaje_id] : backendStrings['errors.default']) })
				}
			});
			this.setState({
				motivos: res,
				motivosLoading: false
			});
		});
	};

	render() {
		this.formValidation.validate();
		let formData = this.state.formData;
		let validationState = this.formValidation.state;

		var fechaHoraTurnoCompare = this.state.formData.fechaHoraTurno ? moment(this.state.formData.fechaHoraTurno).format('YYYY/MM/DD HH:mm') : null;
		var fechaHoraTurnoPrevioCompare = this.state.fechaHoraTurnoPrevio ? moment(this.state.fechaHoraTurnoPrevio).format('YYYY/MM/DD HH:mm') : null;
		var fechaPrometidaCompare = this.state.formData.fechaPrometida ? moment(this.state.formData.fechaPrometida).format('YYYY/MM/DD') : null;
		var fechaTurnoCompare = this.state.formData.fechaHoraTurno ? moment(this.state.formData.fechaHoraTurno).format('YYYY/MM/DD') : null;
		var cambioElTurno = fechaHoraTurnoPrevioCompare && fechaHoraTurnoCompare !== fechaHoraTurnoPrevioCompare;

		//let requiredSymbol = ' *';
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
				<div className="row">
					<div className="col-md-12">
						<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
						<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
							<div className="form-body">
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section">
												<i className="la la-file-text"></i><FormattedMessage id="ticketModificarAsignacion.render.edit_assignment.header_asignacion" defaultMessage=" Asignación"/>
												<div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="ticketModificarAsignacion.render.edit_assignment.campos_requeridos" defaultMessage="campos requeridos"/></div>
											</h4>
											<div className="row">
                                                {/* GERENCIADOR */}
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-md-4 label-control col-form-label" htmlFor="gerenciador">
                                                            <FormattedMessage id="ticketModificarAsignacion.render.edit_assignment.label_gerenciador" defaultMessage="Gerenciador:"/>
                                                        </label>
                                                        <div className="col-md-8">
                                                            {(this.props.ticket.estado === 'ABIERTO' && Security.hasPermission('TICKETS_MODIFICAR_GERENCIADOR')) || Security.hasPermission('TICKETS_MODIFICAR_PRESUPUESTADOS') ? (
                                                            <div>
                                                                <Select
                                                                    id="gerenciador"
                                                                    name="gerenciador"
                                                                    placeholder={this.props.intl.formatMessage({ id: 'ticketModificarAsignacion.render.edit_assignment.placeholder_gerenciador', defaultMessage: 'Gerenciador' })}
                                                                    options={this.state.gerenciadores}
                                                                    valueKey='value'
                                                                    labelKey='label'
                                                                    value={formData.gerenciador ? {value: formData.gerenciador.id, label: formData.gerenciador.razonSocial} : null}
                                                                    onChange={(e) => this.handleGerenciadorChange(e)}
                                                                    isLoading={this.state.gerenciadoresLoading}
                                                                    disabled={this.state.gerenciadoresDisabled}
                                                                />
                                                            </div>
                                                            ) : (
                                                            <div className="form-control-static col-form-label form-value">{formData.gerenciador ? formData.gerenciador.razonSocial : ''}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* TALLER */}
												<div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-md-4 label-control col-form-label" htmlFor="taller">
                                                            <FormattedMessage id="ticketModificarAsignacion.render.edit_assignment.label_taller" defaultMessage="Taller:"/>
                                                        </label>
                                                        <div className="col-md-8">
                                                            {(this.props.ticket.estado === 'ABIERTO' && Security.hasPermission('TICKETS_MODIFICAR_TALLER')) || Security.hasPermission('TICKETS_MODIFICAR_PRESUPUESTADOS') ? (
                                                                <div>
                                                                    <Select
                                                                    id="taller"
                                                                    name="taller"
                                                                    placeholder={this.props.intl.formatMessage({ id: 'ticketModificarAsignacion.render.edit_assignment.placeholder_taller', defaultMessage: 'Taller' })}
                                                                    options={this.state.talleres}
                                                                    valueKey='value'
                                                                    labelKey='label'
                                                                    value={formData.taller ? {value: formData.taller.id, label: formData.taller.razonSocial} : null}
                                                                                                        onChange={(e) => this.handleTallerChange(e)}
                                                                                                        isLoading={this.state.talleresLoading}
                                                                    disabled={this.state.talleresDisabled}
                                                                    />
                                                                </div>
                                                            ) : (
                                                            <div className="form-control-static col-form-label form-value">{formData.taller ? formData.taller.razonSocial : ''}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
						<div className="row">
							{/* TURNO */}
							<div className="col-md-6">
								<div className="form-group row">
									<label className="col-md-4 label-control col-form-label" htmlFor="fechaHoraTurno">
									<FormattedMessage id="ticketModificarAsignacion.render.edit_assignment.label_turno" defaultMessage="Turno:"/>
									</label>
									<div className="col-md-8">
									{(this.props.ticket.estado === 'ABIERTO'  && Security.hasPermission('TICKETS_MODIFICAR_TALLER'))  || Security.hasPermission('TICKETS_MODIFICAR_PRESUPUESTADOS') ? (
										<div>
											<DatePicker
												id="fechaHoraTurno"
												name="fechaHoraTurno"
												className="form-control date-picker-placeholder"
												dateFormat="DD/MM/YYYY HH:mm"
												placeholderText={this.props.intl.formatMessage({ id: 'ticketModificarAsignacion.render.edit_assignment.placeholder_turno', defaultMessage: 'DD/MM/AAAA' })}
												selected={formData.fechaHoraTurno === null || formData.fechaHoraTurno === '0000-00-00 00:00' ? null : moment(formData.fechaHoraTurno) }
												onChange={(event) => this.handleDatePickerFormChange("fechaHoraTurno", event)}
												onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaHoraTurno", event.target.value)}
												minDate={this.props.ticket ? moment(this.props.ticket.fechaHoraAlta) : null}
												showTimeSelect
												timeIntervals = { 10 }
												popperPlacement="right"
											/>
										</div>
									) : (
										<div className="form-control-static col-form-label form-value">{formData.fechaHoraTurno === null || formData.fechaHoraTurno === '0000-00-00' ? '' : moment(formData.fechaHoraTurno).format('L')}</div>
									)}
									</div>
								</div>
							</div>
							{/* FECHA PROMETIDA */}
							<div className="col-md-6">
								<div className="form-group row">
									<label className="col-md-4 label-control col-form-label" htmlFor="fechaPrometida">
										<FormattedMessage id="ticketModificarAsignacion.render.edit_assignment.label_fecha_prometida" defaultMessage="Fecha Prometida:"/>
									</label>
									<div className="col-md-8">
										{this.props.ticket.estado === 'ABIERTO' || Security.hasPermission('TICKETS_MODIFICAR_PRESUPUESTADOS') ? (
											<div>
												<DatePicker
													ref={(datepickerFechaPrometida) => { this.datepickerFechaPrometida = datepickerFechaPrometida }}
													id="fechaPrometida"
													name="fechaPrometida"
													className="form-control date-picker-placeholder"
													placeholderText={this.props.intl.formatMessage({ id: 'ticketModificarAsignacion.render.edit_assignment.placeholder_fecha_prometida', defaultMessage: 'DD/MM/AAAA' })}
													selected={formData.fechaPrometida === null || formData.fechaPrometida === '0000-00-00' ? null : moment(formData.fechaPrometida) }
													onChange={(event) => this.handleDatePickerFormChange("fechaPrometida", event)}
													onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaPrometida", event.target.value)}
													minDate={moment()}
													popperPlacement="right"
												/>
												{
													fechaTurnoCompare && fechaPrometidaCompare && fechaTurnoCompare  > fechaPrometidaCompare ?
														<small className="text-danger">
															<FormattedMessage id="La_fecha_prometida_debe_ser_posterior_o_igual_a_la_fecha_de_turno" defaultMessage="La fecha prometida debe ser posterior o igual a la fecha de turno"/>
														</small>
													: 
														""
												}
											</div>
										) : (
											<div className="form-control-static col-form-label form-value">{formData.fechaPrometida === null || formData.fechaPrometida === '0000-00-00' ? '' : moment(formData.fechaPrometida).format('L') }</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							{/* EN TALLER */}
							<div className="col-md-6">
								<div className="form-group row">
									<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="enTaller">
																	<FormattedMessage id="ticketModificarAsignacion.render.edit_assignment.label_en_taller" defaultMessage="En Taller:"/>
									</label>
									<div className="col-md-8">
									<Switch
										onChange={this.handleEnTallerChange}
										checked={formData.enTaller ? formData.enTaller : false}
										id="enTaller"
										name="enTaller"
										disabled={this.state.inmovilizadoEnabled ? true : (this.state.props.ticket.estado !== 'CANCELADO' && this.state.props.ticket.estado !== 'CERRADO' && Security.hasPermission('TICKETS_MODIFICAR_EN_TALLER')  ? false : true )}
										offColor="#FF4961"
										onColor="#28D094"
									/>
									</div>
								</div>
							</div>
                      	</div>
						{ 
							cambioElTurno ? 
								<div className="row mb-3">
									<div className="col-md-2">
									</div>
									<div className="col-md-10">
										<div className="form-row p-1" style={{background:"#e3e4e5"}}>
											<div className="col-md-2">
													<div className="row-12 d-flex justify-content-center mt-3">
														<i className="fa fa-exclamation-triangle fa-3x" style={{color:"#e97c3a"}}></i>
													</div>
													<div className="row-12 d-flex justify-content-center mb-1 mt-1">
														<FormattedMessage 
															id="Debe_consignarse_el_motivo_del_cambio_de_turno" 
															defaultMessage="Debe consignarse el motivo del cambio de turno"
														/>
													</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="motivo" className="">
														<FormattedMessage id="Motivo_del_cambio" defaultMessage="Motivo del cambio"/>* :
													</label>
													<Select
														name="motivo"
														id="motivo"
														className="select-personal-llantas z-15"
														maxMenuHeight={10}
														placeholder={this.props.intl.formatMessage({
															id: 'Motivo_del_cambio',
															defaultMessage: 'Motivo del cambio',
														})}
														options={this.state.motivos}
														labelKey="nombre"
														valueKey="id"
														value={this.state.motivo}
														onChange={this.handleFormChangeSelectObject}
														onInputChange={this.handleInputMotivoChange}
														isLoading={this.state.motivosLoading}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label className="" htmlFor="comentario">
														<FormattedMessage id="Observaciones" defaultMessage="Observaciones"/>* :
													</label>
													<textarea 
														className="form-control"
														id="comentario"
														name="comentario"
														rows="7"
														placeholder={this.props.intl.formatMessage({ id: 'Observaciones', defaultMessage: 'Observaciones' })}
														value={this.state.comentario}
														onChange={e => this.setState({comentario: e.target.value})} >
													</textarea>
												</div>
											</div>
										</div>
									</div>
								</div>
							: ""
						}
											<div className="row">
                        {/* OBSERVACIONES TALLER */}
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-md-2 label-control col-form-label" htmlFor="observacionesTaller">
                              <FormattedMessage id="ticketModificarAsignacion.render.edit_assignment.label_observaciones" defaultMessage="Observaciones:"/>
                            </label>
                            <div className="col-md-10">
                              <div>
                                <textarea className="form-control" id="observacionesTaller" name="observacionesTaller" rows="3" placeholder="" value={formData.observacionesTaller ? formData.observacionesTaller : ''} onChange={this.handleInputFormChange}></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
										</div>
									</div>
								</div>
							</div>
							<div className="card pull-up">
								<div className="card-content">
									<div className="card-body">
										<div className="text-cd text-right">
											{this.props.action !== 'VIEW' && (
											<button 
												type="submit"
												className="btn btn-primary mr-1" 
												disabled={
													!validationState.form.valid ||
													(cambioElTurno ? (this.state.motivo && this.state.comentario ? false : true) : false) ||
													(fechaTurnoCompare && fechaPrometidaCompare && fechaTurnoCompare  > fechaPrometidaCompare ? true : false)
												}
											>
											<i className="fa fa-check-circle"></i><FormattedMessage id="ticketModificarAsignacion.finish_button.guardar" defaultMessage=" Guardar"/>
											</button>
											)}
											<button type="button" className="btn btn-danger" onClick={this.handleCancel}>
											<i className="fa fa-times-circle"></i><FormattedMessage id="ticketModificarAsignacion.finish_button.cancelar" defaultMessage=" Cancelar"/>
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TicketModificarAsignacion);
