import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
// import FormularioGrid from './FormularioGrid.js'
import FormularioGrid from './FormularioGrid.jsx'
import FormularioAbm from './FormularioAbm.js'
import {FormattedMessage, injectIntl} from 'react-intl';

class Formulario extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      					<h3 className="content-header-title"><FormattedMessage id="formulario.label_listado_inspecciones" defaultMessage="Listado de Inspecciones"/></h3>
      				</div>
				</div>
				<div className="content-body">
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={FormularioGrid} />
						<Route exact path={`${this.props.match.url}/add`} render={(props) => <FormularioAbm {...props} intl={this.props.intl} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <FormularioAbm {...props} intl={this.props.intl} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} render={(props) => <FormularioAbm {...props} action="VIEW" />} />
					</Switch>
				</div>
			</div>
	    );
  	}
}

export default injectIntl(Formulario);
