import * as Utils from '../../commons/utils/Utils';
import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage} from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";

const TicketBodegaStock = (props) => {
  const [bodegasStock, setBodegasStock] = useState(null);
	const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
  }, []);

	const init = () => {
		setLoading(true);
		Utils.getDataNoErrorMessage('/almacenes/search?external_code=' + props.external_code)
		.then((res) => {
			if(Array.isArray(res)){
				setBodegasStock(res);
			}else{
				if(res){
					let result = [{'id': res.id, 'partQuantity': res.partQuantity, 'warehouseName': res.warehouseName}];
					setBodegasStock(result);
				}
			}
		}).catch((error) => {
			console.log(error);
			setBodegasStock(null);
		}).finally(() => {
			setLoading(false);
		});
  };

  return (
    <>
      <div>
				<div className="text-center mt-2">
					<h3>
						<FormattedMessage id="Repuesto" defaultMessage="Repuesto" /> : {props.external_code}
					</h3>
				</div>
        <div className="table-responsive" style={{padding: "15px"}}>
					<table
						id="dataTable"
						className="dataTable table nowrap server-side table-hover dataTableMovil"
						width="100%"
					>
						<thead>
							<tr>
								{/* <th scope="col"></th> */}
								<th scope="col">
									<FormattedMessage id="Bodega" defaultMessage="Bodega" />
								</th>
								<th scope="col">
									<FormattedMessage id="Cantidad" defaultMessage="Cantidad" />
								</th>
							</tr>
						</thead>
						<tbody>
							{bodegasStock ? (
								bodegasStock.map((bodegaStock) => (
									<tr key={bodegaStock.warehouseCode}>
										<td className="all custom-lineHeight">
											{bodegaStock.warehouseName}
										</td>
										<td className="all custom-lineHeight">
											{bodegaStock.partQuantity}
										</td>
									</tr>
								))
							) : (
								<tr>
									<td align={"center"} colSpan={14}>
										{loading ? (
											<CircularProgress />
										) : (
											<FormattedMessage
												id="No_Hay_Stock"
												defaultMessage="No Hay Stock"
											/>
										)}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
    	</div>
    </>
  );
};

export default TicketBodegaStock;
