module.exports = function(intl){
	return {
		language: {
			"sProcessing": "<i class=\"fa fa-spinner spinner\" style=\"font-size: 14px\"></i> "+intl.formatMessage({ id: 'dataTablesConfig.procesando', defaultMessage: 'Procesando' })+"...",
			"sLengthMenu": intl.formatMessage({ id: 'dataTablesConfig.page_number.label_mostrar', defaultMessage: 'Mostrar' })+" _MENU_",
			"sZeroRecords": intl.formatMessage({ id: 'dataTablesConfig.searh_filter.no_se_encontraron_datos', defaultMessage: 'No se encontraron datos' }),
			"sEmptyTable": intl.formatMessage({ id: 'dataTablesConfig.ningun_dato_disponible_en_esta_tabla', defaultMessage: 'Ningún dato disponible en esta tabla' }),
			"sInfo": intl.formatMessage({ id: 'dataTablesConfig.page_number.mostrando', defaultMessage: 'Mostrando' })+" _START_ "+intl.formatMessage({ id: 'dataTablesConfig.page_number.a', defaultMessage: 'a' })+" _END_ "+intl.formatMessage({ id: 'dataTablesConfig.page_number.de', defaultMessage: 'de' })+" _TOTAL_",
			"sInfoEmpty": "",
			"sInfoFiltered": "("+intl.formatMessage({ id: 'dataTablesConfig.page_number.filtrado_de_un_total_de', defaultMessage: 'filtrado de un total de' })+" _MAX_)",
			"sInfoPostFix": "",
			"sSearch": intl.formatMessage({ id: 'dataTablesConfig.buscar', defaultMessage: 'Buscar:' }),
			"sUrl": "",
			"sInfoThousands": ",",
			"sLoadingRecords": " ",
			"oPaginate": {
			"sFirst": '<i class="fa fa-angle-double-left"></i>',
			"sLast": '<i class="fa fa-angle-double-right"></i>',
			"sNext": '<i class="fa fa-angle-right"></i>',
			"sPrevious": '<i class="fa fa-angle-left"></i>'
			},
			"oAria": {
		    "sSortAscending": ": "+intl.formatMessage({ id: 'dataTablesConfig.activar_para_ordenar_la_columna_de_manera_ascendente', defaultMessage: 'Activar para ordenar la columna de manera ascendente' }),
		    "sSortDescending": ": "+intl.formatMessage({ id: 'dataTablesConfig.activar_para ordenar_la_columna_de_manera_descendente', defaultMessage: 'Activar para ordenar la columna de manera descendente' })
			}
		},
		pagingType: 'full_numbers',
		responsive: true
	}
};

/*import {FormattedMessage, injectIntl} from 'react-intl';

export default {
	language: {
		"sProcessing": "<i class=\"fa fa-spinner spinner\" style=\"font-size: 14px\"></i> Procesando...",
		"sLengthMenu": "Mostrar _MENU_",
		"sZeroRecords": "No se encontraron datos",
		"sEmptyTable": "Ningún dato disponible en esta tabla",
		"sInfo": "Mostrando _START_ a _END_ de _TOTAL_",
		"sInfoEmpty": "",
		"sInfoFiltered": "(filtrado de un total de _MAX_)",
		"sInfoPostFix": "",
		"sSearch": "Buscar:",
		"sUrl": "",
		"sInfoThousands": ",",
		"sLoadingRecords": " ",
		"oPaginate": {
		"sFirst": '<i class="fa fa-angle-double-left"></i>',
		"sLast": '<i class="fa fa-angle-double-right"></i>',
		"sNext": '<i class="fa fa-angle-right"></i>',
		"sPrevious": '<i class="fa fa-angle-left"></i>'
		},
		"oAria": {
		    "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
		    "sSortDescending": ": Activar para ordenar la columna de manera descendente"
		}
	},
	pagingType: 'full_numbers',
	responsive: true
}*/