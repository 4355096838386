import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
//import LogoCompany from '../../assets/images/logo-company-login-tc-print.png'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import swal from 'sweetalert'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import MovilesInmovilizadosDetail from './MovilesInmovilizadosDetail.js';
import ReactDOM from 'react-dom'

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class MovilesInmovilizadosGrid extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.dataService = '/moviles-inmovilizados/grid';
		this.columnsToPrint = [2,3,4];
		moment.locale('es');
		
		this.state = {
			redirectTo: null,
			editKmEnabled: false,
			maxDifferenceKM: 0,
			canModifyKM: false, 
			totales: 0
		}

		this.redirectToTicket = this.redirectToTicket.bind(this);
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('REPORTES_MOVILES_INMOVILIZADOS')) {
	      this.ajaxHandler.subscribe(this);
				this.initGrid();
	    } else {
		    	this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	redirectToTicket(id) {
		this.setState({
			redirectTo: '/tickets/' + id + '/edit'
		});
	}

	handleKmChange() {
		alert('bla');
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row">
					<div className="col-12">
						<div className="card bg-gradient-x-primary">
							<div className="card-content">
								<div className="row">
									<div className="col-lg-4 col-md-6 col-sm-12 border-right-primary border-right-lighten-3">
										<div className="card-body card-body-comb-invalidas text-center">
											<h1 className="text-white" style={{fontSize: '3rem'}}> { this.state.totales.totalOperativos }</h1>
											<span className="text-white"><FormattedMessage id="movilesInmovilizadosGrid.render.card_total_de_moviles_operativos" defaultMessage="Total de Móviles Operativos"/></span>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 col-sm-12 border-right-primary border-right-lighten-3">
										<div className="card-body card-body-comb-invalidas text-center">
											<h1 className="text-white" style={{fontSize: '3rem'}}>{ this.state.totales.totalInmovilizados }</h1>
											<span className="text-white"><FormattedMessage id="movilesInmovilizadosGrid.render.card_total_de_moviles_paralizados" defaultMessage="Total de Móviles Paralizados"/></span>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 col-sm-12 border-right-primary border-right-lighten-3">
										<div className="card-body card-body-comb-invalidas text-center">
											<h1 className="text-white" style={{fontSize: '3rem'}}>{ this.state.totales.totalPorcentajeInm }</h1>
											<span className="text-white"><FormattedMessage id="movilesInmovilizadosGrid.render.card_porcentaje_de_moviles_paralizados" defaultMessage="Porcentaje de Móviles Paralizados"/></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
    			</div>
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">
												
											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;

		Promise.all([
			this.ajaxHandler.getJson('/bases/select-filtered-by-user/movil'),
			this.ajaxHandler.getJson('/gerenciadores/select-filtered-by-user'),
			this.ajaxHandler.getJson('/moviles-inmovilizados/totales')
		]).then((data) => {
			let filters = {
				base: data[0] ? data[0]: [],
				gerenciador: data[1] ? data[1] : []
			};
			component.setState({
				totales: data[2] ? data[2] : []
			});

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					
					processing: true,
					serverSide: true,
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + this.dataService,
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {
							component.ajaxHandler.handleResponseErrorsIsValid(xhr);
						},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = null; //JSON.parse(localStorage.getItem('DataTables_' + component.constructor.name)).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [
					{
						title: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.column_region.label_region', defaultMessage: 'Región' }),
						className: 'all',
						data: 'region',
						filterType: 'input',
						render: function(data, type, full, meta) {
								return '<div class="d-flex align-items-center"><div><i class="fa fa-angle-double-right paralizados-show-detail fa-2x"></i></div><div><spam style="position: relative; top: -3px; left: 3px">' + (data ? data : '')+'</spam></div></div>';
							return data ? data : '';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('div').tooltip();
						},
					}, {
						title: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.column_base.label_base', defaultMessage: 'Base' }),
						name: 'base',
						className: 'all',
						data: 'base',
						filterType: 'select'
					}, {
						name: 'gerenciador',
						title: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.column_manager.label_gerenciador', defaultMessage: 'Gerenciador' }),
						className: 'all',
						data: 'gerenciador',
						filterType: 'select'
					},{
						name: 'moviles_operativos',
						title: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.column_Q_Moviles_OPERATIVOS.label_Q_Moviles_OPERATIVOS', defaultMessage: 'Q Moviles OPERATIVOS' }),
						className: 'all center-column',
						data: 'total',
					}, {
						name: 'moviles_paralizados',
						title: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.column_Q_paralizadas.label_Q_paralizadas', defaultMessage: 'Q Paralizadas' }),
						className: 'all center-column',
						data: 'inmovilizados'
					}, {
						name: 'porcentaje_paralizados',
						title: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.column_paralizadas.label_paralizadas ', defaultMessage: '% Paralizadas ' }),
						className: 'all center-column',
						data: 'inmovilizados',
						orderable: false,
						render: function(data, type, full, meta){
							if(data === "0" || full['total'] === "0")
								return 0;
							return parseFloat((100* parseFloat(data)) /parseFloat(full['total'])).toFixed(2);
						}
					}],
					drawCallback: function() {
						var detailRows = [];
						$('tbody').off('click', 'i.paralizados-show-detail');
						$('tbody').on( 'click', 'i.paralizados-show-detail', function () {
			                var tr = $(this).closest('tr');
							var row = component.table.row($(this).parents('tr'));
			                var idx = $.inArray( tr.attr('id'), detailRows );
			                if ( row.child.isShown() ) {
								//se elimina el componente
								let unmount = ReactDOM.unmountComponentAtNode(tr.next('tr').get(0));
			                    tr.removeClass( 'details' );
			                    row.child.hide();
			                    // Remove from the 'open' array
			                    detailRows.splice( idx, 1 );
			                    tr.find("td:first-child > div > div > i").addClass("fa-angle-double-right").removeClass("fa-angle-double-down");
			                    tr.find("td:first-child").blur();
			                }else {
			                    tr.find("td:first-child > div > div > i").addClass("fa-angle-double-down").removeClass("fa-angle-double-right");
			                    tr.find("td:first-child").blur();
			                    let dataRow = component.table.row($(this).parents('tr')).data();
								//se crea un hijo ṕara el detalle
								row.child('<hr>').show();
								//se renderiza el contenido del detalle
								ReactDOM.render(<td colSpan="100%"><MovilesInmovilizadosDetail redirectToTicket={component.redirectToTicket} intl={component.props.intl} base={dataRow.baseId}/></td>, tr.next('tr').get(0));

								tr.addClass( 'details' );

								// Add to the 'open' array
								if ( idx === -1 ) {
									detailRows.push( tr.attr('id') );
								}

			                }
			            } );
					/*	$(this).find('.action').on('click', function() {
							let data = component.table.row($(this).parents('tr')).data();
							let redirectTo;
							if ($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
							if ($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
							if ($(this).hasClass('correctivo')) redirectTo = '/correctivos/add/movil/' + data.id + '/' + data.dominio;
							component.setState({
								redirectTo: redirectTo
							});
						});*/
					},
					rowCallback: function(row, data) {
						if (data.marca) {
							if (data.marca.foto) {
								let photoUrl = Config.get('apiUrlBase') + '/marcas/foto/' + data.marca.foto;
								fetch(photoUrl, {
									method: 'GET',
									headers: {
										'Authorization-Token': localStorage.getItem("token")
									}
								}).then(response => {
									if (response.status === 200) {
										return response.blob();
									} else {
										$('#avatar', row).addClass('dt-user-avatar');
										$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
									}
								}).then(imgBlob => {
									$('#avatar', row).addClass('dt-user-avatar-image');
									$('#avatar', row).css('background-image', 'url(' + URL.createObjectURL(imgBlob) + ')');
								}).catch(() => {
									$('#avatar', row).addClass('dt-user-avatar');
									$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
								});
							} else {
								$('#avatar', row).addClass('dt-user-avatar');
								$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
							}
						} else {
							$('#avatar', row).addClass('dt-user-avatar');
							$('#avatar', row).html('');
						}

					}
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					extend: 'print',
					name: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win) {
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						} else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css('font-size', '10pt')
							.prepend(`<div class="container">
												<div class="row mb-2">
													<div class="col-6">
														<img style="height: 40px" src="` + LogoCompany + `" />
													</div>
													<div class="col-6	text-right">
														<img style="height: 40px" src="` + LogoVecFleet + `" />
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12" style="border-bottom: 1px solid #000;">
														<h2>`+component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.print_content.header_listado_de_moviles', defaultMessage: 'Listado de Móviles' })+`</h2>
													</div>
												</div>
											</div>`);

						$(win.document.body).find('table')
							.addClass('compact')
							.css('font-size', 'inherit');
					},
					exportOptions: {
						columns: component.columnsToPrint
					},
					titleAttr: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
				}, {
					name: 'exportExcel',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.tools_export.select_exportar_a_excel', defaultMessage: 'Exportar a Excel' }),
					action: function (e, dt, node, config) {
						swal({
							title: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.tools_export.modal_se_estan_exportando_los_datos', defaultMessage: 'Se están exportando los datos.' }),
							text: component.props.intl.formatMessage({ id: 'movilesInmovilizadosGrid.tools_export.modal_esta_accion_puede_demorar', defaultMessage: 'Esta acción puede demorar.' }),
							buttons: {}
						});
						let region = dt.context[0].aoPreSearchCols[0].sSearch;
						let base = dt.context[0].aoPreSearchCols[1].sSearch;
						let gerenciador = dt.context[0].aoPreSearchCols[2].sSearch;
						let query = "?region_nombre=" + region + "&base_id=" + base;
						query += "&gerenciador_id=" + gerenciador;
						component.ajaxHandler.fetch('/moviles-inmovilizados/exportar-excel' + query, {
							method: 'GET'
						}).then(response => {
							if (response.status === 200) {
								return response.blob();
							}
						}).then(fileBlob => {
							let fileUrl = URL.createObjectURL(fileBlob);
							$("<a />", {
								href: fileUrl,
								download: "fleet-inmovilizados.xlsx"
							}).appendTo("body").get(0).click();
						}).catch(() => {
						}).finally(() => {
							swal.close();
						});
					}
				},  ]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('print:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('columns:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('export:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('import:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('exportExcel:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();

			$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
	}

	getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}
}

export default injectIntl(MovilesInmovilizadosGrid);