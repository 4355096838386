import axios from 'axios'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import 'dropzone/dist/min/dropzone.min.css'
import moment from 'moment'
import 'moment/min/locales'
import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropzone-component/styles/filepicker.css'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Redirect } from 'react-router-dom'
import 'react-select/dist/react-select.css'
import '../../assets/css/vec-dropzone.css'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Security from '../../commons/security/Security.js'
import backendStrings from '../../lang/backendStrings.js'
import Loading from '../ui/Loading.js'
import TicketAdjuntos from './TicketAdjuntos.js'
import TicketAsignacion from './TicketAsignacion.js'
import TicketComentarios from './TicketComentarios.js'
import TicketDatosGenerales from './TicketDatosGenerales.js'
import TicketHeader from './TicketHeader.js'
import TicketPresupuestos from './TicketPresupuestos.js'

const configAxios = {
  headers: {
      'Authorization-Token': localStorage.getItem("token"),
      'Accept': 'application/json'
  }
};

class TicketsAbm extends Component {
  constructor(props) {
		super(props);

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: null,
      errors: [],
      loading: false,
      headerFields: null,
      actions: [],
      adjuntos: [],
      modalCargarPresupuestoKey: 1, // Para forzar la recarga del componente TicketPresupuestos
    }
  }

  componentDidMount() {
    if(Security.hasPermission('TICKETS_VISUALIZAR')) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: '/error'
      });
    }
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  initForm() {
    return new Promise((resolve, reject)=>{

    this.loadFormData().then(()=>{ resolve() });

    });
    //window.scrollTo(0, 0);
    //$('#nombre').focus();
  }


  handlePresupuestoSave () {
    this.initForm();
    this.child.init();
    this.childDG.init();
  }

  handlePresupuestoClose () {
    this.child.init();
  }

  handleAsignacionSave () {
    this.initForm();
    this.child.init();
    this.childDG.init();
  }

  handleAsignacionClose () {
  }

  handleDataUpdate() {

    this.initForm().then(()=>{

      this.child.init();
      this.childDG.init();
      this.childAdjuntos.initForm();});
      this.refreshPresupuestos();
  }

  handleComentariosUpdate() {
    this.initForm();
    this.childDG.init();
  }

  handleAdjuntosUpdate(adjuntos) {
    this.setState({
      adjuntos: adjuntos
    });
  }

  handleEstadoSiniestro(siniestro, numero){

    let ticketCopy = JSON.parse(JSON.stringify(this.state.formData));

    ticketCopy.esSiniestro = siniestro;
    ticketCopy.numeroSiniestro = numero;
    this.setState({formData: ticketCopy});
  }

  handleEstadoReemplazo(reemplazo, fechaEntrega, placaReemplazo){
    let ticketCopy = JSON.parse(JSON.stringify(this.state.formData));
    ticketCopy.esReemplazoDeVehiculo = reemplazo;
    ticketCopy.fechaEntrega = fechaEntrega;
    ticketCopy.placaReemplazo = placaReemplazo;  
    this.setState({formData: ticketCopy});
  }

  handleEstadoNegligencia(negligencia){
    let ticketCopy = JSON.parse(JSON.stringify(this.state.formData));
    ticketCopy.negligencia = negligencia;
    this.setState({formData: ticketCopy});
  }

  reloadActions() {
    let component = this;
    this.setState({ loading: true });
    axios.get(Config.get('apiUrlBase') +'/tickets/' + this.state.props.match.params.id + '/actions', configAxios)
    .then(response => {
      if (response.status === 200) {
        console.log(response.data)
        component.setState({ actions: response.data })
        this.setState({ loading: false })
      }
    }).catch((e) => {
      console.log(e)
      this.setState({ loading: false })
    })
  }

  loadFormData() {
    let component = this;
    this.setState({ loading: true });
   return new Promise((resolve, reject)=>{
      Promise.all([
      component.ajaxHandler.getJson('/tickets/' + this.state.props.match.params.id),
      component.ajaxHandler.getJson('/tickets/' + this.state.props.match.params.id + '/actions'),
      ConfigBusiness.get('tickets.inmovilizado.habilitado')
    ]).then((data) => {
      
      let headerFields= [
        {
          row: 1,
          labelCols: 4,
          valueCols: 8,
          fields:[
            {
              label: component.props.intl.formatMessage({ id: 'ticketsAbm.initGrid.label_nro_ticket', defaultMessage: 'Nro. Ticket' }),
              value: data[0].id
            },
            {
              label: component.props.intl.formatMessage({ id: 'ticketsAbm.initGrid.label_fecha_creacion', defaultMessage: 'Fecha Creación' }),
              value:  moment(data[0].fechaHoraAlta).format('L')
            }
          ]
        },
        {
          row: 2,
          labelCols: 4,
          valueCols: 8,
          fields:[
            {
              label: component.props.intl.formatMessage({ id: 'ticketsAbm.initGrid.label_tipo', defaultMessage: 'Tipo' }),
              value: data[0].ticketTipo
            },
            {
              label: component.props.intl.formatMessage({ id: 'ticketsAbm.initGrid.label_estado', defaultMessage: 'Estado' }),
              value: data[0].estado
            }
          ]
        }
      ];

      if(data[0]) component.setState({
        formData: data[0],
        headerFields: headerFields,
        actions: data[1],
        inmovilizadoEnabled: data[2] === 'true' ? true : false
      });
    }).catch(function(error) {
      component.ajaxHandler.handleError(error);
      reject();
    }).finally(() => {
      component.setState({ loading: false });
      resolve();
    });
  });
  }

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/tickets'
    });
  }

  redirectLlanta = (ticket) => {
    this.setState({
			redirectTo: "/llantas/" + ticket.llanta.id + '/select'
    });
  }

    // Función para actualizar el estado y refrescar TicketPresupuestos
    refreshPresupuestos = () => {
        this.setState((prevState) => ({
            // Cambia la key para forzar el renderizado
            modalCargarPresupuestoKey: prevState.modalCargarPresupuestoKey + 1, 
        }));
    };

    render() {
        const { modalCargarPresupuestoKey } = this.state;    
		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <div className="form form-horizontal">
              <div className="form-body">
                {this.state.headerFields ? (
                <TicketHeader
                    redirectLlanta={(ticket) => this.redirectLlanta(ticket)}
                    ticket={this.state.formData}
                    adjuntos={this.state.adjuntos}
                    onRef={ref => (this.child = ref)}
                    actions={this.state.actions}
                    callbackDataUpdate={this.handleDataUpdate.bind(this)}
                    action={this.props.action}
                    refreshPresupuestos={this.refreshPresupuestos} // Pasar función al componente hijo
                    >
                </TicketHeader>
                ) : ( null )}
                {
                  this.state.formData ? (
                    <TicketDatosGenerales 
                      ticket={this.state.formData}
                      inmovilizado={this.state.inmovilizadoEnabled}
                      onRef={ref => (this.childDG = ref)}
                      actions={this.state.actions}
                      callbackDataUpdate={this.handleDataUpdate.bind(this)}
                      action={this.props.action}
                      cambioEstadoSiniestro={this.handleEstadoSiniestro.bind(this)}
                      cambioEstadoReemplazo={this.handleEstadoReemplazo.bind(this)}
                      reloadActions={this.reloadActions.bind(this)}
                      cambioEstadoNegligencia={this.handleEstadoNegligencia.bind(this)}
                    >
                    </TicketDatosGenerales>
                )   : ( null )
                }
                {(this.state.formData && !this.state.formData.autogestion && this.state.formData.ticketTipo !== 'GESTORIA') ? (
                  <div className="row">
                    <div className="col-md-6">
                      <TicketAsignacion ticket={this.state.formData} inmovilizado={this.state.inmovilizadoEnabled} onRef={ref => (this.child = ref)} callbackClose={this.handleAsignacionClose.bind(this)} callbackSave={this.handleAsignacionSave.bind(this)} actions={this.state.actions} action={this.props.action}></TicketAsignacion>
                    </div>
                    {(!this.state.formData.abono) ? (
                        <div className="col-md-6">
                            <TicketPresupuestos
                                ticket={this.state.formData}
                                onRef={ref => (this.child = ref)}
                                callbackClose={this.handlePresupuestoClose.bind(this)}
                                callbackSave={this.handlePresupuestoSave.bind(this)}
                                actions={this.state.actions}
                                action={this.props.action}
                                key={modalCargarPresupuestoKey * 17} // Forzar el re-render basado en la key
                                >
                            </TicketPresupuestos>
                        </div>
                    ) : null}
                  </div>
                ) : ( null )}
                {this.state.formData && (
                <TicketAdjuntos ticket={this.state.formData} onRef={ref => (this.childAdjuntos = ref)} actions={this.state.actions} action={this.props.action} callbackUpdate={this.handleAdjuntosUpdate.bind(this)}></TicketAdjuntos>
                )}
                {this.state.formData ? (
                <TicketComentarios ticket={this.state.formData} callbackUpdate={this.handleComentariosUpdate.bind(this)} actions={this.state.actions} action={this.props.action}></TicketComentarios>
                ) : ( null )}
              </div>
            </div>
					</div>
				</div>
        {this.props.action !== 'VIEW' && (
        <div className="card pull-up">
          <div className="card-content">
            <div className="card-body">
              <div className="text-cd text-right">
                <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                  <i className="fa fa-times-circle"></i><FormattedMessage id="ticketsAbm.finish_button.volver" defaultMessage=" Volver"/>
                </button>
              </div>
            </div>
          </div>
        </div>
        )}
			</React.Fragment>
		);
	}
}

export default injectIntl(TicketsAbm);
