import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import Security from '../../commons/security/Security.js'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
//import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import $ from 'jquery'
import dtConfig from './PanolGrid.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { getCategorias, getSubCategorias, createSubCategorias, updateSubCategorias, deleteSubCategorias } from './MetodosPanol.js' 

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class SubCatAbm extends Component { 
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			isLoading: false,
			grid: null,
			create: null,
			update: null,
			delete: null,
			categorias: []
		}
	}
	async componentDidMount() {
		
    	let component = this;
    	let filters = null;
	    const fetchLoad = async () => {

			this.setState({isLoading: true});
			Promise.all([
				await getSubCategorias().then(res=>{
			    		let dataR = res.data;
				        this.setState({
				            grid: dataR
				        })
				        return this.state.grid
			    }),
			  	await getCategorias().then(res=>{
		   				let appendState = res.data;
		            	this.setState({ categorias: this.state.categorias.concat(appendState) });
		       			return this.state.categorias
		    	}),				   
			])
			.then(allResults => {
				this.setState({isLoading: false})
				this.forceUpdate();
			})
			.catch(err => console.log(err))
		}	
		await fetchLoad();
	    const config = {
    	headers: { 
    		'Content-Type': 'application/json',
			'Authorization-Token': localStorage.getItem("token")
    	 }
		};	    
       	let table = $(this.refs.main).DataTable(
       		Object.assign({
				//dom: '<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
				stateSave: true,
				stateSaveCallback: function(settings,data) {
					localStorage.setItem('DataTables_SubCatAbm', JSON.stringify(data) );
				},
				stateLoadCallback: function(settings) {
					return JSON.parse( localStorage.getItem('DataTables_SubCatAbm') );
				},
           	ajax: {
					type: 'GET',
					url: Config.get('apiUrlBase') + '/panol/subcategorias/grid',
					
					headers: {
						'Content-Type': 'application/json',
						'Authorization-Token': localStorage.getItem("token")
					},
					dataSrc: function(res){
						return component.state.grid
					},
					error: function (xhr, error, thrown) {
					},
					cache: false
			},
			initComplete: function (settings) {
				let stateColumns = JSON.parse(localStorage.getItem('DataTables_SubCatAbm')).columns;
				var sets = settings;
				var index = 0;
				this.api().columns().every( function () {
					var column = this;
					if(sets.aoColumns[index].filterType){
						if(sets.aoColumns[index].filterType === 'select') {
							var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'subCatAbm.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
								.appendTo( $(column.footer()).empty() )
								.on( 'change', function () {
									var val = $.fn.dataTable.util.escapeRegex($(this).val());
									column
										.search( val ? val : '', true, false )
										.draw();
								});								
							if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
								filters[sets.aoColumns[index].name].map(e => {
									select.append('<option value="'+e.value+'">'+e.label+'</option>');
									return true;
								});
							} else {
								column.data().unique().sort().each( function ( d, j ) {
									select.append( '<option value="'+d+'">'+d+'</option>' );
								});
							}
							if(stateColumns[index].search.search) select.val(stateColumns[index].search.search);
						}
						if(sets.aoColumns[index].filterType === 'input') {
							var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'subCatAbm.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
							if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
							input.appendTo($(column.footer()).empty());
							input.on( 'keyup change', function () {
								if ( column.search() !== this.value ) {
									column
									.search( this.value )
									.draw()
									.ajax.reload(null, false);
								}
							});
						}
					}
					index++;
					return '';
				});
				$('tfoot tr').appendTo('thead');
			},
           	columns: [
           		{ 
	            title: component.props.intl.formatMessage({ id: 'subCatAbm.column_id.label_id', defaultMessage: 'id' }),
	            className: "text-center",
	            visible: false,
	            data: "id"
	            },
           		{ 
	            title: component.props.intl.formatMessage({ id: 'subCatAbm.column_subCategory_name.label_nombre_subCategoria', defaultMessage: 'Nombre SubCategoria' }),
	            className: "panolRow",
	            data: "nombre",
		        filterType: 'input'
	            },
	            { 
	            title: component.props.intl.formatMessage({ id: 'subCatAbm.column_subCategory_name.label_category', defaultMessage: 'Categoria' }),
	            className: "",
	            visible: false,
	            data: "categoria.id"
	            },
	            { 
	            title: component.props.intl.formatMessage({ id: 'subCatAbm.column_category.label_categoria', defaultMessage: 'Categoria' }),
	            className: "panolRow",
	            data: "categoria.nombre",
		        filterType: 'input'
	            },
	            {
				orderable: false,
				data: null,
				className: "text-center",
				width: '120px',
	            render: function () {
					let html =
						(Security.hasPermission('PANOL_SUBCATEGORIAS_MODIFICAR') ? `
						<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'subCatAbm.column_actions.button_title_editar', defaultMessage: 'Editar' })+`" >
							<i class="fa fa-pencil fa-xs"></i>
						</button>`: '') +
                        (Security.hasPermission('PANOL_SUBCATEGORIAS_ELIMINAR') ? `
						<button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'subCatAbm.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`">
							<i class="fa fa-trash fa-xs"></i>
						</button>`: '');
                        return html;
				}
        	}],
        	sDom: 'lrtip',
			orderCellsTop: true,
        	fixedHeader: true,
        	drawCallback: function () {
						$(this).find('.action').on('click', function () {
							
							if($(this).hasClass('edit')) {
								let data = table.row( $(this).parents('tr') ).data();
								let dataId = data.id;
								$("#editSubCategoriasModal #editIdSubCategoria").val(data.id);
								$("#editSubCategoriasModal #editNombreSubCategoria").val(data.nombre);
								$("#editSubCategoriasModal #editCategoriaSubCategoria").val(data.categoria.id);
								$('#editSubCategoriasModal').on('shown.bs.modal', function() {
								    $('#editNombreCategoria').trigger('focus');
								    $("#editNombreCategoria").keyup(function(e)
									{
									    if (e.which == 13)
									    {
									        $(this).submit();
									    }
									});
								  });
								$("#editSubCategoriasModal").modal({show: true});
							}
							if($(this).hasClass('delete')) {
								swal({
								title: component.props.intl.formatMessage({ id: 'subCatAbm.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
								text: null,
								icon: "warning",
								buttons: {
									confirm: {
										text: component.props.intl.formatMessage({ id: 'subCatAbm.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
										value: true,
										visible: true,
										className: "btn btn-primary",
										closeModal: false
									},
									cancel: {
										text: component.props.intl.formatMessage({ id: 'subCatAbm.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
										value: null,
										visible: true,
										className: "btn btn-danger",
										closeModal: true,
									}
								}
							}).then((isConfirm) => {
								if (isConfirm) {
									let data = table.row( $(this).parents('tr') ).data();
									let dataId = data.id;
									deleteSubCategorias(dataId).then(res => {
										let dataR = res.mensaje;
										swal(component.props.intl.formatMessage({ id: 'subCatAbm.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
										let getGrid2 = getSubCategorias().then(res2=>{
							    		let dataR2 = res2.data;
									        component.setState({
									            grid: dataR2
									        })
										table.ajax.reload();
									    })
									})
									.catch(function(error) {
										swal(component.props.intl.formatMessage({ id: 'subCatAbm.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
									});
								}
							}); //fin accion
							
							}
						});
						
					},

		}, dtConfig)
		)
		$(document).find("#example thead th").css("height","43px");
		$(document).find("#example thead th").css("padding-left","20px");
		$(document).find("#example tbody td").css("padding-left","20px");
		$('[data-toggle="tooltip"]').tooltip();

		this.setState({ loading: false });

		new $.fn.dataTable.Buttons( table, {
			buttons: [
				{
          extend: 'print',
          text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win)
					{
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						}	else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css( 'font-size', '10pt' )
							.prepend(`<div class="container">
													<div class="row mb-2">
														<div class="col-6">
															<img style="height: 35px" src="`+LogoCompany+`" />
														</div>
														<div class="col-6	text-right">
															<img style="height: 40px" src="`+LogoVecFleet+`" />
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-12" style="border-bottom: 1px solid #000;">
															<h2>`+component.props.intl.formatMessage({ id: 'SubCatAbm.print_content.header_listado_de_regiones', defaultMessage: 'Listado de Regiones' })+`</h2>
														</div>
													</div>
												</div>`);

						$(win.document.body).find( 'table' )
							.addClass( 'compact' )
							.css( 'font-size', 'inherit' );
			 		},
          exportOptions: {
              columns: [ 3,4,5,6 ]
          },
					titleAttr: component.props.intl.formatMessage({ id: 'subCatAbm.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
        },
        {
          extend: 'colvis',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'subCatAbm.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
        },
        {
          extend: 'collection',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'subCatAbm.tools_export.title_exportar', defaultMessage: 'Exportar' }),
          buttons: [
          {
            extend: 'copyHtml5',
            exportOptions: {
              columns: ':visible'
            },
						text: component.props.intl.formatMessage({ id: 'subCatAbm.tools_export.select_text_copiar', defaultMessage: 'Copiar' }),
						titleAttr: component.props.intl.formatMessage({ id: 'subCatAbm.tools_export.select_copiar', defaultMessage: 'Copiar' })
          },
          {
            extend: 'excelHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'subCatAbm.tools_export.select_exportar_excel', defaultMessage: 'Exportar a Excel' })
          },
          {
            extend: 'csvHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'subCatAbm.tools_export.select_exportar_formato_csv', defaultMessage: 'Exportar en formato .csv' })
          },
          {
            extend: 'pdfHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'subCatAbm.tools_export.select_exportar_PDF', defaultMessage: 'Exportar a PDF' })
					}
					]
				},
				{
					text: '<i class="ft-download"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'subCatAbm.tools_import.title_importar', defaultMessage: 'Importar' })
        },
			]
		});

		table.buttons( 0, null ).container().appendTo('#buttons');
		/*
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
		*/

		$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
			if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
				$('.dt-button-collection>.dropdown-menu').css('display','block');
		});
    }  
	componentWillUnmount(){
		
       $('.data-table-wrapper')
       .find('table')
       .DataTable()
       .destroy(true);
       
    }
    shouldComponentUpdate() {
        return false;
    }
    onChange = (e) => {
    this.props.history.push(`/panol/config${e.target.value}`);
  }
	onModalEditSubmit = (e) => {
	  		e.preventDefault();  		
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();
	  		updateSubCategorias(e).then(res =>{

				$(this).find('form').trigger('reset');
				$("#editSubCategoriasModal").modal("hide");
				swal(component.props.intl.formatMessage({ id: 'subCatAbm.modal_edit.information_modificado', defaultMessage: 'Modificado!' }), "", "success");
				let getGrid2 = getSubCategorias().then(res2=>{
	    		let dataR2 = res2.data;
			    component.setState({
			            grid: dataR2
			    })
				table.ajax.reload();
			    })
			})
	  	}
	  	onModalNewSubmit = (e) => {
	  		e.preventDefault();
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();
	  		createSubCategorias(e).then(res =>{

				    $("#newSubCategoriasModal").find('form').trigger('reset');
				    $("#newSubCategoriasModal").modal("hide");

				swal(component.props.intl.formatMessage({ id: 'subCatAbm.modal_add.information_agregado', defaultMessage: 'Agregado!' }), "", "success");
										
				let getGrid2 = getSubCategorias().then(res2=>{
	    		let dataR2 = res2.data;
			        component.setState({
			            grid: dataR2
			        })
				table.ajax.reload();

			    })
			    
			})
			
	  	}

    render() {
	    if (this.state.isLoading) return <div><FormattedMessage id="subCatAbm.render.loading" defaultMessage="Cargando..."/></div>;
	      return (
	      	<React.Fragment>
        	{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
    		<div className="row mt-2">
				<div className="col-12">
					<div className="card">
						<div className="card-header">
							<h1><FormattedMessage id="subCatAbm.render.select_module.header_seleccione_el_modulo_a_configurar" defaultMessage="Seleccione el módulo a configurar"/></h1>
						</div>
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								
				               	<select className="form-control form-control-lg" defaultValue="/subcategorias" onChange={this.onChange}>
								  <option value="">Medidas</option>
								  <option value="/marcas">Marcas</option>
								  <option value="/categorias">Categorias</option>
								  <option value="/subcategorias">Sub-Categorias</option>
								  <option value="/items">Items</option>
								</select>
				            </div>
			            </div>
					</div>
				{/*modal new SubCategorias*/}
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
										<div className="col-6">
                                        {Security.renderIfHasPermission('PANOL_SUBCATEGORIAS_CREAR', (
											<div 
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"  
												data-toggle="tooltip" 
												data-placement="right" 
												title={this.props.intl.formatMessage({ id: 'subCatAbm.render.button_add_subCategorias.title_nueva_subCategoria', defaultMessage: 'Nueva SubCategoria' })}
												data-toggle="modal" 
												data-target="#newSubCategoriasModal"
												>
												<i className="ft-plus"></i>
											</div>))}
											
										</div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div> 
				               	<div>
				               		<table id="example" ref="main" className="nowrap table-hover dataTableMovil" width="100%">
										<tfoot>
											<tr style={{backgroundColor: '#fff'}}>
												<th className=""></th>	
												<th className="dt-search-header"><FormattedMessage id="subCatAbm.render.table_column_nombre_subCategoria" defaultMessage="Nombre SubCategoria"/></th>
												<th className=""></th>
												<th className="dt-search-header"><FormattedMessage id="subCatAbm.render.table_column_categoria" defaultMessage="Categoria"/></th>
												<th className=""></th>
											</tr>
										</tfoot>
									</table>
					            </div>

				            </div>
			            </div>
					</div>
				</div>
			{/*---Modal nueva Subcategoria---*/}
				<div className="modal fade" id="newSubCategoriasModal" tabIndex="-1" role="dialog" aria-labelledby="newSubCategoriasModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="newSubCategoriasModalLabel"><FormattedMessage id="subCatAbm.render.modal_add.header_nueva_subCategoria" defaultMessage="Nueva SubCategoria"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <form onSubmit={this.onModalNewSubmit}>
					      <div className="modal-body">
					        <div className="row">
						        <div className="col-md-8">
						          <div className="form-group">
							           	<label htmlFor="newNombreSubCategoria" className="col-form-label"><FormattedMessage id="subCatAbm.render.modal_add.label_nombre_de_la_subCategoria" defaultMessage="Nombre de la SubCategoria:"/></label>
							            <input 
							            	type="text" 
							            	className="form-control" 
							            	name="nombre" 
							            	id="newNombreSubCategoria"
							            	placeholder={this.props.intl.formatMessage({ id: 'subCatAbm.render.modal_add.placeholder_nombre_de_la_subCategoria', defaultMessage: 'Ej: 15w40' })}
							            />
						          </div>
						          </div>
						          <div className="col-md-4">
						          <div className="form-group">
							           	<label htmlFor="newCategoria" className="col-form-label"><FormattedMessage id="subCatAbm.render.modal_add.label_categoria" defaultMessage="Categoria:"/></label>
							            <select className="form-control" id="newCategoria" name="categoria">
												  {this.state.categorias.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
						          </div>
						          </div>
						        </div>
				          	</div>					       
					      <div className="modal-footer">
					        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="subCatAbm.render.modal_add.finish_button.cancelar" defaultMessage="Cancelar"/></button>
					        <button type="submit" className="btn btn-primary"><FormattedMessage id="subCatAbm.render.modal_add.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
					      </div>
				      </form>
				    </div>
				  </div>
				</div>
				{/*---Modal edit subcategoria---*/}
				<div className="modal fade" id="editSubCategoriasModal" tabIndex="-1" role="dialog" aria-labelledby="editSubCategoriasModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="editSubCategoriasModalLabel"><FormattedMessage id="subCatAbm.render.modal_edit.header_editar_subCategoria" defaultMessage="Editar SubCategoria"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <form onSubmit={this.onModalEditSubmit}>
					      <div className="modal-body">
					        <div className="row">
						        <div className="col-md-8">
						          <div className="form-group">
							          	<input 
							            	type="hidden" 
							            	className="form-control" 
							            	name="id" 
							            	id="editIdSubCategoria"
							            />
							           	<label htmlFor="editNombreSubCategoria" className="col-form-label"><FormattedMessage id="subCatAbm.render.modal_edit.label_nombre_de_la_subCategoria" defaultMessage="Nombre de la SubCategoria:"/></label>
							            <input 
							            	type="text" 
							            	className="form-control" 
							            	name="nombre" 
							            	id="editNombreSubCategoria"
							            	placeholder={this.props.intl.formatMessage({ id: 'subCatAbm.render.modal_edit.placeholder_nombre_de_la_subCategoria', defaultMessage: 'Ej: 15w40' })}
							            />
						          </div>
						          </div>
						          <div className="col-md-4">
						          <div className="form-group">
							           	<label htmlFor="editCategoriaSubCategoria" className="col-form-label"><FormattedMessage id="subCatAbm.render.modal_edit.label_categoria" defaultMessage="Categoria:"/></label>
							            <select className="form-control" id="editCategoriaSubCategoria" name="categoria">
												  {this.state.categorias.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
						          </div>
						          </div>
						        </div>
				          	</div>					       
					      <div className="modal-footer">
					        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="subCatAbm.render.modal_edit.finish_button.cancelar" defaultMessage="Cancelar"/></button>
					        <button type="submit" className="btn btn-primary"><FormattedMessage id="subCatAbm.render.modal_edit.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
					      </div>
				      </form>
				    </div>
				  </div>
				</div>

				
			</div>
		</React.Fragment>
            );
    }
}

export default injectIntl(SubCatAbm);