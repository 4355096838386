import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../ui/Loading.js";
import $ from "jquery";
import "moment/min/locales";
import "xlsx/dist/xlsx.full.min.js";
import { FormattedMessage, injectIntl } from "react-intl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Utils from '../../commons/utils/Utils';
import swal from 'sweetalert'
import Fade from "@material-ui/core/Fade";
import TicketBodegaStock from "./TicketBodegaStock";
import TicketPresupuestoEditModal from "./TicketPresupuestoEditModal";
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'
import {Grid} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const requiredSymbol = ' *';

function Row(props) {
  const intl = props.intl;
  const [servicioItem, setServicioItem] = useState(props.servicioItem);
  const [showModalStock, setShowModalStock] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  
  const useStylesCambioLlanta = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "15px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "0px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: "15px",
    },
  }));

  const classesCambio = useStylesCambioLlanta();

  const total = () => {
    let total = null;
    if(servicioItem.cantidad !== null){
      if(servicioItem.costo !== null){
        total = servicioItem.cantidad * servicioItem.costo;
      }
    }
    return total;
  };

  const closeModalStock = (event) => {
    setShowModalStock(false);
  };

  const verItemSock = (event) => {
    setShowModalStock(true);
  };

  const updateElement = (data) => {
    props.updateServicioItem(data, props.index);
    servicioItem.cantidad = data.cantidad;
    servicioItem.costo = data.costo;
    closeModalEdit();
  }

  const closeModalEdit = (event) => {
    setShowModalEdit(false);
  };

  return (
    <React.Fragment>
      <tr key={servicioItem.id}>
        <Modal
          open={showModalStock}
          onClose={closeModalStock}
          className={classesCambio.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModalStock}>
            <Box sx={style}>
              <TicketBodegaStock
                intl={props.intl}
                external_code={servicioItem.external_code}
                closeModalStock={closeModalStock}
              />
            </Box>
          </Fade> 
        </Modal>
        <Modal
          open={showModalEdit}
          onClose={closeModalEdit}
          className={classesCambio.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModalEdit}>
            <Box sx={style}>
              <TicketPresupuestoEditModal
                type="servicioItem"
                intl={props.intl}
                element={servicioItem}
                updateElement={updateElement}
                closeModalEdit={closeModalEdit}
              />
            </Box>
          </Fade> 
        </Modal>
        <td className="all custom-lineHeight">
          {servicioItem.external_code}
        </td>
        <td className="all custom-lineHeight">
          {servicioItem.descripcion}
        </td>
        <td className="all custom-lineHeight">
          {servicioItem.cuenta_mayor}
        </td>
        <td className="all custom-lineHeight">
          {servicioItem.cuenta_mayor_descripcion}
        </td>
        <td className="all custom-lineHeight">
          {servicioItem.cantidad}
        </td>
				<td className="all custom-lineHeight">
          {servicioItem.costo}
        </td>
        <td className="all custom-lineHeight">
          {total()}
        </td>
				<td className="all custom-lineHeight">
          <button
            disabled={servicioItem.external_code ? false : true}
            className="action view btn btn-sm btn-icon btn-dt-grid text-success"
            title={intl.formatMessage({ id: 'Ver', defaultMessage: 'Ver' })}
            data-togle="tooltip"
            data-placement="top"
            onClick={() => verItemSock(servicioItem.external_code)}
          >
            <i className="fa fa-search fa-xs"/>
          </button>
          <button
            className="action edit btn btn-sm btn-icon btn-dt-grid text-warning"
            title={intl.formatMessage({ id: 'Editar', defaultMessage: 'Editar' })}
            data-togle="tooltip"
            data-placement="top"
            onClick={() => setShowModalEdit(true)}
          >
            <i className="fa fa-pencil fa-xs"/>
          </button>
          <button
            className="action view btn btn-sm btn-icon btn-dt-grid text-danger"
            title={intl.formatMessage({ id: 'Eliminar', defaultMessage: 'Eliminar' })}
            data-togle="tooltip"
            data-placement="top"
            onClick={() => props.deleteItem(props.index)}
          >
            <i className="fa fa-trash fa-xs"></i>
          </button>
        </td>
      </tr>


    </React.Fragment>
  );
}

const NEW_SERVICIO_ITEM_INIT = {
  external_code: '',
  descripcion: '',
  cuenta_mayor: '',
  cuenta_mayor_descripcion: '',
  cantidad: '',
  costo: '',
  id_item: null,
  servicio_id: null
};

const TicketPresupuestoRepuesto = (props) => {
  const intl = props.intl;
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [newServicioItem, setNewServicioItem] = useState({ ...NEW_SERVICIO_ITEM_INIT })
  const [items, setItems] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [servicio, setServicio] = useState('');
  const MySwal = withReactContent(Swal);

  const setLoadingAndUpdate = (state) => {
    setLoading(state);
  };

  const handleMaterialChange = (name, value) => {
    let copy = JSON.parse(JSON.stringify(newServicioItem));
    if (name === "costo" || name === "cantidad") {
      if ((value && value >= 0) || !value) {
        copy[name] = value;
      }
    }else{
      copy[name] = value;
    }
    setNewServicioItem(copy)
  };

  const HandleURepuesto = (e) => {
    let external_code = e.target.value;
    if (external_code) { validateRepuesto(external_code); }
  };

  const validateRepuesto = (external_code) => {
    Utils.getData('/items/simple-search?external_code=' + external_code)
    .then((data) => {
      if(data.length > 0){
        let copy = JSON.parse(JSON.stringify(newServicioItem));
        copy.external_code = data[0].external_code ? data[0].external_code : ''
        copy.descripcion = data[0].descripcion ? data[0].descripcion : ''
        copy.cuenta_mayor = data[0].cuenta_mayor ? data[0].cuenta_mayor : ''
        copy.cuenta_mayor_descripcion = data[0].cuenta_mayor_descripcion ? data[0].cuenta_mayor_descripcion : ''
        copy.costo = data[0].costo ? data[0].costo : "0"
        copy.id_item = data[0].id
        setNewServicioItem(copy)
        loadServices(data[0].id)
      }else{
        swal(
          intl.formatMessage({id: 'El_código_de_repuesto_es_inexistente', defaultMessage: 'El código de repuesto es inexistente.' }),
          intl.formatMessage({id: 'Por_favor_ingrese_los_datos_manualmente.', defaultMessage: 'Por favor ingrese los datos manualmente.' }),
          "warning"
        );
        let copy = JSON.parse(JSON.stringify(newServicioItem));
        copy.external_code = '';
        copy.descripcion = ''
        copy.id_item = ''
        copy.cuenta_mayor = ''
        copy.cuenta_mayor_descripcion = ''
        copy.costo = ''
        setServicio('');
        setServicios([]);
        setNewServicioItem(copy)
      }
    });
  };

  const handleFormChangeSelectObject = (value) => {
    let copy = JSON.parse(JSON.stringify(newServicioItem));
    if(value){
      copy.external_code = value.external_code ? value.external_code : ''
      copy.descripcion = value.descripcion ? value.descripcion : ''
      copy.cuenta_mayor = value.cuenta_mayor ? value.cuenta_mayor : ''
      copy.cuenta_mayor_descripcion = value.cuenta_mayor_descripcion ? value.cuenta_mayor_descripcion : ''
      copy.costo = value.costo ? value.costo : "0"
      copy.id_item = value.id
      loadServices(value.id)
    }else{
      copy.descripcion = ''
      copy.id_item = null
    }
    setNewServicioItem(copy)
  };

  const handleInputItemsChange = (e) => {
    let value = e.target.value;
    let copy = JSON.parse(JSON.stringify(newServicioItem));
    copy.descripcion = value;
    setNewServicioItem(copy)
    Utils.getData('/items/simple-search?descripcion=' + value)
    .then((data) => {
      setItems(data)
    });
  };

  const addItem = () => {
    props.addItemServicio(newServicioItem)
    setNewServicioItem({ ...NEW_SERVICIO_ITEM_INIT })
    setServicio('');
    setServicios([]);
  };

  const validate = () => {
    let result = false
    result = (!newServicioItem.costo || !newServicioItem.cantidad)
    if(!newServicioItem.descripcion) {result = true}
    if(!newServicioItem.external_code){
      if(!newServicioItem.cuenta_mayor) {result = true}
      if(!newServicioItem.cuenta_mayor_descripcion) {result = true}
    }else{
      if(!newServicioItem.cuenta_mayor) {result = true}
    }
    return result;
  };

  const handleChangeSelectCuentaMayor = (value) => {
    setServicio(value);
    let copy = JSON.parse(JSON.stringify(newServicioItem));
    if(value){
      copy['cuenta_mayor'] = value.external_code
      copy['cuenta_mayor_descripcion'] = value.nombre
      copy['cantidad'] = value.cantidad ? value.cantidad : '';
      copy['servicio_id'] = value.id;
    }else{
      copy['cuenta_mayor'] = ''
      copy['cuenta_mayor_descripcion'] = ''
      copy['cantidad'] = '';
      copy['servicio_id'] = null;
    }
    
    setNewServicioItem(copy)
  };

  const loadServices = (itemId) => {
    Utils.getData('/servicios/select-dinant/filter?tipo=item&ticketTipo=' + props.ticket.ticketTipo + '&itemId=' + itemId)
    .then((data) => {
      setServicios(data)
    });
  }

  return (
    <React.Fragment>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}
			<div className="table-responsive" style={{padding: "15px"}}>
        <div style={{marginRight: "3px", marginLeft: "3px", padding: "6px"}}>
          <Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <TextField
                  id="external_code"
                  InputLabelProps={newServicioItem.external_code ? { shrink: true} : ""}
                  label={intl.formatMessage({ id: 'Repuesto', defaultMessage: 'Repuesto' })}
                  placeholder={intl.formatMessage({ id: 'Repuesto', defaultMessage: 'Repuesto' })}
                  value={newServicioItem.external_code}
                  onChange={e => handleMaterialChange('external_code', e.target.value)}
                  onBlur={HandleURepuesto}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  disabled={newServicioItem.external_code ? true : false}
                  name="itemDescripcion"
                  options={items}
                  value={newServicioItem}
                  getOptionLabel={(option) => option.descripcion ? option.descripcion : ''}
                  onChange={(event, value) => handleFormChangeSelectObject(value)}
                  renderInput={(params) => (
                    <TextField {...params}
                      label={intl.formatMessage({ id: "Descripción", defaultMessage: "Descripción" })}
                      placeholder={intl.formatMessage({id: 'Descripción', defaultMessage: 'Descripción'})}
                      value={newServicioItem.descripcion}
                      onChange={handleInputItemsChange}
                      onBlur={(e) => loadServices(newServicioItem.id_item ? newServicioItem.id_item : '')}
                    />
                  )}
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <TextField
                  disabled={newServicioItem.external_code || newServicioItem.descripcion ? false : true}
                  id="cuenta_mayor"
                  type="text"
                  select
                  label={intl.formatMessage({ id: 'Cuenta_Mayor', defaultMessage: 'Cuenta Mayor' })}
                  placeholder={intl.formatMessage({ id: 'Cuenta_Mayor', defaultMessage: 'Cuenta Mayor' })}
                  onChange={(e) => handleChangeSelectCuentaMayor(e.target.value)}
                  value={servicio}
                  className="col-12"
                  InputLabelProps={newServicioItem.cuenta_mayor ? { shrink: true} : ""}
                >
                  {servicios.map(option => (
                    <MenuItem key={option.id} value={option}>
                      {option.external_code}
                    </MenuItem>
                  ))}
                </TextField>
              </form>
            </Grid>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <TextField
                  disabled={true}
                  id="cuenta_mayor_descripcion"
                  type="text"
                  InputLabelProps={newServicioItem.cuenta_mayor_descripcion ? { shrink: true} : ""}
                  label={intl.formatMessage({ id: 'descrip._Cuenta_Mayor', defaultMessage: 'Descrip. Cuenta Mayor' })}
                  placeholder={intl.formatMessage({ id: 'descrip._Cuenta_Mayor', defaultMessage: 'Descrip. Cuenta Mayor' })}
                  value={newServicioItem.cuenta_mayor_descripcion}
                  onChange={e => handleMaterialChange('cuenta_mayor_descripcion', e.target.value)}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={1}>
              <form noValidate autoComplete="off">
                <TextField
                  id="cantidad"
                  type="number"
                  InputLabelProps={newServicioItem.cantidad ? { shrink: true} : ""}
                  InputProps={{
                    inputProps: { min: 0 }
                  }}
                  label={intl.formatMessage({ id: 'Cantidad', defaultMessage: 'Cantidad' })}
                  placeholder={intl.formatMessage({ id: 'Cantidad', defaultMessage: 'Cantidad' })}
                  value={newServicioItem.cantidad}
                  onChange={e => handleMaterialChange('cantidad', e.target.value)}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={2}>
              <form noValidate autoComplete="off">
                <TextField
                  disabled={newServicioItem.external_code ? true : false}
                  id="costo"
                  type="number"
                  InputLabelProps={newServicioItem.costo ? { shrink: true} : ""}
                  InputProps={{
                    inputProps: { min: 0 }
                  }}
                  label={intl.formatMessage({ id: 'Precio', defaultMessage: 'Precio' })}
                  placeholder={intl.formatMessage({ id: 'Precio', defaultMessage: 'Precio' })}
                  value={newServicioItem.costo}
                  onChange={e => handleMaterialChange('costo', e.target.value)}
                  className="col-12"
                />
              </form>
            </Grid>
            <Grid component={Box} item xs={1}>
              <form noValidate autoComplete="off">
                <button
                  className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main"
                  style={{ height: 49 }}
                  onClick={() => addItem()}
                  disabled={validate()}
                >
                  <i className="ft-plus"></i>
                </button>
              </form>
            </Grid>
          </Grid>
        </div>
      
				<table
					id="dataTable"
					className="dataTable table nowrap server-side table-hover dataTableMovil"
					width="100%"
				>
					<thead>
						<tr>
							{/* <th scope="col"></th> */}
							<th scope="col">
								<FormattedMessage id="Repuesto" defaultMessage="Repuesto" />
							</th>
							<th scope="col" style={{ maxWidth: "3px" }}>
								<FormattedMessage id="Descripción" defaultMessage="Descripción" />
							</th>
							<th scope="col">
								<FormattedMessage id="Cuenta_Mayor" defaultMessage="Cuenta Mayor" />
							</th>
              <th scope="col">
								<FormattedMessage id="Descripción_Cuenta_Mayor" defaultMessage="Descripción Cuenta Mayor" />
							</th>
							<th scope="col">
								<FormattedMessage id="Cantidad" defaultMessage="Cantidad" />
							</th>
							<th scope="col">
								<FormattedMessage id="Precio" defaultMessage="Precio" />
							</th>
							<th scope="col">
								<FormattedMessage id="Total" defaultMessage="Total" />
							</th>
							<th scope="col">
							</th>
						</tr>
					</thead>
					<tbody>
						{props.servicioItems ? (
							props.servicioItems.map((servicioItem, index) => (
								<Row
                  index={index}
									intl={props.intl}
									setRedirectTo={setRedirectTo}
									servicioItem={servicioItem}
									loading={(state) => setLoadingAndUpdate(state)}
									key={servicioItem.external_code + servicioItem.descripcion + index}
                  deleteItem={props.deleteItem}
                  updateServicioItem={props.updateServicioItem}
								/>
							))
						) : (
							<tr>
								<td align={"center"} colSpan={14}>
									{loading ? (
										<CircularProgress />
									) : (
										<FormattedMessage
											id="llantasGrid.rennder.sin_datos_en_tabla"
											defaultMessage="No hay datos disponibles."
										/>
									)}
								</td>
							</tr>
						)}
					</tbody>
				</table>
        <div className="row p-1" style={{justifyContent: "right"}}>
          <label className="col-md-2 label-control col-form-label" htmlFor="repuestos">
            <FormattedMessage id="Total_Repuestos" defaultMessage="Total Repuestos"/> {requiredSymbol}:
          </label>
          <div className="col-md-3">
            <div>
              <input disabled 
                type="number"
                className="form-control text-right pr-1"
                id="total_repuestos"
                name="total_repuestos"
                value={props.totalRepuestos}
              />
            </div>
          </div>
        </div>
			</div>
    </React.Fragment>
  );
};

export default injectIntl(TicketPresupuestoRepuesto);
