import React from "react";
import { FormattedMessage } from "react-intl";

function RopGridHeaders(props) {
    const { rop } = props;

    return (
        <tr>
            <th scope="col">
                <FormattedMessage id="ropsGrid.rennder.column_fecha.label" defaultMessage="Fecha" />
            </th>
            <th scope="col">
                <FormattedMessage id="ropsGrid.rennder.column_numero.label" defaultMessage="Número" />
            </th>
            <th scope="col">
                <FormattedMessage id="ropsGrid.rennder.column_equipo.label" defaultMessage="Equipo" />
            </th>
            <th scope="col">
                <FormattedMessage id="ropsGrid.rennder.column_taller.label" defaultMessage="Taller" />
            </th>
            <th scope="col">
                <FormattedMessage id="ropsGrid.rennder.column_clasificacion.label" defaultMessage="Clasificación" />
            </th>
            <th scope="col">
                <FormattedMessage id="ropsGrid.rennder.column_estado.label" defaultMessage="Estado" />
            </th>
            <th scope="col" align="center"></th>
        </tr>
    )
}

export default RopGridHeaders;