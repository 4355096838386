import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import MovilesInmovilizadosGrid from './MovilesInmovilizadosGrid'
import {FormattedMessage, injectIntl} from 'react-intl';


class MovilesInmovilizados extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      			<h3 className="content-header-title"><FormattedMessage id="movilesInmovilizados.header_moviles_inmovilizados" defaultMessage="Móviles inmovilizados"/></h3>
      		</div>
				</div>
				<div className="content-body">								
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={MovilesInmovilizadosGrid} />
					</Switch>										
				</div>
			</div>
	    );
  	}
}

export default MovilesInmovilizados;
