import React, { Component } from 'react'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Config from '../../commons/config/Config.js'
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone'
import $ from 'jquery'
import Select from 'react-select'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

class PreventivosImportarReporte extends Component {
	constructor(props) {
		super(props);
		this.props = props;

		this.state = {
			tipo: null,
			tiposArchivos: []
		};

	}

	componentDidMount() {

		this.ajaxHandler = new AjaxHandler();
		this.ajaxHandler.subscribe(this);

	}

	componentWillUnmount() {

		this.ajaxHandler.unsubscribe();

	}

	handleUploadFiles(file){
		this.setState({archivo: file});
	}

	resetModal(message="", faltantes=null, error){
		this.setState({
			tipo: null,
			archivo: null,
			loading: false
		})
		this.props.callbackClose(message, faltantes, error);
	}

	handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.props.callbackCancel();
	}

	handleSave(event) {

		this.setState({loading: true },()=>this.dropzone.processQueue());
		event.preventDefault();
	}

	render() {

		return (
				<React.Fragment>
					<div className="card pull-up">
						<div className="card-content pl-1 pr-1">
							<div className="card-body form">
								<h4 className="form-section">
									Filas que fallaron
								</h4>

								<div className="row mb-1" style={{border: 0}}>
									<div className="content-body" style={{margin: "auto"}}>
										{this.props.data ? Object.entries(this.props.data).map(([index,pagina])=> {

										return <div className="row mt-2" key={index}>
										<div className="table-responsive">
										<table id="dataTable" className="table dataTable nowrap server-side table-hover dataTableMovil" ref="grid" width="100%">
											<thead>
												<tr>
													<th >Fila</th>
													<th >Dominio</th>
													<th >Motivo Falla</th>
												</tr>
											</thead>
											<tbody>
												{pagina.map((filas,index)=>{
													return <tr key={index}>
														<td>
															{ filas.fila }
														</td>
														<td>
															{ filas.dominio }
														</td>
														<td>
															{ filas.motivo_falla }
														</td>
													</tr>
												})}
											</tbody>
										</table>
									</div>

										</div>
										}) : null}
									</div>
								</div>

							</div>

						</div>
					</div>

				</React.Fragment>)}
}

export default PreventivosImportarReporte
