import React, { Component } from 'react'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import {FormattedMessage, injectIntl} from 'react-intl';
import Timezone from '../../commons/timezone/Timezone.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

class TicketVerPresupuestosDetallado extends Component {
	constructor(props) {
		super(props);
		this.props = props;

		this.ajaxHandler = new AjaxHandler();

		moment.locale('es');

		if(props.ticket) {
			this.ticket = props.ticket;
		}
		this.action = props.action;

		this.state = {
			loading: false,
			presupuestos: [],
			indexPresupuestoDetalle: null,
			presupuestoActivo: null,
			presupuestoActivo: null
		}
    }

  componentDidMount() {
		if(Security.hasPermission('TICKETS_VER_PRESUPUESTOS')) {
			this.ajaxHandler.subscribe(this);
			this.loadFormData();
		} else {
			this.setState({
			redirectTo: '/error'
			});
		}
  }

  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
  }

  componentDidUpdate() {
		$('[data-tooltip="tooltip"]').tooltip();
  }

  loadFormData() {
		let component = this;
		component.setState({ loading: true });
		component.ajaxHandler.getJson('/ticket-presupuestos/ticket/' + this.props.ticket.id + '/grid')
		.then(data => {
			if(data){
				let index = data.findIndex((element) => element.activo === true);
				let presupuestoActivo = data[index];
				data.splice(index, 1);
				component.setState({presupuestos: data, presupuestoActivo: presupuestoActivo});
			}
		}).finally(() => {
			component.setState({ loading: false });
		});
  }

  handleViewAdjunto(e, adjunto) {
		this.ajaxHandler.fetch('/ticket-presupuestos/adjunto/' + adjunto, {
			method: 'GET'
		}).then(response => {
			if (response.status === 200) {
			return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			window.open(fileUrl);
		}).catch(() => {
		});
		e.preventDefault();
  }

	viewDetalle(e, index){
		if(this.state.indexPresupuestoDetalle === index){
			this.setState({indexPresupuestoDetalle: null});
		}else{
			this.setState({indexPresupuestoDetalle: index});
		}
		e.preventDefault();
	}

    renderTableTheadTarea(field, index){
		switch (field) {
			case 'index':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Items" defaultMessage="Items" />
					</th>
				);
			case 'id':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Id" defaultMessage="Id" />
					</th>
				);
			case 'external_code':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Tarea" defaultMessage="Tarea" />
					</th>
				);
			case 'nombre':
				return (
					<th key={index} scope="col" style={{ maxWidth: "3px" }}>
						<FormattedMessage id="Presupuesto.Descripción" defaultMessage="Descripción" />
					</th>
				);
			case 'cuenta_mayor':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cuenta_Mayor" defaultMessage="Cuenta Mayor" />
					</th>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Descripción_Cuenta_Mayor" defaultMessage="Descripción Cuenta Mayor" />
					</th>
				);
			case 'cantidad':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cantidad" defaultMessage="Cantidad" />
					</th>
				);
			case 'precio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Precio" defaultMessage="Precio" />
					</th>
				);
			case 'total':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Total" defaultMessage="Total" />
					</th>
				);
			case 'servicio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Servicio" defaultMessage="Servicio" />
					</th>
				);
			default:
				return null;
		}
	}

    renderTableTheadItem(field, index){
		switch (field) {
			case 'index':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Items" defaultMessage="Items" />
					</th>
				);
			case 'id':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Id" defaultMessage="Id" />
					</th>
				);
			case 'external_code':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Tarea" defaultMessage="Tarea" />
					</th>
				);
			case 'descripcion':
				return (
					<th key={index} scope="col" style={{ maxWidth: "3px" }}>
						<FormattedMessage id="Presupuesto.Descripción" defaultMessage="Descripción" />
					</th>
				);
			case 'cuenta_mayor':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cuenta_Mayor" defaultMessage="Cuenta Mayor" />
					</th>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Descripción_Cuenta_Mayor" defaultMessage="Descripción Cuenta Mayor" />
					</th>
				);
			case 'cantidad':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cantidad" defaultMessage="Cantidad" />
					</th>
				);
			case 'costo':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Precio" defaultMessage="Precio" />
					</th>
				);
            case 'marcaNombre':
                return (
                    <th key={index} scope="col">
                        <FormattedMessage id="Presupuesto.Marca" defaultMessage="Marca" />
                    </th>
                );
			case 'total':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Total" defaultMessage="Total" />
					</th>
				);
			case 'servicio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Servicio" defaultMessage="Servicio" />
					</th>
				);
			default:
				return null;
		}
	}

    renderTableTbodyTarea(element, field, index, index_2){
		switch (field) {
			case 'index':
				return (
					<td key={index} className="all custom-lineHeight">
						{(index_2 ? index_2 : index) + 1}
					</td>
				);
			case 'id':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.id_tarea}
					</td>
				);
			case 'external_code':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.external_code}
					</td>
				);
			case 'nombre':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.nombre}
					</td>
				);
			case 'cuenta_mayor':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.cuenta_mayor}
					</td>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.cuenta_mayor_descripcion}
					</td>
				);
			case 'cantidad':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.cantidad}
					</td>
				);
			case 'precio':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.precio}
					</td>
				);
			case 'total':
				return (
					<td key={index} className="all custom-lineHeight">
                        {element.precio && element.cantidad ? ('$ ' + Math.round((element.precio*element.cantidad)*100)/100) : ''}
					</td>
				);
			case 'servicio':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.servicio_nombre}
					</td>
				);
			default:
				return null;
		}
	}

    renderTableTbodyItem(element, field, index, index_2){
		switch (field) {
			case 'index':
				return (
					<td key={index} className="all custom-lineHeight">
						{(index_2 ? index_2 : index) + 1}
					</td>
				);
			case 'id':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.id_item}
					</td>
				);
			case 'external_code':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.external_code}
					</td>
				);
			case 'descripcion':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.descripcion}
					</td>
				);
			case 'cuenta_mayor':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.cuenta_mayor}
					</td>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.cuenta_mayor_descripcion}
					</td>
				);
			case 'cantidad':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.cantidad}
					</td>
				);
			case 'costo':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.precio}
					</td>
				);
            case 'marcaNombre':
                return (
                    <td key={index} className="all custom-lineHeight">
                        {element.marcaNombre}
                    </td>
                );
			case 'total':
				return (
					<td key={index} className="all custom-lineHeight">
                        {element.precio && element.cantidad ? ('$ ' + Math.round((element.precio*element.cantidad)*100)/100) : ''}
					</td>
				);
			case 'servicio':
				return (
					<td key={index} className="all custom-lineHeight">
						{element.servicio_nombre}
					</td>
				);
			default:
				return null;
		}
	}

	render() {
        let enabledItems = ConfigBusiness.get('tickets.presupuesto.items.habilitado') === 'true';
        let showItems = ConfigBusiness.get('tickets.presupuesto.items.show').split(',');
		let enabledTareas = ConfigBusiness.get('tickets.presupuesto.tareas.habilitado') === 'true'
		let showTareas = ConfigBusiness.get('tickets.presupuesto.tareas.show').split(',');
		if(this.props.ticket) {
			return (
			<React.Fragment>
				{this.state.loading && <Loading />}
				<div className="row-12">
					{this.state.presupuestoActivo ? (
					<>
						<div className="d-flex justify-content-md-center justify-content-start pt-4">
							<div className="col-12 col-md-4">
								<div className="card text-dark" style={{borderLeftWidth: "0px", background: "#eaecef"}}>
									<div className="card-body text-center" style={{padding: "15px"}}>
										<div className="row-md-12" style={{padding: "3px"}}>
											<div className="row-12">
												<p className="card-text"><FormattedMessage id="Presupuesto.Fecha" defaultMessage="Fecha"/>: <b>{Timezone.getDateForClient(this.state.presupuestoActivo.fechaHora, 'YYYY-MM-DD HH:mm:ss', "DD/MM/YYYY HH:mm:ss")}</b></p>
											</div>
											<div className="row-12">
												<p className="card-text"><FormattedMessage id="Presupuesto.Subido_por" defaultMessage="Subido por"/>: <b>{this.state.presupuestoActivo.persona ? (this.state.presupuestoActivo.persona.nombre + ' '  + this.state.presupuestoActivo.persona.apellido) : ''}</b></p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-8">
								<div className="card text-dark " style={{borderLeftWidth: "0px", background: "#eaecef"}}>
									<div className="card-body">
										<div className="d-flex justify-content-around align-items-center" style={{fontSize: "24px"}}>
											<div>
												<p className="card-text"><b><FormattedMessage id="Presupuesto.Total_Presupuesto_para_el_Ticket" defaultMessage="Total Presupuesto para el Ticket"/>: {this.props.ticket.id}</b></p>
											</div>
											<div>
												<p className="card-text">$<b>{Math.round((this.state.presupuestoActivo.manoDeObra + this.state.presupuestoActivo.repuestos + this.state.presupuestoActivo.impuestos + this.state.presupuestoActivo.otros)*100)/100}</b></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
                        {enabledTareas && showTareas.length > 0 ?
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-12">
                                        <h2 className="p-2" style={{color: "#44c8cf"}}><b><FormattedMessage id="Presupuesto.Mano_de_Obra" defaultMessage="Mano de Obra"/></b></h2>
                                        <table className="table">
                                            <thead className="thead-fleet">
                                                <tr>
                                                    {showTareas.map((field, index) => this.renderTableTheadTarea(field, index))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.presupuestoActivo.presupuestoManoObras.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {showTareas.map((field, index_2) => this.renderTableTbodyTarea(element, field,index_2, index))}
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <td className="align-middle" colSpan="2">
                                                        <b style={{fontSize: "17px"}}><FormattedMessage id="Presupuesto.Total_Mano_de_Obra" defaultMessage="Total Mano de Obra"/></b>
                                                    </td>
                                                    <td className="align-middle" colSpan={showTareas.length  - 3}>
                                                    </td>
                                                    <td className="align-middle">
                                                        <b style={{fontSize: "17px"}}>$ {Math.round(this.state.presupuestoActivo.manoDeObra*100)/100}</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        : null}
                        {enabledItems && showItems.length > 0 ?
                            <div className="row pt-2">
                                <div className="col-md-12">
                                    <div className="col-md-12">
                                        <h2 className="p-2" style={{color: "#44c8cf"}}><b><FormattedMessage id="Presupuesto.Repuestos" defaultMessage="Repuestos"/></b></h2>
                                        <table className="table">
                                            <thead className="thead-fleet">
                                                <tr>
                                                    {showItems.map((field, index) => this.renderTableTheadItem(field, index))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.presupuestoActivo.presupuestoRepuestos.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {showItems.map((field, index_2) => this.renderTableTbodyItem(element, field, index_2, index))}
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <td className="align-middle" colSpan="2">
                                                        <b style={{fontSize: "17px"}}><FormattedMessage id="Presupuesto.Total_Repuestos" defaultMessage="Total Repuestos"/></b>
                                                    </td>
                                                    <td className="align-middle" colSpan={showItems.length - 3}>
                                                    </td>
                                                    <td className="align-middle">
                                                        <b style={{fontSize: "17px"}}>$ {Math.round(this.state.presupuestoActivo.repuestos*100)/100}</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        : null}
						<div className="d-flex pt-2">
							<div className="col-8 col-md-8">
							</div>
							<div className="col-3 col-md-3">
								<b style={{fontSize: "17px"}}><FormattedMessage id="Presupuesto.Subtotal" defaultMessage="Subtotal"/></b>
							</div>
							<div className="col-1 col-md-1">
								<b style={{fontSize: "17px"}}>$ {Math.round((this.state.presupuestoActivo.repuestos + this.state.presupuestoActivo.manoDeObra)*100)/100 }</b>
							</div>
						</div>
						<div className="d-flex pt-2">
							<div className="col-8 col-md-8">
							</div>
							<div className="col-3 col-md-3">
								<span style={{fontSize: "17px"}}><FormattedMessage id="Presupuesto.Impuestos" defaultMessage="Impuestos"/></span>
							</div>
							<div className="col-1 col-md-1">
								<span style={{fontSize: "17px"}}>$ {Math.round(this.state.presupuestoActivo.impuestos*100)/100}</span>
							</div>
						</div>
						<div className="d-flex pt-2">
							<div className="col-8 col-md-8">
							</div>
							<div className="col-3 col-md-3">
								<span style={{fontSize: "17px"}}><FormattedMessage id="Presupuesto.Otros" defaultMessage="Otros"/></span>
							</div>
							<div className="col-1 col-md-1">
								<span style={{fontSize: "17px"}}>$ {Math.round(this.state.presupuestoActivo.otros*100)/100}</span>
							</div>
						</div>
						<div className="d-flex pt-2">
							<div className="col-8 col-md-8">
							</div>
							<div className="col-3 col-md-3 p-1" style={{borderTopStyle: "solid", borderColor: "#0ABFBC"}}>
								<b style={{fontSize: "17px"}}><FormattedMessage id="Total" defaultMessage="Total"/></b>
							</div>
							<div className="col-1 col-md-1 p-1" style={{borderTopStyle: "solid", borderColor: "#0ABFBC"}}>
								<b style={{fontSize: "17px"}}>$ {Math.round((this.state.presupuestoActivo.manoDeObra + this.state.presupuestoActivo.repuestos + this.state.presupuestoActivo.impuestos + this.state.presupuestoActivo.otros)*100)/100}</b>
							</div>
						</div>
						<div className="col-12 col-md-12 pt-2" hidden={this.state.presupuestoActivo.adjunto ? false : true}>
							<div className="d-flex flex-row-reverse">
								<button className="btn btn-primary" onClick={(e) => this.handleViewAdjunto(e, this.state.presupuestoActivo.adjunto)}>
									<FormattedMessage id="Ver_Archivos_Adjuntos" defaultMessage="Ver Archivos Adjuntos"/> <i className="fa fa-paperclip fa-xs"></i>
								</button>
							</div>
						</div>
					</> ): ''}
					<div className="row pt-5">
						<div className="col-md-12">
							<div className="col-md-12">
								<h2 className="p-2" style={{color: "#44c8cf"}}><b><FormattedMessage id="Historial_de_Presupuestos" defaultMessage="Historial de Presupuestos"/></b></h2>
								<table className="table">
									<thead className="thead-fleet">
										<tr>
											<th scope="col">
												<FormattedMessage id="Fecha" defaultMessage="Fecha"/>
											</th>
											<th scope="col">
												<FormattedMessage id="Nombre" defaultMessage="Nombre"/>
											</th>
											<th scope="col">
												<FormattedMessage id="Subido_por" defaultMessage="Subido por"/>
											</th>
											<th scope="col">
												<FormattedMessage id="Mano_de_obra" defaultMessage="Mano de obra"/>
											</th>
											<th scope="col">
												<FormattedMessage id="Repuestos" defaultMessage="Repuestos"/>
											</th>
											<th scope="col">
												<FormattedMessage id="Impuestos" defaultMessage="Impuestos"/>
											</th>
											<th scope="col">
												<FormattedMessage id="Otros" defaultMessage="Otros"/>
											</th>
											<th scope="col">
												<FormattedMessage id="Total" defaultMessage="Total"/>
											</th>
											<th scope="col">
												<FormattedMessage id="Adicional" defaultMessage="Adicional"/>
											</th>
											<th className="text-right" scope="col">
												<FormattedMessage id="Acciones" defaultMessage="Acciones"/>
											</th>
										</tr>
									</thead>
									<tbody>
										{this.state.presupuestos.length > 0 ?
											this.state.presupuestos.map((presupuesto, index) => {
												return (
													<React.Fragment key={index}>
														<tr>
															<td className="align-middle">
																{Timezone.getDateForClient(presupuesto.fechaHora, 'YYYY-MM-DD HH:mm:ss', "DD/MM/YYYY HH:mm:ss")}
															</td>
															<td className="align-middle">
																{presupuesto.nombre}
															</td>
															<td className="align-middle">
																{presupuesto.persona.nombre + ' '  + presupuesto.persona.apellido }
															</td>
															<td className="align-middle">
																{Math.round(presupuesto.manoDeObra*100)/100}
															</td>
															<td className="align-middle">
																{Math.round(presupuesto.repuestos*100)/100}
															</td>
															<td className="align-middle">
																{Math.round(presupuesto.impuestos*100)/100}
															</td>
															<td className="align-middle">
																{Math.round(presupuesto.otros*100)/100}
															</td>
															<td className="align-middle">
																{Math.round((presupuesto.manoDeObra + presupuesto.repuestos + presupuesto.impuestos + presupuesto.otros)*100)/100}
															</td>
															<td className="align-middle">
																{presupuesto.adicional? (<div className="text-center success dt-info-icon"><i className="ft-check"></i></div>) : ''}
															</td>
															<td className="text-right">
																<div className="d-inline-flex">
																	<button
																		className="action view btn btn-sm btn-icon btn-dt-grid text-fleet"
																		title="Ver"
																		data-togle="tooltip"
																		data-placement="top"
																		onClick={(e) => this.viewDetalle(e, index)}
																	>
																		<i className="fa fa-eye fa-xs"/>
																	</button>
                                                                    {presupuesto.adjunto ?
                                                                        <button
                                                                            className="action btn btn-icon text-fleet btn-sm btn-dt-grid p-0 m-0"
                                                                            data-tooltip="tooltip"
                                                                            data-placement="top"
                                                                            title={this.props.intl.formatMessage({ id: 'Ver_Adjunto', defaultMessage: 'Ver Adjunto' })}
                                                                            onClick={(e) => this.handleViewAdjunto(e, presupuesto.adjunto)}
                                                                        >
                                                                            <i className="fa fa-paperclip fa-xs"></i>
                                                                        </button>
                                                                    : null}
																</div>
															</td>
														</tr>
														{
															this.state.indexPresupuestoDetalle === index ?
															<tr style={{background: "#eaecef"}}>
																<td colSpan="10">
																	<div className="p-2">
																		<div className="col-md-12">
																			<div className="col-md-12">
																				<h2 className="p-2" style={{color: "#44c8cf"}}><b><FormattedMessage id="Mano_de_Obra" defaultMessage="Mano de Obra"/></b></h2>
																				<table className="table">
																					<thead className="thead-fleet">
																						<tr>
                                                                                            {showTareas.map((field, index) => this.renderTableTheadTarea(field, index))}
																						</tr>
																					</thead>
																					<tbody>
																						{presupuesto.presupuestoManoObras.map((element, index) => {
																							return (
																								<tr key={index}>
																									{showTareas.map(field => this.renderTableTbodyTarea(element, field, index, null))}
																								</tr>
																							);
																						})}
																						<tr>
																							<td className="align-middle" colSpan="2">
																								<b style={{fontSize: "17px"}}><FormattedMessage id="Presupuesto.Total_Mano_de_Obra" defaultMessage="Total Mano de Obra"/></b>
																							</td>
																							<td className="align-middle" colSpan={showTareas.length - 3}>
																							</td>
																							<td className="align-middle">
																								<b style={{fontSize: "17px"}}>$ {Math.round(presupuesto.manoDeObra*100)/100}</b>
																							</td>
																						</tr>
																					</tbody>
																				</table>
																			</div>
																		</div>
																	</div>
																	<div className="p-2">
																		<div className="col-md-12">
																			<div className="col-md-12">
																				<h2 className="p-2" style={{color: "#44c8cf"}}><b><FormattedMessage id="Repuestos" defaultMessage="Repuestos"/></b></h2>
																				<table className="table">
																					<thead className="thead-fleet">
																						<tr>
                                                                                            {showItems.map((field, index) => this.renderTableTheadItem(field, index))}
																						</tr>
																					</thead>
																					<tbody>
																						{presupuesto.presupuestoRepuestos.map((element, index) => {
																							return (
																								<tr key={index}>
																									{showItems.map(field => this.renderTableTbodyItem(element, field, index, null))}
																								</tr>
																							);
																						})}
																						<tr>
																							<td className="align-middle" colSpan="2">
																								<b style={{fontSize: "17px"}}><FormattedMessage id="Presupuesto.Total_Repuestos" defaultMessage="Total Repuestos"/></b>
																							</td>
																							<td className="align-middle" colSpan={showItems.length - 3}>
																							</td>
																							<td className="align-middle">
																								<b style={{fontSize: "17px"}}>$ {Math.round(presupuesto.repuestos*100)/100}</b>
																							</td>
																						</tr>	
																					</tbody>
																				</table>
																			</div>
																		</div>
																	</div>
																</td>	
															</tr>
															: null
														}
													</React.Fragment>)
										})
									: 
										<tr>
											<td colSpan="10" align={"center"}>
												<FormattedMessage
													id="No_hay_datos_disponibles"
													defaultMessage="No hay datos disponibles"
												/>
											</td>
										</tr>
									}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
			)
		} else {
			return null;
		}
  }
}

export default injectIntl(TicketVerPresupuestosDetallado);
