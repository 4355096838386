import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
//import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
//import duallistboxConfig from '../../commons/duallistbox/DuallistboxConfig.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
// import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import DireccionAutocompletar from '../direccionAutocompletar/DireccionAutocompletar'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';


class TalleresAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();


    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        razon_social: '',
        externalCode: null,
        activo: true,
        telefono: '',
        observaciones: '',
        direccion: '',
        numero: '',
        subregiones: [],
        localidad: null,
        latitud: '',
        longitud: ''

      },
      cecos:[{ id: 0, nombre: '124567'},{ id: 1, nombre: '9863456'}], // CAMBIAR POR WS
      regiones:[],
      jefes:[],

      personasCombo:[],
      responsables:[],
      subregiones:[],
      paises: [],
      provincias: [],
      localidades: [],
      subRegionesDisponibles: [],
      estados: [{ id: 1, nombre: 'Activo'},{ id: 0, nombre: 'Inactivo'}],
      tipos: [{ id: 1, nombre: 'Taller'},{ id: 2, nombre: 'Gestor'}], // CAMBIAR POR WS

      categorias: [],
      gerenciadores: [],
      errors: [],

      loading: false,
      direccionForm: null
    };

    this.handleEstadoChange = this.handleEstadoChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleProvinciaChange = this.handleProvinciaChange.bind(this);

    this.handleNotificacionesActivasChange = this.handleNotificacionesActivasChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
    this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsuarioFormChange = this.handleUsuarioFormChange.bind(this);
    this.handlePerfilChange = this.handlePerfilChange.bind(this);
    this.handleMovilChange = this.handleMovilChange.bind(this);
    this.handleGerenciadorChange = this.handleGerenciadorChange.bind(this);
    this.handleBasesChange = this.handleBasesChange.bind(this);
    this.handleLocalidadChange = this.handleLocalidadChange.bind(this);
    this.handleChangeDireccion = this.handleChangeDireccion.bind(this);

    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.razonSocial': (value) => Validator.notEmpty(value),
        'formData.contacto': (value) => Validator.notEmpty(value),
      /* 'formData.razon_social': (value) => Validator.notEmpty(value),
       'formData.email': (value) => Validator.notEmpty(value),
       'formData.contacto': (value) => Validator.notEmpty(value)*/
        'formData.direccionValid': (value) => Validator.notEmpty(value),

			}
    });

  }

  initForm() {
    this.setState({ loading: true });
    let component = this;


		Promise.all([
      this.ajaxHandler.getJson('/regiones', null),
      //this.ajaxHandler.getJson('/gerenciadores/tipo/1/select', null),

      this.ajaxHandler.getJson('/gerenciadores/select-filtered-by-user/tipo/1'),
      this.ajaxHandler.getJson('/subregiones', null),
      // this.state.props.action !== 'ADD' ? this.getData('talleres', this.state.props.match.params.id) : null,
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/talleres/' + this.state.props.match.params.id) : null

     // this.ajaxHandler.getJson('/permisos')

		]).then((data) => {
      let regiones = data[0];
      let gerenciadores = data[1];
      let subRegionesDisponibles = data[2];
      console.log(data[3]);
      let formData = data[3];

     // let permisosDisponibles = data[5];



      component.setState({
        regiones: regiones,
        gerenciadores: gerenciadores,
        subRegionesDisponibles: subRegionesDisponibles,
        // permisosDisponibles: permisosDisponibles

			});

      if(formData){
        //guardo los datos para el componente de direccion
        let direccionParams = {
          calle: formData.direccion,
          numero: formData.numero,
          localidad: formData.localidad,
          provincia: formData.provincia,
          pais: formData.pais,
          latitud: formData.latitud,
          longitud: formData.longitud
        };

        component.setState({
          formData: formData,
          direccionForm: direccionParams
        });
      }

    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });
  }
  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}
	componentWillMount() {
    if ((Security.hasPermission('TALLERES_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('TALLERES_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('TALLERES_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }

	}

  getData(service, param) {
    let serviceURL ="";
    if ( service === "provincias" || service === "localidades" || service === "gerenciadores") {
      serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param+ '/select') : (Config.get('apiUrlBase') + '/' + service);
    } else {
      serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
    }
    return fetch(serviceURL, {
      method: 'GET',
			headers: {
				'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
      return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  getSubRegiones() {
		return fetch(Config.get('apiUrlBase') + '/subregiones', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}
  handleRegionChange(name, object) {
    Promise.all([
      this.getData('subregiones', object.id)
		]).then((data) => {
      let subregiones = data[0];
			this.setState({
        subregiones: subregiones
      });
      this.handleSelectFormChange(name, object);
		}).catch(function(error) {
			// TODO: Manejo de errores
			console.log(error);
    });
  }




  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activo'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }

  handleEsUsuarioChange(esUsuario) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['esUsuario'] = esUsuario;
    this.setState({
			formData: formDataCopy
		});
  }

  handleNotificacionesActivasChange(notificacionesActivas) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
		this.setState({
      formData: formDataCopy
    });
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

	handleBasesChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }


  handleUsuarioFormChange(event){
    const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

    //TODO: CHEQUEAR QUE EL USUARIO SEA ÜNICO LO MISMO CON EL NRO. DE LEGAJO
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleSelectFormChange(name, object) {
    const value = object === null ? null : object.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handlePerfilChange(name, perfil) {
    const value = perfil === null ? null : perfil.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		}, () => {
      if(perfil){
        perfil.permisos.includes('USUARIO_POSEER_MOVIL') ? this.setState({ hasPermisoMovil : true }) : this.setState({ hasPermisoMovil : false });
        perfil.permisos.includes('USUARIO_ACTUAR_COMO_GERENCIADOR') ? this.setState({ hasPermisoGerenciador : true }) : this.setState({ hasPermisoGerenciador : false });
        perfil.permisos.includes('USUARIO_TRABAJAR_BASES') ? this.setState({ hasPermisoBases : true }) : this.setState({ hasPermisoBases : false });
      }
		});
  }

  handleMovilChange(name, movil) {
    const value = movil === null ? null : movil.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }
  /*
  handleGerenciadorChange(name, gerenciador) {
    const value = gerenciador === null ? null : gerenciador.value;

     let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
     //formDataCopy[name] = value;
     formDataCopy[name]['id'] = value;
     formDataCopy[name].label = gerenciador.label;
     this.setState({
       formData: formDataCopy
     });

   }
  */
  handleGerenciadorChange(name, gerenciador) {
   const value = gerenciador === null ? null : gerenciador.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
    });

  }
  handleDatePickerFormChange(name, event) {
    let date = event ? event.format(): '';
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }

  handleDatePickerFormRawChange(name, date) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }


  handleProvinciaChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.provincia = { id: object.value, label: object.label };
        this.setState({
          formData: formDataCopy,
        }, () => {
          resolve();        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.provincia = null;
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          localidades: []
        }, () => resolve());
      }
    });
  }

  handleLocalidadChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.localidad = { id: object.value, label: object.label };
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      }
    });
  }

  handlePaisChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.pais = { id: object.value, label: object.label };
        formDataCopy.provincia = null;
        this.setState({
          formData: formDataCopy,
          provincias: [],
          localidades: []
        }, () => {
          this.setState({ provinciasLoading: true });
          this.ajaxHandler.getJson('/provincias/' + object.value + '/select')
          .then((data) => {
            this.setState({
              provincias: data
            }, () => resolve());
          }).finally(() => {
            this.setState({ provinciasLoading: false });
          });
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.pais = null;
        formDataCopy.provincia = null;
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          provincias: [],
          localidades: []
        }, () => resolve());
      }
    });
  }


  handleChangeDireccion(dataDireccion, valueDireccion, direccionValid){
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

    formDataCopy[dataDireccion] = valueDireccion;
    formDataCopy['direccionValid'] = direccionValid;
    this.setState({
      formData: formDataCopy
    });

  }

  handleFormChangeSelectObject(name, object) {

    return new Promise((resolve, reject) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy[name] = object ? { id: object.value } : null;
      this.setState({
        formData: formDataCopy
      }, () => resolve());
    });
  }
	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
    this.ajaxHandler.fetch('/talleres' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(response.status !== 400) {

        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/talleres'
		});
	}

	render() {
    this.formValidation.validate();
		let formData = this.state.formData;
    let validationState = this.formValidation.state;

    let state = this.state;
   // let pais = formData.pais ? state.paises.find(e => e.value === formData.pais.id) : null;
   // let provincia = formData.provincia ? state.provincias.find(e => e.value === formData.provincia.id) : null;
  //  let localidad = formData.localidad ? state.localidades.find(e => e.value === formData.localidad.id) : null;

    let gerenciador = formData.gerenciador ? state.gerenciadores.find(e => e.value === formData.gerenciador.id) : null;
    //let requiredSymbol = state.props.action !== 'VIEW' ? ' *' : '';

    return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="talleresAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="talleresAbm.render.general_data.campos_requeridos" defaultMessage="campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* Taller */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="razonSocial">
                              <FormattedMessage id="talleresAbm.render.general_data.label_taller" defaultMessage="Taller "/>*:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.razonSocial}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="razonSocial" name="razonSocial" placeholder={this.props.intl.formatMessage({ id: 'talleresAbm.render.general_data.placeholder_taller', defaultMessage: 'Taller' })} value={formData.razonSocial} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.razonSocial.pristine || validationState.formData.razonSocial.valid}>{validationState.formData.razonSocial.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                       {/* GERENCIADORES */}
                       <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-3 label-control col-form-label" htmlFor="gerenciador">
                                  <FormattedMessage id="talleresAbm.render.general_data.label_gerenciador" defaultMessage="Gerenciador:"/>
                                </label>
                                <div className="col-md-9">
                                  <div>
                                  <Select
                                      id="gerenciador"
                                      name="gerenciador"
                                      placeholder={this.props.intl.formatMessage({ id: 'talleresAbm.render.general_data.placeholder_gerenciador', defaultMessage: 'Gerenciador' })}
                                      options={this.state.gerenciadores}
                                      valueKey='value'
                                      labelKey='label'
                                      value={gerenciador ? { value: gerenciador.id, label: gerenciador.label } : null}
                                      onChange={(e) => this.handleFormChangeSelectObject("gerenciador", e)}
                                    />
                                  </div>

                                </div>
                              </div>

                          </div>
                        </div>
                      

                      <div className="row">
                        {/* CODIGO EXTERNO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="externalCode">
                              <FormattedMessage id="talleresAbm.render.general_data.label_externalCode" defaultMessage="Código Externo"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.externalCode}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="externalCode" name="externalCode" placeholder={this.props.intl.formatMessage({ id: 'talleresAbm.render.general_data.placeholder_externalCode', defaultMessage: 'Código' })} value={formData.externalCode} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* ESTADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage id="talleresAbm.render.general_data.label_activo" defaultMessage="Activo:"/>
                            </label>
                            <div className="col-md-9">
                              <Switch
                                onChange={this.handleEstadoChange}
                                checked={formData.activo}
                                options={this.state.estados}
                                id="activo"
                                name="activo"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="ft-phone"></i><FormattedMessage id="talleresAbm.render.contact_information.header_datos_de_contacto" defaultMessage=" Datos de Contacto"/>
                      </h4>

                      <div className="row">
                        {/* Contacto */}
                        <div className="col-md-6">

                          <div  className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="contacto">
                              <FormattedMessage id="talleresAbm.render.contact_information.label_contacto" defaultMessage="Contacto "/>*:
                            </label>
                            <div className="col-md-9">
                            {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.contacto}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="contacto" name="contacto" placeholder={this.props.intl.formatMessage({ id: 'talleresAbm.render.contact_information.placeholder_contacto', defaultMessage: 'Contacto' })} value={formData.contacto} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.contacto.pristine || validationState.formData.contacto.valid}>{validationState.formData.contacto.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="row">
                        {/* TELEFONO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="telefono">
                              <FormattedMessage id="talleresAbm.render.contact_information.label_telefono" defaultMessage="Teléfono :"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.telefono}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="telefono" name="telefono" placeholder={this.props.intl.formatMessage({ id: 'talleresAbm.render.contact_information.placeholder_telefono', defaultMessage: 'Teléfono' })} value={formData.telefono} onChange={this.handleInputFormChange} />

                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        {/* EMAIL */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="email">
                              <FormattedMessage id="talleresAbm.render.contact_information.label_email" defaultMessage="Email :"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.email}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="email" name="email" placeholder={this.props.intl.formatMessage({ id: 'talleresAbm.render.contact_information.placeholder_email', defaultMessage: 'Email' })} value={formData.email} onChange={this.handleInputFormChange} />
                                {/*<div className="help-block text-danger field-message" hidden={validationState.formData.email.pristine || validationState.formData.email.valid}>{validationState.formData.email.message}</div>*/}

                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body" id="container-direccion">
                      <h4 className="form-section mt-2">
                        <i className="ft-map-pin"></i><FormattedMessage id="talleresAbm.render.location_data.header_datos_de_ubicacion" defaultMessage=" Datos de Ubicación"/>
                      </h4>
                      <DireccionAutocompletar
											action={this.props.action}
											camposHidden={["codigoPostal"]}
											cambioDireccion={this.handleChangeDireccion}
											formulario={this.state.direccionForm}
											localidadString={true}
											crearProvincias={true}
											crearLocalidades={true}
                     	camposObligatorios={["provincia", "pais", "latitud", "longitud"]}/>

                      <div className="row">
                        {/* OBSERVACIONES */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="observaciones">
                              <FormattedMessage id="talleresAbm.render.location_data.label_observaciones" defaultMessage="Observaciones:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label">{formData.observaciones}</div>
                              ) : (
                              <div>
                                 <textarea className="form-control" id="observaciones" name="observaciones" rows="3" placeholder={this.props.intl.formatMessage({ id: 'talleresAbm.render.contact_information.placeholder_observaciones', defaultMessage: 'Observaciones' })} value={formData.observaciones} onChange={this.handleInputFormChange}></textarea>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="talleresAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'talleresAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'talleresAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TalleresAbm);
