import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import ActualizacionKmGrid from './ActualizacionKmGrid'
import {FormattedMessage, injectIntl} from 'react-intl';


class ActualizacionKm extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      				<h3 className="content-header-title"><FormattedMessage id="actualizacionKm.header_actualizacion_de_medidor" defaultMessage="Actualización de Medidor"/></h3>
      		</div>
				</div>
				<div className="content-body">								
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={ActualizacionKmGrid} />
					</Switch>										
				</div>
			</div>
	    );
  	}
}

export default ActualizacionKm;
