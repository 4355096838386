import { Collapse, TableCell, TableRow } from '@material-ui/core';
import 'moment/min/locales';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert2';
import Config from '../../commons/config/Config.js';
import Security from '../../commons/security/Security.js';
import Timezone from '../../commons/timezone/Timezone.js';
import { StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles';

const PanolGridRow = ({ key, baseUrl, item, isConsolidado }) => {
    const intl = useIntl();
    const [redirectTo, setRedirectTo] = useState(null);
    return (
        <>
            {redirectTo && <Redirect push to={redirectTo} />}
            <TableRow>
                <StyledTableCell show={false} align="left" style={{ maxWidth: '30px' }}>
                    {item.id}
                </StyledTableCell>
                <StyledTableCell align="left">{item.item}</StyledTableCell>
                <StyledTableCell align="left">{item.subcategoria}</StyledTableCell>
                <StyledTableCell align="left">{parseFloat(item.cantidad).toFixed(4)}</StyledTableCell>
                <StyledTableCell align="left">{item.costo}</StyledTableCell>
                {isConsolidado && <StyledTableCell align="left">{item.total}</StyledTableCell>}
                <StyledTableCell align="left">{item.unidad}</StyledTableCell>
            </TableRow>
        </>
    );
};

export default PanolGridRow;
