module.exports = function(intl) {
    return {
        tiempo_respuesta: intl.formatMessage({ id: 'popoverText.tooltip_tiempo_de_respuesta', defaultMessage: 'Tooltip tiempo de respuesta' }),
        correctivos_marca: intl.formatMessage({ id: 'popoverText.tooltipo_marca', defaultMessage: 'Tooltipo marca' }),
        widget_activos: intl.formatMessage({ id: 'popoverText.activos', defaultMessage: 'activos' }),
        widget_presupuestados: intl.formatMessage({ id: 'popoverText.presupuestados', defaultMessage: 'presupuestados' }),
        widget_utilizacion: intl.formatMessage({ id: 'popoverText.utilizacion', defaultMessage: 'utilización' }),
        widget_sin_gps: intl.formatMessage({ id: 'popoverText.moviles_sin_disponibilidad_satelital_el_procentaje_total_se_calcula_sobre_los_moviles', defaultMessage: 'Móviles Sin Disponibilidad Satelital.   El procentaje total se calcula sobre los móviles que tienen instalado módulo de rastreo' }),
        widget_disponibles: intl.formatMessage({ id: 'popoverText.disponibles', defaultMessage: 'Disponibles' }),
        widget_en_taller: intl.formatMessage({ id: 'popoverText.moviles_en_taller', defaultMessage: 'Móviles en taller' }),
        widget_listos_para_retirar: intl.formatMessage({ id: 'popoverText.moviles_listos_para_retirar', defaultMessage: 'Móviles listos para retirar' }),
        widget_aprobados: intl.formatMessage({ id: 'popoverText.aprobados', defaultMessage: 'Aprobados' }),
        widget_tickets_en_gestion: intl.formatMessage({ id: 'popoverText.tickets_distinto_CERRADOS_CANCELADOS', defaultMessage: 'Tickets en estado distinto a CERRADOS o CANCELADOS' }),
        widget_no_operativo: intl.formatMessage({ id: 'popoverText.moviles_estado_INMOVILIZADO', defaultMessage: 'Móviles que permanecen en estado INMOVILIZADO' }),
        widget_fuera_de_servicio: intl.formatMessage({ id: 'popoverText.moviles_fuera_servicio', defaultMessage: 'Móviles fuera de servicio' }),
        widget_en_comodato: intl.formatMessage({ id: 'popoverText.moviles_en_comodato', defaultMessage: 'Móviles En Comodato' }),
        solid_preventivos: intl.formatMessage({ id: 'popoverText.mantenimientos_preventivos', defaultMessage: 'Mantenimientos preventivos' }),
        solid_correctivos: intl.formatMessage({ id: 'popoverText.mantenimientos_correctivos', defaultMessage: 'Mantenimientos correctivos' }),
        solid_gestoria: intl.formatMessage({ id: 'popoverText.gestoria', defaultMessage: 'Gestoría' }),
        solid_moviles: intl.formatMessage({ id: 'popoverText.vencimientos_de_moviles', defaultMessage: 'vencimientos de móviles' }),
        solid_personas: intl.formatMessage({ id: 'popoverText.vencimientos_de_personas', defaultMessage: 'Vencimientos de personas' }),
        column_line: intl.formatMessage({ id: 'popoverText.tickets_cerrados_por_tipo', defaultMessage: 'Tickets cerrados por tipo' }),
        radar_gasto_servicio: intl.formatMessage({ id: 'popoverText.gasto_total_por_servicio', defaultMessage: 'Gasto total por servicio' }),
        radar_gasto_anio: intl.formatMessage({ id: 'popoverText.gasto_total_por_anio', defaultMessage: 'Gasto total por año' })
    }
}