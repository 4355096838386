import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class TicketInformarFactura extends Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.ajaxHandler = new AjaxHandler();

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				id: this.props.ticket.id,
				factura: null
			},
			errors: [],
			loading: false
		};

		this.handleFormChange = this.handleFormChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleInputFormChange = this.handleInputFormChange.bind(this);

		this.formValidation = new FormValidation({
			component: this,
			validators: {
				'formData.factura': (value) => Validator.notEmpty(value)
			}
		});
	}

	componentDidMount() {
		this.ajaxHandler.subscribe(this);
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		this.setState({
			loading: false,
			formData: {
				id: null,
				factura: null
			}
		 }, ()=>{
			this.formValidation.validate();
			this.props.callbackSave();
		 });
	}

	handleFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
	}

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

	handleSubmit(event) {
		this.setState({ loading: true });
		this.ajaxHandler.fetch( '/tickets/' + this.props.ticket.id, {
			method: 'PATCH',
			body: JSON.stringify({
				...this.state.formData
			})
		}).then(response => {
			if(response.status === 400) {
				response.json()
				.then(data => {
					this.setState({
						errors: data.detalle
					});
				});
			} else {
				this.setState({
					loading: false,
				 }, ()=>{
					this.props.callbackSave();
				 });
			}
			window.scrollTo(0,0);
		}).catch((error) => {
			this.ajaxHandler.handleError(error);
		}).finally(() => {
		});
		event.preventDefault();
	}

	handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.setState({
			loading: false,
			formData: {
				id: null,
				factura: null
			}
		 }, ()=>{
			this.props.callbackClose();
		 });
	}

	render() {
		this.formValidation.validate();
		let formData = this.state.formData;
		let validationState = this.formValidation.state;
		let requiredSymbol = ' *';

		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
				<div className="row">
					<div className="col-md-12">
						<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
						<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
							<div className="form-body">
								<div className="card">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section">
												<i className="la la-file-text"></i><FormattedMessage id="ticketInformarFactura.render.inform_invoice.header_informar_factura" defaultMessage=" Informar Factura"/>
												<div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="ticketInformarFactura.render.inform_invoice.campos_requeridos" defaultMessage="campos requeridos"/></div>
											</h4>
											<div className="row">
												<div className="col-md-12">
													<div className="form-group row">
														{/* FACTURA */}
														<div className="col-md-6">
															<div className="form-group row">
																<label className="col-md-6 label-control col-form-label" htmlFor="factura">
																	<FormattedMessage id="ticketInformarFactura.render.inform_invoice.label_factura" defaultMessage="Factura"/>{requiredSymbol}:
																</label>
																<div className="col-md-6">
																	<div>
                                    <input type="text" className="form-control" id="factura" name="factura" placeholder={this.props.intl.formatMessage({ id: 'ticketInformarFactura.render.inform_invoice.placeholder_factura', defaultMessage: '(Requerido)' })} value={formData.factura ? formData.factura : ''} onChange={this.handleInputFormChange} />
																		<div className="help-block text-danger field-message" hidden={validationState.formData.factura.pristine || validationState.formData.factura.valid}>{validationState.formData.factura.message}</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card">
								<div className="card-content">
									<div className="card-body">
										<div className="text-cd text-right">
											<button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
											<i className="fa fa-check-circle"></i><FormattedMessage id="ticketInformarFactura.finish_button.guardar" defaultMessage=" Guardar"/>
											</button>
											<button type="button" className="btn btn-danger" onClick={this.handleCancel}>
											<i className="fa fa-times-circle"></i><FormattedMessage id="ticketInformarFactura.finish_button.cancelar" defaultMessage=" Cancelar"/>
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TicketInformarFactura);
