import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
//import LogoCompany from '../../assets/images/logo-company-login-tc-print.png'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import PreventivoVerde from '../../assets/images/tareas/preventivo_verde.svg'
import PreventivoRojo from '../../assets/images/tareas/preventivo_rojo.svg'
import PreventivoAmarillo from '../../assets/images/tareas/preventivo_amarillo.svg'
import PreventivoTicketRojo from '../../assets/images/tareas/preventivostickets-06-rojo.svg'
import VencimientoVerde from '../../assets/images/tareas/vencimiento_verde.svg'
import VencimientoRojo from '../../assets/images/tareas/vencimiento_rojo.svg'
import VencimientoAmarillo from '../../assets/images/tareas/vencimiento_amarillo.svg'
import VencimientoTicketRojo from '../../assets/images/tareas/vencimientostickets-07-rojo.svg'
import Calendario from '../../assets/images/tareas/calendario.svg'
import Autopista from '../../assets/images/tareas/autopista.svg'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import GravatarOption from './GravatarOption'
import swal from 'sweetalert'
import Switch from "react-switch"
import { Transform } from 'stream';
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js'
import Timezone from '../../commons/timezone/Timezone.js';

const tiposMedidor = Object.values(UNIDAD_MEDIDOR);
tiposMedidor.unshift({id: null, label: "Ambos"});

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class KmMovilGrid extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.dataService = '/km-movil/grid';
		this.columnsToPrint = [2,3,4,5,7];
		moment.locale('es');

		this.state = {
			redirectTo: null,
			loading: false,
			tipo: null,
			editKmEnabled: false,
			maxDifferenceKM: 0,
			canModifyKM: false,
			regionEnabled: false,
			unidadEnabled: false,
			colorEnabled: false,

			kilometros: null,
			tipoUnidadMedidor: null,
			filtroPeriodoInicio: moment().subtract(30, 'days').format('YYYY-MM-DD'),
			filtroPeriodoFin: moment().format('YYYY-MM-DD'),
			// periodoSelect:  {id:365, label:"Este año"},
			// tipoTicketSelect: {id:1,label:"CORRECTIVO"},
			periodos: [				
				{id:1, label: this.props.intl.formatMessage({ id: 'kmMovilGrid.filter_date.select_hoy', defaultMessage: 'Hoy' })},
				{id:2, label: this.props.intl.formatMessage({ id: 'kmMovilGrid.filter_date.select_ayer', defaultMessage: 'Ayer' })},
				{id:7, label: this.props.intl.formatMessage({ id: 'kmMovilGrid.filter_date.select_ultimos_siete_dias', defaultMessage: 'Últimos 7 días' })},
				{id:14, label: this.props.intl.formatMessage({ id: 'kmMovilGrid.filter_date.select_ultimos_catorce_dias', defaultMessage: 'Últimos 14 días' })},
				{id:20, label: this.props.intl.formatMessage({ id: 'kmMovilGrid.filter_date.select_ultimos_treinta_dias', defaultMessage: 'Últimos 30 días' })},
				{id:30, label: this.props.intl.formatMessage({ id: 'kmMovilGrid.filter_date.select_este_mes', defaultMessage: 'Este mes' })},
				{id:60, label: this.props.intl.formatMessage({ id: 'kmMovilGrid.filter_date.select_mes_pasado', defaultMessage: 'Mes Pasado' })},
				{id:365, label: this.props.intl.formatMessage({ id: 'kmMovilGrid.filter_date.select_este_anio', defaultMessage: 'Este año' })},
				{id:400, label: this.props.intl.formatMessage({ id: 'kmMovilGrid.filter_date.select_anio_pasado', defaultMessage: 'Año Pasado' })},
				{id:12, label:""}
			],

			dataToExport :{
				dominio : true,
				unidad : true,
				region : true,
				subRegion : true,
				baseDescripcion : true,
				responsableBaseNombreApellido : true,
				gerenciador : true,
				activo : true,
				marcaNombre : true,
				modeloNombre : true,
				modeloTipoNombre : true,
				chasis : true,
				motor : true,
				color : true,
				anio : true,
				fechaAlta : true,
				fechaBaja : true,
				responsable1NombreApellido : true,
				responsable2NombreApellido : true,
				supervisorNombreApellido : true,
				temporalNombreApellido : true,
				centroCostosNombre : true,
				cebeNombre : true,
				poliza : true,
				lugarGuarda : true,
				certificadoVtv : true,
				telepeaje : true,
				ypfRuta : true,
				kmActual : true,
				kmRecorridos : true,
				dnrpaSeccional : true,
				activoFijo : true,
				nroTitulo : true,
				observaciones : true,
				estado : true,
				combustible : true,
				titular : true,
				proveedorGps : true,
				companiaOrigen : true,
				planPreventivoDescripcion : true
			},
			ExportarTodo : true,
			periodoSelect: {id:20, label:"Últimos 30 días"},
			estadosActivos: null,
			estadosInactivos: null,
			estadosResaltados: null
		}

		this.handleInputFormChange = this.handleInputFormChange.bind(this);
	}

	handleDataExport(nombre){
		const miState = {...this.state};
		switch (nombre) {
			case "dominio":
				miState.dataToExport.dominio = !miState.dataToExport.dominio;
		break;
			case "unidad":
				miState.dataToExport.unidad = !miState.dataToExport.unidad;
		break;
			case "region":
			miState.dataToExport.region = !miState.dataToExport.region;
		break;
			case "subRegion":
			miState.dataToExport.subRegion = !miState.dataToExport.subRegion;
		break;
			case "baseDescripcion":
			miState.dataToExport.baseDescripcion = !miState.dataToExport.baseDescripcion;
		break;
			case "responsableBaseNombreApellido":
			miState.dataToExport.responsableBaseNombreApellido = !miState.dataToExport.responsableBaseNombreApellido;
		break;
			case "gerenciador":
			miState.dataToExport.gerenciador = !miState.dataToExport.gerenciador;
		break;
			case "activo":
			miState.dataToExport.activo = !miState.dataToExport.activo;
		break;
			case "marcaNombre":
			miState.dataToExport.marcaNombre = !miState.dataToExport.marcaNombre;
		break;
			case "modeloNombre":
			miState.dataToExport.modeloNombre = !miState.dataToExport.modeloNombre;
		break;		
			case "modeloTipoNombre":
			miState.dataToExport.modeloTipoNombre = !miState.dataToExport.modeloTipoNombre;
		break;
			case "chasis":
			miState.dataToExport.chasis = !miState.dataToExport.chasis;
		break;
			case "motor":
			miState.dataToExport.motor = !miState.dataToExport.motor;
		break;
			case "color":
			miState.dataToExport.color = !miState.dataToExport.color;
		break;
			case "anio":
			miState.dataToExport.anio = !miState.dataToExport.anio;
		break;
			case "fechaAlta":
			miState.dataToExport.fechaAlta = !miState.dataToExport.fechaAlta;
		break;
			case "fechaBaja":
			miState.dataToExport.fechaBaja = !miState.dataToExport.fechaBaja;
		break;
			case "responsable1NombreApellido":
			miState.dataToExport.responsable1NombreApellido = !miState.dataToExport.responsable1NombreApellido;
		break;
			case "responsable2NombreApellido":
			miState.dataToExport.responsable2NombreApellido = !miState.dataToExport.responsable2NombreApellido;
		break;
			case "supervisorNombreApellido":
			miState.dataToExport.supervisorNombreApellido = !miState.dataToExport.supervisorNombreApellido;
		break;
			case "temporalNombreApellido":
			miState.dataToExport.temporalNombreApellido = !miState.dataToExport.temporalNombreApellido;
		break;
			case "centroCostosNombre":
			miState.dataToExport.centroCostosNombre = !miState.dataToExport.centroCostosNombre;
		break;
			case "cebeNombre":
			miState.dataToExport.cebeNombre = !miState.dataToExport.cebeNombre;
		break;
			case "poliza":
			miState.dataToExport.poliza = !miState.dataToExport.poliza;
		break;
			case "lugarGuarda":
			miState.dataToExport.lugarGuarda = !miState.dataToExport.lugarGuarda;
		break;
			case "certificadoVtv":
			miState.dataToExport.certificadoVtv = !miState.dataToExport.certificadoVtv;
		break;
			case "telepeaje":
			miState.dataToExport.telepeaje = !miState.dataToExport.telepeaje;
		break;
			case "ypfRuta":
			miState.dataToExport.ypfRuta = !miState.dataToExport.ypfRuta;
		break;
			case "kmActual":
			miState.dataToExport.kmActual = !miState.dataToExport.kmActual;
		break;
			case "kmRecorridos":
			miState.dataToExport.kmRecorridos = !miState.dataToExport.kmRecorridos;
		break;
			case "dnrpaSeccional":
			miState.dataToExport.dnrpaSeccional = !miState.dataToExport.dnrpaSeccional;
		break;
			case "activoFijo":
			miState.dataToExport.activoFijo = !miState.dataToExport.activoFijo;
		break;
			case "nroTitulo":
			miState.dataToExport.nroTitulo = !miState.dataToExport.nroTitulo;
		break;
			case "observaciones":
			miState.dataToExport.observaciones = !miState.dataToExport.observaciones;
		break;
			case "estado":
			miState.dataToExport.estado = !miState.dataToExport.estado;
		break;
			case "combustible":
			miState.dataToExport.combustible = !miState.dataToExport.combustible;
		break;
			case "titular":
			miState.dataToExport.titular = !miState.dataToExport.titular;
		break;
			case "proveedorGps":
			miState.dataToExport.proveedorGps = !miState.dataToExport.proveedorGps;
		break;
			case "companiaOrigen":
			miState.dataToExport.companiaOrigen = !miState.dataToExport.companiaOrigen;
		break;
			case "planPreventivoDescripcion":
			miState.dataToExport.planPreventivoDescripcion = !miState.dataToExport.planPreventivoDescripcion;
		break;
		}

		this.setState(miState);
	}

	handleSelectFormChange(name, object) {
		
		this.setState({loading: true});
		// const value = object === null ? null : object.id;
		if(name === "periodo"){
			this.setState({
				periodoSelect: object,	
				}, ()=>{
			
			let inicio = "";
			let fin = "";
		if(object && object.id !== 12){
			
			switch (object.id) {
			case 1:
				inicio = moment();
				fin = moment();
				break;
			case 2:
				inicio = moment().subtract(1, 'days');
				fin = inicio;
				break;
			case 7:
				inicio = moment().subtract(7, 'days');
				fin = moment();
				break;
			case 14:
				inicio = moment().subtract(14, 'days');
				fin = moment();
				break;
			case 20:
				inicio = moment().subtract(30, 'days');
				fin = moment();
				break;
			case 30:
				inicio = moment().startOf('month');
				fin = moment();
				break;
			case 60:
				inicio = moment().subtract(1, 'months').startOf('month');
				fin = moment().subtract(1, 'months').endOf('month');
				break;
			case 400:
				inicio = moment().subtract(1, 'year').startOf('year');
				fin = moment().subtract(1, 'year').endOf('year');
				break;
				default:
				inicio = moment().startOf('year');
				fin = moment();
			}
		
		}else if(object.inicio){
			inicio = moment(object.inicio).format('YYYY-MM-DD');
			fin = moment(object.fin).format('YYYY-MM-DD');
		}
	
		inicio = Timezone.getDateForClient(inicio, null, 'YYYY-MM-DD');
		fin = Timezone.getDateForClient(fin, null, 'YYYY-MM-DD');
	
			this.setState({filtroPeriodoInicio: inicio, filtroPeriodoFin: fin}, ()=>{
				this.cargarDatosFiltrados().then(()=>{
					this.setState({loading:false});
				});
			});
			
			});
				this.setState({loading:false});
		} else if (name === "tipoUnidadMedidor") {
			this.setState({
				tipoUnidadMedidor: object.id
			}, ()=>{
				this.cargarDatosFiltrados().then(()=>{
					this.setState({loading:false});
				});
			});
		}else{
			//ocurre cuando se aplica cualquier filtro distinto a periodo
			this.setState({	[name]: object}, ()=>{
				this.cargarDatosFiltrados().then(()=>{
					this.setState({loading:false});
				});
			});
			}
		
	}
			
	cargarDatosFiltrados(){
		return new Promise((resolve, reject)=>{
			this.table.ajax.url(Config.get('apiUrlBase') + this.dataService +'/'+ this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+this.state.kilometros+'/'+this.state.tipoUnidadMedidor).load();
   /*
		   let unidad = this.calcularUnidadTiempo().then((unidad)=>{
			   this.getData('/combustibles/sparkline/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+unidad)
				   .then((estadisticas)=>{
					   this.setState({
						   estadisticas: estadisticas
					   }, resolve());
			   });
		   });*/
		});
	}

	handleInputFormChange(event) {

		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({loading: true});
	
		this.setState({	[name]: value !== '' ? value : null}, ()=>{
			this.cargarDatosFiltrados().then(()=>{
				this.setState({loading:false});
			});
		});
  	}
  
	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('REPORTES_KM_MOVIL_LISTAR')) {
	      this.ajaxHandler.subscribe(this);
				this.initGrid();
	    } else {
		    	this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	handleExportExcel() {
		let component = this;
		this.setState({loading:true});
		component.ajaxHandler.fetch('/km-movil/exportar-excel', {
			method: 'POST',
			body: JSON.stringify({
				colsAExportar: this.state.dataToExport,
				fechaInicio: this.state.filtroPeriodoInicio,
				fechaFin: this.state.filtroPeriodoFin,
				kilometros: this.state.kilometros ? this.state.kilometros : null				
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-km-movil.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			component.handleExportCancel();
			this.setState({ loading: false });
		});
	}
	
	handleExportCancel() {
		$('#export_modal').modal('toggle');
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons" style={{marginBottom:"15px"}}>
											<div className="col-9" style={{display:"flex"}}>
												<div className="from-group col-3" style={{display:"flex"}}>
													<label style={{padding:"5px", marginTop:"5px"}}><FormattedMessage id="kmMovilGrid.filter_date.label_periodo" defaultMessage="Período: "/></label>
													<Select 
													id="periodo" 
													name="periodo" 
													clearable={false}	
													options={this.state.periodos} 
													optionComponent={GravatarOption} 
													valueKey="id" 
													labelKey="label" 
													className="col-10 select-periodo" 
													value={this.state.periodoSelect ? this.state.periodoSelect : null} 
													onChange={(e) => this.handleSelectFormChange("periodo",e)} 
													/> 
												</div>
												<div className="from-group col-4" style={{display:"flex"}}>
														<label className="col-md-6 label-control col-form-label" htmlFor="kilometros">
															<FormattedMessage id="kmMovilGrid.filter_date.label_medidor_menos_de" defaultMessage="Medidor menos de: "/>
														</label>
														<div className="col-md-6">
															<div>
																<input type="number" className="form-control" id="kilometros" name="kilometros" placeholder="" value={this.state.kilometros ? this.state.kilometros : ''} onChange={this.handleInputFormChange} />															
															</div>
														</div>
													</div>
												<div className="from-group col-6" style={{display:"flex"}}>
														<label className="col-md-6 label-control col-form-label" htmlFor="tipoUnidadMedidor">
															<FormattedMessage id="kmMovilGrid.filter_date.label_tipo_medidor" defaultMessage="Tipo Unidad Medidor: "/>
														</label>
														<div className="col-md-6">
														<Select 
															id="tipoUnidadMedidor" 
															name="tipoUnidadMedidor" 
															placeholder="Ambos"
															clearable={false}	
															options={tiposMedidor} 
															valueKey="id" 
															labelKey="label" 
															className="col-10 select-periodo" 
															value={this.state.tipoUnidadMedidor} 
															onChange={(e) => this.handleSelectFormChange("tipoUnidadMedidor",e)} 
															/> 
														</div>
												</div>
											</div>
											<div className="col-3" id="buttons"></div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover dataTableKmMovil" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													<th className=""></th>
													<th className="p-0"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className=""></th>
													<th className="dt-search-header"></th>
													<th className={(this.state.regionEnabled) ? "dt-search-header" : "dt-search-header d-none"}></th>
													<th className="dt-search-header"></th>
													<th className=""></th>
													<th className=""></th>
													<th className="dt-search-header"></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			<div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
				<div className="modal-xl modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="kmMovilGrid.render.export_modal.header_exportar_km_x_Movil" defaultMessage=" Exportar Km x Movil"/></h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body modal-grey modal-mh ovf-x-hidden">
							<div className="row">
								<div className="col-md-12">
									{/*<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
										{this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
									</div>*/}
									<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
										<div className="form-body">
														<div className="card pull-up">
																	<div className="card-content">
																		<div className="card-body">
																			<h4 className="form-section">
																				<i className="la la-columns"></i><FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.header_columnas_a_exportar" defaultMessage=" Columnas a exportar"/>
																			</h4>
																			<div className="row m-1">
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="dominio">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_dominio" defaultMessage="Dominio:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("dominio")}
																						checked = {this.state.dataToExport.dominio ? true : false}
																						value={this.state.dataToExport.dominio}
																						id = "dominio"
																						name = "dominio"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				{(this.state.unidadEnabled) ? 
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="unidad">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_unidad" defaultMessage="Unidad:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("unidad")}
																						checked = {this.state.dataToExport.unidad ? true : false}
																						value={this.state.dataToExport.unidad}
																						id = "unidad"
																						name = "unidad"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>: ""}
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="region">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_region" defaultMessage="Region:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("region")}
																						checked = {this.state.dataToExport.region ? true : false}
																						value={this.state.dataToExport.region}
																						id = "region"
																						name = "region"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="subRegion">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_subRegion" defaultMessage="SubRegion:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("subRegion")}
																						checked = {this.state.dataToExport.subRegion ? true : false}
																						value={this.state.dataToExport.subRegion}
																						id = "subRegion"
																						name = "subRegion"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"
																					/>
																				</div>
																			</div>
																			<div className="row m-1">
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="baseDescripcion">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_base" defaultMessage="Base:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("baseDescripcion")}
																						checked = {this.state.dataToExport.baseDescripcion ? true : false}
																						value={this.state.dataToExport.baseDescripcion}
																						id = "baseDescripcion"
																						name = "baseDescripcion"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="responsableBaseNombreApellido">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_responsable_base:" defaultMessage="Responsable Base:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("responsableBaseNombreApellido")}
																						checked = {this.state.dataToExport.responsableBaseNombreApellido ? true : false}
																						value={this.state.dataToExport.responsableBaseNombreApellido}
																						id = "responsableBaseNombreApellido"
																						name = "responsableBaseNombreApellido"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="gerenciador">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_gerenciador" defaultMessage="Gerenciador:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("gerenciador")}
																						checked = {this.state.dataToExport.gerenciador ? true : false}
																						value={this.state.dataToExport.gerenciador}
																						id = "gerenciador"
																						name = "gerenciador"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="activo">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_activo" defaultMessage="Activo:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("activo")}
																						checked = {this.state.dataToExport.activo ? true : false}
																						value={this.state.dataToExport.activo}
																						id = "activo"
																						name = "activo"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				</div>
    																		<div className="row m-1">
																					<div className=" col-md-3">
																						<label className="col-md-6 label-control col-form-label" htmlFor="personaNombreApellido">
																							<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_marca" defaultMessage="Marca:"/>
																						</label>
																						<Switch
																							onChange = {(event) => this.handleDataExport("marcaNombre")}
																							checked = {this.state.dataToExport.marcaNombre ? true : false}
																							value={this.state.dataToExport.marcaNombre}
																							id = "marcaNombre"
																							name = "marcaNombre"
																							offColor = "#FF4961"
																							onColor = "#28D094"
																							disabled = {false}
																							className =  "col-md-6 align-middle"
																						/>
																					</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="modeloNombre">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_modelo" defaultMessage="Modelo:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("modeloNombre")}
																						checked = {this.state.dataToExport.modeloNombre ? true : false}
																						value={this.state.dataToExport.modeloNombre}
																						id = "modeloNombre"
																						name = "modeloNombre"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="modeloTipoNombre">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_tipo" defaultMessage="Tipo:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("modeloTipoNombre")}
																						checked = {this.state.dataToExport.modeloTipoNombre ? true : false}
																						value={this.state.dataToExport.modeloTipoNombre}
																						id = "modeloTipoNombre"
																						name = "modeloTipoNombre"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="chasis">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_chasis" defaultMessage="Chasis:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("chasis")}
																						checked = {this.state.dataToExport.chasis ? true : false}
																						value={this.state.dataToExport.chasis}
																						id = "chasis"
																						name = "chasis"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				</div>
																				<div className="row m-1">
																					<div className=" col-md-3">
																						<label className="col-md-6 label-control col-form-label" htmlFor="motor">
																							<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_motor" defaultMessage="Motor:"/>
																						</label>
																						<Switch
																							onChange = {(event) => this.handleDataExport("motor")}
																							checked = {this.state.dataToExport.motor ? true : false}
																							value={this.state.dataToExport.motor}
																							id = "motor"
																							name = "motor"
																							offColor = "#FF4961"
																							onColor = "#28D094"
																							disabled = {false}
																							className =  "col-md-6 align-middle"
																						/>
																					</div>
																					{(this.state.colorEnabled) ? 
																					<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="color">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_color:" defaultMessage="Color:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("color")}
																						checked = {this.state.dataToExport.color ? true : false}
																						value={this.state.dataToExport.color}
																						id = "color"
																						name = "color"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>: ""}
																			<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="anio">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_anio" defaultMessage="Año:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("anio")}
																						checked = {this.state.dataToExport.anio ? true : false}
																						value={this.state.dataToExport.anio}
																						id = "anio"
																						name = "anio"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																			
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="fechaAlta">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_fec_alta:" defaultMessage="Fec. Alta:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("fechaAlta")}
																						checked = {this.state.dataToExport.fechaAlta ? true : false}
																						value={this.state.dataToExport.fechaAlta}
																						id = "fechaAlta"
																						name = "fechaAlta"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				</div>
 																			<div className="row m-1">
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="fechaBaja">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_fec_baja" defaultMessage="Fec. Baja:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("fechaBaja")}
																						checked = {this.state.dataToExport.fechaBaja ? true : false}
																						value={this.state.dataToExport.fechaBaja}
																						id = "fechaBaja"
																						name = "fechaBaja"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="responsable1NombreApellido">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_responsable1" defaultMessage="Responsable1:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("responsable1NombreApellido")}
																						checked = {this.state.dataToExport.responsable1NombreApellido ? true : false}
																						value={this.state.dataToExport.responsable1NombreApellido}
																						id = "responsable1NombreApellido"
																						name = "responsable1NombreApellido"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"
																					/>
																				</div>
																			<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="responsable2NombreApellido">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_Responsable2" defaultMessage="Responsable2:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("responsable2NombreApellido")}
																						checked = {this.state.dataToExport.responsable2NombreApellido ? true : false}
																						value={this.state.dataToExport.responsable2NombreApellido}
																						id = "responsable2NombreApellido"
																						name = "responsable2NombreApellido"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																			<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="supervisorNombreApellido">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_supervisor" defaultMessage="Supervisor:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("supervisorNombreApellido")}
																						checked = {this.state.dataToExport.supervisorNombreApellido ? true : false}
																						value={this.state.dataToExport.supervisorNombreApellido}
																						id = "supervisorNombreApellido"
																						name = "supervisorNombreApellido"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				</div>
																			<div className="row m-1">
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="temporalNombreApellido">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_temporal" defaultMessage="Temporal:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("temporalNombreApellido")}
																						checked = {this.state.dataToExport.temporalNombreApellido ? true : false}
																						value={this.state.dataToExport.temporalNombreApellido}
																						id = "temporalNombreApellido"
																						name = "temporalNombreApellido"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="centroCostosNombre">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_centro_costos" defaultMessage="Centro Costos:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("centroCostosNombre")}
																						checked = {this.state.dataToExport.centroCostosNombre ? true : false}
																						value={this.state.dataToExport.centroCostosNombre}
																						id = "centroCostosNombre"
																						name = "centroCostosNombre"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="cebeNombre">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_cebeNombre" defaultMessage="CebeNombre:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("cebeNombre")}
																						checked = {this.state.dataToExport.cebeNombre ? true : false}
																						value={this.state.dataToExport.cebeNombre}
																						id = "cebeNombre"
																						name = "cebeNombre"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="poliza">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_poliza" defaultMessage="Poliza:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("poliza")}
																						checked = {this.state.dataToExport.poliza ? true : false}
																						value={this.state.dataToExport.poliza}
																						id = "poliza"
																						name = "poliza"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"
																					/>
																				</div>
																				</div>
																			<div className="row m-1">
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="lugarGuarda">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_lugar_guarda" defaultMessage="Lugar Guarda:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("lugarGuarda")}
																						checked = {this.state.dataToExport.lugarGuarda ? true : false}
																						value={this.state.dataToExport.lugarGuarda}
																						id = "lugarGuarda"
																						name = "lugarGuarda"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																			
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="certificadoVtv">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_vtv" defaultMessage="VTV:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("certificadoVtv")}
																						checked = {this.state.dataToExport.certificadoVtv ? true : false}
																						value={this.state.dataToExport.certificadoVtv}
																						id = "certificadoVtv"
																						name = "certificadoVtv"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="telepeaje">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_telepeaje" defaultMessage="Telepeaje:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("telepeaje")}
																						checked = {this.state.dataToExport.telepeaje ? true : false}
																						value={this.state.dataToExport.telepeaje}
																						id = "telepeaje"
																						name = "telepeaje"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="ypfRuta">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_ypfRuta" defaultMessage="YPFRuta:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("ypfRuta")}
																						checked = {this.state.dataToExport.ypfRuta ? true : false}
																						value={this.state.dataToExport.ypfRuta}
																						id = "ypfRuta"
																						name = "ypfRuta"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																				</div>
																			<div className="row m-1">
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="kmActual">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_km_actual" defaultMessage="KM Actual:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("kmActual")}
																						checked = {this.state.dataToExport.kmActual ? true : false}
																						value={this.state.dataToExport.kmActual}
																						id = "kmActual"
																						name = "kmActual"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="kmActual">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_km_recorridos" defaultMessage="KM Recorridos:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("kmRecorridos")}
																						checked = {this.state.dataToExport.kmRecorridos ? true : false}
																						value={this.state.dataToExport.kmRecorridos}
																						id = "kmRecorridos"
																						name = "kmRecorridos"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="dnrpaSeccional">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_DNRPA" defaultMessage="DNRPA:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("dnrpaSeccional")}
																						checked = {this.state.dataToExport.dnrpaSeccional ? true : false}
																						value={this.state.dataToExport.dnrpaSeccional}
																						id = "dnrpaSeccional"
																						name = "dnrpaSeccional"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="activoFijo">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_activo_fijo" defaultMessage="Activo Fijo:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("activoFijo")}
																						checked = {this.state.dataToExport.activoFijo ? true : false}
																						value={this.state.dataToExport.activoFijo}
																						id = "activoFijo"
																						name = "activoFijo"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																			</div>
																			<div className="row m-1">
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="nroTitulo">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_titulo" defaultMessage="Titulo:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("nroTitulo")}
																						checked = {this.state.dataToExport.nroTitulo ? true : false}
																						value={this.state.dataToExport.nroTitulo}
																						id = "nroTitulo"
																						name = "nroTitulo"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"
																					/>
																				</div>
																				<div className=" col-md-3">
																				<label className="col-md-6 label-control col-form-label" htmlFor="observaciones">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_obs" defaultMessage="Obs.:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("observaciones")}
																						checked = {this.state.dataToExport.observaciones ? true : false}
																						value={this.state.dataToExport.observaciones}
																						id = "observaciones"
																						name = "observaciones"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>																			
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="estado">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_estado" defaultMessage="Estado:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("estado")}
																						checked = {this.state.dataToExport.estado ? true : false}
																						value={this.state.dataToExport.estado}
																						id = "estado"
																						name = "estado"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="combustible">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_combustible" defaultMessage="Combustible:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("combustible")}
																						checked = {this.state.dataToExport.combustible ? true : false}
																						value={this.state.dataToExport.combustible}
																						id = "activcombustibleoFijo"
																						name = "combustible"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																			</div>
																			<div className="row m-1">
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="titular">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_titular" defaultMessage="Titular:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("titular")}
																						checked = {this.state.dataToExport.titular ? true : false}
																						value={this.state.dataToExport.titular}
																						id = "titular"
																						name = "titular"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"
																					/>
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="proveedorGps">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_prov_gps" defaultMessage="Prov. Gps:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("proveedorGps")}
																						checked = {this.state.dataToExport.proveedorGps ? true : false}
																						value={this.state.dataToExport.proveedorGps}
																						id = "proveedorGps"
																						name = "proveedorGps"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>	
																				</div>																			
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="companiaOrigen">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_cnia_orig" defaultMessage="Cnia. Orig.:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("companiaOrigen")}
																						checked = {this.state.dataToExport.companiaOrigen ? true : false}
																						value={this.state.dataToExport.companiaOrigen}
																						id = "companiaOrigen"
																						name = "companiaOrigen"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"										
																					/>	
																				</div>
																				<div className=" col-md-3">
																					<label className="col-md-6 label-control col-form-label" htmlFor="planPreventivoDescripcion">
																						<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.label_plan_mant" defaultMessage="Plan Mant.:"/>
																					</label>
																					<Switch
																						onChange = {(event) => this.handleDataExport("planPreventivoDescripcion")}
																						checked = {this.state.dataToExport.planPreventivoDescripcion ? true : false}
																						value={this.state.dataToExport.planPreventivoDescripcion}
																						id = "planPreventivoDescripcion"
																						name = "planPreventivoDescripcion"
																						offColor = "#FF4961"
																						onColor = "#28D094"
																						disabled = {false}
																						className =  "col-md-6 align-middle"														
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
													</div>
											<div className="col-md-12 alert alert-info small" role="alert">
												<FormattedMessage id="kmMovilGrid.render.export_modal.export_columns.information_esta_accion_puede_demorar_unos_minutos" defaultMessage="Esta acción puede demorar unos minutos."/>
											</div>
									</form>
								</div>
							</div>
						</div>
						<div className="modal-footer modal-grey">
							<button type="button" className="btn btn-default btn-fleet" onClick={this.handleExportExcel.bind(this)} ><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="kmMovilGrid.render.export_modal.finish_button.guardar" defaultMessage=" Exportar a Excel"/></button>
							<button type="button" className="btn btn-danger"onClick={this.handleExportCancel} ><i className="fa fa-times-circle"></i> Cancelar</button>
						</div>
					</div>
				</div>
			</div>
			</React.Fragment>
		);
	}

	initGrid() {
	
		window.scrollTo(0, 0);
		let component = this;

		Promise.all([
			this.getData('/marcas/select'),
			this.getData('/bases/select-filtered-by-user/movil'),
			this.getData('/modelo-tipos/select'),
			this.getData('/regiones/select'),
			ConfigBusiness.get('moviles.estados'),
			ConfigBusiness.get('moviles.estadosActivos'),
			ConfigBusiness.get('moviles.estadosInactivos'),
			ConfigBusiness.get('moviles.estadosResaltados')
		]).then((data) => {

			let estados = data[4] ? data[4].split(',') : null;
			let menuEstado = [];
			estados.forEach(estado => {
				menuEstado.push({value: estado, label: estado});
			});

			let estadosActivos = data[5] ? data[5].split(',') : null;
			let estadosInactivos = data[6] ? data[6].split(',') : null;
			let estadosResaltados = data[7] ? data[7].split(',') : null;


			let filters = {
				marca: data[0] ? data[0] : [],
				base: data[1] ? data[1] : [],
				tipo: data[2] ? data[2] : [],
				region: data[3] ? data[3] : [],
				menuEstado: menuEstado//[{value: '', label: 'Todos'},{value: 'estadosActivos', label: 'Activos'},{value: 'estadosInactivos', label: 'Inactivos'}]
			};
			let dataToExportCopy = JSON.parse(JSON.stringify(component.state.dataToExport));
			dataToExportCopy.color = ConfigBusiness.get('moviles.color.habilitado') === 'true' ? true : false;
			dataToExportCopy.unidad = ConfigBusiness.get('moviles.unidad.grilla') === 'true' ? true : false;
			component.setState({
				editKmEnabled: ConfigBusiness.get('moviles.editaKm.habilitado') === 'true' ? true : false,
				maxDifferenceKM: ConfigBusiness.get('moviles.editaKm.habilitado') === 'true' ? ConfigBusiness.get('moviles.editaKm.maximo') : 0,
				canModifyKM: Security.hasPermission('MOVILES_MODIFICAR_KM'),
				regionEnabled: ConfigBusiness.get('tickets.region.habilitado') === 'true' ? true : false,
				unidadEnabled: ConfigBusiness.get('moviles.unidad.grilla') === 'true' ? true : false,
				colorEnabled: ConfigBusiness.get('moviles.color.habilitado') === 'true' ? true : false,
				dataToExport: dataToExportCopy,
				estadosActivos: estadosActivos,
				estadosInactivos: estadosInactivos,
				estadosResaltados: estadosResaltados				
			});

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					stateSave: true,
					stateSaveCallback: function(settings, data) {
						localStorage.setItem('DataTables_KmMovilGrid', JSON.stringify(data));
					},
					stateLoadCallback: function(settings) {
						return JSON.parse(localStorage.getItem('DataTables_KmMovilGrid'));
					},
					processing: true,
					serverSide: true,
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + this.dataService +'/'+ component.state.filtroPeriodoInicio+'/'+component.state.filtroPeriodoFin+'/'+component.state.kilometros+'/'+component.state.tipoUnidadMedidor,
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_KmMovilGrid')).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'kmMovilGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'kmMovilGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}

								//menu-estado
								if (sets.aoColumns[index].filterType === 'menuEstado') {
																		
									var menuEstado = $('<div class="btn-group dropright"><button type="button" class="dropdown-toggle button-embudo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ft-filter"></i></button><div class="dropdown-menu" id="menuEstadoDrop"><h6 class="dropdown-header">Estado</h6></div></div>')
											.appendTo($(column.footer()).empty());
										
											filters[sets.aoColumns[index].name].map(e => {
												$('#menuEstadoDrop').append('<button class="dropdown-item" value="' + e.value + '">' + e.label + '</button>');
												return true;
											});
											$('#menuEstadoDrop').on('click', function(e) {
												var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
													.search(e.target.value ? e.target.value : '', true, false)
												.draw();
											});
									if (stateColumns && stateColumns[index].search.search) menuEstado.val(stateColumns[index].search.search);
										
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [{
						title: '',
						className: 'all',
						defaultContent: '',
						orderable: false,
						render: function(data, type, full, meta) {
							if(full.marca && full.marca.nombre) {
								return '<div id="avatar" data-placement="top" data-toggle="tooltip" title="' + full.marca.nombre + '"></div>';
							} else {
								return '<div id="avatar"></div>';
							}
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('div').tooltip();
						},
						filterType: 'none'
					}, {
						title: '',
						data: 'estado',
						name: 'menuEstado',
						width: 3,
						padding: 0,
						orderable: false,
						className: 'p-0 all',// embudo',
						render: function(data, type, full, meta) {
							return '';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$('div.tooltip[role="tooltip"]').tooltip('dispose');
							$('div.tooltip[role="tooltip"]').remove();
							
							$(td).attr('data-togle', 'tooltip');
							$(td).attr('data-placement', 'top');
							$(td).attr('title', cellData);
							$(td).tooltip();
							
							if(component.state.estadosResaltados && component.state.estadosResaltados.indexOf(cellData) >=0){
								$(td).addClass('status-orange');
							} else{
								if (component.state.estadosActivos.indexOf(cellData) >=0) {
									$(td).addClass('status-green');
								} else {
									$(td).addClass('status-red');
								}
							}

						},
						filterType: 'none'//'menuEstado'
					}, {
						title: component.props.intl.formatMessage({ id: 'kmMovilGrid.column_dominio.label_dominio', defaultMessage: 'Dominio' }),
						className: 'all',
						data: 'dominio',
						filterType: 'input'
					}, {
						name: 'marca',
						title: component.props.intl.formatMessage({ id: 'kmMovilGrid.column_marca.label_Marca', defaultMessage: 'Marca' }),
						className: 'all',
						data: 'marca',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'modelo',
						title: component.props.intl.formatMessage({ id: 'kmMovilGrid.column_modelo.label_modelo', defaultMessage: 'Modelo' }),
						className: 'all',
						data: 'modelo',
						render: function(data, type, row) {
							return data && data.nombre ? data.nombre : '';
						},
						filterType: 'input'
					},  {
						name: 'tipo',
						title: component.props.intl.formatMessage({ id: 'kmMovilGrid.column_type.label_tipo', defaultMessage: 'Tipo' }),
						className: 'all',
						data: 'modelo.tipo',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'region',
						title: (component.state.regionEnabled) ? component.props.intl.formatMessage({ id: 'kmMovilGrid.column_region.label_region', defaultMessage: 'Región' }) : '',
						className: (component.state.regionEnabled) ? 'all' : 'd-none',
						data: 'region',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'base',
						title: component.props.intl.formatMessage({ id: 'kmMovilGrid.column_base.label_Base', defaultMessage: 'Base' }),
						className: 'all',
						data: 'base',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.descripcion ? data.descripcion : '';
							}
						},
						filterType: 'select'
					}, {
						title: '',
						className: 'all',
						defaultContent: '',
						render: function(data, type, full, meta) {
							return full.fechaHoraActualizacionKM ? '<div class="text-center success dt-info-icon"><i class="la la-clock-o" data-html="true" data-placement="top" data-toggle="tooltip" title="'+component.props.intl.formatMessage({ id: 'kmMovilGrid.column_base.label_ult_actualizacion_KM', defaultMessage: 'Ult. actualización KM: ' })+ Timezone.getDateForClient(full.fechaHoraActualizacionKM, null, 'DD/MM/YYYY') + ' <br/>Usuario: ' + (full.persona ? (full.persona.nombre  + ' ' + full.persona.apellido) : 'Sistema') + '"></i></div>' : '';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('div > i').tooltip();
						},
						orderable: false,
						filterType: 'none'
					}, {
						title: component.props.intl.formatMessage({ id: 'kmMovilGrid.column_kM_Actual.label_Medidor_actual', defaultMessage: 'Medidor Actual' }),
						className: 'all',
						data: 'km',
						filterType: 'input',
						render: function(data, type, full, meta) {
							return Math.floor(data).toLocaleString('de-DE') + ' ' + UNIDAD_MEDIDOR[full.tipoUnidadMedidor]['unidades'];
						}
					}, {
						title: component.props.intl.formatMessage({ id: 'kmMovilGrid.column_type.label_medidor_corrido', defaultMessage: 'Medidor Corrido' }),
						className: 'all',
						data: 'kmRecorridos',
						filterType: 'input',
						render: function (data, type, full, meta) {
							return Math.floor(data).toLocaleString('de-DE') + ' ' + UNIDAD_MEDIDOR[full.tipoUnidadMedidor]['unidades'];
						}
					}],
					drawCallback: function() {
						$(this).find('.action').on('click', function() {
							let data = component.table.row($(this).parents('tr')).data();
							let redirectTo;
							if ($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
							if ($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
							if ($(this).hasClass('mantYVenc')) redirectTo = component.props.match.url + '/' + data.id + '/tickets';
							if ($(this).hasClass('correctivo')) redirectTo = '/correctivos/add/movil/' + data.id + '/' + data.dominio;
							component.setState({
								redirectTo: redirectTo
							});
						});
					},
					rowCallback: function(row, data) {
						if (data.marca) {
							if (data.marca.foto) {
								let photoUrl = Config.get('apiUrlBase') + '/marcas/foto/' + data.marca.foto;
								fetch(photoUrl, {
									method: 'GET',
									headers: {
										'Authorization-Token': localStorage.getItem("token")
									}
								}).then(response => {
									if (response.status === 200) {
										return response.blob();
									} else {
										$('#avatar', row).addClass('dt-user-avatar');
										$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
									}
								}).then(imgBlob => {
									$('#avatar', row).addClass('dt-user-avatar-image');
									$('#avatar', row).css('background-image', 'url(' + URL.createObjectURL(imgBlob) + ')');
								}).catch(() => {
									$('#avatar', row).addClass('dt-user-avatar');
									$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
								});
							} else {
								$('#avatar', row).addClass('dt-user-avatar');
								$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
							}
						} else {
							$('#avatar', row).addClass('dt-user-avatar');
							$('#avatar', row).html('');
						}					

					}
				}, datatablesConfig(this.props.intl))

				
			);			

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					name: 'filterReset',
					text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
					titleAttr:component.props.intl.formatMessage({ id: 'kmMovilGrid.tools_restore_filters.title_restaurar_filtros_y_ordenamiento', defaultMessage: 'Restaurar filtros y ordenamiento' }),
					action: function (e, dt, node, config) {
						var index = 0;

						component.setState({loading: true});

						component.setState({
							filtroPeriodoInicio: moment().subtract(30, 'days').format('YYYY-MM-DD'),
							filtroPeriodoFin: moment().format('YYYY-MM-DD'),
							kilometros: null,
							periodoSelect: {id:20, label: component.props.intl.formatMessage({ id: 'kmMovilGrid.render.periodoSelect.ultimos_treinta_dias', defaultMessage: 'Últimos 30 días' })}
						});
						component.table.columns().every(function() {
							var column = this;

							if (dt.settings()[0].aoColumns[index].filterType) {
								column.search('', true, false);
							}
							index++;
							return null;
						});
						$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
							$(element).val('');
						});
						dt.table().order([ 0, 'asc' ]);
						//dt.ajax.reload();
						component.cargarDatosFiltrados().then(()=>{
							component.setState({loading:false});
						});
					}
				}, {
					extend: 'print',
					name: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win) {
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						} else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css('font-size', '10pt')
							.prepend(`<div class="container">
												<div class="row mb-2">
													<div class="col-6">
														<img style="height: 40px" src="` + LogoCompany + `" />
													</div>
													<div class="col-6	text-right">
														<img style="height: 40px" src="` + LogoVecFleet + `" />
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12" style="border-bottom: 1px solid #000;">
														<h2>`+component.props.intl.formatMessage({ id: 'kmMovilGrid.print_content.header_listado_de_vencimientos', defaultMessage: 'Listado de Móviles' })+`</h2>
													</div>
												</div>
											</div>`);

						$(win.document.body).find('table')
							.addClass('compact')
							.css('font-size', 'inherit');
					},
					exportOptions: {
						columns: component.columnsToPrint
					},
					titleAttr: component.props.intl.formatMessage({ id: 'kmMovilGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'kmMovilGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
				}, {
					name: 'exportExcel',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'kmMovilGrid.tools_export.title_exportar_a_excel', defaultMessage: 'Exportar a Excel' }),
					action: function (e, dt, node, config) {
						component.setState({
							exportFechaDesde: moment().startOf('year'),
							exportFechaHasta: moment()
						}, () => {
							$('#export_modal').modal({backdrop: 'static'});
						});
					}
				}]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('filterReset:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('print:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('columns:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('export:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('exportExcel:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();

			$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
	}

	getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}

}

export default injectIntl(KmMovilGrid);
