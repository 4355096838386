import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import swal from 'sweetalert'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import Timezone from '../../commons/timezone/Timezone.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');


pdfMake.vfs = pdfFonts.pdfMake.vfs;

class FacturasGrid extends Component {
	constructor(props) {
		super(props);
		
		
		moment.locale('es');

		this.ajaxHandler = new AjaxHandler();

		this.columnsToPrint = [0,1,2,3,4,5,6,7,8,9,10];

		this.state = {
			redirectTo: null
		}
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('FACTURAS_LISTAR')) {
	      	this.ajaxHandler.subscribe(this);
			this.initGrid();
	    } else {
		    this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">
												{Security.renderIfHasPermission('FACTURAS_CREAR', (
												<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNew.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'facturasGrid.render.add_button.title_nueva', defaultMessage: 'Nueva' })}>
													<i className="ft-plus"></i>
												</div>
												))}
											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className=""></th>
													<th className=""></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;
		var sap = false;

		Promise.all([
			this.ajaxHandler.getJson('/gerenciadores/select-filtered-by-user'),
			ConfigBusiness.get('facturas.sap.habilitado') === 'true' ? true : false
		]).then((data) => {
			sap = data[1];
			let filters = {
				gerenciador: data[0] ? data[0] : [],
				quincena: [{value: 1, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_fortnight_filter.label_primera', defaultMessage: 'Primera' })}, {value: 2, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_fortnight_filter.label_segunda', defaultMessage: 'Segunda' })}],
				mes: [{value:1, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_enero', defaultMessage: 'Enero' })}, {value:2, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_febrero', defaultMessage: 'Febrero' })}, {value:3, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_marzo', defaultMessage: 'Marzo' })}, {value:4, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_abril', defaultMessage: 'Abril' })}, {value:5, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_mayo', defaultMessage: 'Mayo' })}, {value:6, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_junio', defaultMessage: 'Junio' })}, {value:7, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_julio', defaultMessage: 'Julio' })}, {value:8, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_agosto', defaultMessage: 'Agosto' })}, {value:9, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_septiembre', defaultMessage: 'Septiembre' })}, {value:10, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_octubre', defaultMessage: 'Octubre' })}, {value:11, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_noviembre', defaultMessage: 'Noviembre' })}, {value:12, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month_filter.label_diciembre', defaultMessage: 'Diciembre' })}],
				estado: [{value: 'PREFACTURADA', label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_satate_filter.label_prefacturadas', defaultMessage: 'Prefacturadas' })},{value: 'PENDIENTE', label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_satate_filter.label_pendientes', defaultMessage: 'Pendientes' })}, {value: 'APROBADA', label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_satate_filter.label_aprobadas', defaultMessage: 'Aprobadas' })}, {value: 'RECHAZADA', label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_satate_filter.label_rechazadas', defaultMessage: 'Rechazadas' })}]
			}
      
			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					stateSave: true,
					stateSaveCallback: function(settings, data) {
						localStorage.setItem('DataTables_FacturasGrid', JSON.stringify(data));
					},
					stateLoadCallback: function(settings) {
						return JSON.parse(localStorage.getItem('DataTables_FacturasGrid'));
					},
					processing: true,
					serverSide: true,
					order: [[ 4, "desc" ]],
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + '/facturas/grid',
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {
							component.ajaxHandler.handleResponseErrorsIsValid(xhr);
						},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_FacturasGrid')).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.columns_filters.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.columns_filters.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [{
						name: 'gerenciador',
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_fortnight.label_prefacturadas', defaultMessage: 'Gerenciador' }),
						className: 'all w-100-px',
						data: 'gerenciador',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.razonSocial ? data.razonSocial : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'tipo',
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_type.label_tipo', defaultMessage: 'Tipo' }),
						className: 'all w-100-px',
						data: 'tipo',
						filterType: 'input'
					}, {
						name: 'puntoDeVenta',
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_pointOfSale.label_punto_Venta', defaultMessage: 'Punto de Venta' }),
						className: 'all w-75-px',
						data: 'puntoDeVenta',
						filterType: 'input'
					}, {
						name: 'numero',
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_number.label_numero', defaultMessage: 'Número' }),
						className: 'all w-75-px',
						data: 'numero',
						filterType: 'input'
					}, {
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_date.label_fecha', defaultMessage: 'Fecha' }),
						name: 'fecha',
						className: 'all',
						data: 'fecha',
						render: function(data, type, full, meta) {
							return data ? Timezone.getDateForClient(data, 'YYYY-MM-DD HH:mm:ss', 'L') : '';
						},
						filterType: 'input'
					}, {
						name: 'quincena',
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_fortnight.label_quincena', defaultMessage: 'Quincena' }),
						className: 'all w-75-px' ,
						data: 'quincena',
						filterType: 'select',
						render: function(data, type, full, meta) {
							if (type === 'filter') {
								return data
							} else {
								if(data === 1) return component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_fortnight.content_primera', defaultMessage: 'Primera' });
								if(data === 2) return component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_fortnight.content_segunda', defaultMessage: 'Segunda' });
							}
						}
					}, {
						name: 'mes',
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.label_mes', defaultMessage: 'Mes' }),
						className: 'all w-75-px',
						data: 'mes',
						render: function(data, type, row) {
							let meses = [{id: 1, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_enero', defaultMessage: 'Enero' })}, {id: 2, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_febrero', defaultMessage: 'Febrero' })}, {id: 3, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_marzo', defaultMessage: 'Marzo' })}, {id: 4, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_abril', defaultMessage: 'Abril' })}, {id: 5, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_mayo', defaultMessage: 'Mayo' })}, {id: 6, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_junio', defaultMessage: 'Junio' })}, {id: 7, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_julio', defaultMessage: 'Julio' })}, {id: 8, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_agosto', defaultMessage: 'Agosto' })}, {id: 9, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_septiembre', defaultMessage: 'Septiembre' })}, {id: 10, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_octubre', defaultMessage: 'Octubre' })}, {id: 11, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_noviembre', defaultMessage: 'Noviembre' })}, {id: 12, label: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_month.content_diciembre', defaultMessage: 'Diciembre' })}];
							return data ? meses.filter(e => e.id === data)[0].label : '';
						},
						filterType: 'select'
					}, {
						name: 'anio',
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_year.label_año', defaultMessage: 'Año' }),
						className: 'all w-75-px',
						data: 'anio',
						filterType: 'input'
					}, {
						name: 'importe',
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_amount.label_importe', defaultMessage: 'Importe' }),
						className: 'all',
						data: 'importe',
						render: function(data, type, row) {
							return data ? new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(data) : '';
						},
						filterType: 'input'
					}, {
						name: 'estado',
						title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_state.label_estado', defaultMessage: 'Estado' }),
						className: 'all',
						data: 'estado',
						filterType: 'select'
					}, {
						orderable: false,
						name: 'observacionesCambioEstado',
						title: '',
						data: 'observacionesCambioEstado',
						className: 'all',
						render: function(data, type, full, meta) {
							return data ? '<div class="text-success dt-info-icon"><i class="ft-message-square" data-togle="tooltip" data-placement="top" title="'+ data + '"></i></div>' : '';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('div > i').tooltip();
						},
						filterType: 'none'
					}, {
						orderable: false,
						data: null,
						className: "text-left all",
						width: '120px',
						render: function(data, type, full, meta) {
							let html =
								(Security.hasPermission('FACTURAS_VISUALIZAR') ? `
								<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_actions.title_ver', defaultMessage: 'Ver' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-search fa-xs"></i>
								</button>` : '') +
								(data.estado === 'PREFACTURADA' && Security.hasPermission('FACTURAS_MODIFICAR') ? `
								<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_actions.title_editar', defaultMessage: 'Editar' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-pencil fa-xs"></i>
								</button>` : '') +
								(data.estado === 'PREFACTURADA' && Security.hasPermission('FACTURAS_ELIMINAR') ? `
								<button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_actions.title_eliminar', defaultMessage: 'Eliminar' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-trash fa-xs"></i>
								</button>` : '') +
								(data.estado === 'PENDIENTE' && Security.hasPermission('FACTURAS_APROBAR') ? `
								<button class="action approve btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_actions.title_aprobar', defaultMessage: 'Aprobar' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-thumbs-up fa-xs"></i>
								</button>` : '') +
								(data.estado === 'PENDIENTE' && Security.hasPermission('FACTURAS_RECHAZAR') ? `
								<button class="action reject btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_actions.title_rechazar', defaultMessage: 'Rechazar' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-thumbs-down fa-xs"></i>
								</button>` : '') +
								(Security.hasPermission('FACTURAS_EXPORTAR') ? `
								<button class="action export btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.column_actions.title_exportar_tickets_a_Excel', defaultMessage: 'Exportar tickets a Excel' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-download fa-xs"></i>
								</button>` : '');
							return html;
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('button').tooltip();
						},
						filterType: 'none'
					}],
					drawCallback: function() {
						
						$(this).find('.action').on('click', function() {
							let data = component.table.row($(this).parents('tr')).data();

							if ($(this).hasClass('view')) {
								component.setState({
									redirectTo: component.props.match.url + '/' + data.id
								});
							}

							if ($(this).hasClass('edit')) {
								component.setState({
									redirectTo: component.props.match.url + '/' + data.id + '/edit'
								});
							}

							if ($(this).hasClass('delete')) {
								swal({
									title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
									text: null,
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_delete.confirm_eliminacion.No', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								})
								.then((isConfirm) => {
									if (isConfirm) {
										fetch(Config.get('apiUrlBase') + '/facturas/' + data.id, {
											method: 'DELETE',
											headers: {
												'Accept': 'application/json',
												'Content-Type': 'application/json',
												'Authorization-Token': localStorage.getItem("token")
											}
										})
										.then(function(response) {
											component.table.ajax.reload(null, false);
											swal(component.props.intl.formatMessage({ id: 'facturasGrid.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
										})
										.catch(function(error) {
											swal(component.props.intl.formatMessage({ id: 'facturasGrid.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
										});
									}
								});
							}
             
						
							if ($(this).hasClass('approve')) {
								let div = document.createElement("div");
								if (sap) {
									var numeroWE = document.createElement('input');
									numeroWE.type = "number";
									numeroWE.className = "swal-content__input";
									numeroWE.placeholder = component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.approve_invoice.placeholder_we', defaultMessage: 'WE *' });
									numeroWE.id = "confirm-WECambioEstado";
									numeroWE.pattern = "[0-9]{10}";

									var errorWE = document.createElement("div");
									errorWE.className = "help-block text-danger field-message error-we";
									errorWE.innerText = component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.approve_invoice.errors_numero_dies_digitos', defaultMessage: 'Debe ser un número de 10 dígitos' });
									errorWE.hidden = true;
									numeroWE.oninput = function(){
										if(this.value.length === 10){
											$(".error-we").attr('hidden',true);					
										}
									};
									var numeroOE = document.createElement('input');
									numeroOE.type = "text";
									numeroOE.className = "swal-content__input swal-input-two";
									numeroOE.placeholder = component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.approve_invoice.placeholder_OE', defaultMessage: 'OE' });
									numeroOE.id = "confirm-OECambioEstado";
									div.append(numeroWE);
									div.append(errorWE);
									div.append(numeroOE);
							  }
								var observaciones = document.createElement('textarea');
								observaciones.placeholder= component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.approve_invoice.placeholder_observaciones', defaultMessage: 'Observaciones' });
								observaciones.className = "swal-content__textarea swal-input-two";
								observaciones.id = "confirm-observacionesCambioEstado";
								
								div.append(observaciones);
							
								function recursiveCall(index) {
								return new Promise((resolve)=>{
									swal({
									title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_approve_invoice.confirm_titel_aprobar', defaultMessage: 'Aprobar' }),
									text: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_approve_invoice.confirm_aprobar_factura', defaultMessage: '¿Confirma que desea aprobar la factura?' }),
									icon: "info",
									content: div,
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_approve_invoice.confirm_aprobar_factura.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false,
											id: 'btn-approve'
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_approve_invoice.confirm_aprobar_factura.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								}).then((isConfirm) => {
									if (isConfirm) {
										if(sap) {
											if(numeroWE.value.length === 10){
											} else{
												$(".error-we").prop('hidden',false);
												return resolve(recursiveCall(++index))								
										}
									} 
									component.ajaxHandler.fetch('/facturas/' + data.id + '/aprobar', {
										method: 'POST',
										body: JSON.stringify({
											observaciones: $('#confirm-observacionesCambioEstado').val(),
											oe: $("#confirm-OECambioEstado").val(),
											we: $("#confirm-WECambioEstado").val()
										}),
										headers: {
											'Accept': 'application/json',
											'Content-Type': 'application/json',
											'Authorization-Token': localStorage.getItem("token")
										}
									}).then((response) => {
										if(response.status === 200) {
											component.table.ajax.reload(null, false);
											resolve();
											swal(component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_approve_invoice.information_factura_aprobada', defaultMessage: 'Factura aprobada.' }), "", "success");
										}
									}).catch((error) => {
										component.ajaxHandler.handleError(error);
									});
									}
								});
							});
							}
								
							recursiveCall(0);
							}

							if ($(this).hasClass('reject')) {
								swal({
									title: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_refuse_invoice.title_rechazar', defaultMessage: 'Rechazar' }),
									text: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_refuse_invoice.confirm_rechazar_factura', defaultMessage: '¿Confirma que desea rechazar la factura?' }),
									icon: "warning",
									content: {
										element: "textarea",
										attributes: {
											placeholder: "Observaciones",
											id: 'confirm-observacionesCambioEstado'
										}
									},
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_refuse_invoice.confirm_rechazar_factura.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_refuse_invoice.confirm_rechazar_factura.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								}).then((isConfirm) => {
									if (isConfirm) {
										component.ajaxHandler.fetch('/facturas/' + data.id + '/rechazar', {
											method: 'POST',
											body: JSON.stringify({
												observaciones: $('#confirm-observacionesCambioEstado').val()
											}),
											headers: {
												'Accept': 'application/json',
												'Content-Type': 'application/json',
												'Authorization-Token': localStorage.getItem("token")
											}
										}).then((response) => {
											if(response.status === 200) {
												component.table.ajax.reload(null, false);
												swal(component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.modal_refuse_invoice.information_factura_rechazada.', defaultMessage: 'Factura rechazada.' }), "", "success");
											}
										}).catch((error) => {
											component.ajaxHandler.handleError(error);
										});
									}
								});
							}

							if ($(this).hasClass('export')) {
								let filename = "";
								component.ajaxHandler.fetch('/facturas/exportar-excel/' + data.id, {
								method: 'GET'
								}).then(response => {
									let disposition = response.headers.get('Content-Disposition');
									if (disposition && disposition.indexOf('attachment') !== -1) {
											let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
											let matches = filenameRegex.exec(disposition);
											if (matches != null && matches[1]) {
												filename = matches[1].replace(/['"]/g, '');
											}
									}
									if (response.status === 200) {
										return response.blob();
									}
								}).then(fileBlob => {
									let fileUrl = URL.createObjectURL(fileBlob);
									$("<a />", {
										href: fileUrl,
										download: filename
									}).appendTo("body").get(0).click();
								}).catch(() => {
								}).finally(() => {
								});
							}
						});
					}
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					name: 'filterReset',
					text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'facturasGrid.initGrid.tools_clean_filters.title_restaurar_filtros', defaultMessage: 'Restaurar filtros y ordenamiento' }),
					action: function (e, dt, node, config) {
						var index = 0;
						component.table.columns().every(function() {
							var column = this;

							if (dt.settings()[0].aoColumns[index].filterType) {
								column.search('', true, false);
							}
							index++;
							return null;
						});
						$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
							$(element).val('');
						});
						dt.table().order([ 4, 'desc' ]);
						dt.ajax.reload();
					}
				}]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('filterReset:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			this.setState({
				loading: false
			});
		});
	}
}

export default injectIntl(FacturasGrid);