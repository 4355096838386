import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import Security from '../../commons/security/Security.js'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert2'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import $ from 'jquery'
import Loading from '../ui/Loading.js'

import {
	getItems,
	getAlmacenes,
	createItems,
	updateItems,
	deleteItems,
	getMedidas,
	getCategorias,
	getSubCategorias,
	getMarcas,
	getSubCategoriasByCat,
	getItemsBySubCat,
	createMovTransfer,
	getOrden,
}
from './MetodosPanol.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class MovStockTransfer extends Component {
	constructor(props) {
		super(props);
		this.ordenCancel = this.ordenCancel.bind(this);
		this.state = {
			redirectTo: null,
			isLoaded: false,
			grid: null,
			almacenes: [],
			create: null,
			update: null,
			delete: null,
			categorias: [],
			subcategorias: [],
			items: [],
			medidas: [],
			marcas: [],
			createMovIngreso: null,
			itemSelected: null,
			getOrden: null,
			tablaItems: []

		}
		  this.onModalNewSubmit = this.onModalNewSubmit.bind(this);
	}
	async componentDidMount() {

    	let component = this;

		const fetchLoad = async () => {

			this.setState({isLoading: true});
			console.log("empieza carga: "+ this.state.isLoading);
			Promise.all([
					await getAlmacenes().then(res=>{
		    			let dataR = res.data;
			        	this.setState({ almacenes: this.state.almacenes.concat(dataR) });
		       			return this.state.almacenes
		       		}),
				  	await getCategorias().then(res=>{
			   				let appendState = res.data;
			            	this.setState({ categorias: appendState });
			       			return this.state.categorias
			    	}),
			    	await getSubCategorias().then(res=>{
			   				let appendState = res.data;
			            	this.setState({ subcategorias: appendState });
			       			return this.state.subcategorias
			    	}),
				   	await getItems().then(res=>{
		    			let appendState = res.data;
		    			this.setState({ items: appendState });
							this.setState({ itemSelected: appendState[0]})
		       		return this.state.items
			    	}),
				   	await getMedidas().then(res=>{
		   				let appendState = res.data;
		            	this.setState({ medidas: this.state.medidas.concat(appendState) });
		       			return this.state.medidas
			    	}),
			])
			.then(allResults => {
				console.log(allResults);
				this.setState({isLoading: false})
				console.log("termina la  carga: "+ this.state.isLoading);
				//this.forceUpdate();

				$.fn.dataTable.ext.errMode = 'none';
				let table = $(this.refs.main).DataTable({

           	dom: '<"data-table-wrapper"t>',
           	data: this.state.tablaItems,
           	select: true,
           	lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "Todos"]],
           	columns: [
           		{
	            title: component.props.intl.formatMessage({ id: 'movStockTransfer.column_id.label_id', defaultMessage: 'id' }),
	            className: "text-center",
	            visible: false,
	            data: "item"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'movStockTransfer.column_quantity.label_cantidad', defaultMessage: 'Cantidad' }),
	            className: "text-center",
	            data: "cantidad"
	            },
           		{
	            title: component.props.intl.formatMessage({ id: 'movStockTransfer.column_description.label_descripcion', defaultMessage: 'Descripcion' }),
	            className: "text-center",
	            data: "descripcion"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'movStockTransfer.column_subcategory.label_id', defaultMessage: 'Subcategoria' }),
	            className: "text-center",
	            data: "subcategoria"
	            },
	            {
	            title: component.props.intl.formatMessage({ id: 'movStockTransfer.column_unit.label_unidad', defaultMessage: 'Unidad' }),
	            className: "text-center",
	            data: "unidadNombre"
	            },
	            {
				orderable: false,
				data: null,
				className: "text-center",
				width: '120px',
	            render: function () {
					let html =
						(Security.hasPermission('PANOL_STOCK_TRANSFERENCIA_ELIMINAR') ? `
						<button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'movStockTransfer.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`">
							<i class="fa fa-trash fa-xs"></i>
						</button>`: '');
                        return html;
				}
        	}],
			orderCellsTop: true,
        	fixedHeader: true,
        	drawCallback: function () {
						$(this).find('.action').on('click', function () {

							if($(this).hasClass('edit')) {
								let data = table.row( $(this).parents('tr') ).data();
								let dataId = data.id;
								console.log(data);
								$("#editItemsModal #editIdItem").val(data.id);
								$("#editItemsModal #editNombreItem").val(data.descripcion);
								$("#editItemsModal #editSubCatItem").val(data.subcategoria.id);
								$("#editItemsModal #editMedidaItem").val(data.unidad.id);
								$("#editItemsModal #editTipoItem").val(data.tipo);
								$("#editItemsModal #editNroParteItem").val(data.numeroDeParte);
								$("#editItemsModal #editReferenciaItem").val(data.referencia);
								$("#editItemsModal #editMarcaItem").val(data.marca.id);
								$("#editItemsModal #editCostoItem").val(data.costo);
								$('#editItemsModal').on('shown.bs.modal', function() {
								    $('#editNombreItem').trigger('focus');
								    $("#editNombreItem").keyup(function(e)
									{
									    if (e.which == 13)
									    {
									        $(this).submit();
									    }
									});
								  });
								$("#editItemsModal").modal({show: true});
							}
							if($(this).hasClass('delete')) {
								swal.fire({
								title: component.props.intl.formatMessage({ id: 'movStockTransfer.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
  								type: 'warning',
								showCancelButton: true,
								confirmButtonColor: '#3085d6',
								cancelButtonColor: '#d33',
								cancelButtonText: component.props.intl.formatMessage({ id: 'movStockTransfer.modal_delete.confirm_eliminacion.no', defaultMessage: 'No, cancelar.' }),
								confirmButtonText: component.props.intl.formatMessage({ id: 'movStockTransfer.modal_delete.confirm_eliminacion.si', defaultMessage: 'SI, eliminar.' })

							}).then((result) => {
								if (result.value) {
									let data = table.row( $(this).parents('tr') ).data();
									let dataId = data.id;
									console.log("se eliminara el item: "+ data.descripcion);
									table.row( $(this).parents('tr') ).remove().draw();
									console.log("lista antes de eliminar")
									console.log(component.state.tablaItems);
									let itemTabla = (value, arr, prop) => {
									    for(var i = 0; i < arr.length; i++) {
									        if(arr[i][prop] === value) {
									            return i;
									        }
									    }
									    return -1; //to handle the case where the value doesn't exist
									}
								    let item = itemTabla(data.id, component.state.tablaItems, 'id');
								    const newlist = [].concat(component.state.tablaItems) // Clone array with concat or slice(0)
								    newlist.splice(item.index, 1);
								    console.log("lista luego de eliminar");
								    console.log(newlist);
								    component.setState({tablaItems: newlist});
								}
							}); //fin accion

							}
						});

					},

		}, datatablesConfig(this.props.intl))
			})
			.catch(err => console.log(err))
		}
		fetchLoad();
	    const config = {
	    	headers: {
	    		'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
	    	 }
    	}

		$(document).find("#example thead th").css("height","43px");
		$(document).find("#example thead th").css("padding-left","20px");
		$(document).find("#example tbody td").css("padding-left","20px");
		$('[data-toggle="tooltip"]').tooltip();
		    	//$(".cardItems").hide();

    }
	componentWillUnmount(){
		/*
       $('.data-table-wrapper')
       .find('table')
       .DataTable()
       .destroy(true);
       */
    }

    shouldComponentUpdate() {
       return true;
    }

    onChange = (e) => {
    this.props.history.push(`/panol/config${e.target.value}`);
  }
  	onCatChange = (e) => {
  		let catId = e.target.value;
  		console.log("categoria id: "+catId);
  		getSubCategoriasByCat(catId).then(res=>{
   				let appendState = res.data;
            	this.setState({ subcategorias: appendState });
            	console.log(this.state.subcategorias);

       			return this.state.subcategorias
    	});
    	this.forceUpdate();
  	}
  	onSubCatChange = (e) => {
  		let subCatId = e.target.value;
  		console.log("Sub-categoria id: "+subCatId);
  		getItemsBySubCat(subCatId).then(res=>{
   				let appendState = res.data;
							this.setState({ itemSelected: appendState[0] });
            	this.setState({ items: appendState });
            	console.log(this.state.items);

       			return this.state.items
    	});
    	this.forceUpdate();
  	}

		onItemChange = (e) => {
			let itemId = e.target.value;
			for (let item of this.state.items) {
				if (item.id == itemId) {
					this.setState({ itemSelected: item });
					console.log(item);

				}
			}
		}
		filtrarMedidas = () => {
			let medidas = this.state.medidas
			if (this.state.itemSelected) {
				medidas = medidas
					.filter(medida => {
						return medida.tipo.id === this.state.itemSelected.unidad.tipo.id
					})
			}
			return medidas.map((medida) => {
				return <option key={medida.id} value={medida.id}>{medida.descripcion}</option>
			})
		}

  	ordenSubmit = (e) => {
  		let component = this;
  		let almacenId = $("#datosOrden").find("#almacenDestino").val();
  		console.log("almacen a redirigir: "+almacenId);
  		let datosOrden = new FormData(document.querySelector("#datosOrden"));
  		let datosTabla = this.state.tablaItems;
  		for(let i = 0; i < datosTabla.length; i++) {
  			delete datosTabla[i].descripcion;
	  		delete datosTabla[i].unidadNombre;
	  		delete datosTabla[i].subcategoria;
  		}
  		let table = $(this.refs.main).DataTable();
  		createMovTransfer(e, datosOrden, datosTabla).then(res =>{
  			if (res.data.id) {
  				swal(component.props.intl.formatMessage({ id: 'movStockTransfer.modal_successful_transfer.information_transferencia_realizada', defaultMessage: 'Transferencia realizada!' }), "", "success")
  				.then((value) => {
						 	let redirectTo = '/panol/stock/almacen/'+almacenId;
							component.setState({redirectTo: redirectTo});
					})
  			} else {
  				let errCod = res.codigo;
  				let errMsg = res.mensaje;
  				let errDet = res.detalle;
  				swal(component.props.intl.formatMessage({ id: 'movStockTransfer.modal_successful_transfer.information_error', defaultMessage: 'Error: ' })+errCod, ""+errDet, "error")
  			}
		  });

  	}
  	ordenCancel = (e) => {
		this.props.history.goBack();
  	}
  	submitAll = (e) => {
  		e.preventDefault();
  		console.log("crea orden");
  	}

  	onModalNewSubmit = (e, orden) => {
  		e.preventDefault();
  		let component = this;
  		let table = $(this.refs.main).DataTable();
  		let datosForm = new FormData(e.target);
  		let itemId = datosForm.get('item');
  		let itemCantidad = datosForm.get('cantidad');
  		let itemUnidadId = datosForm.get('unidad');
  		let itemDesc = $("#newItem").find('option:selected').text();
  		let itemSubCat = $("#newSubCatItem").find('option:selected').text();
  		let itemUnidad = $("#newMedidaItem").find('option:selected').text();
  		let itemJson = [
  		{
  			"item": itemId,
  			"descripcion": itemDesc,
  			"unidad": itemUnidadId,
  			"unidadNombre": itemUnidad,
  			"subcategoria": itemSubCat,
  			"cantidad": itemCantidad
  		}];
  		console.log(itemJson);
        this.setState({ tablaItems: this.state.tablaItems.concat(itemJson) }, () => {console.log(this.state.tablaItems)});

        $('#example').dataTable().fnAddData(itemJson);
        $("#newItemsModal").find('form').trigger('reset');
		$("#newItemsModal").modal("hide");
		swal(component.props.intl.formatMessage({ id: 'movStockTransfer.modal_item_added.information_item_agregado', defaultMessage: 'Item Agregado!' }), "", "success");


  	}

    render() {
	    if (this.state.isLoading) return <div><FormattedMessage id="movStockTransfer.render.loading_items.cargando" defaultMessage="Cargando..."/></div>;
	      return (
        	<React.Fragment>

        	{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}

    		<div className="row mt-2">
				<div className="col-12">

					<div className="card">
					<div className="card-header">
							<h1><FormattedMessage id="movStockTransfer.render.order_data.header_datos_de_orden" defaultMessage="Datos de Orden:"/></h1>
						</div>
						<div className="card-content collpase show">
						<form id="datosOrden" onSubmit={this.ordenSubmit}>

							<div className="card-body card-dashboard">
							    <div className="row">
								     	<div className="col-md-4">
							          		<div className="form-group">
									            <label htmlFor="message-text" className="col-form-label"><FormattedMessage id="movStockTransfer.render.order_data.label_tipo_de_movimiento" defaultMessage="Tipo de movimiento:"/></label>
 												<input id="tipoMovimientoD" className="form-control" value={this.props.intl.formatMessage({ id: 'movStockTransfer.render.order_data.value_tipo_de_movimientoD', defaultMessage: 'Orden de transferencia' })} disabled/>
									            <input id="tipoMovimiento" type="hidden" name="tipoMovimiento" className="form-control" value={this.props.intl.formatMessage({ id: 'movStockTransfer.render.order_data.value_tipo_de_movimiento', defaultMessage: 'Transferencia de stock' })}/>
									        </div>
						          		</div>
								        <div className="col-md-4">
							          		<div className="form-group">
									            <label htmlFor="message-text" className="col-form-label"><FormattedMessage id="movStockTransfer.render.order_data.label_almacen_origen" defaultMessage="Almacen Origen:"/></label>
									            <select className="form-control" name="almacenOrigen">
												  	{this.state.almacenes.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
												</select>
								          	</div>
						          		</div>
										<div className="col-md-4">
							          		<div className="form-group">
									            <label htmlFor="message-text" className="col-form-label"><FormattedMessage id="movStockTransfer.render.order_data.label_almacen_destino" defaultMessage="Almacen Destino:"/></label>
									            <select id="almacenDestino" className="form-control" name="almacenDestino">
												  	{this.state.almacenes.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
												</select>
								          	</div>
						          		</div>
						          	</div>
								</div>

							 </form>
						</div>
					</div>
					<div className="card ">
					<div className="card-header">
							<h1><FormattedMessage id="movStockTransfer.render.order_items.header_items_de_orden" defaultMessage="Items de orden:"/></h1>
						</div>
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
										<div className="col-6">
                                        {Security.renderIfHasPermission('PANOL_STOCK_TRANSFERENCIA_CREAR', (
											<div
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'movStockTransfer.render.order_items.button_add_items.title_nuevo_item', defaultMessage: 'Nuevo Item' })}
												data-toggle="modal"
												data-target="#newItemsModal"
												>
												<i className="ft-plus"></i>
											</div>))}

										</div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div>
				               	<div>
				               		<table id="example" ref="main" className="nowrap table-hover dataTableMovil" width="100%">
									</table>
					            </div>

				            </div>
			            </div>
					</div>
					<div className="card">
						<div className="card-body">
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={this.ordenCancel}><FormattedMessage id="movStockTransfer.render.finish_button.cancelar" defaultMessage="Cancelar"/></button>
					        	<button type="button" className="btn btn-primary" onClick={this.ordenSubmit}><FormattedMessage id="movStockTransfer.render.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
							</div>
						</div>
					</div>
				</div>
			{/*---Modal Agregar Item---*/}
				<div className="modal fade" id="newItemsModal" tabIndex="-1" role="dialog" aria-labelledby="newItemsModalLabel" aria-hidden="true">
				  <div className="modal-dialog modal-lg" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="newPlantillasModalLabel"><FormattedMessage id="movStockTransfer.render.modal_add_item.header_agregar_item" defaultMessage="Agregar Item"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <form onSubmit={this.onModalNewSubmit}>
						    <div className="modal-body">
						        <div className="row">
							        <div className="col-md-3">
							          	<div className="form-group">
								           	<label htmlFor="categoriaItem" className="col-form-label"><FormattedMessage id="movStockTransfer.render.modal_add_item.label_categoria" defaultMessage="Categoria:"/></label>
								           <select className="form-control" id="newCatItem" name="categoria" onChange={this.onCatChange} required>
												  {this.state.categorias.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
							          	</div>
							          </div>
							          <div className="col-md-3">
							          	<div className="form-group">
								           	<label htmlFor="newSubCatItem" className="col-form-label"><FormattedMessage id="movStockTransfer.render.modal_add_item.label_subcategoria" defaultMessage="Subcategoria:"/></label>
								            <select className="form-control" id="newSubCatItem" name="subcategoria" onChange={this.onSubCatChange} required>
												  	{this.state.subcategorias.map((value) => {
								                       return <option key={value.id} value={value.id}>{value.nombre}</option>
								                    })}
											</select>
							          	</div>
							          </div>
							          <div className="col-md-6">
							          	<div className="form-group">
								           	<label htmlFor="newItem" className="col-form-label"><FormattedMessage id="movStockTransfer.render.modal_add_item.label_item" defaultMessage="Item:"/></label>
														<select className="form-control" id="newItem" name="item" required onChange={this.onItemChange}>
																{this.state.items.map((value) => {
																	 return <option key={value.id} value={value.id}>{value.descripcion}</option>
																})}
														</select>
							          	</div>
							          </div>
							          <div className="col-md-4">
								          	<div className="form-group">
									            	<label htmlFor="newMedidaItem" className="col-form-label"><FormattedMessage id="movStockTransfer.render.modal_add_item.label_unidad_de_medida" defaultMessage="Unidad de medida:"/></label>
																<select className="form-control" id="newMedidaItem" name="unidad" required>
														  				{this.filtrarMedidas()}
																</select>
								          	</div>
								        </div>
							           <div className="col-md-4">
							          	<div className="form-group">
								           		<label htmlFor="newCantidadItem" className="col-form-label"><FormattedMessage id="movStockTransfer.render.modal_add_item.label_cantidad" defaultMessage="Cantidad:"/></label>
								            	<input
																min="1"
									            	type="number"
									            	className="form-control"
									            	name="cantidad"
									            	id="newCantidadItem"
									            	placeholder={this.props.intl.formatMessage({ id: 'movStockTransfer.render.modal_add_item.placeholder_cantidad', defaultMessage: 'Ej: 23' })}
									            	required
									            />
							          	</div>
							          </div>
							        </div>
					          	</div>
					      <div className="modal-footer">
					        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="movStockTransfer.render.modal_add_item.finish_button.cancelar" defaultMessage="Cancelar"/></button>
					        <button type="submit" className="btn btn-primary"><FormattedMessage id="movStockTransfer.render.modal_add_item.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
					      </div>
				      </form>
				    </div>
				  </div>
				</div>



			</div>
		</React.Fragment>
            );
		//}
    }
}

export default injectIntl(MovStockTransfer);
