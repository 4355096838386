import React, { Component } from 'react'
import Security from '../../commons/security/Security.js'
import TicketVerPresupuestos from './TicketVerPresupuestos.js'
import TicketCargarPresupuesto from './TicketCargarPresupuesto.js'
import TicketPresupuesto from './TicketPresupuesto.js'
import TicketPresupuestoDetallado from './TicketPresupuestoDetallado.js'
import TicketInformarFactura from './TicketInformarFactura.js'
import $ from 'jquery'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import moment from 'moment'
import 'moment/min/locales'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import TicketsAbm from './TicketsAbm.js'
import DatePicker from 'react-datepicker'
import { Portal } from 'react-overlays'
import Timezone from '../../commons/timezone/Timezone.js';
import TicketVerPresupuestosConRepuestoManoObra from './TicketVerPresupuestosConRepuestoManoObra.js';
import TicketVerPresupuestosDetallado from './TicketVerPresupuestosDetallado.js';

const CalendarContainer = ({children}) => {
	return (
	  <Portal >
		{children}
	  </Portal>
	)
}

class TicketPresupuestos extends Component {
	constructor(props) {
    super(props);
    this.props = props;
    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

    this.state = {
      modalCargarPresupuestoKey: 0,
      modalPresupuestoKey: 0,
      modalInformarFacturaKey: 0,
      editPresupuesto: null,
      deletePresupuestoModal: false,
      deleteModalPresupues: false,
      comparativaPrecios: null,
      ticketsReferenciales: [],
      modalVerOtroTicket: 0,
      ticketPropsMatch: null,
      otroTicketId: null,
      filtroFechaDesde: this.props.ticket.presupuestoFechaHora !== "0000-00-00 00:00:00" ? Timezone.getDateForClient(this.props.ticket.presupuestoFechaHora, null, null).subtract(3, 'months') : moment().subtract(3, 'months'),
			filtroFechaHasta: moment()
    }
  }

  componentDidMount() {
    this.ajaxHandler.subscribe(this);
    this.getComparativaPrecios();
  }

  componentWillMount() {
    this.props.onRef(this);
    this.init();
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  init() {

  }

  canDo(action) {
    return this.props.actions.indexOf(action) >= 0;
  }

  handleCargarPresupuestoSave() {
    let modalCargarPresupuestoKey = this.state.modalCargarPresupuestoKey + 1;
    this.setState({
      modalCargarPresupuestoKey: modalCargarPresupuestoKey,
      deletePresupuestoModal: true
    });
    $('#cargar_presupuesto_modal').modal('toggle');
    this.props.callbackSave();
    this.getComparativaPrecios();
  }

  handlePresupuestoSave() {
    let modalCargarPresupuestoKey = this.state.modalCargarPresupuestoKey + 1;
    this.setState({
      modalCargarPresupuestoKey: modalCargarPresupuestoKey,
      deletePresupuestoModal: true
    });
    $('#presupuesto_modal').modal('toggle');
    this.props.callbackSave();
    this.props.callbackClose();
    this.getComparativaPrecios();
  }

  handlePresupuestoDetalladoSave() {
    let modalCargarPresupuestoKey = this.state.modalCargarPresupuestoKey + 1;
    this.setState({
      modalCargarPresupuestoKey: modalCargarPresupuestoKey,
      deletePresupuestoModal: true
    });
    $('#presupuesto_detallado_modal').modal('toggle');
    this.props.callbackSave();
    this.props.callbackClose();
    this.getComparativaPrecios();
  }

  handleCargarPresupuestoClose() {
    let modalCargarPresupuestoKey = this.state.modalCargarPresupuestoKey + 1;
    this.setState({
      modalCargarPresupuestoKey: modalCargarPresupuestoKey,
      deletePresupuestoModal: true
    });
    $('#cargar_presupuesto_modal').modal('toggle');
    this.props.callbackClose();
  }

  handlePresupuestoDetalladoClose() {
    let modalCargarPresupuestoKey = this.state.modalCargarPresupuestoKey + 1;
    this.setState({
      modalCargarPresupuestoKey: modalCargarPresupuestoKey,
      deletePresupuestoModal: true
    });
    $('#presupuesto_detallado_modal').modal('toggle');
    this.props.callbackClose();
  }

  handlePresupuestoClose() {
    let modalPresupuestoKey = this.state.modalPresupuestoKey + 1;
    this.setState({
      modalPresupuestoKey: modalPresupuestoKey,
      deleteModalPresupuesto: true
    });
    $('#presupuesto_modal').modal('toggle');
    this.props.callbackClose();
  }

  handleInformarFacturaSave() {
    let modalInformarFacturaKey = this.state.modalInformarFacturaKey + 1;
    this.setState({
      modalInformarFacturaKey: modalInformarFacturaKey
    });
    $('#informar_factura_modal').modal('toggle');
    this.props.callbackSave();
  }

  handleInformarFacturaClose() {
    let modalInformarFacturaKey = this.state.modalInformarFacturaKey + 1;
    this.setState({
      modalInformarFacturaKey: modalInformarFacturaKey
    });
    $('#informar_factura_modal').modal('toggle');
    this.props.callbackClose();
  }

  handleVerAdjunto(e) {
    this.ajaxHandler.fetch('/ticket-presupuestos/adjunto/' + this.props.ticket.adjunto, {
      method: 'GET',
      headers: {
        'Authorization-Token': localStorage.getItem("token")
      }
    }).then(response => {
      if (response.status === 200) {
        return response.blob();
      }
    }).then(fileBlob => {
      let fileUrl = URL.createObjectURL(fileBlob);
      window.open(fileUrl);
    }).catch(() => {
    });
    e.preventDefault();
  }

    handleEditPresupuesto(presupuesto){
        this.setState({editPresupuesto: presupuesto});
        $('#ver_presupuestos_modal').modal('toggle');
        $('#cargar_presupuesto_modal').modal();
    }

  getComparativaPrecios() {
    if (ConfigBusiness.get('tickets.comparativaPrecios.habilitado') == 'true') {
      this.ajaxHandler.getJson('/tickets/comparar-precios/'+ this.props.ticket.id)
      .then(data => {
        this.setState({
          comparativaPrecios: data,
          ticketsReferenciales: data && data.ticketsReferenciales !== null ? data.ticketsReferenciales : [],
        });
      }).catch((error) => {
      console.log(error);
    });
  }
  }

  handleVerTicket(id) {
    let modalVerOtroTicket = this.state.modalVerOtroTicket + 1;

    this.setState({
			modalVerOtroTicket: modalVerOtroTicket,
      ticketPropsMatch: {
        isExact: true,
        params: {
          id: id
        },
        path: "/tickets/:" + id,
        url: "/tickets/" + id
      },
      otroTicketId: id,
    }, () => {
      $('#ver_otro_ticket_en_modal').modal({backdrop: 'static'});
    });
  }

  handleFilter(){
    let ticketsReferenciales = this.state.comparativaPrecios.ticketsReferenciales;
    let ticketFiltrados = ticketsReferenciales.filter(ticket => ( moment(ticket.fecha).isAfter(moment(this.state.filtroFechaDesde).startOf('day')) ) && ( moment(ticket.fecha).isBefore(moment(this.state.filtroFechaHasta).endOf('day')) ));
    this.setState({
      ticketsReferenciales: ticketFiltrados
    });
  }

  handleClearFilter(){
    this.setState({ 
      ticketsReferenciales: this.state.comparativaPrecios.ticketsReferenciales,
      filtroFechaDesde: moment(this.props.ticket.presupuestoFechaHora).subtract(3, 'months'),
      filtroFechaHasta: moment(),
    })
  }

	render() {
    let ticket = this.props.ticket;
    let minDate = ticket.presupuestoFechaHora !== "0000-00-00 00:00:00" ? Timezone.getDateForClient(ticket.presupuestoFechaHora, null, null).subtract(6, 'months') : moment().subtract(6, 'months');
    let maxDate = moment();
    

    if(ticket) {
      return (
        <div>
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body">
                <h4 className="form-section">
                  <i className="la la-file-text"></i><FormattedMessage id="ticketPresupuestos.render.budget.header_datos_generales" defaultMessage=" Presupuesto "/><div style={{display: 'inline'}} hidden={!ticket.presupuestoAdicional}> - Adicional</div>
                </h4>
                <div className="float-right ticket-buttons">
                  {ticket.movil ?
                    <div>
                      {ConfigBusiness.get('tickets.comparativaPrecios.habilitado') == 'true' && this.state.comparativaPrecios != null && this.state.comparativaPrecios.estado === 'VERDE' ? (
                      <button 
                        className="btn btn-float-sm btn-round ml-1 btn-fleet-bg-green"
                        data-tooltip="tooltip" data-placement="top" title="" 
                        data-original-title={this.props.intl.formatMessage({ id:'ticketPresupuestos.render.budget.button_comparativa_precios_verde', defaultMessage: 'El presupuesto esta acorde a trabajos similares' })} 
                        data-toggle="modal" data-target="#ver_comparativa_modal">
                        <i className="la la-usd align-middle"></i>
                      </button>
                      ) : ''}
                      {ConfigBusiness.get('tickets.comparativaPrecios.habilitado') == 'true' && this.state.comparativaPrecios != null && this.state.comparativaPrecios.estado === 'AMARILLO' ? (
                      <button 
                        className="btn btn-float-sm btn-round ml-1 btn-fleet-bg-yellow"
                        data-tooltip="tooltip" data-placement="top" title="" 
                        data-original-title={this.props.intl.formatMessage({ 
                          id: 'ticketPresupuestos.render.budget.button_comparativa_precios_amarillo', 
                          defaultMessage: 'El presupuesto se encuentra por encima de trabajos similares un ' }) + ConfigBusiness.get('tickets.comparativaPrecios.porcentajeAmarillo') + '%'} 
                        data-toggle="modal" data-target="#ver_comparativa_modal">
                        <i className="la la-usd align-middle"></i>
                      </button>
                      ) : ''}
                      {ConfigBusiness.get('tickets.comparativaPrecios.habilitado') == 'true' && this.state.comparativaPrecios != null && this.state.comparativaPrecios.estado === 'ROJO' ? (
                      <button 
                        className="btn btn-float-sm btn-round ml-1 btn-fleet-bg-red"
                        data-tooltip="tooltip" data-placement="top" title="" 
                        data-original-title={this.props.intl.formatMessage({ 
                          id: 'ticketPresupuestos.render.budget.button_comparativa_precios_rojo', 
                          defaultMessage: 'El presupuesto se encuentra por encima de trabajos similares un ' }) + ConfigBusiness.get('tickets.comparativaPrecios.porcentajeRojo') + '%'}  
                        data-toggle="modal" data-target="#ver_comparativa_modal">
                        <i className="la la-usd align-middle"></i>
                      </button>
                      ) : ''}
                    </div>
                    : ''
                  }
                  {ticket.adjunto ? (
                  <button 
                    className="btn btn-float-sm btn-round btn-fleet ml-1"
                    data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_ver_adjunto', defaultMessage: 'Ver Adjunto' })} onClick={e => this.handleVerAdjunto(e)}>
                    <i className="la la-paperclip align-middle"></i>
                  </button>
                  ) : ''}
                  {(((this.canDo('PRESUPUESTAR') || this.canDo('PRESUPUESTAR_ADIC')) && Security.hasPermission('TICKETS_CARGAR_PRESUPUESTO')) ||
                    (Security.hasPermission('TICKETS_MODIFICAR_PRESUPUESTADOS') && !ticket.factura)) &&
                    ticket &&
                    ticket.gerenciador && ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') !== 'true' &&
                    ConfigBusiness.get('tickets.presupuesto.tipo') === 'simple'? (
                      <button 
                        className="btn btn-float-sm btn-round btn-fleet ml-1"
                        style={{display:  this.props.action === 'VIEW' ? 'none': ''}} 
                        data-tooltip="tooltip"
                        data-placement="top"
                        title=""
                        data-original-title={this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_cargar_presupuesto', defaultMessage: 'Cargar Presupuesto' }) +
                          (this.canDo('PRESUPUESTAR_ADIC') ? this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_cargar_presupuesto.adicional', defaultMessage: ' Adicional' }) : '')
                        }
                        data-toggle="modal"
                        data-target="#cargar_presupuesto_modal"
                      >
                        <i className="la la-cloud-upload align-middle"></i>
                      </button>
                    ) : ''
                  }
                  {(((this.canDo('PRESUPUESTAR') || this.canDo('PRESUPUESTAR_ADIC')) && Security.hasPermission('TICKETS_CARGAR_PRESUPUESTO')) ||
                    (Security.hasPermission('TICKETS_MODIFICAR_PRESUPUESTADOS') && !ticket.factura)) &&
                    ticket &&
                    ticket.gerenciador &&
                    !["SOLICITUD_DE_PEDIDO", "PRESUPUESTADO"].includes(ticket.estado) &&
                    ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') === 'true' &&
                    ConfigBusiness.get('tickets.presupuesto.tipo') === 'estandar'  ? (
                      <button
                        className="btn btn-float-sm btn-round btn-fleet ml-1"
                        style={{display:  this.props.action === 'VIEW' ? 'none': ''}}
                        data-tooltip="tooltip"
                        data-placement="top"
                        title=""
                        data-original-title={this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_cargar_presupuesto', defaultMessage: 'Cargar Presupuesto' }) +
                          (this.canDo('PRESUPUESTAR_ADIC') ? this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_cargar_presupuesto.adicional', defaultMessage: ' Adicional' }) : '')
                        }
                        data-toggle="modal"
                        data-target="#presupuesto_modal"
                      >
                        <i className="la la-cloud-upload align-middle"></i>
                      </button>
                    ) : ''
                  }
                  {(((this.canDo('PRESUPUESTAR') || this.canDo('PRESUPUESTAR_ADIC')) && Security.hasPermission('TICKETS_CARGAR_PRESUPUESTO')) ||
                    (Security.hasPermission('TICKETS_MODIFICAR_PRESUPUESTADOS') && !ticket.factura)) &&
                    ticket &&
                    ticket.gerenciador && !["SOLICITUD_DE_PEDIDO", "PRESUPUESTADO"].includes(ticket.estado) &&
                    ConfigBusiness.get('tickets.presupuesto.tipo') === 'detallado' ? (
                      <button
                        className="btn btn-float-sm btn-round btn-fleet ml-1"
                        style={{display:  this.props.action === 'VIEW' ? 'none': ''}}
                        data-tooltip="tooltip"
                        data-placement="top"
                        title=""
                        data-original-title={this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_cargar_presupuesto', defaultMessage: 'Cargar Presupuesto' }) +
                          (this.canDo('PRESUPUESTAR_ADIC') ? this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_cargar_presupuesto.adicional', defaultMessage: ' Adicional' }) : '')
                        }
                        data-toggle="modal"
                        data-target="#presupuesto_detallado_modal"
                      >
                        <i className="la la-cloud-upload align-middle"></i>
                      </button>
                    ) : ''
                  }
                   {/* {Security.hasPermission('TICKETS_INFORMAR_FACTURA') ? (
                  <button className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_informar_factura', defaultMessage: 'Informar Factura' })} data-toggle="modal" data-target="#informar_factura_modal">
                    <i className="la la-inbox align-middle"></i>
                  </button>
                  ) : ''} */}
                  {
                    Security.hasPermission('TICKETS_VER_PRESUPUESTOS') ? (
                      ConfigBusiness.get('tickets.presupuesto.tipo') === 'detallado' ? 
                        <button className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_ver_presupuestos', defaultMessage: 'Ver Presupuestos' })} data-toggle="modal" data-target="#ver_presupuestos_modal_detallado">
                          <i className="la la-list align-middle"></i>
                        </button>
                      : ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') === 'true' && ConfigBusiness.get('tickets.presupuesto.tipo') === 'estandar'?
                        <button className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_ver_presupuestos', defaultMessage: 'Ver Presupuestos' })} data-toggle="modal" data-target="#ver_presupuestos_repuesto_mano_obra_modal">
                          <i className="la la-list align-middle"></i>
                        </button>
                        : 
                          <button className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.button_title_ver_presupuestos', defaultMessage: 'Ver Presupuestos' })} data-toggle="modal" data-target="#ver_presupuestos_modal">
                            <i className="la la-list align-middle"></i>
                          </button>
                    ) : ''
                  }
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-6 label-control col-form-label"><FormattedMessage id="ticketPresupuestos.render.budget.label_mano_de_obra" defaultMessage="Mano de Obra:"/></label>
                      <div className="col-md-6">
                        <div className="form-control-static col-form-label form-value text-right pr-1">{ticket.manoDeObra !== null ? parseFloat(ticket.manoDeObra).toFixed(2) : ''}</div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 label-control col-form-label"><FormattedMessage id="ticketPresupuestos.render.budget.label_repuestos" defaultMessage="Repuestos:"/></label>
                      <div className="col-md-6">
                        <div className="form-control-static col-form-label form-value text-right pr-1">{ticket.repuestos !== null ? parseFloat(ticket.repuestos).toFixed(2) : ''}</div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-md-6 label-control col-form-label"><FormattedMessage id="ticketPresupuestos.render.budget.label_impuestos" defaultMessage="Impuestos:"/></label>
                      <div className="col-md-6">
                        <div className="form-control-static col-form-label form-value text-right pr-1">{ticket.impuestos !== null ? parseFloat(ticket.impuestos).toFixed(2) : ''}</div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 label-control col-form-label"><FormattedMessage id="ticketPresupuestos.render.budget.label_otros" defaultMessage="Otros:"/></label>
                      <div className="col-md-6">
                        <div className="form-control-static col-form-label form-value text-right pr-1">{ticket.otros !== null ? parseFloat(ticket.otros).toFixed(2) : ''}</div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-md-6 label-control col-form-label"><FormattedMessage id="ticketPresupuestos.render.budget.label_total" defaultMessage="Total:"/></label>
                      <div className="col-md-6">
                        <div className="form-control-static col-form-label form-value text-right pr-1">
                          {(ticket.manoDeObra !== null && ticket.repuestos !== null) ? parseFloat(ticket.manoDeObra + ticket.repuestos + ticket.impuestos + ticket.otros).toFixed(2) : ''}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketPresupuestos.render.budget.label_nombre" defaultMessage="Nombre:"/></label>
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value text-left pr-1">{ticket.presupuestoNombre ? ticket.presupuestoNombre : ''}</div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketPresupuestos.render.budget.label_fecha" defaultMessage="Fecha:"/></label>
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value text-left pr-1">{ticket.presupuestoFechaHora && ticket.presupuestoFechaHora !== '0000-00-00 00:00:00' ? Timezone.getDateForClient(ticket.presupuestoFechaHora, null, 'L') : ''}</div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketPresupuestos.render.budget.label_factura" defaultMessage="Factura:"/></label>
                      <div className="col-md-8">
                      <div className="form-control-static col-form-label form-value text-left pr-1">{ticket.nroFactura ? ticket.nroFactura : ''}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade"  id="presupuesto_modal" role="dialog" aria-labelledby="myModalLabel1">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel1"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.cargar_presupuesto_modal.header_cargar_presupuesto_para_el_ticket', defaultMessage: ' Cargar presupuesto para el ticket: ' }) + ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketPresupuesto key={this.state.modalPresupuestoKey} ticket={ticket} actions={this.props.actions} callbackClose={this.handlePresupuestoClose.bind(this)} callbackSave={this.handlePresupuestoSave.bind(this)} editPresupuesto={this.state.editPresupuesto} deletePresupuestoModal={this.state.deletePresupuestoModal}></TicketPresupuesto>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="cargar_presupuesto_modal" role="dialog" aria-labelledby="myModalLabel1">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel1"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.cargar_presupuesto_modal.header_cargar_presupuesto_para_el_ticket', defaultMessage: ' Cargar presupuesto para el ticket: ' }) + ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketCargarPresupuesto key={this.state.modalPresupuestoKey} ticket={ticket} actions={this.props.actions} callbackClose={this.handleCargarPresupuestoClose.bind(this)} callbackSave={this.handleCargarPresupuestoSave.bind(this)} editPresupuesto={this.state.editPresupuesto} deletePresupuestoModal={this.state.deletePresupuestoModal}></TicketCargarPresupuesto>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="presupuesto_detallado_modal" role="dialog" aria-labelledby="presupuesto_detallado_modal">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="presupuesto_detallado_modal"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.cargar_presupuesto_modal.header_cargar_presupuesto_para_el_ticket', defaultMessage: ' Cargar presupuesto para el ticket: ' }) + ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketPresupuestoDetallado key={this.state.modalPresupuestoKey} ticket={ticket} actions={this.props.actions} callbackClose={this.handlePresupuestoDetalladoClose.bind(this)} callbackSave={this.handlePresupuestoDetalladoSave.bind(this)} editPresupuesto={this.state.editPresupuesto} deletePresupuestoModal={this.state.deletePresupuestoModal}></TicketPresupuestoDetallado>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="ver_presupuestos_modal" role="dialog" aria-labelledby="myModalLabel2">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel2"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.ver_presupuestos_modal_modal.header_cargar_presupuesto_para_el_ticket', defaultMessage: ' Ver Presupuestos del Ticket: ' }) + ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketVerPresupuestos key={this.state.modalCargarPresupuestoKey * 17} ticket={ticket} action={this.props.action} editPresupuesto={this.handleEditPresupuesto.bind(this)}></TicketVerPresupuestos>
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal">Ok</button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="ver_presupuestos_repuesto_mano_obra_modal" role="dialog" aria-labelledby="myModalLabel3">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel3"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'Presupuesto_del_Ticket', defaultMessage: 'Presupuesto del Ticket' }) + ': ' + ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketVerPresupuestosConRepuestoManoObra key={this.state.modalCargarPresupuestoKey * 18} ticket={ticket} action={this.props.action} editPresupuesto={this.handleEditPresupuesto.bind(this)}></TicketVerPresupuestosConRepuestoManoObra>
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="Cerrar" defaultMessage="Cerrar"/></button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="ver_presupuestos_modal_detallado" role="dialog" aria-labelledby="myModalLabel4">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel4"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'Presupuesto_del_Ticket', defaultMessage: 'Presupuesto del Ticket' }) + ': ' + ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketVerPresupuestosDetallado key={this.state.modalCargarPresupuestoKey * 18} ticket={ticket} action={this.props.action}></TicketVerPresupuestosDetallado>
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="Cerrar" defaultMessage="Cerrar"/></button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="informar_factura_modal" role="dialog" aria-labelledby="myModalInformarFactura">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalInformarFactura"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.informar_factura_modal.header_cargar_presupuesto_para_el_ticket', defaultMessage: ' Ticket: ' }) + ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketInformarFactura key={this.state.modalInformarFacturaKey} ticket={ticket} callbackClose={this.handleInformarFacturaClose.bind(this)} callbackSave={this.handleInformarFacturaSave.bind(this)} ></TicketInformarFactura>
                </div>
              </div>
            </div>
          </div>
          {ticket.movil ?
            <div className="modal fade" tabIndex="1" id="ver_comparativa_modal" role="dialog" aria-labelledby="myModalComparativa">
              <div className="modal-xl modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header bg-fleet">
                    <h4 className="modal-title text-white" id="myModalComparativa">
                      <i className="la la-ticket align-middle icon-modal-title"></i>
                      {this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.budget.ver_comparativa_modal.header_ver_comparativa', defaultMessage: ' Comparativa de Tickets: ' }) + ticket.movil.marca.nombre +' '+ ticket.movil.modelo.nombre +' '+ ticket.movil.anio +' - '+ticket.servicio.nombre}
                    </h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  </div>
                  <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                    <div className="card" style={{paddingTop: '20px'}}>
                      {/* <h4 className="form-section">
                        <i className="la la-filter"></i><FormattedMessage id="ticketsPresupuesto.render.comparativa_modal.filter.header_filtros" defaultMessage=" Filtros"/>
                      </h4> */}
                      <div className="form-group row col-md-10">
                        <label className="col-md-2 label-control col-form-label" htmlFor="labelDesde">
                          <FormattedMessage id="ticketsPresupuesto.render.comparativa_modal.filter.label_desde" defaultMessage="Desde "/>:
                        </label>
                        <div className="col-md-2">
                          <DatePicker
                            popperContainer={CalendarContainer}
                            id="filtroFechaDesde"
                            name="filtroFechaDesde"
                            className="form-control date-picker-placeholder"
                            placeholderText={this.props.intl.formatMessage({ id: 'ticketsPresupuesto.render.comparativa_modal.filter.placeholder_desde', defaultMessage: 'DD/MM/AAAA' })}
                            minDate={minDate}
                            maxDate={maxDate}
                            openToDate={this.state.filtroFechaDesde}
                            selected={this.state.filtroFechaDesde}
                            onChange={(event) => {
                              this.setState({filtroFechaDesde: event});
                            }
                          }
                            showMonthDropdown
                            showYearDropdown
                            dateFormatCalendar="DD/MM/YYYY"
                            dropdownMode="select"
                            selectsStart
                            startDate={this.state.filtroFechaDesde}
                            endDate={this.state.filtroFechaHasta}
                          />
                        </div>
                        <label className="col-md-1 label-control col-form-label" htmlFor="labelHasta">
                          <FormattedMessage id="TicketPresupuesto.render.comparativa_modal.filter.hasta" defaultMessage="Hasta "/>:
                        </label>
                        <div className="col-md-2">
                          <DatePicker
                            popperContainer={CalendarContainer}
                            id="filtroFechaHasta"
                            name="filtroFechaHasta"
                            className="form-control date-picker-placeholder"
                            placeholderText={this.props.intl.formatMessage({ id: 'TicketPresupuesto.render.filtro_modal.filter.placeholder_hasta', defaultMessage: 'DD/MM/AAAA' })}
                            minDate={minDate}
                            maxDate={maxDate}
                            openToDate={this.state.filtroFechaHasta}
                            selected={this.state.filtroFechaHasta}
                            onChange={(event) => {
                              this.setState({filtroFechaHasta: event});
                            }}
                            showMonthDropdown
                            showYearDropdown
                            dateFormatCalendar="DD/MM/YYYY"
                            dropdownMode="select"
                            selectsEnd
                            startDate={this.state.filtroFechaDesde}
                            endDate={this.state.filtroFechaHasta}
                          />
                        </div>
                        <div className="col-md-1">
                          <button type="button" className="btn btn-default btn-fleet" onClick={(e) => this.handleFilter(e)}>
                            <i className="la la-filter"></i><FormattedMessage id="TicketsPresupuesto.comparativa_modal_button.filter" defaultMessage=" Filtrar"/>
                          </button>
                        </div>
                        <div className="col-md-1">
                          <button type="button" className="btn btn-default btn-fleet" onClick={(e) => this.handleClearFilter(e)}>
                            <i className="la la-trash"></i><FormattedMessage id="TicketsPresupuesto.comparativa_modal_button.filter_clear" defaultMessage=" Limpiar Filtro"/>
                          </button>
                        </div>
                      </div>
                    </div>
                    {this.state.comparativaPrecios ? (
                    <div className="card">
                      <div className="card-content">
                        <div className="card-body">
                          <h4 className="form-section">
                            <i className="la la-ticket"></i>
                            <FormattedMessage id="ticketVerPresupuestos.render.budget.header_table_average" defaultMessage=" Promedios - "/>
                            <FormattedMessage id="ticketVerPresupuestos.render.budget.header_table_promedio_mano_de_obra" defaultMessage=" Mano de Obra $ "/>
                            { Math.floor(this.state.comparativaPrecios.comparativaPromedioManoDeObra).toLocaleString('de-DE') }
                            <FormattedMessage id="ticketVerPresupuestos.render.budget.header_table_promedio_repuestos" defaultMessage=" - Repuestos $ "/>
                            { Math.floor(this.state.comparativaPrecios.comparativaPromedioRepuestos).toLocaleString('de-DE') }
                            <FormattedMessage id="ticketVerPresupuestos.render.budget.header_table_promedio_total" defaultMessage=" - Total $ "/>
                            { Math.floor(this.state.comparativaPrecios.comparativaPromedioTotal).toLocaleString('de-DE') }
                          </h4>
                          <div className="row">
                            <div className="col-md-12">
                              <table className="table">
                                <thead className="thead-fleet">
                                  <tr>
                                    <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budget.comparativa_modal_table_column_nro_ticket" defaultMessage="Nro de Ticket"/></th>
                                    <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budget.comparativa_modal_table_column_dominio" defaultMessage="Dominio"/></th>
                                    <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budget.comparativa_modal_table_column_base" defaultMessage="Base"/></th>
                                    <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budget.comparativa_modal_table_column_gerenciador" defaultMessage="Gerenciador"/></th>
                                    <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budget.comparativa_modal_table_column_taller" defaultMessage="Taller"/></th>
                                    <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budget.comparativa_modal_table_column_mano_de_obra" defaultMessage="Mano Obra"/></th>
                                    <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budget.comparativa_modal_table_column_repuestos" defaultMessage="Repuestos"/></th>
                                    <th scope="col"><FormattedMessage id="ticketVerPresupuestos.render.budget.comparativa_modal_table_column_total" defaultMessage="Total"/></th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                {this.state.ticketsReferenciales.map((ticketR, index)=>{
                                  return(
                                    <tr key={index}>
                                      <td className="align-middle">{ ticketR.id }</td>
                                      <td className="align-middle">{ ticketR.movil.dominio }</td>
                                      <td className="align-middle">{ ticketR.base.descripcion }</td>
                                      <td className="align-middle">{ ticketR.gerenciador.razon_social }</td>
                                      <td className="align-middle">{ ticketR.taller.razon_social }</td>
                                      <td className="align-middle">{ Math.floor(ticketR.mano_de_obra).toLocaleString('de-DE') }</td>
                                      <td className="align-middle">{ Math.floor(ticketR.repuestos).toLocaleString('de-DE') }</td>
                                      <td className="align-middle">{ Math.floor(ticketR.presupuesto).toLocaleString('de-DE') }</td>
                                      <td className="align-middle">
                                        <a className="btn btn-icon text-fleet p-0 m-0" role="button" onClick={(e) => this.handleVerTicket(ticketR.id)}>
                                          <i className="fa fa-search fa-xs"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                                {this.state.ticketsReferenciales.length == 0 ? (
                                  <tr>
                                    <td className="align-middle text-center" colSpan="9"><FormattedMessage id="ticketPresupuestos.render.budget.comparativa_modal_table.filter_empty" defaultMessage="No se encontraron tickets para las fechas seleccionadas"/></td>
                                  </tr>
                                ): ''}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>  
                  ) : ''}
                  </div>
                  <div className="modal-footer modal-grey">
                    <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal">Ok</button>
                  </div>
                </div>
              </div>
            </div>
          : ''}
          <div className="modal fade" tabIndex="-1" id="ver_otro_ticket_en_modal" role="dialog" aria-labelledby="myModalLabel6">
          { (this.state.ticketPropsMatch !== null && this.state.modalVerOtroTicket) ? (
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel6"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketPresupuestos.render.ver_otro_ticket_en_modal.header_ticket', defaultMessage: 'Ticket: ' }) + this.state.otroTicketId}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <br/>
               <TicketsAbm key={this.state.modalVerOtroTicket} action="VIEW" match={this.state.ticketPropsMatch}></TicketsAbm> 
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="ticketPresupuestos.render.ver_otro_ticket_en_modal.finish_button.ok" defaultMessage="Ok"/></button>
                </div>
              </div>
            </div>
          ) : ''}
          </div>
        </div>
        

      )
    } else {
      return null;
    }
  }
}

export default injectIntl(TicketPresupuestos);