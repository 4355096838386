import React from "react";
import Security from "../../commons/security/Security.js";
import Timezone from '../../commons/timezone/Timezone.js';

function RopGridRow(props) {
    const { rop } = props;

    return (
        <tr>
            <td className="all custom-lineHeight">
                <div>
                    {rop.fechaCreacion ? Timezone.getDateForClient(rop.fechaCreacion, null, 'DD/MM/YYYY HH:mm') : ''}
                </div>
            </td>
            <td className="all custom-lineHeight">
                <div>
                    {rop.numero}
                </div>
            </td>
            <td className="all custom-lineHeight">
                <div>
                    {rop.movil.dominio}
                </div>
            </td>
            <td className="all custom-lineHeight">
                <div>
                    {rop.taller ? rop.taller.razonSocial : ''}
                </div>
            </td>
            <td className="all custom-lineHeight">
                <div>
                    {rop.clasificacion.descripcion}
                </div>
            </td>
            <td className="all custom-lineHeight">
                <div>
                    {rop.estado}
                </div>
            </td>
            <td align="center" className="custom-lineHeight">
                <div
                    style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    {Security.renderIfHasPermission("ROPS_VISUALIZAR",
                        <button
                            className="action-btn-llantas btn btn-sm btn-icon btn-dt-grid text-success"
                            data-togle="tooltip"
                            data-placement="top"
                            title="Ver"
                            onClick={() => props.redirectToView(rop.id)}
                        >
                            <i className="fa fa-search fa-xs"></i>
                        </button>
                    )}
                </div>
            </td>
        </tr>
    )
}

export default RopGridRow;