import React, {Component} from 'react';
import Switch from "react-switch"
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

/*const stringOrNode = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.node,
]);
*/
class GravatarOption extends Component{
	/*propTypes: {
		
  },*/
  constructor(props) {
	super(props);

	this.state = {
		filtroPeriodoInicio: moment().subtract(30, 'days').format('YYYY-MM-DD'),
		filtroPeriodoFin: moment(),
	}
	
	this.handleMouseDown = this.handleMouseDown.bind(this);
	this.handleMouseEnter = this.handleMouseEnter.bind(this);
	this.handleMouseMove = this.handleMouseMove.bind(this);
	this.handleDatePickerPeriodoChange = this.handleDatePickerPeriodoChange.bind(this);
	this.handlePeriodoSelect = this.handlePeriodoSelect.bind(this);
	this.handleCancelarFiltro = this.handleCancelarFiltro.bind(this);
  }
	handleMouseDown (event) {
		event.preventDefault();
		event.stopPropagation();
		if(this.props.option.id !== 12 )
			this.props.onSelect(this.props.option, event);
	}
	handleMouseEnter (event) {
		if(this.props.option.id !== 12 )
			this.props.onFocus(this.props.option, event);
	}
	handleMouseMove (event) {
		if (this.props.isFocused) 
			return;
		if(this.props.option.id !== 12 )
			this.props.onFocus(this.props.option, event);
	}

	handleDatePickerPeriodoChange(name, event){
    
    let date = event ? event.format(): '';
    
    this.setState({[name]: date});
    
	}
	handlePeriodoSelect(event){
		this.props.onSelect({inicio: this.state.filtroPeriodoInicio, fin: this.state.filtroPeriodoFin, id:12, label: 'Del '+moment(this.state.filtroPeriodoInicio).format('DD-MM-YY')+' al '+moment(this.state.filtroPeriodoFin).format('DD-MM-YY')});
	}
	
	handleCancelarFiltro(event){
		this.props.onSelect({inicio: null, fin: null, id:0, label: this.props.intl.formatMessage({ id: 'combustiblesGrid.periodos.todo.label', defaultMessage: 'Todo' })});
	}

	componentDidMount(){
		//console.log(this.props);
	}

	render () {
		
		let gravatarStyle = {
			borderRadius: 3,
			display: 'inline-block',
			marginRight: 10,
			position: 'relative',
			top: -2,
			verticalAlign: 'middle',
		};
		return (
		<React.Fragment>
			
			<div className={this.props.className}
				onMouseDown={this.handleMouseDown}
				onMouseEnter={this.handleMouseEnter}
				onMouseMove={this.handleMouseMove}
				
				> 
				{this.props.children}

				{this.props.option.id === 12 ? //personalizado
				<div>
			<div className="row">
			<div className="col-6">DESDE</div>
			<div className="col-6">HASTA</div>
			<div className="col-12" style={{display:"flex"}}>
			
									<div className="from-group " style={{display:"flex", marginLeft:"0", marginRight:"0.5rem"}}>
					  <DatePicker
						  selected={this.state.filtroPeriodoInicio ? moment(this.state.filtroPeriodoInicio) : null}
						  className="form-control date-picker-placeholder datepicker-on-select "
						  selectsStart
						  startDate={moment(this.state.filtroPeriodoInicio)}
						  openToDate={this.state.filtroPeriodoInicio ? moment(this.state.filtroPeriodoInicio) : moment()}
						  endDate={moment(this.state.filtroPeriodoFin)}
						  maxDate={moment()}
							onChange={(event)=>this.handleDatePickerPeriodoChange('filtroPeriodoInicio', event)}
							popperPlacement="top-start"
							popperModifiers={{
							  offset: {
								  enabled: true,
								  offset: '5px, 10px'
							  },
							  preventOverflow: {
								  enabled: true,
								  escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
								  boundariesElement: 'viewport'
							  }
							}}
					  />
					</div>
					<div className="from-group " style={{display:"flex", marginLeft:"0.5rem", marginRight:"0"}}>
					  <DatePicker
							selected={this.state.filtroPeriodoFin ? moment(this.state.filtroPeriodoFin) : moment()}
							className="form-control date-picker-placeholder datepicker-on-select"
							selectsEnd
							startDate={moment(this.state.filtroPeriodoInicio)}
							endDate={this.state.filtroPeriodoFin ? moment(this.state.filtroPeriodoFin) : moment()}
							openToDate={this.state.filtroPeriodoFin ? moment(this.state.filtroPeriodoFin) : moment()}
							onChange={(event)=>this.handleDatePickerPeriodoChange('filtroPeriodoFin', event)}
							minDate={moment(this.state.filtroPeriodoInicio)}
							maxDate={moment()}
							popperPlacement="top-end"
							popperModifiers={{
								offset: {
									enabled: true,
									offset: '5px, 10px'
								},
								preventOverflow: {
									enabled: true,
									escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
									boundariesElement: 'viewport'
								}
							}}
						/>
					</div>
					</div>
					</div>
					<div className="col-12" className="from-group" style={{display:"flex"}}>
					  <button type="button" className="btn btn-primary" style={{marginTop:"5px", marginLeft:"auto", marginRight:"0.5em", width:"48%"}} onClick={(e) => this.handlePeriodoSelect(e)} ><i className="fa fa-check-circle"></i><FormattedMessage id="gravatarOption.button.aplicar" defaultMessage=" Aplicar"/></button>
						<button type="button" className="btn btn-danger" style={{marginTop:"5px", marginLeft:"0.5em", marginRight:"auto", width:"48%"}} onClick={(e)=>this.handleCancelarFiltro(e)}><i className="fa fa-times-circle"></i><FormattedMessage id="gravatarOption.button.cancelar" defaultMessage=" Cancelar"/></button>
					</div>
				  </div>
				:
				""}
				
			</div>
		</React.Fragment>
		);
	}
}export default injectIntl(GravatarOption)


GravatarOption.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	isDisabled: PropTypes.bool,
	isFocused: PropTypes.bool,
	isSelected: PropTypes.bool,
	onFocus: PropTypes.func,
	onSelect: PropTypes.func,
	option: PropTypes.object.isRequired,
  };