import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
//import $ from 'jquery'
/*import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
//import duallistboxConfig from '../../commons/duallistbox/DuallistboxConfig.js'*/
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'

import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
//import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import DireccionAutocompletar from '../direccionAutocompletar/DireccionAutocompletar'
import InfraccionesAdjuntos from './InfraccionesAdjuntos'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import Timezone from '../../commons/timezone/Timezone.js';

class InfraccionesAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();


    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        movil: '',
        fechaEmision: moment(),
        acta: null,
				direccion: '',
        codigoInfraccion: '',
        conductores: null,
				descripcion: '',
        infraccion: '',
        importe:null,
				puntosDescontados: 0,
        adjuntos: [],
        litros: null
      },
      errors: [],
      loading: false,
      moviles: [],
      conductoresAll: null,
      direccionForm: null,
      mostrarHora: true
    };

    this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleChangeDireccion = this.handleChangeDireccion.bind(this);
		this.getBackendMoviles = this.getBackendMoviles.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConductorChange = this.handleConductorChange.bind(this);
    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.movil': (value) => Validator.notEmpty(value),
        'formData.acta': (value) => Validator.notEmpty(value),
        'formData.importe': (value) => Validator.notEmpty(value),
        'formData.latitud': (value) => Validator.notEmpty(value),
        'formData.longitud': (value) => Validator.notEmpty(value),
        'formData.direccionValid': (value) => Validator.notEmpty(value),
       }
    });

  }

  initForm() {
    this.setState({ loading: true });
    let component = this;

		Promise.all([
      this.ajaxHandler.getJson('/personas/select?filterNotInternal=true'),
			this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/infracciones/' + this.state.props.match.params.id) : null,
		]).then((data) => {

      let conductoresAll = data[0];
      let formData = data[1];

      component.setState({
        conductoresAll: conductoresAll
      });

      if(formData){
        if(formData.fechaEmision){
          formData.fechaEmision = Timezone.getDateForClient(formData.fechaEmision, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
        }
        //guardo los datos para el componente de direccion
        let direccionParams = {
          direccion: formData.direccion,
          numero: formData.numeroDireccionInformada,
          localidad: formData.localidadInformada,
          provincia: formData.provinciaInformada,
          pais: formData.provinciaInformada ? formData.provinciaInformada.pais : null,
          latitud: formData.latitud,
          longitud: formData.longitud
        };

        formData.conductor = conductoresAll.filter(e => e.value === formData.conductor)[0];
        formData.conductor && (formData.conductor.id = formData.conductor.value);
        formData.conductor && (formData.conductor.name = formData.conductor.label);
        component.setState({
          formData: formData,
          direccionForm: direccionParams
        });
      }

      if(this.state.props.action === 'EDIT') {
        if(formData['provincia']) this.handlePaisChange({ value: formData['provincia'].pais.id, label: formData['provincia'].pais.nombre })
        .then(() => {

          if(formData['provincia']) this.handleProvinciaChange({ value: formData['provincia'].id, label: formData['provincia'].nombre })

        });

      }

    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });
  }
  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}
	componentWillMount() {

    if ((Security.hasPermission('INFRACCIONES_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('INFRACCIONES_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('INFRACCIONES_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }

  }

  getData(service, param) {
    let serviceURL ="";
    if ( service === "provincias" || service === "localidades") {
      serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param+ '/select') : (Config.get('apiUrlBase') + '/' + service);
    } else {
      serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
    }
    return fetch(serviceURL, {
      method: 'GET',
			headers: {
				'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
      return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }


  handleAdjuntosChange(adjuntoNuevo) {
		this.state.formData.adjuntos.push(adjuntoNuevo)
    this.setState({
      formData: this.state.formData
    });
  }

	callbackAdjuntosRemove(archivoNombre){
		this.state.formData.adjuntos = this.state.formData.adjuntos.filter(adjunto => adjunto.archivoNombre != archivoNombre)
		this.setState({
      formData: this.state.formData
    });
	}


  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activo'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }

  handleEsUsuarioChange(esUsuario) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['esUsuario'] = esUsuario;
    this.setState({
			formData: formDataCopy
		});
  }

  handleNotificacionesActivasChange(notificacionesActivas) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
		this.setState({
      formData: formDataCopy
    });
  }

	handleInputFormChange(event) {

		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = value !== '' ? value : null;

		this.setState({
			formData: formDataCopy
		});
  }

	handleBasesChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }


  handleUsuarioFormChange(event){
    const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

    //TODO: CHEQUEAR QUE EL USUARIO SEA ÜNICO LO MISMO CON EL NRO. DE LEGAJO
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleSelectFormChange(name, object) {

    const value = object === null ? null : object;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = value;

		this.setState({
			formData: formDataCopy
		});
  }


	getBackendMoviles (searchTerm) {
		return this.ajaxHandler.getJson('/moviles/simple-search?search=' + searchTerm).then(res => {
			return {
				options: res,
				complete: true
			}
		});
	}

  handleMovilChange(object) {
    if(object){
      this.setState({ loading: true });
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.movil = object;
      this.setState({
        formData: formDataCopy
      }, () => {
          this.setState({ loading: true });
          this.ajaxHandler.getJson('/moviles/conductores/' + object.id + '/' + Timezone.getDateForServer(this.state.formData.fechaEmision).format('YYYY-MM-DD HH:mm:ss'))
              .then((data) => {
                formDataCopy.conductores= data ? ([{value: data.idRes1, label: data.res1},{value: data.idRes2, label: data.res2}]) : null;
                formDataCopy.conductor= data ? (data.idRes1 ? {id:data.idRes1, name: data.res1} : (data.idRes2 ? {id:data.idRes2, name: data.res2} : null)) : null;
                this.setState({
                  formData: formDataCopy,
                  loading: false
                });
              });
            });
    } else {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.movil = null;
      formDataCopy.conductores = null;
      formDataCopy.conductor = null;
      this.setState({
        formData: formDataCopy
      });
    }
  }

  handleDatePickerFormChange(name, event) {
    if(event && event.isValid()){
      let nuevaFecha = "";
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      if(name === "horaActual" && event ){
        nuevaFecha = moment(moment(this.state.formData.fechaEmision).format('L')+ ' '+event.format('LTS'), "DD-MM-YYYY hh:mm:ss a");
        formDataCopy['fechaEmision'] = nuevaFecha;
        formDataCopy[name] = event.format('LTS');
      } else {
        formDataCopy[name] = event;
      }
      let mostrarHora = this.state.mostrarHora;
      if("fechaEmision" && !event){
        mostrarHora = false;
      } else{
        mostrarHora = true;
      }

      this.setState({
        formData: formDataCopy,
        mostrarHora: mostrarHora
      }, () => {
        if(this.state.formData.movil){
          this.setState({ loading: true });
          this.ajaxHandler.getJson('/moviles/conductores/' + this.state.formData.movil.id +'/'+ Timezone.getDateForServer(this.state.formData.fechaEmision).format('YYYY-MM-DD HH:mm:ss'))
                .then((data) => {
                  formDataCopy.conductores= data ? ([{value: data.idRes1, label: data.res1},{value: data.idRes2, label: data.res2}]) : null;
                  formDataCopy.conductor= data ? (data.idRes1 ? {id:data.idRes1, name: data.res1} : (data.idRes2 ? {id:data.idRes2, name: data.res2} : null)) : null;
                  this.setState({
                    formData: formDataCopy,
                    loading: false
                  });
                });
            }
        }
      );
    } 
  }

  handleDatePickerFormRawChange(name, date) {
    if(moment(date,"DD/MM/YYYY", true).isValid()){
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy[name] = date;
      this.setState({
        formData: formDataCopy
      });
    }
  }


  handleConductorChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.conductor = { id: object.value, name: object.label };
        this.setState({
          formData: formDataCopy,
        }, () => {
          resolve();        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.conductor = null;
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      }
    });
  }

  handlePaisChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.pais = { id: object.value, label: object.label };
        formDataCopy.provincia = null;
        this.setState({
          formData: formDataCopy,
          provincias: [],
          localidades: []
        }, () => {
          this.setState({ provinciasLoading: true });
          this.ajaxHandler.getJson('/provincias/' + object.value + '/select')
          .then((data) => {
            this.setState({
              provincias: data
            }, () => resolve());
          }).finally(() => {
            this.setState({ provinciasLoading: false });
          });
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.pais = null;
        formDataCopy.provincia = null;
        formDataCopy.localidad = null;
        this.setState({
          formData: formDataCopy,
          provincias: [],
          localidades: []
        }, () => resolve());
      }
    });
  }


  handleChangeDireccion(keyDireccion, valueDireccion, direccionValid){
		// debugger
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

    formDataCopy[keyDireccion] = valueDireccion;
    formDataCopy['direccionValid'] = direccionValid;
    this.setState({
      formData: formDataCopy
    });

  }

	handleSubmit(event) {
    //formatea la fecha antes de enviarla
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.fechaEmision = Timezone.getDateForServer(this.state.formData.fechaEmision).format('YYYY-MM-DD HH:mm:ss');
    this.setState({ loading: true });
    let component = this;
    let data = this.state.formData;
    this.ajaxHandler.fetch('/infracciones' + (this.props.action === 'ADD' ? '' : '/' + data.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...formDataCopy
      }),
    }).then(response => {
      if(response.status !== 400) {

        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/moduloInfracciones'
		});
	}

	render() {
    this.formValidation.validate();
		let formData = this.state.formData;
    let validationState = this.formValidation.state;

    let state = this.state;

    let requiredSymbol = this.state.props.action !== 'VIEW' ? ' *' : '';

    return (
			<React.Fragment>
        {state.redirectTo && <Redirect push to={state.redirectTo} />}
				{state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={state.errors.length===0}>
							{state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="infraccionesAbm.render.infraction_details.header_detalles_infraccion" defaultMessage=" Detalles de la Infracción "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="infraccionesAbm.render.infraction_details.campos_requeridos" defaultMessage="* campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* MOVIL */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="movil">
                              <FormattedMessage id="infraccionesAbm.render.infraction_details.label_movil" defaultMessage="Movil"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-9">
                              {(this.props.action === 'VIEW' || this.props.action === 'EDIT' || this.props.action === 'DEL') ? (
                              <div className="form-control-static col-form-label form-value">{formData.movil ? formData.movil.dominio : ''}</div>
                              ) : (
                              <div>
																<Select.Async
																		placeholder={this.props.intl.formatMessage({ id: 'infraccionesAbm.render.infraction_details.placeholder_movil', defaultMessage: 'Buscar móvil' })}
																		id="movil"
																    name="movil"
																    value={this.state.formData.movil}
																    valueKey="id"
																    labelKey="dominio"
																    loadOptions={this.getBackendMoviles}
																    onChange={(e) => this.handleMovilChange(e)}
																		disabled={this.props.action === 'VIEW'}
																/>

                              </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* FECHA DE EMISION*/}
                        <div className="col-md-3">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaEmision">
                              <FormattedMessage id="infraccionesAbm.render.infraction_details.label_fecha_emision" defaultMessage="Fecha Emisión"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('INFRACCIONES_MODIFICAR')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaEmision === null || formData.fechaEmision === '0000-00-00' ? '' : moment(formData.fechaEmision).format('L') }</div>
                              ) : (
                              <div>
                                <DatePicker
                                  id="fechaEmision"
                                  name="fechaEmision"
                                  className="form-control date-picker-placeholder"
                                  placeholderText={this.props.intl.formatMessage({ id: 'infraccionesAbm.render.infraction_details.placeholder_fecha_emision', defaultMessage: 'DD/MM/AAAA' })}
                                  selected={formData.fechaEmision === null || formData.fechaEmision === '0000-00-00' ? null : moment(formData.fechaEmision) }
                                  onChange={(event) => this.handleDatePickerFormChange("fechaEmision", event)}
                                  onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaEmision", event.target.value)}
                                  maxDate={moment()}
                                  dateFormat="L"
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* HORA DE EMISION */}
                        <div className="col-md-3" hidden={!state.mostrarHora}>
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="horaActual">
                              <FormattedMessage id="infraccionesAbm.render.infraction_details.label_hora" defaultMessage="Hora"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.hora_actual === null || formData.hora_actual === '00:00' ? '' : moment(formData.fechaEmision).format('LT') }</div>
                              ) : (
                              <div>
                                <DatePicker
                                    id="horaActual"
                                    name="horaActual"
                                    className="form-control date-picker-placeholder"
                                    selected={formData.fechaEmision === null || formData.fechaEmision === '00:00' ? null : moment(formData.fechaEmision)}
                                    onChange={(event) => this.handleDatePickerFormChange("horaActual", event)}
                                    onChangeRaw={(event) => this.handleDatePickerFormRawChange("horaActual", event.target.value)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={10}
                                    dateFormat="h:mm a"
                                    timeCaption="Hora"
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* CONDUCTOR */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="base">
                              <FormattedMessage id="infraccionesAbm.render.infraction_details.label_conductor" defaultMessage="Conductor:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.conductor ? formData.conductor.name : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="conductor"
                                  name="conductor"
                                  placeholder={this.props.intl.formatMessage({ id: 'infraccionesAbm.render.infraction_details.placeholder_conductor', defaultMessage: 'Conductor' })}
                                  options={formData.conductores ? formData.conductores : state.conductoresAll}
                                  valueKey='value'
                                  labelKey='label'
                                  value={formData.conductor ? {value: formData.conductor.id, label: formData.conductor.name} : null}
                                  onChange={(e) => this.handleConductorChange(e)}
                                />

                              </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* FECHA DE VENCIMIENTO*/}
                        <div className="col-md-3">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaVencimiento">
                              <FormattedMessage id="infraccionesAbm.render.infraction_details.label_fecha_vencimiento" defaultMessage="Fecha Vencimiento:"/>
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('INFRACCIONES_MODIFICAR')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaVencimiento === null || formData.fechaVencimiento === '0000-00-00' ? '' : moment(formData.fechaVencimiento).format('L') }</div>
                              ) : (
                              <div>
                                <DatePicker
                                  id="fechaVencimiento"
                                  name="fechaVencimiento"
                                  className="form-control date-picker-placeholder"
                                  placeholderText={this.props.intl.formatMessage({ id: 'infraccionesAbm.render.infraction_details.placeholder_fecha_vencimiento', defaultMessage: 'DD/MM/AAAA' })}
                                  selected={formData.fechaVencimiento === null || formData.fechaVencimiento === '0000-00-00' ? null : moment(formData.fechaVencimiento) }
                                  onChange={(event) => this.handleDatePickerFormChange("fechaVencimiento", event)}
                                  onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaVencimiento", event.target.value)}
                                  dateFormat="L"
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* # Acta */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="acta">
                              <FormattedMessage id="infraccionesAbm.render.infraction_details.label_nro_acta" defaultMessage="Nro. Acta*:"/>
                            </label>
                            <div className="col-md-9">
                              {(this.props.action === 'VIEW' || this.props.action === 'EDIT' || this.props.action === 'DEL') ? (
                                <div className="form-control-static col-form-label form-value">{formData.acta ? formData.acta : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="acta" name="acta" placeholder="" value={formData.acta ? formData.acta : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* IMPORTE */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="importe">
                              <FormattedMessage id="infraccionesAbm.render.infraction_details.label_importe" defaultMessage="importe *:"/>
                            </label>
                            <div className="col-md-3">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.importe ? formData.importe.toFixed(2) : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="importe" name="importe" placeholder="" value={formData.importe ? formData.importe : ''} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.importe.pristine || validationState.formData.importe.valid}>Número decimal Ej: 2.31</div>
                              </div>
                              )}
                            </div>
                            <label className="col-md-3 label-control col-form-label" htmlFor="puntosDescontados">
                              <FormattedMessage id="infraccionesAbm.render.infraction_details.label_puntos_descontados" defaultMessage="Puntos Descontados:"/>
                            </label>
                            <div className="col-md-3">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.puntosDescontados ? formData.puntosDescontados : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="puntosDescontados" name="puntosDescontados" placeholder="" value={formData.puntosDescontados} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                    <div className="row">
                        {/* Código de Infracción */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="codigoInfraccion">
                              <FormattedMessage id="infraccionesAbm.render.infraction_details.label_codigo_infraccion" defaultMessage="Código de Infracción:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('INFRACCIONES_CREAR')) ? (
                                <div className="form-control-static col-form-label form-value">{formData.codigoInfraccion ? formData.codigoInfraccion : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="codigoInfraccion" name="codigoInfraccion" placeholder="" value={formData.codigoInfraccion ? formData.codigoInfraccion : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Observaciones de Infracción */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="descripcion">
															<FormattedMessage id="infraccionesAbm.render.infraction_details.label_observaciones" defaultMessage="Observaciones:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('INFRACCIONES_CREAR')) ? (
                                <div className="form-control-static col-form-label form-value">{formData.descripcion ? formData.descripcion : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="descripcion" name="descripcion" placeholder="" value={formData.descripcion ? formData.descripcion : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>

												{/* Descripción de Infracción */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="infraccion">
															<FormattedMessage id="infraccionesAbm.render.infraction_details.label_descripcion_infraccion" defaultMessage="Descripción de Infraccion:"/>
														</label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('INFRACCIONES_CREAR')) ? (
                                <div className="form-control-static col-form-label form-value">{formData.infraccion ? formData.infraccion : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="infraccion" name="infraccion" placeholder="" value={formData.infraccion ? formData.infraccion : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body" id="container-direccion">
                      <h4 className="form-section mt-2">
                        <i className="ft-map-pin"></i><FormattedMessage id="infraccionesAbm.render.location_infraction.label_ubicacion_infraccion" defaultMessage=" Ubicación de la Infracción"/>
                      </h4>
                      <DireccionAutocompletar
											action={this.props.action}
											camposHidden={["codigoPostal"]}
											cambioDireccion={this.handleChangeDireccion}
											formulario={state.direccionForm}
											crearProvincias={true}
											crearLocalidades={true}
											priorizarCoordenadas={true}
											camposObligatorios={["latitud", "longitud"]}/>
                    </div>
                  </div>
                </div>

                {this.props.action === 'ADD' || (this.props.action !== 'ADD' && this.state.formData.id !== 0) ? (
                <InfraccionesAdjuntos comprobante={false}  infraccion={this.state.formData} action={this.props.action} callbackRemove={this.callbackAdjuntosRemove.bind(this)}  callbackUpdate={this.handleAdjuntosChange.bind(this)}></InfraccionesAdjuntos>
                ):''}

								{(!!this.state.formData.fechaAbono) ? (
                <InfraccionesAdjuntos comprobante={true} infraccion={this.state.formData} action={this.props.action} callbackRemove={this.callbackAdjuntosRemove.bind(this)} callbackUpdate={this.handleAdjuntosChange.bind(this)}></InfraccionesAdjuntos>
                ):''}

                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="infraccionesAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'infraccionesAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'infraccionesAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(InfraccionesAbm);
