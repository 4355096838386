import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert2'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import $ from 'jquery'
import Loading from '../ui/Loading.js'
import { getMovimiento } from './MetodosPanol.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class MovStockView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			isLoaded: false,
			create: null,
			update: null,
			delete: null,
			createMovIngreso: null,
			getOrden: null,
			movimiento: {
					almacen: {},
					items: []
			}

		}
		this.componentDidMount()
	}
	componentDidMount() {
    	let component = this;

			let { id } = this.props.match.params;
			let idAlmacen = Object.values({id}).toString();
			this.setState({isLoading: true});

			getMovimiento(idAlmacen).then(res=>{
        	this.setState({ movimiento: res.data });
					console.log(res.data);
					component.forceUpdate();
   		}).then(() => {
					this.setState({isLoading: false})
			})
		}

    shouldComponentUpdate() {
       return true;
    }

    render() {
			let almacen;

			if (!this.state.movimiento.almacen) {
				almacen =
				<>
					<div className="col-md-4">
						<div className="form-group">
							<label htmlFor="message-text" className="col-form-label"><FormattedMessage id="movStockView.render.label_almacen_origen" defaultMessage="Almacen Origen:"/></label>
							<select id="almacen" className="form-control" disabled name="almacen">
								<option>{this.state.movimiento.almacenOrigen.nombre}</option>
							</select>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group">
							<label htmlFor="message-text" className="col-form-label"><FormattedMessage id="movStockView.render.label_almacen_destino" defaultMessage="Almacen Destino:"/></label>
							<select id="almacen" className="form-control" disabled name="almacen">
								<option>{this.state.movimiento.almacenDestino.nombre}</option>
							</select>
						</div>
					</div>
				</>
			} else {
				almacen =
				<>
					<div className="col-md-4">
						<div className="form-group">
							<label htmlFor="message-text" className="col-form-label"><FormattedMessage id="movStockView.render.label_almacen" defaultMessage="Almacen:"/></label>
							<select id="almacen" className="form-control" disabled name="almacen">
								<option>{this.state.movimiento.almacen.nombre}</option>
							</select>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group">
						</div>
					</div>
				</>

			}

	    if (this.state.isLoading) return <div><FormattedMessage id="movStockView.render.loading_items.cargando" defaultMessage="Cargando..."/></div>;
	      return (
        	<React.Fragment>

        	{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}

    		<div className="row mt-2">
				<div className="col-12">

					<div className="card">
					<div className="card-header">
							<h1><FormattedMessage id="movStockView.render.order_data.header_datos_de_orden" defaultMessage="Datos de Orden:"/></h1>
						</div>
						<div className="card-content collpase show">
						<form id="datosOrden" onSubmit={this.ordenSubmit}>

							<div className="card-body card-dashboard">
								    <div className="row">
								     <div className="col-md-4">
							          		<div className="form-group">
									            <label htmlFor="message-text" className="col-form-label"><FormattedMessage id="movStockView.render.order_data.label_tipo_de_movimiento" defaultMessage="Tipo de movimiento:"/></label>
									            <input id="tipoMovimientoD" className="form-control" value={this.state.movimiento.tipoMovimiento} disabled/>
								          	</div>
						          		</div>

													{ almacen }


						          		<div className="col-md-3">
							          		<div className="form-group">
									            <label htmlFor="recipient-name" className="col-form-label"><FormattedMessage id="movStockView.render.order_data.label_tipo_de_documento" defaultMessage="Tipo de Documento:"/></label>
									            <input value={this.state.movimiento.tipoDocumento} type="text" className="form-control" id="formCodigo" name="tipoDocumento" disabled/>
									          </div>
							          	</div>
							        	<div className="col-md-3">
									          <div className="form-group">
									            <label htmlFor="recipient-name" className="col-form-label"><FormattedMessage id="movStockView.render.order_data.label_nro_documento" defaultMessage="Nro Documento:"/></label>
									            <input value={this.state.movimiento.numeroDocumento} type="text" className="form-control" id="formNombre" name="numeroDocumento" disabled/>
									          </div>
							          	</div>
							          	<div className="col-md-3">
							          		<div className="form-group">
									            	<label htmlFor="categoriaItem" className="col-form-label"><FormattedMessage id="movStockView.render.order_data.label_tipo_de_ingreso" defaultMessage="Tipo de Ingreso:"/></label>
										           	<select className="form-control" id="newTipoIngreso" name="tipoIngreso" disabled>
														 			<option>{this.state.movimiento.tipo} </option>
																</select>
									          </div>
							          	</div>
						          		<div className="col-md-3">
							          		<div className="form-group">
									            <label htmlFor="message-text" className="col-form-label"><FormattedMessage id="movStockView.render.order_data.label_motivo" defaultMessage="Motivo:"/></label>
									            <input type="text" className="form-control" name="motivo" id="formCosto" value={this.state.movimiento.motivo} disabled />
								          	</div>
								          	{/*<input type="hidden" name="items" value="" />*/}
						          		</div>
						          	</div>



							</div>

							 </form>
						</div>
					</div>
					<div className="card ">
					<div className="card-header">
							<h1><FormattedMessage id="movStockView.render.order_items.header_items_de_orden" defaultMessage="Items de orden:"/></h1>
						</div>
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<table class="table">
										<thead>
												<tr>
														<th scope="col"><FormattedMessage id="movStockView.render.order_items.table_column_numero" defaultMessage="#"/></th>
														<th scope="col"><FormattedMessage id="movStockView.render.order_items.table_column_stock_modificado" defaultMessage="Stock Modificado"/></th>
														<th scope="col"><FormattedMessage id="movStockView.render.order_items.table_column_costo_unitario" defaultMessage="Costo Unitario"/></th>
												</tr>
										</thead>
										<tbody>
											{this.state.movimiento.items.map((item) => {
												return <tr key={item.id}>
																		<th scope="row">{item.item.descripcion}</th>
																		<td>{item.cantidad} {item.unidad.abreviatura}</td>
																		<td>$ {item.item.costo}</td>
																</tr>
											})}
										</tbody>
								</table>
							</div>
            </div>
					</div>
				</div>
			</div>
		</React.Fragment>
            );
		//}
    }
}

export default injectIntl(MovStockView);
