import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import swal from 'sweetalert'
import OTImport from './OTImport';
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import Timezone from '../../commons/timezone/Timezone.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class OTGrid extends Component {
	constructor(props) {
		super(props);

		moment.locale('es');

		this.ajaxHandler = new AjaxHandler();

		this.columnsToPrint = [0,1,2,3,4,5,6,7,8,9,10];

		this.state = {
			redirectTo: null
		}
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('OT_LISTAR')) {
	      this.ajaxHandler.subscribe(this);
			  this.initGrid();
	    } else {
		    this.setState({
          redirectTo: '/error'
        });
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">
												{Security.renderIfHasPermission('OT_CREAR', (
												<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNew.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'oTGrid.render.button_add.title_nueva', defaultMessage: 'Nueva' })}>
													<i className="ft-plus"></i>
												</div>
												))}
											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className=""></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;

		Promise.all([
			this.ajaxHandler.getJson('/servicios/tipo-ticket/GESTORIA/select'),
      this.ajaxHandler.getJson('/gerenciadores/tipo/2/select'),
			this.ajaxHandler.getJson('/gerenciadores/tipo/3/select'),
			this.ajaxHandler.getJson('/ordenestrabajo/estados')
		]).then((data) => {
			let estados = data[3] ? data[3].map(e =>({value: e, label: e})) : [];
			let filters = {
				servicio: data[0] ? data[0] : [],
        gestor: data[1] ? data[1] : [],
				escribano: data[2] ? data[2] : [],
				estado: estados
			}

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					stateSave: true,
					stateSaveCallback: function(settings, data) {
						localStorage.setItem('DataTables_OTGrid', JSON.stringify(data));
					},
					stateLoadCallback: function(settings) {
						return JSON.parse(localStorage.getItem('DataTables_OTGrid'));
					},
					processing: true,
					serverSide: true,
					order: [[ 0, "desc" ]],
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + '/ordenestrabajo/grid',
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {
							component.ajaxHandler.handleResponseErrorsIsValid(xhr);
						},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_OTGrid')).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'oTGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'oTGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [{
						name: 'id',
						title: component.props.intl.formatMessage({ id: 'oTGrid.column_OT.label_OT', defaultMessage: 'OT' }),
						className: 'all',
						data: 'id',
						filterType: 'input'
					}, {
						name: 'servicio',
						title: component.props.intl.formatMessage({ id: 'oTGrid.column_service.label_servicio', defaultMessage: 'Servicio' }),
						className: 'all',
						data: 'servicio',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'gestor',
						title: component.props.intl.formatMessage({ id: 'oTGrid.column_gestor.label_gestor', defaultMessage: 'Gestor' }),
						className: 'all',
						data: 'gestor',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.razonSocial ? data.razonSocial : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'escribano',
						title: component.props.intl.formatMessage({ id: 'oTGrid.column_escribano.label_escribano', defaultMessage: 'Escribano' }),
						className: 'all',
						data: 'escribano',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.razonSocial ? data.razonSocial : '';
							}
						},
						filterType: 'select'
					}, {
						title: component.props.intl.formatMessage({ id: 'oTGrid.column_date.label_fecha', defaultMessage: 'Fecha' }),
						name: 'fecha',
						className: 'all',
						data: 'fecha',
						render: function(data, type, full, meta) {
							return data ? Timezone.getDateForClient(data, null, 'DD/MM/YYYY') : '';
						},
						filterType: 'input'
					}, {
						name: 'estado',
						title: component.props.intl.formatMessage({ id: 'oTGrid.column_state.label_estado', defaultMessage: 'Estado' }),
						className: 'all',
						data: 'estado',
						filterType: 'select'
					}, {
						orderable: false,
						data: null,
						className: "text-left all",
						width: '120px',
						render: function(data, type, full, meta) {
							let v_import = false;
							if (!full.servicio.vencimiento) {
								v_import = false;
							}
							else if (full.acciones.indexOf("GESTIONAR")!=(-1)) {
								v_import = false;
							}
							else if (full.acciones.indexOf("CERRAR")==(-1)) {
								v_import = false;
							} else {
								v_import = true;
							}
							let html =
								(Security.hasPermission('OT_VISUALIZAR') ? `
								<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'oTGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-search fa-xs"></i>
								</button>` : '') +
								(Security.hasPermission('OT_EXPORTAR') ? `
								<button class="action export btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'oTGrid.column_actions.button_title_exportar_tickets_a_excel', defaultMessage: 'Exportar tickets a Excel' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-download fa-xs"></i>
								</button>` : '') +
								(Security.hasPermission('OT_IMPORTAR') && v_import ? `
								<button class="action import btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'oTGrid.column_actions.button_title_importar_tickets_a_excel', defaultMessage: 'Importar tickets a Excel' })+`" data-togle="tooltip" data-placement="top" data-ot="${full.id}">
									<i class="fa fa-upload fa-xs"></i>
								</button>` : '');
							return html;
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('button').tooltip();
						},
						filterType: 'none'
					}],
					drawCallback: function() {
						$(this).find('.action').on('click', function() {
							let data = component.table.row($(this).parents('tr')).data();

							if ($(this).hasClass('view')) {
								component.setState({
									redirectTo: component.props.match.url + '/' + data.id
								});
							}

							if ($(this).hasClass('edit')) {
								component.setState({
									redirectTo: component.props.match.url + '/' + data.id + '/edit'
								});
							}

							if ($(this).hasClass('delete')) {
								swal({
									title: component.props.intl.formatMessage({ id: 'oTGrid.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
									text: null,
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'oTGrid.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'oTGrid.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								})
								.then((isConfirm) => {
									if (isConfirm) {
										fetch(Config.get('apiUrlBase') + '/ordenestrabajo/' + data.id, {
											method: 'DELETE',
											headers: {
												'Accept': 'application/json',
												'Content-Type': 'application/json',
												'Authorization-Token': localStorage.getItem("token")
											}
										})
										.then(function(response) {
											component.table.ajax.reload(null, false);
											swal(component.props.intl.formatMessage({ id: 'oTGrid.modal_delete.information_eliminado', defaultMessage: 'Eliminada!' }), "", "success");
										})
										.catch(function(error) {
											swal(component.props.intl.formatMessage({ id: 'oTGrid.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
										});
									}
								});
							}

							if ($(this).hasClass('approve')) {
								swal({
									title: component.props.intl.formatMessage({ id: 'oTGrid.modal_aprobar.confirma_aprobar', defaultMessage: 'Aprobar' }),
									text: component.props.intl.formatMessage({ id: 'oTGrid.modal_aprobar.confirma_que_desea_aprobar_la_OT', defaultMessage: '¿Confirma que desea aprobar la OT?' }),
									icon: "info",
									content: {
										element: "textarea",
										attributes: {
											placeholder: "Observaciones",
											id: 'confirm-observacionesCambioEstado'
										}
									},
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'oTGrid.modal_aprobar.confirma_aprobar.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'oTGrid.modal_aprobar.confirma_aprobar.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								}).then((isConfirm) => {
									if (isConfirm) {
										component.ajaxHandler.fetch('/ordenestrabajo/' + data.id + '/aprobar', {
											method: 'POST',
											body: JSON.stringify({
												observaciones: $('#confirm-observacionesCambioEstado').val()
											}),
											headers: {
												'Accept': 'application/json',
												'Content-Type': 'application/json',
												'Authorization-Token': localStorage.getItem("token")
											}
										}).then((response) => {
											if(response.status === 200) {
												component.table.ajax.reload(null, false);
												swal(component.props.intl.formatMessage({ id: 'oTGrid.modal_aprobar.information_ot_aprobada', defaultMessage: 'OT aprobada.' }), "", "success");
											}
										}).catch((error) => {
											component.ajaxHandler.handleError(error);
										});
									}
								});
							}

							if ($(this).hasClass('reject')) {
								swal({
									title: component.props.intl.formatMessage({ id: 'oTGrid.modal_reject.confirma_rechazar', defaultMessage: 'Rechazar' }),
									text: component.props.intl.formatMessage({ id: 'oTGrid.modal_reject.confirma_que_desea_rechazar_la_OT', defaultMessage: '¿Confirma que desea rechazar la OT?' }),
									icon: "warning",
									content: {
										element: "textarea",
										attributes: {
											placeholder: "Observaciones",
											id: 'confirm-observacionesCambioEstado'
										}
									},
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'oTGrid.modal_reject.confirma_rechazar.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'oTGrid.modal_reject.confirma_rechazar.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								}).then((isConfirm) => {
									if (isConfirm) {
										component.ajaxHandler.fetch('/ordenestrabajo/' + data.id + '/rechazar', {
											method: 'POST',
											body: JSON.stringify({
												observaciones: $('#confirm-observacionesCambioEstado').val()
											}),
											headers: {
												'Accept': 'application/json',
												'Content-Type': 'application/json',
												'Authorization-Token': localStorage.getItem("token")
											}
										}).then((response) => {
											if(response.status === 200) {
												component.table.ajax.reload(null, false);
												swal(component.props.intl.formatMessage({ id: 'oTGrid.modal_reject.information_ot_rechazada', defaultMessage: 'OT rechazada.' }), "", "success");
											}
										}).catch((error) => {
											component.ajaxHandler.handleError(error);
										});
									}
								});
							}

							if ($(this).hasClass('export')) {
								let filename = "";
								component.ajaxHandler.fetch('/ordenestrabajo/exportar-excel/' + data.id, {
								method: 'GET'
								}).then(response => {
									let disposition = response.headers.get('Content-Disposition');
									if (disposition && disposition.indexOf('attachment') !== -1) {
											let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
											let matches = filenameRegex.exec(disposition);
											if (matches != null && matches[1]) {
												filename = matches[1].replace(/['"]/g, '');
											}
									}
									if (response.status === 200) {
										return response.blob();
									}
								}).then(fileBlob => {
									let fileUrl = URL.createObjectURL(fileBlob);
									$("<a />", {
										href: fileUrl,
										download: filename
									}).appendTo("body").get(0).click();
								}).catch(() => {
								}).finally(() => {
								});
							}

							if ($(this).hasClass('import')) {
								component.setState({
									redirectTo: component.props.match.url + '/' + data.id + '/import'
								});
							}
						});
					}
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					name: 'filterReset',
					text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'oTGrid.tools_restore_filters.title_restaurar_filtros_y_ordenamiento', defaultMessage: 'Restaurar filtros y ordenamiento' }),
					action: function (e, dt, node, config) {
						var index = 0;
						component.table.columns().every(function() {
							var column = this;

							if (dt.settings()[0].aoColumns[index].filterType) {
								column.search('', true, false);
							}
							index++;
							return null;
						});
						$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
							$(element).val('');
						});
						dt.table().order([ 4, 'desc' ]);
						dt.ajax.reload();
					}
				}]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('filterReset:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			this.setState({
				loading: false
			});
		});
	}
}

export default injectIntl(OTGrid);