import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import Loading from '../ui/Loading.js'

import moment from 'moment'
import 'moment/min/locales'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import AtributoDinamico from '../atributoDinamico/AtributoDinamico.js'
import * as Constants from '../atributoDinamico/constants.js'
import { DragDropContext } from 'react-beautiful-dnd'
import { Droppable } from 'react-beautiful-dnd'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class TipoEquipamientoAbm extends Component {
	constructor(props) {
    super(props);
		this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				nombre: '',
        descripcion: '',
        activo: true,
        atributos_dinamicos: [],
      },
      estados: [{ id: 1, nombre: 'Activo'},{ id: 0, nombre: 'Inactivo'}],
      errors: [],
			loading: false,
			mostrarInactivos: true,
    };

    this.handleEstadoChange = this.handleEstadoChange.bind(this);
		this.handleChangeAtributoDinamico = this.handleChangeAtributoDinamico.bind(this);
		this.handleAddAtributoDinamico = this.handleAddAtributoDinamico.bind(this);
		this.handleDeleteAtributoDinamico = this.handleDeleteAtributoDinamico.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);


    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.nombre': (value) => Validator.notEmpty(value)
			}
    });

  }

	onDragEnd(result) {
			this.setState({ loading: true });
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			let atributos_dinamicos = formDataCopy.atributos_dinamicos
			let atributoEnMovimiento = atributos_dinamicos[result.source.index]
			atributos_dinamicos.splice(result.source.index, 1);
			atributos_dinamicos.splice(result.destination.index, 0, atributoEnMovimiento);
			this.setState({formData: formDataCopy}, () =>	this.setState({ loading: false }))
	}

  initForm() {
    this.setState({ loading: true });
    let component = this;

		Promise.all([
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/tipoequipamiento/' + this.state.props.match.params.id) : null,
		]).then((data) => {
      let formDataNew = data[0];
			if(formDataNew) {
				formDataNew.atributos_dinamicos = formDataNew.atributos_dinamicos.map(atributo => {
					atributo.value = atributo.valorDefault
					return atributo
				})
				component.setState({
					formData: formDataNew
				});
			} else
				this.handleAddAtributoDinamico()
    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });
  }
	componentWillMount() {

        if ((Security.hasPermission('EQUIPAMIENTO_TIPOS_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('EQUIPAMIENTO_TIPOS_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('EQUIPAMIENTO_TIPOS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
        }
	}
  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}


  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activo'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }


	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }


	handleAddAtributoDinamico() {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		let templateAtributoCopy = JSON.parse(JSON.stringify(Constants.TEMPLATE_ATRIBUTO));
		formDataCopy.atributos_dinamicos.push(templateAtributoCopy);
		this.setState({
			formData: formDataCopy
		});
	}

	handleDeleteAtributoDinamico(index) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.atributos_dinamicos.splice(index, 1)
		this.setState({
			formData: formDataCopy
		});
	}


	handleChangeAtributoDinamico(index, key, value) {
		let atributoDinamico = this.state.formData.atributos_dinamicos[index]
		atributoDinamico[key] = value;
		if (key == 'value')
			atributoDinamico.valorDefault = value;
		this.setState({
			formData: this.state.formData
		});
	}

	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
		this.state.formData.atributos_dinamicos.map((atributo, index) => {
			atributo.order = index
			return atributo
		})
    this.ajaxHandler.fetch('/tipoequipamiento' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(response.status !== 400) {

        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/tipoequipamiento'
		});
	}

	render() {
    let state = this.state;
    this.formValidation.validate();
		let formData = this.state.formData;
    let validationState = this.formValidation.state;

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="tipoEquipamientoAbm.general_data.label_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="tipoEquipamientoAbm.general_data.campos_requeridos" defaultMessage="* campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* Nombre */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                              <FormattedMessage id="tipoEquipamientoAbm.general_data.label_nombre" defaultMessage="Nombre *:"/>
                            </label>
                            <div className="col-md-9">
	                              <input disabled={this.props.action === 'VIEW'} type="text" className="form-control" id="nombre" name="nombre" placeholder={this.props.intl.formatMessage({ id: 'tipoEquipamientoAbm.general_data.placeholder_nombre', defaultMessage: 'Modelo' })} value={formData.nombre} onChange={this.handleInputFormChange} />
	                              <div className="help-block text-danger field-message" hidden={validationState.formData.nombre.pristine || validationState.formData.nombre.valid}>{validationState.formData.nombre.message}</div>
                            </div>
                          </div>
                        </div>
                      </div>

											<div className="row">
                        {/* Descripción */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                              <FormattedMessage id="tipoEquipamientoAbm.general_data.label_descripcion" defaultMessage="Descripción:"/>
                            </label>
                            <div className="col-md-9">
	                              <textarea disabled={this.props.action === 'VIEW'} className="form-control" id="descripcion" name="descripcion" value={formData.descripcion} onChange={this.handleInputFormChange}>
																</textarea>
                            </div>
                          </div>
                        </div>
                      </div>


											<div className="row">
                        {/* ESTADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage id="tipoEquipamientoAbm.general_data.label_activo" defaultMessage="Activo:"/>
                            </label>
                            <div className="col-md-9">
                              <Switch
                                onChange={this.handleEstadoChange}
                                checked={formData.activo}
                                options={this.state.estados}
                                id="activo"
                                name="activo"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
								{!this.state.loading &&

									<DragDropContext
										onDragEnd={this.onDragEnd}
									>
										<Droppable droppableId={"1"}>
											{(provided, snapshot) =>
													<div
														ref={provided.innerRef}
														{...provided.droppableProps}
														style={snapshot.isDraggingOver ? {"background" : "lightblue"} : {}}
													>


													{this.state.formData.atributos_dinamicos
														.map((atributoDinamico, index)  => (
														(atributoDinamico && (atributoDinamico.activo || this.state.mostrarInactivos)) &&
														<AtributoDinamico
															mostrarInactivos={this.state.mostrarInactivos}
															handleDelete={this.handleDeleteAtributoDinamico}
															key={index}
															index={index}
															previsualizacion={true}
															modulo={'equipamiento'}
															disabled={this.state.props.action === 'VIEW'}
															draggable={false}
															handleChange={this.handleChangeAtributoDinamico}
															formulario={atributoDinamico}
														/>
													))}
													{provided.placeholder}
												</div>
											}
										</Droppable>

									</DragDropContext>
							  }

                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
												<>
													<button  type="button" className="btn btn-success pull-left mr-1" onClick={this.handleAddAtributoDinamico} >
	                          <i className="fa fa-plus"></i><FormattedMessage id="tipoEquipamientoAbm.add_dynamic_form.button" defaultMessage=" Agregar Atributo Dinámico"/>
	                        </button>
	                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
	                          <i className="fa fa-check-circle"></i><FormattedMessage id="tipoEquipamientoAbm.button_finish.guardar" defaultMessage=" Guardar"/>
	                        </button>
                        </>)}
	                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
	                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'tipoEquipamientoAbm.button_finish.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'tipoEquipamientoAbm.button_finish.cancelar', defaultMessage: 'Cancelar' })}
	                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TipoEquipamientoAbm);
