import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Security from '../../commons/security/Security.js'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import Switch from "react-switch"
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js'

const defaultSelect = [{id:0, label:"Días", value:0},{id:1, label:"Mes", value:1},{id:2, label:"Año", value:2}];

class PreventivosAbm extends Component {
	constructor(props) {
		super(props);

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        servicio: null,
        movil: null,
        realizarALos: null,
        realizarEl: null,
        comentario: '',
        ticket: null,
        activo: true
      },
      realizarCadaTipo: [],
      selectLoadingTime1: false,
      selectLoadingTime1: false,
      serviciosLoading: false,
      errors: [],
      loading: false
    };

    this.handleActivoChange = this.handleActivoChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleFormChangeSelectString = this.handleFormChangeSelectString.bind(this);
    this.handleFormChangeSelectObject = this.handleFormChangeSelectObject.bind(this);
    this.handleMovilChange = this.handleMovilChange.bind(this);
    this.getBackendMoviles = this.getBackendMoviles.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
    this.getServicios = this.getServicios.bind(this);
    this.handleServicioChange = this.handleServicioChange.bind(this);

    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.servicio': (value) => Validator.notEmpty(value),
        'formData.movil': (value) => Validator.conditionalNotEmpty(this.state.formData.servicio && this.state.formData.servicio.vencimientoAplicaA === 'MOVIL', value),
        'formData.realizarALos': (value) => Validator.intNumber(value)
			}
    });
  }

  componentDidMount() {
    if((Security.hasPermission('PREVENTIVOS_CREAR') && this.state.props.action === 'ADD') ||
       (Security.hasPermission('PREVENTIVOS_MODIFICAR') && this.state.props.action === 'EDIT') ||
       (Security.hasPermission('PREVENTIVOS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: '/error'
      });
    }
  }
	componentWillMount() {
		this.ajaxHandler.subscribe(this);
	}

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  initForm() {
    let component = this;
    if(this.state.props.action === 'VIEW') {
      this.loadFormData();
      component.setState({
        ...this.state.formData,
        realizarCadaTipo: 0,
        generarCadaTipo: 0,
      });
    }

    if(this.state.props.action === 'ADD' || this.state.props.action === 'EDIT') {
      this.setState({
        loading: this.state.props.action === 'EDIT'
      });

      if(component.state.props.action === 'EDIT') this.loadFormData();
    }

    window.scrollTo(0, 0);
  }

  loadFormData() {
    let component = this;
    this.setState({ loading: true });
    this.ajaxHandler.getJson('/preventivos/' + this.state.props.match.params.id)
    .then(data => {
      if(data){
        component.setState({
          formData: Object.assign(this.state.formData, data)
        });
      }
    }).finally(() => {
      // this.filtroServicios(this.state.formData.movil.unidadMedidorId);
      this.setState({ loading: false });
    });
  }

  handleFormChangeSelectString(name, object) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = object === null ? '' : object.value;
    this.setState({
      formData: formDataCopy
    });
  }

  handleFormChangeSelectObject(name, object) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = object ? { id: object.value } : null;
    this.setState({
      formData: formDataCopy
    });
  }

  handleMovilChange(movil) {
    let component = this;
    console.log(movil);
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["movil"] = movil;
    formDataCopy["servicio"] = null;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleServicioChange(object) {
    let component = this;

    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    const momentCadaTipo = ['days','months','years'];
    if(object) {
      this.ajaxHandler.getJson('/servicios/' + object.value)
      .then((data) => {
        formDataCopy.servicio = data;
        formDataCopy.realizarEl = data.intervalo_temporal ? moment().add(data.intervaloTemporalCada, momentCadaTipo[data.intervaloTemporalCadaTipo]) : null;
        this.setState({
          formData: formDataCopy
        });
      }).catch(function(error) {
        component.ajaxHandler.handleError(error);
      });
    } else {
      formDataCopy.servicio = null;
      this.setState({
        formData: formDataCopy
      });
    }
  }

  getServicios(){
    let unidadMedidorId = this.state.formData.movil ? this.state.formData.movil.unidadMedidorId : null;
    this.setState({ loading: true });
      if (!this.component)
        this.ajaxHandler.subscribe(this);
      return this.ajaxHandler.getJson(`/servicios/tipo-ticket/PREVENTIVO/select?unidad_medidor_id=${unidadMedidorId}`)
    .then(data => {
      return {
          options: data,
          complete: true
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleActivoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.activo = activo;
		this.setState({
			formData: formDataCopy
    });
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleForzar (){
    this.setState({
      loading: true
    }, () => {
      let component = this
      this.ajaxHandler.fetch('/preventivos/forzar/' + this.state.formData.id, {
        method: 'POST'
      }).then(response => {
        if(response.status !== 400) {
          component.exit();
        } else {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        }
        window.scrollTo(0,0);
      }).catch((error) => {
        this.ajaxHandler.handleError(error);
      }).finally(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleNumericChange(e) {
    let name = e.target.name;
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = (e.target.validity.valid) ? e.target.value : this.state.formData[name];
    this.setState({
      formData: formDataCopy
    });
  }

  getBackendMoviles(searchTerm) {
    return this.ajaxHandler.getJson("/moviles/simple-search?search=" + searchTerm).then((res) => {
      return {
        options: res,
        complete: true,
      };
    });
  }

	handleSubmit(event) {
    this.setState({ loading: true });
      let component = this;
      this.state.formData.movil_id = this.state.formData.movil ? this.state.formData.movil.id : null;
      this.state.formData.servicio_id = this.state.formData.servicio ? this.state.formData.servicio.id : null;


      this.ajaxHandler
      .fetch('/preventivos' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
        method: this.props.action === 'ADD' ? 'POST' : 'PUT',
        body: JSON.stringify({
          ...this.state.formData
        }),
      })
      .then(response => {
        if(response.status !== 400) {
          component.exit();
        } else {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        }
        window.scrollTo(0,0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
  }

  handleDatePickerFormChange(name, event) {
    let nuevaFecha = "";
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.realizarEl = event;
    this.setState({
      formData: formDataCopy
    });
  }

  handleDatePickerFormRawChange(name, date) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
  }

	exit() {
		this.setState({
			redirectTo: '/preventivos'
    });
	}

	render() {
    this.formValidation.validate();
    let state = this.state;
		let formData = this.state.formData;
    let validationState = this.formValidation.state;
    let requiredSymbol = state.props.action !== 'VIEW' ? ' *' : '';
    // let servicio = formData.servicio ? state.servicios.find(e => e.value === formData.servicio.id) : null;

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-hourglass-2"></i><FormattedMessage id="preventivosAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>* { this.props.action === 'VIEW' ? '' : this.props.intl.formatMessage({ id: 'preventivosAbm.render.general_data.campos_requeridos', defaultMessage: 'campos requeridos' }) }</div>
                      </h4>

                      <div className="row">
                        {/* MOVIL */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="movil">
                              <FormattedMessage id="preventivosAbm.render.general_data.label_movil" defaultMessage="Móvil"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.movil && formData.movil.dominio ? formData.movil.dominio : ''}</div>
                              ) : (
                              <div>
                               <Select.Async
                                    name="storageTypeId"
                                    value={this.state.formData.movil}
                                    valueKey="id"
                                    labelKey="dominio"
                                    loadOptions={this.getBackendMoviles}
                                    onChange={this.handleMovilChange}
                                    disabled={this.props.action === "VIEW"}
                                  />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* BASE */}
                        {formData.movil ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label">
                              <FormattedMessage id="preventivosAbm.render.general_data.label_base" defaultMessage="Base:"/>
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">{formData.movil && formData.movil.base ? formData.movil.base.descripcion : ''}</div>
                            </div>
                          </div>
                        </div>
                        ): null}
                      </div>

                      <div className="row">
                        {/* SERVICIO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="servicio">
                              <FormattedMessage id="preventivosAbm.render.general_data.label_servicio" defaultMessage="Servicio"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.servicio && formData.servicio.nombre ? formData.servicio.nombre : ''}</div>
                              ) : (
                              <div>
                                <Select.Async
                                    name="servicio"
                                    id="servicio"
                                    placeholder={this.props.intl.formatMessage({ id: 'preventivosAbm.render.general_data.placeholder_servicio', defaultMessage: 'Seleccione' })}
                                    value={formData.servicio ? { value: formData.servicio.id, label: formData.servicio.nombre } : null}
                                    valueKey="value"
                                    labelKey="label"
                                    cache={false}
                                    loadOptions={this.getServicios}
                                    onChange={this.handleServicioChange}
                                    isLoading={state.serviciosLoading}
                                    disabled={this.props.action === "VIEW" || !this.state.formData.movil}
                                    key={this.state.formData.movil ? this.state.formData.movil.unidad_medidor_id : 0}
                                  />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.servicio.pristine || validationState.formData.servicio.valid}>{validationState.formData.servicio.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* KM ACTUAL */}
                        { formData.movil ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="kmActual">
                              { formData.movil && formData.movil.unidadMedidorId && UNIDAD_MEDIDOR[formData.movil.unidadMedidorId]['label'] === 'ODOMETRO' ? (
                                <FormattedMessage id="preventivosAbm.render.general_data.label_km_actual" defaultMessage="Km. Actual:"/>
                              ) : ''}
                              { formData.movil && formData.movil.unidadMedidorId && UNIDAD_MEDIDOR[formData.movil.unidadMedidorId]['label'] === 'HOROMETRO' ? (
                                <FormattedMessage id="preventivosAbm.render.general_data.label_horometro_actual" defaultMessage="Horometro Actual:"/>
                              ):''}
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">{Math.floor(formData.movil.kmActual)}</div>
                            </div>
                          </div>
                        </div>
                        ) : null }
                      </div>

                      <div className="row">
                        {/* REALIZAR A LOS */}
                        { formData.movil ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="realizarALos">
                            { formData.movil && formData.movil.unidadMedidorId && UNIDAD_MEDIDOR[formData.movil.unidadMedidorId]['label'] === 'ODOMETRO' ? (
                              <FormattedMessage id="preventivosAbm.render.general_data.label_realizar_a_los_km" defaultMessage="Realizar a los :"/>
                              ) : '' }{requiredSymbol}:
                            { formData.movil && formData.movil.unidadMedidorId && UNIDAD_MEDIDOR[formData.movil.unidadMedidorId]['label'] === 'HOROMETRO' ? (
                              <FormattedMessage id="preventivosAbm.render.general_data.label_realizar_a_las_horas" defaultMessage="Realizar a las *:"/>

                              ): '' }
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.realizarALos ? formData.realizarALos : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" pattern="[0-9]*" id="realizarALos" name="realizarALos" placeholder={this.props.intl.formatMessage({ id: 'preventivosAbm.render.general_data.placeholder_realizar_a_los_km', defaultMessage: 'Sólo números' })} value={formData.realizarALos ? formData.realizarALos : ''}  onInput={this.handleNumericChange.bind(this)} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.realizarALos.pristine || validationState.formData.realizarALos.valid}>{validationState.formData.realizarALos.message}</div>
                              </div>
                              )}

                            </div>
                            <label className="col-md-2 label-control col-form-label text-nowrap" htmlFor="realizarALosTipo">{  formData.movil && formData.movil.unidadMedidorId && UNIDAD_MEDIDOR[formData.movil.unidadMedidorId]['unidades'] }</label>
                          </div>
                        </div>
                        ): null }

                        {/* REALIZAR EL */}
                        {  formData.servicio && formData.servicio.intervalo_temporal ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="realizarEl">
                                  <FormattedMessage id="preventivosAbm.render.general_data.label_realizar_el" defaultMessage="Realizar el"/>{requiredSymbol}:
                                </label>
                                <div className="col-md-8">
                                  <DatePicker
                                    id="realizarEl"
                                    name="realizarEl"
                                    className="form-control date-picker-placeholder"
                                    selected={formData.realizarEl === null || formData.realizarEl === '0000-00-00' ? null : moment(formData.realizarEl)}
                                    placeholderText={this.props.intl.formatMessage({ id: 'preventivosAbm.render.general_data.fecha.placeholder', defaultMessage: 'DD/MM/AAAA' })}
                                    onChange={(event) => this.handleDatePickerFormChange("realizarEl", event)}
                                    onChangeRaw={(event) => this.handleDatePickerFormRawChange("realizarEl", event.target.value)}
                                    minDate={moment()}
                                    dateFormat="L"
                                    disabled={this.props.action === 'VIEW'}
                                  />
                                </div>
                          </div>
                        </div>
                        ) : ''}
                      </div>

                      <div className="row">
                        {/* SERVICIO PREVENTIVO CADA */}
                        { formData.servicio ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="cada">
                              <FormattedMessage id="preventivosAbm.render.general_data.label_cada" defaultMessage="Preventivo cada"/>
                            </label>
                            <div className="col-md-6">
                              <div>
                                <div className="form-control-static col-form-label form-value">{ formData.servicio.preventivo_cada ? formData.servicio.preventivo_cada : '' }</div>
                              </div>
                            </div>
                            <label className="col-md-2 label-control col-form-label text-nowrap" htmlFor="preventivoCadaTipo"> { UNIDAD_MEDIDOR[formData.servicio.unidad_medidor_id]['unidades'] } </label>
                          </div>
                        </div>
                        ):''}

                        {/* SERVICIO INTERVALO TEMPORAL CADA */}
                        <div className="col-md-6">
                        { formData.servicio && formData.servicio.intervalo_temporal ? (
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="intervaloTemporalCada">
                              <FormattedMessage id="preventivosAbm.render.general_data.label_intervalo_temporal_cada" defaultMessage="Intervalo cada"/>
                            </label>
                            <div className="col-md-5">
                              <div>
                                <div className="form-control-static col-form-label form-value">{formData.servicio.intervalo_temporal_cada}</div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <Select
                                  placeholder={!state.serviciosLoading ? this.props.intl.formatMessage({ id: 'preventivosAbm.render.general_data.placeholder_intervalo_temporal_cada_tipo', defaultMessage: 'Seleccione' }) : ''}
                                  valueKey='value'
                                  labelKey='label'
                                  value={defaultSelect[formData.servicio.intervalo_temporal_cada_tipo]}
                                  isLoading={state.selectLoadingTime1}
                                  readOnly={true}
                                  disabled={true}
                                />
                            </div>
                          </div>
                        ) : '' }
                        </div>
                      </div>

                      { formData.servicio ? (
                        <div className="row">
                          {/* SERVICIO ALERTAR A LOS KM */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="generarTktAntes">
                                <FormattedMessage id="preventivosAbm.render.general_data.label_generar_tkt_antes" defaultMessage="Generar Tkt antes"/>
                              </label>
                              <div className="col-md-6">
                                <div className="form-control-static col-form-label form-value">{ formData.servicio.preventivo_alertar_previos ? formData.servicio.preventivo_alertar_previos : '' }</div>
                              </div>
                              <label className="col-md-2 label-control col-form-label text-nowrap" htmlFor="preventivoAlertarPreviosTipo"> { UNIDAD_MEDIDOR[formData.servicio.unidad_medidor_id]['unidades'] } </label>
                            </div>
                          </div>

                        {/* SERVICIO ALERTAR INTERVALO TEMPORAL */}
                        <div className="col-md-6">
                        { formData.servicio.intervalo_temporal ? (
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="intervaloTemporalAlertar ">
                              <FormattedMessage id="preventivosAbm.render.general_data.label_intervalo_temporal_alertar" defaultMessage="Generar Tkt antes"/>
                            </label>
                              <div className="col-md-5">
                                <div className="form-control-static col-form-label form-value">{formData.servicio.intervalo_temporal_alertar}</div>
                              </div>
                              <div className="col-md-3">
                              <Select
                                    placeholder={!state.serviciosLoading ? this.props.intl.formatMessage({ id: 'preventivosAbm.render.general_data.placeholder_intervalo_temporal_alertar_tipo', defaultMessage: 'Seleccione' }) : ''}
                                    valueKey='value'
                                    labelKey='label'
                                    value={defaultSelect[formData.servicio.intervalo_temporal_alertar_tipo]}
                                    isLoading={state.selectLoadingTime2}
                                    readOnly={true}
                                    disabled={true}
                                  />
                              </div>
                            </div>
                          ) :''}
                          </div>
                        </div>
                      ) :''}

                      <div className="row" style={{display:'none'}}>
                        {/* COMENTARIOS */}
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-md-2 label-control col-form-label" htmlFor="comentario">
                              <FormattedMessage id="preventivosAbm.render.general_data.label_detalle" defaultMessage="Detalle:"/>
                            </label>
                            <div className="col-md-10">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.comentario ? formData.comentario : ''}</div>
                              ) : (
                              <div>
                                <textarea className="form-control" id="comentario" name="comentario" rows="3" placeholder="" value={formData.comentario ? formData.comentario : ''} onChange={this.handleInputFormChange}></textarea>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* ACTIVO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="activo">
                              <FormattedMessage id="preventivosAbm.render.general_data.label_activo" defaultMessage="Activo:"/>
                            </label>
                            <div className="col-md-8 mt-auto">
                              <Switch
                                onChange={this.handleActivoChange}
                                checked={formData.activo ? formData.activo : false}
                                id="activo"
                                name="activo"
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
                          <i className="fa fa-check-circle"></i><FormattedMessage id="preventivosAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'preventivosAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'preventivosAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(PreventivosAbm);
