
let pdfMake = require('pdfmake/build/pdfmake.js')
let pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

export const handleImprimir = (logo, ticket, tareasState, t) => {
  const movil = ticket.movil || null;
  let tareas = []
  let reparaciones = [[t['reparaciones_realizadas']]];// Primer fila que funciona como encabezado

  if (tareasState.length > 0) {
    tareasState.forEach(function (tarea) {
      var rowTarea = []
      rowTarea.push(tarea.nombre)
      tareas.push(rowTarea)

      var rowRep = []
      rowRep.push(tarea.servicio.nombre + ' - ' + tarea.nombre)
      reparaciones.push(rowRep)
    })
  } else {
    tareas.push([''])
    reparaciones.push([''])
  }

  /* COMPILACION DE TODOS LOS DATOS A INCLUIR EN EL DOCUMENTO */
  const ticketId = ticket.id
  const ticketEstado = ticket.estado
  const firmaResponsable = movil && movil.supervisor ? movil.supervisor.apellido + ', ' + movil.supervisor.nombre : t['firmaAclaracionResponsable']
  const dominio = movil && movil.dominio || ''
  const marcaMovil = movil && movil.marca ? movil.marca.nombre : ''
  const modeloMovil = movil && movil.modelo ? movil.modelo.nombre : ''
  const anio = movil && movil.anio || ''
  const tipoCombustible = (movil && movil.movilCombustibleTipo && movil.movilCombustibleTipo.descripcion) || ''
  const kilometraje = ticket.kmGenerado || ''
  const servicio = ticket.servicio && ticket.servicio.nombre || ''
  const ubicacion = movil && movil.base ? movil.base.descripcion : ''
  const fechaTermTrabajo = ticket.fechaHoraRealizado ? ticket.fechaHoraRealizado.slice(0, -8) : ''
  const kmSalidaTaller = ticket.kmRealizado > 0 ? ticket.kmRealizado : ''
  const base = (ticket.base && ticket.base.descripcion) || (movil && movil.base && movil.base.descripcion) || ''
  const fechaIngreso = ticket.fechaHoraAlta ? ticket.fechaHoraAlta.slice(0, -8) : ''
  const unidad = movil && movil.unidad || ''
  const fechaEstimadaEntrega = ticket.fechaPrometida ? ticket.fechaPrometida.slice(0, -8) : ''
  const detalles = ticket.detalle || ''

  let proveedor = ''
  let tallerContacto = ''
  let tallerTelefono = ''
  let tallerDireccion = ''

  if (ticket.taller) {
    proveedor = ticket.taller.razonSocial || ''
    tallerContacto = ticket.taller.responsable || ''
    tallerTelefono = ticket.taller.telefono || ''

    const calle = ticket.taller.direccion
    const nro = ticket.taller.numero
    const localidad = ticket.taller.localidad
    const provincia = ticket.taller.provincia && ticket.taller.provincia.nombre
    const pais = ticket.taller.pais && ticket.taller.pais.nombre


    tallerDireccion = calle
    if (nro) tallerDireccion += ` ${nro}`
    if (localidad) tallerDireccion += `, ${localidad}`
    if (provincia) tallerDireccion += `, ${provincia}`
    if (pais) tallerDireccion += `, ${pais}`
    tallerDireccion += '.'

  }



  /* SETTINGS GENERALES DEL DOCUMENTO */
  const pageSize = { width: 595, height: 842 }
  const pageMargins = [20, 20, 20, 20]
  const defaultStyles = { alignment: 'left', fontSize: 8 }
  const styles = {
    tableHeader: {
      fontSize: 10, 
      bold: true,
      alignment: 'center'
    },
    flat: {
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0]
    }
  }


  /* SECCIONES QUE COMPONEN EL DOCUMENTO */

  const datosGenerales = [
    [
      { image: logo, fit: [100, 100], style: 'tableHeader' },
      { text: t['orden_reparacion'], style: 'tableHeader' },
      { text: base, style: 'tableHeader', fontSize: 8, bold: false },
      { rowSpan: 2, text: t['orden_reparacion']+' \n' + ticketId, style: 'tableHeader', margin: 10 }
    ],
    [{
      colSpan: 3,
      layout: 'noBorders',
      table: {
        widths: ['40%', '60%'],
        body: [[t['fecha_ingreso']+`: ${fechaIngreso}`, '']],
      }
    },
      ''
    ],
    [{
      colSpan: 3,
      layout: 'noBorders',
      table: {
        widths: ['40%', '60%'],
        body: [[`${t['marca']}: ${marcaMovil}`, `${t['modelo']}: ${modeloMovil}`]],
      }
    },
      '',
      '',
      { text: t['patente']+ '\n' + unidad, alignment: 'center' },      
    ],
    [{
      colSpan: 3,
      layout: 'noBorders',
      table: {
        widths: ['40%', '60%'],
        body: [[`${t['servicio']}: ${servicio}`, `${t['anio']}: ${anio}`]],
      }
    },
      '',
      '',
    { text: t['dominio'] + '\n' + dominio, alignment: 'center' },
    ],
    [{
      colSpan: 3,
      border: [true, true, false, false],
      layout: 'noBorders',
      table: {
        widths: ['40%', '60%'],
        body: [[`CANT.COMBUSTIBLE (${tipoCombustible}):`, '']]
      }
    },
      '',
      '',
    { border: [false, true, true, false], text: ' ' }
    ],
    [{
      colSpan: 3,
      border: [true, true, false, false],
      layout: 'noBorders',
      table: {
        widths: ['40%', '60%'],
        body: [[`${t['ubicacion']}: ${ubicacion}`, `${t['kilometraje']}: ${kilometraje}`]],
      }
    },
      '',
      '',
    { border: [false, true, true, false], text: ' ' }
    ],
    [{
      colSpan: 3,
      border: [true, true, false, false],
      layout: 'noBorders',
      table: {
        widths: ['40%', '60%'],
        body: [[`${t['proveedor']}: ${proveedor}`, `${t['fecha_estimada_entrega']}: ${fechaEstimadaEntrega}`]]
      }
    },
      '',
      '',
    { border: [false, true, true, false], text: ' ' }
    ],
    [{
      colSpan: 4,
      border: [true, true, true, false],
      layout: 'noBorders',
      table: {
        widths: ['*', '*', '50%'],
        body: [[`${t['contacto']}: ${tallerContacto}`, `${t['telefono']}: ${tallerTelefono}`, `${t['direccion']}: ${tallerDireccion}`]]
      }
    },
      '',
      '',
      ''
    ]
  ]

  const ticketDetalle = [{
    colSpan: 4,
    text: `${t['detalles']} ${detalles}`, 
    alignment: 'left',
    border: [true, true, true, false]
  }]

  const listadoDeTareas = [
    [{
      colSpan: 4,
      text: t['seSolicitaReparacion'], 
      alignment: 'left',
      border: [true, true, true, false],
      heights: 10,
      margin: [0, 5, 0, 0]
    }], // Encabezado
    [{
      colSpan: 4,
      border: [true, false, true, false],
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0 : 1
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0 : 0
        }
      },
      heights: 15,
      table: { widths: ['100%'], dontBreakRows: true, body: tareas }
    }]
  ]

  const checkBoxes = (firmante, adjunto, isFecha) => {

    let RUadjunto = adjunto ? { border: [true, false, true, true], alignment: 'center', text: t['ruAdjunto'] } : { border: [true, false, true, false], text: '' };
    let pie = isFecha ? { border: [true, true, true, true], text: t['fechaFormato'] } : { border: [true, true, true, true], text: t['firmaAclaracion'] , alignment: 'center' };

    const getVarios = (cant, obj) => Array.from({ length: cant }, () => ({ ...obj }))

    return {

      table: {
        widths: ['13%', '10%', '14%', '10%', '10%', '11%', '10%', '22%'],
        headerRows: 8,
        dontBreakRows: true,
        keepWithHeaderRows: 1,
        heights: (row) => { return (row == 6 ? 20 : 10) },
        body: [
          [
            { border: [true, true, false, false], text: ' ' },
            ...getVarios(4, { border: [false, true, false, false], text: ' ' }),
            { border: [false, true, false, false], text: t['abollado'], fontSize: 9 },
            { border: [true, true, true, true], text: ' ' },
            {
              rowSpan: 8,
              border: [true, true, true, false],
              alignment: 'left',
              padding: [0, 0, 0, 0],
              text: firmante,
              fontSize: 10
            }
          ],
          [
            {border: [true, false, false, false], text: t['ruedaAuxilio']},
            { border: [true, true, true, true], text: ' ' },
            { border: [false, false, false, false], text: t['llaveDeRueda'] },
            { border: [true, true, true, true], text: ' ' },
            { border: [false, false, false, false], text: ' ' },
            { border: [false, false, false, false], text: t['raspon'], fontSize: 9 },
            { border: [true, true, true, true], text: ' ' },
            { border: [false, false, false, false], text: ' ' }
          ],
          [
            { border: [true, false, false, false], text: t['crique'] },
            { border: [true, true, true, true], text: ' ' },
            { border: [false, false, false, false], text: t['baliza'] },
            { border: [true, true, true, true], text: ' ' },
            ...getVarios(4, { border: [false, false, false, false], text: ' ' })
          ],
          [
            { border: [true, false, false, false], text: t['matafuego'] },
            { border: [true, true, true, true], text: ' ' },
            { border: [false, false, false, false], text: t['botiquin'] },
            { border: [true, true, true, true], text: ' ' },
            ...getVarios(4, { border: [false, false, false, false], text: ' ' })
          ],
          [
            { border: [true, false, false, false], text: t['cubreAlfombra'], fontSize: 7 },
            { border: [true, true, true, true], text: ' ' },
            { border: [false, false, false, false], text: t['tarjetaComb.'] },
            { border: [true, true, true, true], text: ' ' },
            ...getVarios(4, { border: [false, false, false, false], text: ' ' })
          ],
          [
            { border: [true, false, false, false], text: t['estereo'] },
            { border: [true, true, true, true], text: ' ' },
            { border: [false, false, false, false], text: t['telepeajeS'] },
            { border: [true, true, true, true], text: ' ' },
            ...getVarios(4, { border: [false, false, false, false], text: ' ' })
          ],
          [
            { border: [true, false, false, false], text: ' ' },
            {
              margin: 5,
              colSpan: 2,
              border: [false, false, false, false],
              text: t['presionNeumaticos'],
              bold: true
            },
            ...getVarios(6, { border: [false, false, false, false], text: ' ' })
          ],
          [
            {border: [true, false, false, false], text: t['delDerecho']},
            { border: [true, true, true, true], text: ' ' },
            { border: [false, false, false, false], text: t['trasDerecho'] },
            { border: [true, true, true, true], text: ' ' },
            ...getVarios(4, { border: [false, false, false, false], text: ' ' })
          ],
          [
            { border: [true, false, false, false], text: t['delIzquierdo'] },
            { border: [true, true, true, true], text: ' ' },
            { border: [false, false, false, false], text: t['trasIzquierdo'] },
            { border: [true, true, true, true], text: ' ' },
            ...getVarios(3, { border: [false, false, false, false], text: ' ' }),
            RUadjunto
          ],
          [
            { border: [true, false, false, true], text: ' ' },
            ...getVarios(6, { border: [false, false, false, true], text: ' ' }),
            pie
          ]
        ]
      }
    }
  }

  const detalleSalidaTaller = [{
    table: {
      widths: '*',
      heights: [20],
      body: [
        [
          { text: `${t['fechaTermTrabajo']} ${fechaTermTrabajo}`, border: [true, false, false, true], margin: [0, 5, 0, 0] },
          { text: `${t['kmSalidaTaller']} ${kmSalidaTaller}`, border: [false, false, false, true], margin: [0, 5, 0, 0] },
          { text: `${t['combustibleIngresoTaller']}`, border: [false, false, true, true], margin: [0, 5, 0, 0] }
        ]
      ]
    }
  }]

  const listadoDeReparaciones = {

    table: {
      widths: '*',
      dontBreakRows: true,
      body: [
        [
          {
            layout: {
              hLineWidth: function (i, node) {
                return i === 0 || i === 1 || i === node.table.body.length ? 0 : 1
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 0 : 0
              }
            },
            border: [true, false, true, false],
            heights: 15,
            table: {
              widths: '*',
              dontBreakRows: true,
              body: reparaciones
            }
          }
        ]
      ]
    }
  }


  const observaciones = {
    table: {
      widths: ['*'],
      heights: 100,
      body: [[{ text: t['observaciones'], border: [true, false, true, true] }]]
    },
    defaultStyle: { fontSize: 8 }
  }


  /* ARMADO DE DOCUMENTO: SETTINGS + SECCIONES. LUEGO MUESTRA AL USUARIO -> .open() */

  pdfMake.createPdf({
    pageSize: pageSize,
    pageMargins: pageMargins,
    content: [
      {
        table: {
          widths: ['auto', '*', 'auto', '*'],
          headerRows: 1,
          heights: 15,
          body: [...datosGenerales, ticketDetalle, ...listadoDeTareas]
        }
      },
      checkBoxes(firmaResponsable, true, true),
      ...detalleSalidaTaller,
      listadoDeReparaciones,
      checkBoxes(proveedor, false, false),
      observaciones],
    styles: styles,
    defaultStyle: defaultStyles
  }).open()
}
