import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import duallistboxConfig from '../../commons/duallistbox/DuallistboxConfig.js'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
//import DualListBox from 'react-dual-listbox';
import 'react-select/dist/react-select.css'
// import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
//import 'react-datepicker/dist/react-datepicker.css'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import * as Constants from '../unidadMedidor/constants.js'
import SelectWithIcons from '../selectWithIcons/SelectWithIcons.js'
import styled from "styled-components";

const DuallistboxViewMode = styled.div`
  .btn {
    ${props => props.action==='VIEW' ? "display: none;" : ""}
  }
  .box1 {
    ${props => props.action==='VIEW' ? "display: none;" : ""}
  }
`;

class PlanMatenimientoPreventivosAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        nombre: '',
        tipo: '',
        activo: true,
        vtipo: true,
				vservicios: null,
        vunidad_medidor: null,
        vdescripcion: null,
				unidad_medidor_id: 1,
        servicios: [],
        umbral: '',
        umbral_dias: ''

      },
      paises: [],
      estados: [{ id: 1, nombre: 'Activo'},{ id: 0, nombre: 'Inactivo'}],
      tiposPlanes: [{ value: 'PREVENTIVO', label: 'PREVENTIVO'},{ value: 'VENCIMIENTO', label: 'VENCIMIENTO'}],
      errors: [],
      servicios: [],
      serviciosDisponibles: [],
      tiposLoading: false,

			loading: false
    };


    this.handleFormChangeSelectString = this.handleFormChangeSelectString.bind(this);

    this.handleEstadoChange = this.handleEstadoChange.bind(this);

    this.handleNotificacionesActivasChange = this.handleNotificacionesActivasChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);


    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.descripcion': (value) => Validator.notEmpty(value),
        'formData.tipo': (value) => Validator.notEmpty(value),
        'formData.umbral' : (value) => Validator.notEmpty(value)
			}
    });

  }

  
  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}
	componentWillMount() {
    if ((Security.hasPermission('PLANES_MANTENIMIENTO_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('PLANES_MANTENIMIENTO_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('PLANES_MANTENIMIENTO_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }
  }
  initForm() {
		this.setState({ loading: true });
    let component = this;
    if(ConfigBusiness.get('planesMantenimiento.vencimiento.habilitado') === 'false')
    {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.vdescripcion = true;
			formDataCopy.vservicios = true;
      formDataCopy.vunidad_medidor = true;
      formDataCopy.tipo = 'PREVENTIVO';
      formDataCopy.vtipo = false;
      this.setState({
        formData: formDataCopy
      });
    }
		Promise.all([
      //this.getData('paises',null),
      this.ajaxHandler.getJson('/paises/select',null),
      //this.state.props.action !== 'ADD' ? this.getData('regiones', this.state.props.match.params.id) : null

      this.ajaxHandler.getJson(`/servicios/tipo-ticket/preventivo/select`,null),
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/plan-mantenimiento-preventivos/' + this.state.props.match.params.id) : null
		]).then((data) => {
      let paises = data[0];
      let formData = data[2];

      component.setState({
        paises: paises
      });
      /*
      if(formData) component.setState({
				formData: formData
      },()=>{this.initServicio()});
      */
			if(formData) {        
				formData.servicios = formData.servicios.map(servicio => servicio.id)
				component.setState({
	        formData: formData
	      });
			}
      $(this.refs.duallistbox).bootstrapDualListbox({
        moveOnDoubleClick: (this.props.action === 'VIEW') ? false : true,
        moveOnSelect: false,
        moveAllLabel: component.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.add_services.title_mover_todos', defaultMessage: 'Mover todos' }),
        removeAllLabel: component.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.add_services.title_remover_todos', defaultMessage: 'Remover todos' }),
        moveSelectedLabel: component.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.add_services.title_mover_seleccionados', defaultMessage: 'Mover seleccionados' }),
        removeSelectedLabel: component.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.add_services.title_remover_seleccionados', defaultMessage: 'Remover seleccionados' }),
        infoText: false,
        filterPlaceHolder: component.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.add_services.placeholder_filter', defaultMessage: 'Filtro' }),
        nonSelectedListLabel: component.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.add_services.label_servicios_disponibles', defaultMessage: 'Servicios Disponibles:' }),
        selectedListLabel: component.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.add_services.label_servicios_asignados', defaultMessage: 'Servicios Asignados:' })
      });

      $(this.refs.duallistbox).bootstrapDualListbox(duallistboxConfig(this.props.intl));

      $(this.refs.duallistbox).change((e) => {
        if(this.props.action !== 'VIEW') {
          let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
          formDataCopy.servicios = $(this.refs.duallistbox).val();
          this.setState({
            formData: formDataCopy
          });
        }
      });
      if (component.state.formData.tipo !== '')
      	this.handleFormChangeSelectTipo(component.state.formData.tipo)

    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });




		window.scrollTo(0, 0);
	}

  initServicio() {

    $(this.refs.duallistbox).bootstrapDualListbox({
      moveOnSelect: false,
      moveAllLabel: "Mover todos",
      removeAllLabel: "Remover todos",
      moveSelectedLabel: "Mover seleccionados",
      removeSelectedLabel: "Remover seleccionados",
      infoText: false,
      filterPlaceHolder: "Filtro",
      nonSelectedListLabel: "Servicios Disponibles:",
      selectedListLabel: "Servicios Asignados:"
    });

    $(this.refs.duallistbox).bootstrapDualListbox(duallistboxConfig);

    $(this.refs.duallistbox).change((e) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.servicios = $(this.refs.duallistbox).val();
      this.setState({
        formData: formDataCopy
      });
    });
  }

  getData(service, param) {
    let serviceURL = param ? (Config.get('apiUrlBase') + '/' + service + '/' + param) : (Config.get('apiUrlBase') + '/' + service);
		return fetch(serviceURL, {
      method: 'GET',
			headers: {
				'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
      return AjaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }


  handleEstadoChange(activo) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['activo'] = activo;
		this.setState({
			formData: formDataCopy
    });
  }


  handleNotificacionesActivasChange(notificacionesActivas) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.usuario['notificacionesActivas'] = notificacionesActivas;
		this.setState({
      formData: formDataCopy
    });
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }





  handleSelectFormChange(name, object) {
    const value = object === null ? null : object.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleFormChangeSelectString(name, object) {
    return new Promise((resolve, reject) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy[name] = object === null ? '' : object.value;
      this.setState({
        formData: formDataCopy
      }, () => resolve());
    });
  }

  visualizarServicios(tipo, unidad_medidor_id){
    let component = this;
    Promise.all([
      this.ajaxHandler.getJson(`/servicios/tipo-ticket/${tipo}/select?unidad_medidor_id=${unidad_medidor_id}`, null)
    ]).then((data) => {
      let serviciosDisponibles = data[0];
      component.setState({
        serviciosDisponibles: serviciosDisponibles
      });
      $(this.refs.duallistbox).bootstrapDualListbox('refresh',true);
    }).catch(function(error) {
      console.log(error);
      component.exit();
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

	handleChangeUnidadMedidor(id) {
    return new Promise((resolve, reject) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			let latestsId = formDataCopy.unidad_medidor_id
      formDataCopy.unidad_medidor_id = id;
      if (formDataCopy.tipo && latestsId != id){
				formDataCopy.servicios = []
        formDataCopy.serviciosDisponibles = []
      }
      this.setState({
        formData: formDataCopy
      }, () => {
				this.visualizarServicios(formDataCopy.tipo, formDataCopy.unidad_medidor_id)
				resolve()
			});
    });
  }

  handleFormChangeSelectTipo(value) {
    return new Promise((resolve, reject) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.tipo = value === null ? '' : value;
      if(formDataCopy.tipo ==='VENCIMIENTO')
      {
        formDataCopy.vdescripcion = true;
				formDataCopy.vservicios = true;
				formDataCopy.vunidad_medidor = false;
        formDataCopy.unidad_medidor_id = null;
        this.visualizarServicios(formDataCopy.tipo, formDataCopy.unidad_medidor_id);
      }
      else if (formDataCopy.tipo ==='PREVENTIVO') {
        formDataCopy.vdescripcion = true;
        formDataCopy.vservicios = true;
				formDataCopy.vunidad_medidor = true;
				if ( ! formDataCopy.unidad_medidor_id )
						formDataCopy.unidad_medidor_id = 1;
				this.visualizarServicios(formDataCopy.tipo, formDataCopy.unidad_medidor_id);
      }
      else{
        formDataCopy.vdescripcion = false;
				formDataCopy.vunidad_medidor = false;
				formDataCopy.unidad_medidor_id = null;
        formDataCopy.vservicios = false;
      }
      this.setState({
        formData: formDataCopy
      }, () => resolve());
    });
  }

	handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
    this.ajaxHandler.fetch('/plan-mantenimiento-preventivos' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
      method: this.props.action === 'ADD' ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...this.state.formData
      }),
    }).then(response => {
      if(response.status !== 400) {

        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
      event.preventDefault();

	}


	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/planmantenimientopreventivos'
		});
	}


	render() {
    this.formValidation.validate();
		let formData = this.state.formData;
    let validationState = this.formValidation.state;
    let tipo = formData.tipo !== '' ? this.state.tiposPlanes.find(e => e.value === formData.tipo) : null;
    //let state = this.state;

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
        {this.props.action === 'VIEW' || this.props.action === 'EDIT' ? (
            formData.vdescripcion=true,
            formData.vservicios=true
          ) : (
            null
          )
        }
        {ConfigBusiness.get('planesMantenimiento.vencimiento.habilitado') === 'true' ? this.state.formData.vtipo = true : ''}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="planMatenimientoPreventivosAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="planMatenimientoPreventivosAbm.render.general_data.campos_requeridos" defaultMessage="campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* TIPO */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: formData.vtipo ? 'true' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="tipo">
                              <FormattedMessage id="planMatenimientoPreventivosAbm.render.general_data.label_tipo" defaultMessage="Tipo *:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || this.props.action === 'EDIT' ? (
                              <div className="form-control-static col-form-label form-value">{formData.tipo}</div>
                              ) : (
                              <div>
                                <Select
                                  id="tipo"
                                  name="tipo"
                                  placeholder={!this.state.tiposLoading ? this.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.render.general_data.placeholder_tipo', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.tiposPlanes}
                                  valueKey='value'
                                  labelKey='label'
                                  value={tipo ? { value: tipo.id, label: tipo.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectTipo(e.value)}
                                  isLoading={this.state.tiposLoading}
                                  disabled={this.state.tiposLoading}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.tipo.pristine || validationState.formData.tipo.valid}>{validationState.formData.tipo.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Plan */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: formData.vdescripcion ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="descripcion">
                              <FormattedMessage id="planMatenimientoPreventivosAbm.render.general_data.label_plan" defaultMessage="Plan *:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.descripcion}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="descripcion" name="descripcion" placeholder={this.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.render.general_data.placeholder_plan', defaultMessage: 'Plan' })} value={formData.descripcion} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.descripcion.pristine || validationState.formData.descripcion.valid}>{validationState.formData.descripcion.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Umbral */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: formData.vdescripcion ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="umbral">
                              <FormattedMessage id="planMatenimientoPreventivosAbm.render.general_data.label_umbral" defaultMessage="Umbral"/> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.umbral}</div>
                              ) : (
                              <div>
                                <input type="number" className="form-control" id="umbral" name="umbral" placeholder={this.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.render.general_data.placeholder_umbral', defaultMessage: 'Umbral' })} value={formData.umbral} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.umbral.pristine || validationState.formData.umbral.valid}>{validationState.formData.umbral.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Umbral Dias*/}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: formData.vdescripcion ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="umbral_dias">
                              <FormattedMessage id="planMatenimientoPreventivosAbm.render.general_data.label_umbral_dias" defaultMessage="Umbral Días"/> :
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.umbral_dias}</div>
                              ) : (
                              <div>
                                <input type="number" className="form-control" id="umbral_dias" name="umbral_dias" placeholder={this.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.render.general_data.placeholder_umbral_dias', defaultMessage: 'Umbral Días' })} value={formData.umbral_dias} onChange={this.handleInputFormChange}/>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
											<div className="row" style={{display: formData.vunidad_medidor ? '' : 'none' }}>
												<div className="col-md-6">
													<div className="form-group row" >
														<label className="col-md-3 label-control col-form-label" htmlFor="tipo">
														Unidad Medidor *:
														</label>
														<div className="col-md-9">
															<SelectWithIcons
																placeholder="Tipo"
																valueKey="id"
															  disabled={this.state.props.action === 'VIEW'}
																labelKey="label"
																clearable={false}
																onChange={(e) => this.handleChangeUnidadMedidor(e.id)}
																value={this.state.formData.unidad_medidor_id}
																options={Object.values(Constants.TIPOS_UNIDAD_MEDIDOR)}
															/>
														</div>
													</div>
												</div>
											</div>
                      <div className="row">
                        {/* Servicios */}
                        <div className="col-md-12">
                          <div className="form-group row ml-5" style={{display: formData.vservicios ? '' : 'none' }}>
                            <label className="col-md-1 label-control col-form-label" htmlFor="servicios">
                              <FormattedMessage id="planMatenimientoPreventivosAbm.render.general_data.label_servicios" defaultMessage="Servicios *:"/>
                            </label>
                            <div className="col-md-9">
                              <div className="form-group">
                                <DuallistboxViewMode action={this.props.action}>
                                  <select
                                    multiple="multiple"
                                    size="10"
                                    ref="duallistbox"
                                    value={formData.servicios}
                                    onChange={this.handleFormChange}>
                                      { this.state.serviciosDisponibles.length && this.state.serviciosDisponibles.map(function(servicio) {
                                      return <option key={servicio.value} value={servicio.value}>{servicio.label}</option>;
                                    })}
                                  </select>
                                </DuallistboxViewMode>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        {/* ESTADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage id="planMatenimientoPreventivosAbm.render.general_data.label_activo" defaultMessage="Activo:"/>
                            </label>
                            <div className="col-md-9">
                              <Switch
                                onChange={this.handleEstadoChange}
                                checked={formData.activo}
                                options={this.state.estados}
                                id="activo"
                                name="activo"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                        </div>

                    </div>
                  </div>
                </div>




                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="planMatenimientoPreventivosAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'planMatenimientoPreventivosAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(PlanMatenimientoPreventivosAbm);
