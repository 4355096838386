import 'bootstrap/dist/js/bootstrap.js';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Config from '../commons/config/Config.js';
import NoDisponible from './app/NoDisponible.js';
import Reintentos from './app/Reintentos.js';
import Activate from './auth/Activate.js';
import AzureCallback from './auth/AzureCallback.js';
import ForgotPassword from './auth/ForgotPassword.js';
import GeotabLogin from './auth/GeotabLogin.js';
import InternalLogin from './auth/InternalLogin.js';
import Login from './auth/Login.js';
import PointerLogin from './auth/PointerLogin.js';
import RecoverPassword from './auth/RecoverPassword.js';
import Layout from './layout/Layout.js';
import TagManager from 'react-gtm-module'
import { createBrowserHistory } from 'history';

const customHistory = createBrowserHistory();

class App extends Component {
    constructor(props) {
        super(props);

        const countdown = parseInt(localStorage.getItem('countdown')) || 5;
        const retryCount = parseInt(localStorage.getItem('retryCount')) || 0;

        this.state = {
            authorized: null,
            errorConfig: false,
            retryCount: retryCount,
            countdown: countdown,
            configBusinessOk: false,
            configBusinessLoading: true, // Nuevo estado para controlar si se está cargando configBusiness        
        };

        this.startCountdown = this.startCountdown.bind(this);
        this.handleRetry = this.handleRetry.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.loadConfigBusiness = this.loadConfigBusiness.bind(this);
    }

    componentDidMount() {
        this.loadConfigBusiness();
    }

    componentDidUpdate(prevProps, prevState) {
        // Verificar si configBusiness se ha cargado y actualizar el estado correspondiente
        if (prevState.configBusinessLoading && !this.state.configBusinessLoading) {
            // Aquí puedes hacer cualquier cosa que necesites después de cargar configBusiness
        }
    }

    loadConfigBusiness() {
        this.setState({ configBusinessLoading: true }); // se está cargando configBusiness
        this.getConfig()
            .then(() => this.checkSession());
        this.getConfigBusiness()
            .then(() => {
                this.setState({ configBusinessLoading: false }); // se ha cargado configBusiness
            })
            .catch(() => {
                this.setState({ configBusinessLoading: false }); // ha ocurrido un error al cargar configBusiness
                this.handleRetry(); // Manejar reintentos si falla la descarga de configBusiness
            });
    }

    async getConfig() {
        try {
            const response = await fetch('/config/config.json', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            const configData = await response.json();
            localStorage.setItem('config', JSON.stringify(configData));
        } catch (error) {
            console.log(error);
        }
    }

    async getConfigBusiness() {
        const fetchConfigBusiness = () => {
            return fetch(Config.get('apiUrlBase') + '/commons/config-business', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
        };

        try {
            const response = await fetchConfigBusiness();
            if (!response.ok) {
                throw new Error('Error al obtener la configuración de entorno');
            }
            const data = await response.json();
            if ((data.jsonDownloaded && data.jsonDownloaded.complete === 'true')
                || (!data.json && data.json !== "error")) {
                localStorage.setItem("configBusiness", JSON.stringify(data));

                let GTM = data.frontend ?  (data.frontend.GTM ? data.frontend.GTM : 'GTM-00000000') : 'GTM-00000000'
                TagManager.initialize({gtmId: GTM})

                this.unlisten = customHistory.listen((location, action) => {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'pageview',
                            pagePath: location.pathname,
                            pageTitle: document.title,
                            page: { url: window.location.href, action: action }
                        }
                    });
                });

                this.setState({ configBusinessOk: true });
            } else {
                throw new Error('Configuración de entorno incompleta');
            }
        } catch (error) {
            console.log('Error en configuración de entorno', error);
            this.handleRetry();
        }
    }

    checkSession() {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('token');
            if (token) {
                fetch(Config.get('apiUrlBase') + '/auth/check', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization-Token': token,
                    },
                })
                    .then((response) => {
                        this.setState({
                            authorized: response.status === 200,
                        });
                        if (response.status === 200) resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.setState({
                    authorized: false,
                });
            }
        });
    }

    startCountdown() {
        const countdownInterval = setInterval(() => {
            this.setState(
                (prevState) => {
                    const newCountdown = prevState.countdown - 1;
                    localStorage.setItem('countdown', newCountdown.toString());
                    return { countdown: newCountdown };
                },
                () => {
                    if (this.state.countdown <= 0) {
                        clearInterval(countdownInterval);
                        this.getConfigBusiness();
                    }
                }
            );
        }, 1000);
        this.countdownInterval = countdownInterval;
    }

    stopTimer() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
    }

    handleRetry() {
        let newRetryCount = parseInt(localStorage.getItem('retryCount')) || 0;
        if (newRetryCount < 3) {
            newRetryCount += 1;
            localStorage.setItem('retryCount', newRetryCount.toString());
            localStorage.setItem('countdown', '5');

            this.setState({ retryCount: newRetryCount, countdown: 5 }, this.startCountdown);
        } else {
            this.stopTimer();
            localStorage.removeItem('retryCount');
            localStorage.removeItem('countdown');
            this.setState({ errorConfig: true, countdown: 0, retryCount: 0 });
        }
    }

    render() {        
        // Si configBusiness todavía se está cargando, no renderizar nada aún
        if (this.state.configBusinessLoading || this.state.errorConfig) {
            return null;
        }
        if (this.state.errorConfig && !this.state.configBusinessOk) {
            return <NoDisponible />;
        }

        if (this.state.retryCount === 3 && this.state.countdown <= 0 && !this.state.configBusinessOk) {
            return <NoDisponible />;
        }

        if (this.state.retryCount > 0 && this.state.retryCount < 3 && this.state.countdown > 0 && !this.state.configBusinessOk) {
            return <Reintentos countdown={this.state.countdown} />;
        }

        if (this.state.retryCount === 0 && this.state.countdown === 0) {
            localStorage.removeItem('retryCount');
            localStorage.removeItem('countdown');
        }

        return (
            <IntlProvider locale={this.props.locale} messages={this.props.messages} defaultLocale="es">
                {this.state.authorized === null || !this.state.configBusinessOk ? null
                : (!this.state.authorized ? 
                    (
                        <Router >
                            <Switch>
                                <Route exact path='/' component={Login} />
                                <Route path='/activacion/:activationKey' component={Activate} />
                                <Route path='/olvide-clave' component={ForgotPassword} />
                                <Route path='/recuperar-clave/:activationKey' component={RecoverPassword} />
                                <Route path='/auth/azure/callback' component={AzureCallback} />
                                <Route path='/auth/pointer' component={PointerLogin} />
                                <Route path='/auth/geotab' component={GeotabLogin} />
                                <Route path='/auth/internal/login' component={InternalLogin} />
                                <Route component={Login} />
                            </Switch>
                        </Router>
                    ) : (
                        <Router history={customHistory}>
                            <Switch>
                                <Route path='/auth/pointer' component={PointerLogin} />
                                <Route path='/auth/geotab' component={GeotabLogin} />
                                <Layout authorized={this.state.authorized} />
                            </Switch>
                        </Router>
                    ))
                }
            </IntlProvider>
        );
    }
}

export default App;
