import React, { useEffect, useState } from "react";

import { Redirect } from "react-router-dom";
import Config from "../../commons/config/Config.js";
import Security from "../../commons/security/Security.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import moment from "moment";
import "moment/min/locales";
import "xlsx/dist/xlsx.full.min.js";
import { FormattedMessage, injectIntl } from "react-intl";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";

//Configure Table and childs THEME
import { withStyles } from "@material-ui/core/styles";
import { green, yellow, red } from "@material-ui/core/colors";
import backendStrings from "../../lang/backendStrings.js";

const StyledTable = withStyles((theme) => ({
  root: {
    padding: "0 !important",
  },
}))(Table);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#1a1036",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: "400",
    fontFamily: "'Poppins', sans-serif",
  },
  body: {
    fontSize: 14,
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    color: "#6B6F82"
  },
}))(TableCell);

const StyledTableHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#1a1036",
    color: theme.palette.common.white,
    lineHeight: 0,
    fontSize: "1rem",
    fontWeight: "400",
    borderBottom: "solid 4px !important",
    fontFamily: "'Poppins', sans-serif",
  },
  body: {
    fontSize: 10,
  },
}))(TableCell);

const BlankTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F4F5FA",
    color: theme.palette.common.black,
    fontSize: 10,
    fontWeight: "400",
    fontFamily: "'Poppins', sans-serif",
    borderBottom: 0,
  },
  body: {
    fontSize: 10,
  },
}))(TableCell);

const PSITableCell = withStyles((theme) => ({
  body: {
    backgroundColor: yellow[500],
  },
}))(TableCell);

const GreenTableCell = withStyles((theme) => ({
  body: {
    backgroundColor: green[400],
  },
}))(TableCell);

const YellowTableCell = withStyles((theme) => ({
  body: {
    backgroundColor: yellow[500],
  },
}))(TableCell);

const RedTableCell = withStyles((theme) => ({
  body: {
    backgroundColor: red[500],
  },
}))(TableCell);

const MySwal = withReactContent(Swal);

const MovilesInmovilizadosDetail = (props) => {
  const [baseId, setBaseId] = useState(props.base);
  const [elementos, setElementos] = useState([]);
  const [redirectTo, setRedirectTo] = useState(null);
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const [intl, setIntl] = useState(props.intl);
  useEffect(() => {
    getData("/moviles-inmovilizados/base/" + baseId)
      .then((res) => {
        setElementos(res);
      })
      .catch((error) => console.log(error))
      .finally(() => console.log("DONE"));
  }, []);

  const initMa = () => {};

  const getData = (service) => {
    let serviceURL = Config.get("apiUrlBase") + service;
    return fetch(serviceURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization-Token": localStorage.getItem("token"),
      },
    }).then((response) => {
      return ajaxHandler.handleResponseErrorsIsValid(response)
        ? response.json()
        : null;
    });
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect push to={redirectTo} />}
      <div className="card col-12 moviles-detalle-card-gasto">
        <div className="card-body">  
          <TableContainer>
            <Table cellPadding="0" cellSpacing="0">
              <TableHead className="hover-reset">
                <TableRow>
                  <StyledTableHeadCell align="center">
                    {props.intl.formatMessage({
                      id: "movilesInmovilizadosDetail.details_table_column.dominio",
                      defaultMessage: "Dominio"
                    })}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align="center">
                    {props.intl.formatMessage({
                      id: "movilesInmovilizadosDetail.details_table_column.tickets",
                      defaultMessage: "Tickets"
                    })}
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {elementos.map((elemento, index) => (
                  <Row
                    key={index}
                    elemento={elemento}
                    redirectToTicket={props.redirectToTicket}
                    intl={intl}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </React.Fragment>
  );
};

const Row = (props) => {
  const [elemento, setElemento] = useState(props.elemento);
  const [intl, setIntl] = useState(props.intl);
  const [tickets, setTickets] = useState(props.elemento.tickets.split(","));
  

  return <TableRow key={elemento.dominio}>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        {elemento.dominio}
      </StyledTableCell>
      <StyledTableCell
        className="custom-td-llanta-impeccion-detalles"
        padding="none"
        align="center"
      >
        {tickets.map((elemento, index) => (
          <button
            key={index}
            className="action-btn-llantas btn btn-sm btn-icon btn-dt-grid text-success"
            data-togle="tooltip"
            data-placement="top"
            title="Ver"
            onClick={() => props.redirectToTicket(elemento)}
          >
            {elemento}
          </button>
        ))}
      </StyledTableCell>
    </TableRow>
};

export default MovilesInmovilizadosDetail;