import React, { Component } from 'react';
import Loading from '../ui/Loading.js';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js';
import $ from 'jquery';
import Config from '../../commons/config/Config.js';
import backendStrings from '../../lang/backendStrings.js';
import Select from 'react-select';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import lugWrench from '../../assets/images/llantas/svg/lug-wrench.svg';
import Input from '@material-ui/core/Input';
import * as Utils from '../../commons/utils/Utils';

class LlantaAsignacionesDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    this.state = {
      props: this.props,
      formData: {
        llanta: null,
        movil: this.props.movil,
        posicion: this.props.posicion,
      },
      loading: true,
      llantas: [],
      llantasSelect: [],
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModalCloseCambioLlanta = this.handleModalCloseCambioLlanta.bind(this);
  }
  componentDidMount() {
    this.ajaxHandler.subscribe(this);
    this.initForm();
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
    $('.popover2').popover('dispose');
  }

  setLlantaPosicionState() {
    let formDataCopy = { ...this.state.formData };
    formDataCopy.llantaPosicionId = this.props.movil.tipologiaPosicion
      ? this.props.movil.tipologiaPosicion.llanta_posiciones.find(
          (el) => el.posicion === this.props.posicion,
        ).id
      : this.props.movil.modelo.tipologiaPosicion.llanta_posiciones.find(
          (el) => el.posicion === this.props.posicion,
        ).id;
    this.setState({ formData: formDataCopy });
  }

  initForm() {
    this.setState({ loading: false });
    this.props.posicion && this.setLlantaPosicionState();
    Promise.all([
      this.getData(
        '/llantas/llantaslibres/select?unidadMedidorId=' +
          this.props.movil.unidadMedidorId +
          '&serial=',
      ),
    ])
      .then((data) => {
        this.setState({ llantas: data[0] });
        let llantasSelect = [];
        data[0].forEach(function (element) {
          let ultimaMedicion = element.ultima_medicion;
          if (ultimaMedicion) {
            let valor_medidos = ultimaMedicion.valor_medidos;
            let labelValorMedidos = '';
            for (let i = 0; i < valor_medidos.length; i++) {
              labelValorMedidos =
                labelValorMedidos +
                valor_medidos[i].punto_dinamico.nombre +
                ': ' +
                valor_medidos[i].value;
              if (i < valor_medidos.length - 1) labelValorMedidos = labelValorMedidos + ' | ';
            }
            llantasSelect.push({
              id: element.id,
              label:
                element.serial +
                ' - ' +
                element.llanta_marca.nombre +
                ' - ' +
                element.modelo.llanta_medida.nombre +
                ' - ' +
                labelValorMedidos,
            });
          } else {
            llantasSelect.push({
              id: element.id,
              label:
                element.serial +
                ' - ' +
                element.llanta_marca.nombre +
                ' - ' +
                element.llanta_medida.nombre,
            });
          }
        });

        this.setState({ llantasSelect: llantasSelect });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleFormChangeSelectObject(event, name) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = event;
    this.setState({ formData: formDataCopy });
    if (
      event &&
      this.props.movil.unidad_medidor_id !== event.unidad_medidor_id &&
      event.unidad_medidor_id !== null
    ) {
      Swal.fire({
        title: 'Tipo de Medidor Diferente!',
        text: 'Se actualizara el tipo de medidor y reiniciaran los datos de odometro. Desea continuar?',
        icon: 'warning',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then((obj) => {
        if (obj.value) {
          this.handleSubmit();
        }
      });
    }
  }

  getLlantasOptions(data) {
    let llantasSelect = [];
    data.forEach(function (element, index) {
      let ultimaMedicion = element.ultima_medicion;
      if (ultimaMedicion) {
        let valor_medidos = ultimaMedicion.valor_medidos;
        let labelValorMedidos = '';
        for (let i = 0; i < valor_medidos.length; i++) {
          labelValorMedidos =
            labelValorMedidos +
            valor_medidos[i].punto_dinamico.nombre +
            ': ' +
            valor_medidos[i].value;
          if (i < valor_medidos.length - 1) labelValorMedidos = labelValorMedidos + ' | ';
        }
        llantasSelect.push({
          id: element.id,
          label:
            element.serial +
            ' - ' +
            element.llanta_marca.nombre +
            ' - ' +
            element.modelo.llanta_medida.nombre +
            ' - ' +
            labelValorMedidos,
        });
      } else {
        llantasSelect.push({
          id: element.id,
          label:
            element.serial +
            ' - ' +
            element.llanta_marca.nombre +
            ' - ' +
            element.llanta_medida.nombre,
        });
      }
    });
    return llantasSelect;
  }

  handleAsyncSelectLoad(value, callback) {
    return this.ajaxHandler
      .getJson(
        '/llantas/llantaslibres/select?unidadMedidorId=' +
          this.props.movil.unidadMedidorId +
          '&serial=' +
          `${value}`,
      )
      .then((res) => {
        return {
          options: res.map((el) => ({
            ...el,
            label:
              el.serial +
              ' - ' +
              el.llanta_marca.nombre +
              ' - ' +
              el.llanta_modelo.llanta_medida.nombre,
          })),
          complete: true,
        };
      });
  }

  getData(service) {
    let serviceURL = Config.get('apiUrlBase') + service;
    return fetch(serviceURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem('token'),
      },
    }).then((response) => {
      return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  handleSubmit(event) {
    event && event.preventDefault();
    let dataToSend = {
      banda: { nombre: this.state.formData.llanta.banda },
      movil: this.state.formData.movil,
      posicion: {
        posicion: this.state.formData.posicion,
        id: this.state.formData.llantaPosicionId,
      },
      recapadas: {
        value: this.state.formData.llanta.recapadas
      }
    };
    Utils.sendData(`/llantas/llantaasignar/${this.state.formData.llanta.id}`, 'PUT', dataToSend)
      .then((res) => {
        this.props.actualizarDatos();
        swal(
          this.props.intl.formatMessage({
            id: 'llantaAsignacionesDetails.render.modal_asignar_llanta.contenido_llanta_asignada',
            defaultMessage: 'Llanta asignada',
          }),
          this.props.intl.formatMessage({
            id: 'llantaAsignacionesDetails.render.modal_asignar_llanta.contenido_llanta_fue_agregada_al_movil',
            defaultMessage: 'Llanta fue agregada al móvil',
          }),
          'success',
        );
      })
      .catch((err) => console.log(err))
      .finally(() => event && this.props.confirm());
  }

  handleModalCloseCambioLlanta(event) {
    event.preventDefault();
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy['llanta'] = null;
    this.setState({ formData: formDataCopy, errors: [] });
    this.props.cancel();
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Loading />}
        <div className="alert alert-danger" role="alert" hidden={this.state.errors.length === 0}>
          {this.state.errors.map((e, i) => (
            <li key={i}>
              {this.props.intl.formatMessage({
                ...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default']),
              })}
            </li>
          ))}
        </div>
        <form
          className=""
          ref="form"
          onSubmit={this.handleSubmit}
          style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: '1rem' }}
        >
          <div className="text-center">
            <h1 className="mb-2" style={{ fontWeight: '700' }}>
              {this.props.intl.formatMessage({
                id: 'llantaAsignacionesDetails.render.modal_asignar_llanta.header_asignacion_de_llanta',
                defaultMessage: 'Asignacion de Llanta',
              })}
            </h1>
            <img src={lugWrench} alt={'/'} style={{ height: '100px', width: '100px' }} />
          </div>
          <div className="text-center my-1">
            <h5 className="">
              <b>
                {this.props.intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.modal_asignar_llanta.header_asignar_llanta_posición',
                  defaultMessage: 'Asignar una llanta en la posición',
                })}{' '}
                {this.state.formData.posicion ? this.state.formData.posicion : ' de repuesto.'}
              </b>
            </h5>
          </div>
          <Select.Async
            valueKey="id"
            labelKey="label"
            name="select-async-llantas"
            autoload={false}
            filterOptions={(options, filter, currentValues) => {
              return options;
            }}
            placeholder={this.props.intl.formatMessage({
              id: 'llantaAsignacionesDetails.render.modal_asignar_llanta.select_placeholder_cautin',
              defaultMessage: 'Cautin / SN',
            })}
            loadOptions={(value, callback) => this.handleAsyncSelectLoad(value, callback)}
            onChange={(e) => this.handleFormChangeSelectObject(e, 'llanta')}
            value={this.state.formData.llanta ? this.state.formData.llanta : ''}
          />
          <div></div>
          <div className="d-flex justify-content-end mt-1">
            <button
              type="submit"
              disabled={!this.state.formData.llanta}
              className="btn btn-primary mx-1 px-2"
            >
              <i className="fa fa-check-circle"></i>
              {this.props.intl.formatMessage({
                id: 'llantaAsignacionesDetails.render.modal_recapado.button_fininalizar.label_guardar',
                defaultMessage: ' Guardar',
              })}
            </button>
            <button
              type="button"
              className="btn btn-danger px-2"
              onClick={(e) => this.handleModalCloseCambioLlanta(e)}
            >
              <i className="fa fa-times-circle"></i>
              {this.props.intl.formatMessage({
                id: 'llantaAsignacionesDetails.render.modal_recapado.button_fininalizar.label_cancelar',
                defaultMessage: ' Cancelar',
              })}
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default LlantaAsignacionesDetailsModal;
