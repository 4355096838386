import { Collapse, TableCell, TableRow } from '@material-ui/core';
import 'moment/min/locales';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert2';
import Config from '../../commons/config/Config.js';
import Security from '../../commons/security/Security.js';
import Timezone from '../../commons/timezone/Timezone.js';
import { StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles';
import * as Constants from '../atributoDinamico/constants.js';
import FormularioDetail from './FormularioDetail';
import FormularioPdf from './FormularioPdf.js';

const FormulariosGridRow = (props) => {
  const formulario = props.formulario;
  const baseUrl = props.baseUrl;
  const intl = useIntl();
  const [redirectTo, setRedirectTo] = useState(null);
  const [open, setOpen] = useState(false);
  const [isHidden, setIsHidden] = useState('hidden-personal');
  const estadosTooltip = ['', 'CERRADO', 'PENDIENTE', 'SIN COMPROBACION', 'EN PROCESO'];
  const hasPermission = Security.hasPermission('CHECKLIST_VALIDAR_HISTORICO');
  const [observacionesCambioEstado, setObservacionesCambioEstado] = useState(null);


  useEffect(() => {
    getEstado();
  }, []);

  const getEstado = (estado) => {
    switch (estado) {
      case Constants.ID_ESTADO_APROBACION_VALIDO:
        return 'status-green';
        break;
      case Constants.ID_ESTADO_APROBACION_PENDIENTE:
        return 'status-yellow';
        break;
      case Constants.ID_ESTADO_APROBACION_INVALIDO:
        return 'status-red';
        break;
      case Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION:
        return 'status-grey';
        break;
    }
  };

  // Función que cambia el estado "open" y el estado "isHidden" entre "hidden-personal" y "block".
  const toggleCollapse = () => {
    setOpen(!open); // Cambiar el estado "open" a su valor contrario.
    if (isHidden === 'hidden-personal') {
      setIsHidden('block'); // Cambiar el estado "isHidden" a "block" si estaba en "hidden-personal".
    } else {
      setIsHidden('hidden-personal'); // Cambiar el estado "isHidden" a "hidden-personal" si no estaba en "hidden-personal".
    }
  };

  // Función que maneja la confirmación y la solicitud a la API.
  const handleConfirmation = () => {
    if (observacionesCambioEstado === null) {
      // Mostrar el cuadro de diálogo de confirmación.
      swal({
        title: 'Validación de Checklist',
        text: '¿Confirma que desea validar el checklist?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((isConfirmed) => {
        if (isConfirmed.value === true) {
          // Realizar la solicitud a la API.
          fetch(Config.get('apiUrlBase') + '/formulario/' + formulario.id + '/validar', {
            method: 'POST',
            body: JSON.stringify({
              observacion_validacion: observacionesCambioEstado,
            }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization-Token': localStorage.getItem('token'),
            },
          })
            .then((response) => {
              if (response.status === 200) {
                swal({
                  title: 'Checklist Validado',
                  confirmButtonText: "Aceptar",
                }).then(() => {
                  window.location.reload();
                });
              }
            })
            .catch((error) => {
              // Mostrar un mensaje de error con SweetAlert si la solicitud falla.
              swal('Error', 'Hubo un error al cerrar el checklist', 'error');
            });
        }
      });
    } else {
      // Redirigir a la página si ya hay observaciones.
      window.location.href = redirectTo;
    }
  };
  // Función que maneja la cancelación del checklist.
  const handleCancelChecklist = () => {
    let component = this;
    if (observacionesCambioEstado === null) {
      // Mostrar el cuadro de diálogo de confirmación.
      swal({
        title: 'Validación de Checklist',
        text: '¿Confirma que desea cancelar la validación?',
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((isConfirmed) => {
        if (isConfirmed.value === true) {
          // Realizar la solicitud a la API para cancelar.
          fetch(Config.get('apiUrlBase') + '/formulario/' + formulario.id + '/cancelar', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization-Token': localStorage.getItem('token'),
            },
          })
            .then((response) => {
              if (response.status === 200) {
                swal({
                  title: 'Checklist Cancelado',
                  confirmButtonText: "Aceptar",
                }).then((isConfirmed) => {
                  console.log(isConfirmed);
                  if (isConfirmed.value === true) {
                    window.location.reload();
                  }
                });
              }
            })
            .catch((error) => {
              // Mostrar un mensaje de error con SweetAlert si la solicitud falla.
              swal('Error', 'Hubo un error al cancelar el checklist', 'error');
            });
        }
      });
    } else {
      // Redirigir a la página si ya hay observaciones.
      window.location.href = redirectTo;
    }
  };
  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <TableRow>
        <StyledTableCell>
          <div className="d-flex">
            <div onClick={() => toggleCollapse()}>
              {open ? (
                <i className="fa fa-2x fa-angle-double-down personal-no-focus avatar-plus-detail pointer"></i>
              ) : (
                <i className="fa fa-angle-double-right fa-2x personal-no-focus avatar-plus-detail pointer"></i>
              )}
            </div>
            <div className="text-center" style={{ minWidth: '50px' }}>
              <i className={'fa-2x ' + formulario.tipo_formulario.icono} />
            </div>
          </div>
        </StyledTableCell>
        <StyledTooltip title={estadosTooltip[formulario.estado]} arrow placement="right">
          <td
            align="left"
            className={getEstado(formulario.estado) + ' p-0 all custom-lineHeight br'}
            style={{ maxWidth: '3px' }}
          />
        </StyledTooltip>
        <StyledTableCell align="left">{formulario.id}</StyledTableCell>
        <StyledTableCell align="left">{formulario.tipo_formulario.nombre}</StyledTableCell>
        <StyledTableCell align="left">
          {formulario.created_at
            ? Timezone.getDateForClient(formulario.created_at, null, 'DD-MM-YYYY')
            : ''}
        </StyledTableCell>
        <StyledTableCell align="left">{formulario.entidad}</StyledTableCell>
        <StyledTableCell align="left">
          {formulario.usuario_modificacion ? formulario.usuario_modificacion.label : ''}
        </StyledTableCell>
        <StyledTableCell align="left">{formulario.fallas}</StyledTableCell>
        <StyledTableCell align="left">
          {formulario.base ? formulario.base.descripcion : ''}
        </StyledTableCell>
        <StyledTableCell align="left">
          {formulario.chofer ? formulario.chofer.fullName : ''}
        </StyledTableCell>
        <StyledTableCell>
          <div className="d-inline-flex">
            <button
              className="action view btn btn-sm btn-icon btn-dt-grid text-success"
              data-title="Ver"
              data-togle="tooltip"
              data-placement="top"
              onClick={() => setRedirectTo(baseUrl + '/' + formulario.id)}
            >
              <i className="fa fa-search fa-xs" />
            </button>
            <button
              className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
              data-title="Editar"
              data-togle="tooltip"
              data-placement="top"
              onClick={() => setRedirectTo(baseUrl + '/' + formulario.id + '/edit')}
            >
              <i className="fa fa-pencil fa-xs" />
            </button>
            <FormularioPdf formulario_id={formulario.id} intl={intl} />
          </div>
          <div className="d-inline-flex">
            {(
              formulario.estado === Constants.ID_ESTADO_APROBACION_INVALIDO ||
              formulario.estado === Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION ||
              formulario.estado === Constants.ID_ESTADO_APROBACION_PENDIENTE
            ) && hasPermission && (
                <button
                  className="action approve btn btn-sm btn-icon btn-dt-grid text-success"
                  data-title="Validar Checklist"
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={handleConfirmation}
                >
                  <i className="fa fa-checklist fa-xs" />
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              )}
            {formulario.estado === Constants.ID_ESTADO_APROBACION_VALIDO && hasPermission && (
              <button
                className="btn btn-sm btn-icon btn-dt-grid text-secondary"
                data-title="Checklist Cerrado"
                data-toggle="tooltip"
                data-placement="top"
                onClick={handleCancelChecklist}
              >
                <i className="fa fa-lock fa-xs" />
              </button>
            )}
          </div>
        </StyledTableCell>
      </TableRow>
      <TableRow className={isHidden}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, background: 'rgb(244, 245, 250)' }}
          colSpan={19}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <FormularioDetail
              intl={intl}
              formulario_id={formulario.id}
              style={{ maxWidth: '85vw' }}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FormulariosGridRow;
