import React, { Component } from 'react'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone'
import Config from '../../commons/config/Config.js'
import FormValidation from '../../commons/validation/FormValidation.js'
import $ from 'jquery'
import backendStrings from '../../lang/backendStrings.js';

class VencimientosImportar extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {			
        };
        
        this.formValidation = new FormValidation({
			component: this,
			validators: {        
            }
        });

        this.initFileUpload();
        this.handleDownloadFile = this.handleDownloadFile.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);        
        this.dropzone = null;

    }

    componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		if(this.props.onRef) this.props.onRef(undefined);
	}

    componentDidMount() {
		this.ajaxHandler = new AjaxHandler();
		this.ajaxHandler.subscribe(this);
		if(this.props.onRef) this.props.onRef(this);	
    }

    initFileUpload() {
        let component = this;

		this.fileUploadConfig = {
			showFiletypeIcon: false,
            postUrl: Config.get('apiUrlBase') + '/vencimientos/importar-excel'            
        };

        this.fileUploadDjsConfig = {
			maxFiles: 2,
			addRemoveLinks: false,
            thumbnailMethod: 'crop',
            dictDefaultMessage: this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.haga_click_aqui_o', defaultMessage: 'Haga click aquí o' })+'<br>'+this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.arrastre_un_archivo_area', defaultMessage: 'arrastre un archivo a este área' })+'<br>'+this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.para_cargar_los_datos', defaultMessage: 'para cargar los datos.' }),
			dictInvalidFileType: this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.errors.tipo_archivo_incorrecto', defaultMessage: 'El tipo de archivo es incorrecto. Solo se permiten .xlsx' }),
			dictFileTooBig: this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.errors.archivo_muy_grande', defaultMessage: 'El archivo es muy grande ' })+'({{filesize}}MiB)'+this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.errors.maximo_permitido', defaultMessage: '.Máximo permitido: ' })+'{{maxFilesize}}MiB.',
			dictResponseError: 'Error interno con código {{statusCode}}.',
			dictCancelUpload: this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.se_cancelo_subida_archivo', defaultMessage: 'Se canceló la subida del archivo' }),
			dictCancelUploadConfirmation: this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.estas_seguro_queres_cancelar_subida', defaultMessage: 'Estás seguro que querés cancelar la subida?' }),
			dictRemoveFile: this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.archivo_eliminado', defaultMessage: 'Archivo Eliminado' }),
			dictRemoveFileConfirmation:null,
			dictMaxFilesExceeded: this.props.intl.formatMessage({ id: 'vencimientosImportar.import_file.solo_procesar_un_archivo_por_vez', defaultMessage: 'Solo se puede procesar un archivo a la vez.' }),
            uploadMultiple: false,
			parallelUploads: 1,
			acceptedFiles: ".xlsx",
            autoProcessQueue: false,
            timeout: 12000000,            
            headers: {
				"Authorization-Token": localStorage.getItem("token")
			},
            previewTemplate: `
		        <div class="dz-preview">
		          <div class="dz-image">
		            <i class="fa fa-file-excel-o" style="font-size:80px"></i>
		          </div>
		         <div class="dz-success-mark">
		            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">      <title>Check</title>      <defs></defs>      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">        <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#FFFFFF" sketch:type="MSShapeGroup"></path>      </g>    </svg>
		          </div>
		          <div class="dz-error-mark">
		            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">      <title>Error</title>      <defs></defs>      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">        <g id="Check-+-Oval-2" sketch:type="MSLayerGroup" stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475">          <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" sketch:type="MSShapeGroup"></path>        </g>      </g>    </svg>
		          </div>
		          <div class="dz-error-message"><span data-dz-errormessage></span></div>
		        </div>
		    `,
            init: function() {
                let dropzone = this;
                let swalTitle = ''
                let swalMessage = ''
                
                this.on('success', (file, importDetailsRaw, response) => {
                    component.setState({loading: false});
                    dropzone.removeFile(file);
                    let importDetails = JSON.parse(importDetailsRaw);
                    
                    
                    if (importDetails.filas_exitosas > 0) {
                        swalTitle = component.props.intl.formatMessage({ id: "vencimientosImportar.import_file.fue_importado_exito", defaultMessage: "El archivo fue importado con éxito"})
                        swalMessage = ''
                    }

                    if (importDetails.filas_fallaron.length > 0) {
                        swalMessage = ''

                        if (importDetails.filas_exitosas > 0) {
                            swalTitle = component.props.intl.formatMessage({ id: "vencimientosImportar.import_file.fue_importado_exito_parcial", defaultMessage: "Importados exitosamente:"})
                            swalMessage = `${importDetails.filas_exitosas}`
                        }
                    } 
                    
                    component.resetModal(importDetails, swalTitle , swalMessage, 'success')
                });
                
                this.on('error', (file, importDetails, response) => {
                    dropzone.removeFile(file);
                    const backendStringsId = importDetails.detalle && importDetails.detalle[0] ? importDetails.detalle[0] : null;

                    if (importDetails.codigo === 4010) {
                        swalTitle = component.props.intl.formatMessage({ id: "preventivosGrid.alert.sesion_expiro", defaultMessage: "La sesión expiró"})
                        swalMessage = component.props.intl.formatMessage({ id: "preventivosGrid.alert.ingrese_nuevamente",  defaultMessage: "Por favor, ingrese nuevamente." }) 
                        
                    } else if (importDetails.codigo && backendStringsId) {
                        swalTitle = component.props.intl.formatMessage({ ...(backendStrings['errors.default']) }) 
                        swalMessage = component.props.intl.formatMessage({ ...(backendStrings[backendStringsId])})
                    } else {
                        swalTitle = component.props.intl.formatMessage({ ...(backendStrings['errors.default']) }) 
                        swalMessage = component.props.intl.formatMessage({ ...(backendStrings['errors.contacte_al_administrador'])})
                    }

                    component.resetModal(importDetails, swalTitle , swalMessage, 'error')
                });
            }
        }
    }

    handleDownloadFile(e){
        e.preventDefault();
        let swalTitle = ''
        let swalMessage = ''
        let swalIcon = null

        this.ajaxHandler.fetch('/vencimientos/documents/planilla-modelo-importador', {method:'GET'})
            .then(response => {            
                if (response.status === 200) {
                    return response.blob();
                } else {
                    swalIcon = 'error'
                    swalTitle = this.props.intl.formatMessage({ id: 'vencimientosImportar.error.no_pudo_obtener_documento', defaultMessage: 'No se pudo obtener el documento' }) 
                    swalMessage = this.props.intl.formatMessage({ id: 'vencimientosImportar.error.contacte_administrador', defaultMessage: 'Contacte al Administrador' })
                }
            })
            .then(fileBlob => {
                let fileUrl = URL.createObjectURL(fileBlob);
                $("<a />", {
                    href: fileUrl,
                    download: "Modelo-Importador-de-vencimientos.xlsx"
                }).appendTo("body").get(0).click();
                    swalIcon = 'success'
                    swalTitle = this.props.intl.formatMessage({ id: 'vencimientosImportar.descarga_completa', defaultMessage: 'Descarga completa' })
                })
                .catch(() => {})
                .finally(() => {
                this.resetModal(null, swalTitle, swalMessage, swalIcon);
                this.setState({ loading: false });
            });
    }

    handleUploadFiles(file){
        var dz = this.dropzone;
        if(dz.files.length > 1){
            dz.removeFile(dz.files[0]);
        
        }
        this.setState({archivo: file});
	}

    resetModal(importDetails, swalTitle , swalMessage, swalIcon){
		this.setState({			
			archivo: null,
			loading: false
		})
		this.props.callbackClose(importDetails, swalTitle , swalMessage, swalIcon);
	}

    handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.props.callbackCancel();
    }
    
    handleSave(event) {
		this.setState({loading: true },()=>this.dropzone.processQueue());
		event.preventDefault();
	}


    render() {
        const eventHandlers = {
			init: dz => this.dropzone = dz,
			addedfile: this.handleUploadFiles.bind(this)
        }
        this.formValidation.validate();
        let validationState = this.formValidation.state;
        
        return (
            <React.Fragment>
                <div className="card pull-up">
                    <div className="card-content pl-1 pr-1">
                        <div className="card-body">
                            <h4 className="form-section">
                                <i className="la la-file"></i> <FormattedMessage id="vencimientosImportar.import_file.descargar_modelo" defaultMessage="Descargar Modelo"/>
                            </h4>
                            <div className="content-body">                                
                                <button 
                                    type="button"                                     
                                    className="btn btn-primary mr-1 mb-1 col-5" 
                                    onClick={(e) => this.handleDownloadFile(e)} title="Descargar" 
                                >                                        
                                    <FormattedMessage id="vencimientosImportar.import_file.planilla_modelo" defaultMessage="Planilla Modelo"/>
                                </button>                                                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card pull-up">
						<div className="card-content pl-1 pr-1">
							<div className="card-body">
								<h4 className="form-section">
									<i className="la la-file"></i><FormattedMessage id="vencimientosImportar.import_file.importar_archivo" defaultMessage=" Importar Archivo"/>
								</h4>

								<div className="row chat-application ml-0 mr-0 mb-1" style={{border: 0}}>
									<div className="content-body">
                                        <div className="row mt-1">
											<div className="col-md-12">
												<DropzoneComponent id="archivos" config={this.fileUploadConfig} eventHandlers={eventHandlers} djsConfig={this.fileUploadDjsConfig} />
											</div>
										</div>
                                        <hr/>
                                        <div className="row mt-1">
											<div className="col-md-12">
												{this.state.archivo ? (
                                                    <React.Fragment>
                                                        <div className="tab-content px-1 pt-1">
                                                            <table className="table">
																<tbody style={{maxHeight: '200px', overflowY: 'auto', display: 'block'}}>
                                                                    <tr>
																		<td className="align-middle">{this.state.archivo.name}</td>
                                                                        <td className="align-middle"></td>
                                                                    </tr>
                                                                </tbody>
															</table>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
													<div><FormattedMessage id="vencimientosImportar.import_file.no_hay_archivos_cargados" defaultMessage="No hay archivos cargados."/></div>
												)}
                                            </div>                             
                                        </div>                             

                                    </div>                                
                                </div>
                                <div className="col-md-12 alert alert-info small" role="alert">
									<FormattedMessage id="vencimientosImportar.import_file.esta_accion_puede_demorar" defaultMessage="Esta acción puede demorar unos minutos."/>
								</div>
                            </div>
                            <div className="card-footer">                                
                                <div className="text-cd text-right">
                                    <button type="submit" className="btn btn-primary mr-1" onClick={this.handleSave} disabled={!validationState.form.valid || !this.state.archivo}>
                                    <i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-check-circle'}></i><FormattedMessage id="vencimientosImportar.import_file.button.importar" defaultMessage=" Importar"/>
                                    </button>
                                    <button type="button" className="btn btn-danger" onClick={this.handleCancel}>
                                        <i className="fa fa-times-circle"></i><FormattedMessage id="vencimientosImportar.import_file.button.cancelar" defaultMessage=" Cancelar"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(VencimientosImportar);