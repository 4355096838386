import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
//import LogoCompany from '../../assets/images/logo-company-login-tc-print.png'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import swal from 'sweetalert'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js'
import Timezone from '../../commons/timezone/Timezone.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class ActualizacionKmGrid extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.dataService = '/actualizacionkm/grid';
		this.columnsToPrint = [2,3,4];
		moment.locale('es');

		this.state = {
			redirectTo: null,
			editKmEnabled: false,
			maxDifferenceKM: 0,
			canModifyKM: false,
			bases: null
		}
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('REPORTES_ACTUALIZACION_KM')) {
	      this.ajaxHandler.subscribe(this);
				this.initGrid();
	    } else {
		    	this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">
												
											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													<th className=""></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;

		Promise.all([
			this.ajaxHandler.getJson('/bases/select-filtered-by-user/movil'),
		]).then((data) => {
			let filters = {
				base: data[0] ? data[0] : [],
				tipoUnidadMedidor: Object.keys(UNIDAD_MEDIDOR).map((unidadMedidorId, i) => ({value: UNIDAD_MEDIDOR[unidadMedidorId]['id'], label: UNIDAD_MEDIDOR[unidadMedidorId]['label'] }))
			};
			component.setState({
				
			});

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					
					processing: true,
					serverSide: true,
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + this.dataService,
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = null; //JSON.parse(localStorage.getItem('DataTables_' + component.constructor.name)).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'actualizacionKmGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
										
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'actualizacionKmGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [
						{
						title: '',
						className: 'all',
						defaultContent: '',
						orderable: false,
						rrender: function (data, type, row) {
							return '<div class="dt-user-avatar">'+row.dominio.substr(0,1)+'</div>';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('div').tooltip();
						},
						filterType: 'none'
					}, {
						title: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.column_domain.label_dominio', defaultMessage: 'Dominio' }),
						className: 'all',
						data: 'dominio',
						filterType: 'input'
					},{
						name: 'base',
						title: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.column_base.label_base', defaultMessage: 'Base' }),
						className: 'all',
						data: 'base',
					/*	render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.descripcion ? data.descripcion : '';
							}
						},*/
						filterType: 'select'
					},{
						title: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.column_previous_Km.label_medidor_anterior', defaultMessage: 'Medidor Anterior' }),
						name: 'kmAnterior',
						className: 'all',
						data: 'km_anterior',
						
						filterType: 'input'
					}, {
						name: 'kmActualizado',
						title: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.column_updated_kms.label_medidor_actualizado', defaultMessage: 'Medidor Actualizado' }),
						className: 'all',
						data: 'km_actualizado',
						
						filterType: 'input'
					},{
						name: 'tipoUnidadMedidor',
						title: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.column_updated_kms.label_tipo_medidor', defaultMessage: 'Unidad Medidor' }),
						className: 'all',
						data: 'tipo_unidad_medidor',
						render: function(data, type){
							if (type === 'filter') {
								return data &&  data.tipo_unidad_medidor ? data.tipo_unidad_medidor : '';
							} else {
								return data && UNIDAD_MEDIDOR ? UNIDAD_MEDIDOR[data]['unidades'] : '';
							}
						},
						filterType: 'select'
					},{
						name: 'usuarioAnterior',
						title: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.column_previous_user.label_usuario_mod_anterior', defaultMessage: 'Usuario mod. anterior' }),
						className: 'all',
						data: 'usuario_anterior',
						
						filterType: 'input'
					}, {
						name: 'usuario',
						title: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.column_user_who_updated.label_usuario_que_actualizo', defaultMessage: 'Usuario que actualizó' }),
						className: 'all',
						data: 'usuario',
					
						filterType: 'input'

					},{
						name: 'fechaActualizacion',
						title: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.column_update_date.label_fecha_de_actualizacion', defaultMessage: 'Fecha de Actualización' }),
						className: 'all',
						data: 'fecha_actualizacion',
						filterType: 'input',
						render: function(data, type, full, meta) {
							return data ? Timezone.getDateForClient(data, 'DD/MM/YYYY HH:mm', "DD/MM/YYYY HH:mm") : '';
						},
					}],
					drawCallback: function() {
						
					},
					rowCallback: function(row, data) {
						if (data.marca) {
							if (data.marca.foto) {
								let photoUrl = Config.get('apiUrlBase') + '/marcas/foto/' + data.marca.foto;
								fetch(photoUrl, {
									method: 'GET',
									headers: {
										'Authorization-Token': localStorage.getItem("token")
									}
								}).then(response => {
									if (response.status === 200) {
										return response.blob();
									} else {
										$('#avatar', row).addClass('dt-user-avatar');
										$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
									}
								}).then(imgBlob => {
									$('#avatar', row).addClass('dt-user-avatar-image');
									$('#avatar', row).css('background-image', 'url(' + URL.createObjectURL(imgBlob) + ')');
								}).catch(() => {
									$('#avatar', row).addClass('dt-user-avatar');
									$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
								});
							} else {
								$('#avatar', row).addClass('dt-user-avatar');
								$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
							}
						} else {
							$('#avatar', row).addClass('dt-user-avatar');
							$('#avatar', row).html('');
						}

					}
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					extend: 'print',
					name: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win) {
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						} else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css('font-size', '10pt')
							.prepend(`<div class="container">
												<div class="row mb-2">
													<div class="col-6">
														<img style="height: 40px" src="` + LogoCompany + `" />
													</div>
													<div class="col-6	text-right">
														<img style="height: 40px" src="` + LogoVecFleet + `" />
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12" style="border-bottom: 1px solid #000;">
														<h2>`+component.props.intl.formatMessage({ id: 'actualizacionKmGrid.print_content.header_listado_de_moviles', defaultMessage: 'Listado de Móviles' })+`</h2>
													</div>
												</div>
											</div>`);

						$(win.document.body).find('table')
							.addClass('compact')
							.css('font-size', 'inherit');
					},
					exportOptions: {
						columns: component.columnsToPrint
					},
					titleAttr: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
				}, {
					name: 'exportExcel',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.tools_export.exportar_a_excel', defaultMessage: 'Exportar a Excel' }),
					action: function (e, dt, node, config) {
						swal({
							title: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.tools_export.modal_se_estan_exportando_los_datos', defaultMessage: 'Se están exportando los datos.' }),
							text: component.props.intl.formatMessage({ id: 'actualizacionKmGrid.tools_export.modal_esta_accion_puede_demorar', defaultMessage: 'Esta acción puede demorar.' }),
							buttons: {}
						});
						
						component.ajaxHandler.fetch('/actualizacionkm/exportar-excel', {
							method: 'GET'
						}).then(response => {
							if (response.status === 200) {
								return response.blob();
							}
						}).then(fileBlob => {
							let fileUrl = URL.createObjectURL(fileBlob);
							$("<a />", {
								href: fileUrl,
								download: "actualizacion_km.xlsx"
							}).appendTo("body").get(0).click();
						}).catch(() => {
						}).finally(() => {
							swal.close();
						});
					}
				},  ]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('print:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('columns:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('export:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('import:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('exportExcel:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();

			$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
	}

	getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}
}

export default injectIntl(ActualizacionKmGrid);