import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import NoReportanGrid from './NoReportanGrid.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

class NoReportan extends Component {
	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      			<h3 className="content-header-title"><FormattedMessage id="noReportan.header_moviles_que_no_reportaron_posssr" defaultMessage="Móviles que No Reportaron por "/>{`${ConfigBusiness.get('crons.noReportan.intervalo')}`}<FormattedMessage id="noReportan.header_hr" defaultMessage="hs"/></h3>
      		</div>
				</div>
				<div className="content-body">								
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={NoReportanGrid} />
					</Switch>										
				</div>
			</div>
	    );
  	}
}

export default NoReportan;
