import React, { useState, useRef, useEffect } from "react";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import Config from "../../commons/config/Config.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import { TextField } from '@material-ui/core';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import 'moment/min/locales'
import swal from 'sweetalert'
import backendStrings from '../../lang/backendStrings.js';

const sendData = (service, data) => {
  let serviceURL = Config.get("apiUrlBase") + service;
  return fetch(serviceURL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization-Token": localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  });
};

const ajaxHandler = new AjaxHandler();

const CambioDominioMovil = (props) => {
  const formRef = useRef();
  useEffect(() => {}, []);
  const [id, setId] = useState(props.id);
  const [fecha, setFecha] = useState(moment());
  const [razon, setRazon] = useState('');
  const [comentario, setComentario] = useState('');
  const [dominio, setDominio ]= useState(props.dominio);

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      id: id,
      dominio : dominio,
      fecha : fecha.format(),
      razon : razon,
      comentario : comentario,
      fechaHoraCreated: moment()
    }
    sendData("/moviles/actualizar/dominio", data)
    .then((response) => response.json())
    .then(response => {
      if(response.codigo){
        props.dismiss();
        let mensaje = response.detalle ? (response.detalle[0] ? response.detalle[0] : '') : '';
        swal(
          props.intl.formatMessage({ id: 'Error_al_actualizar_el_vehículo', defaultMessage: 'Error al actualizar el vehículo' }),
          mensaje ? props.intl.formatMessage({ ...(backendStrings[mensaje] ? backendStrings[mensaje] : backendStrings['errors.default']) }) : '',
          "error"
        );
      }else{
        props.success(props.intl.formatMessage({ 
          id: 'Se_ha_actualizado_exitosamente_el_vehículo',
          defaultMessage: 'Se ha actualizado exitosamente el vehículo'
        }))
      }
    })
    .catch((error) => {
      console.log("Error:",error)
    });
  };

  return (<>
    <form className="form form-horizontal" ref={formRef} onSubmit={handleSubmit} id="form-centro-costo">
      <div className="col-md-12">
        <label className="col-md-5 label-control col-form-label text-left" htmlFor="fecha">
          {props.intl.formatMessage({ id: 'Fecha_de_Cambio', defaultMessage: 'Fecha de Cambio' })}
        </label>
        <DatePicker
          id="fecha"
          name="fecha"
          selected={fecha}
          className="form-control date-picker-placeholder"  
          placeholderText ="DD/MM/AAAA"   
          maxDate={moment()}
          minDate={moment().subtract(ConfigBusiness.get('moviles.centroCostos.diasHistoricoMaximo'), 'days')}
          onChange={(e)=>setFecha(e)}
        />
      </div>
      <div className="col-md-12">
        <TextField
          id="razon"
          style={{ width: '100%' }}
          label={props.intl.formatMessage({ id: 'Razón_del_cambio', defaultMessage: 'Razón del cambio' })}
          name="razon"
          value={razon}
          onChange={(e) => setRazon(e.target.value)}
        />
      </div>
      <div className="col-md-12">
        <TextField
          id="comentario"
          style={{ width: '100%' }}
          label={props.intl.formatMessage({ id: 'Comentario', defaultMessage: 'Comentario' })}
          name="comentario"
          value={comentario}
          onChange={(e) => setComentario(e.target.value)}
        />
      </div>
      <div className="col-md-12 mt-2 mb-2 text-center">
        <span className="text-danger">
          {props.intl.formatMessage({ 
            id: 'Se_actualizará_automáticamente_el_Dominio.',
            defaultMessage: 'Se actualizará automáticamente el Dominio.'
          })}
        </span>
      </div>
      <div className="col-md-12">
        <div className="d-flex justify-content-center mt-2">
          <button type="submit" className="btn btn-primary mr-1">
            <i className="fa fa-check-circle mr-1"></i>
            {props.intl.formatMessage({ id: 'Actualizar', defaultMessage: 'Actualizar' })}
          </button>
          <button type="button" className="btn btn-danger" onClick={() => props.dismiss()}>
            <i className="fa fa-times-circle mr-1"></i>
            {props.intl.formatMessage({ id: 'Cancelar', defaultMessage: 'Cancelar' })}
          </button>
        </div>
      </div>
    </form>
  </>
  );
};
export default CambioDominioMovil;
