import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
//import LogoCompany from '../../assets/images/logo-company-login-tc-print.png'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import swal from 'sweetalert'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import Timezone from '../../commons/timezone/Timezone.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class NoReportanGrid extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.dataService = '/noreportan/grid';
		this.columnsToPrint = [2,3,4];
		moment.locale('es');

		this.state = {
			redirectTo: null,
			editKmEnabled: false,
			maxDifferenceKM: 0,
			canModifyKM: false
		}
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('REPORTES_NO_REPORTAN')) {
	      this.ajaxHandler.subscribe(this);
				this.initGrid();
	    } else {
		    	this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	handleKmChange() {
		alert('bla');
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">

											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													<th className=""></th>
													<th className="p-0"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;

		Promise.all([
			this.ajaxHandler.getJson('/bases/select-filtered-by-user/movil'),
		]).then((data) => {
			let filters = {

				taller: [{ value: 'NO', label: this.props.intl.formatMessage({ id: 'noReportanGrid.column_enTaller_filter.select_no', defaultMessage: 'NO' })},{ value: 'SI', label: this.props.intl.formatMessage({ id: 'noReportanGrid.column_enTaller_filter.select_si', defaultMessage: 'SI' })}],
				base: data[0] ? data[0]: []
			};
			component.setState({

			});

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',

					processing: true,
					serverSide: true,
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + this.dataService,
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = null; //JSON.parse(localStorage.getItem('DataTables_' + component.constructor.name)).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'noReportanGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'noReportanGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [
						{
						title: '',
						className: 'all',
						defaultContent: '',
						orderable: false,
						rrender: function (data, type, row) {
							return '<div class="dt-user-avatar">'+row.dominio.substr(0,1)+'</div>';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('div').tooltip();
						},
						filterType: 'none'
					}, {
						title: '',
						data: 'activo',
						width: 3,
						padding: 0,
						orderable: false,
						className: 'p-0 all',
						render: function(data, type, full, meta) {
							return '';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$('div.tooltip[role="tooltip"]').tooltip('dispose');
							$('div.tooltip[role="tooltip"]').remove();

							$(td).attr('data-togle', 'tooltip');
							$(td).attr('data-placement', 'top');
							$(td).attr('title', cellData ? 'Activo' : 'Inactivo');
							$(td).tooltip();
							if (cellData) {
								$(td).addClass('status-green');
							} else {
								$(td).addClass('status-red');
							}
						},
						filterType: 'none'
					}, {
						title: component.props.intl.formatMessage({ id: 'noReportanGrid.column_domain.label_dominio', defaultMessage: 'Dominio' }),
						className: 'all',
						data: 'dominio',
						filterType: 'input'
					}, {
						title: component.props.intl.formatMessage({ id: 'noReportanGrid.column_last_update.label_ultima_actualización', defaultMessage: 'Última Actualización' }),
						name: 'fecha',
						className: 'all',
						data: 'fecha',
						filterType: 'input',
						render: function(data, type, full, meta) {
							return data ? Timezone.getDateForClient(data, 'DD/MM/YYYY HH:mm', "DD/MM/YYYY HH:mm") : '';
						},
					}, {
						name: 'taller',
						title: component.props.intl.formatMessage({ id: 'noReportanGrid.column_in_workshop.label_en_taller', defaultMessage: 'En Taller' }),
						className: 'all',
						data: 'taller',

						filterType: 'select'
					}, {
						name: 'direccion',
						title: component.props.intl.formatMessage({ id: 'noReportanGrid.column_last_address.label_ultima_direccion', defaultMessage: 'Última Dirección' }),
						className: 'all',
						data: 'direccion',

						filterType: 'input'
					}, {
						name: 'base',
						title: component.props.intl.formatMessage({ id: 'noReportanGrid.column_base.label_base', defaultMessage: 'Base' }),
						className: 'all',
						data: 'base',
					/*	render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.descripcion ? data.descripcion : '';
							}
						},*/
						filterType: 'select'
					},{
							name: 'proveedor_gps',
							title: component.props.intl.formatMessage({ id: 'noReportanGrid.column_last_address.asdasd', defaultMessage: 'Prov. GPS' }),
							className: 'all',
							data: 'proveedor_gps',
							filterType: 'input'
						},{
						orderable: false,
						data: null,
						className: "text-center all",
						width: '120px',
						render: function(data, type, full, meta) {
							let html = "";


							return html;
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('button').tooltip();
						},
						filterType: 'none'

					}],
					drawCallback: function() {
						$(this).find('.action').on('click', function() {
							let data = component.table.row($(this).parents('tr')).data();
							let redirectTo;
							if ($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
							if ($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
							if ($(this).hasClass('correctivo')) redirectTo = '/correctivos/add/movil/' + data.id + '/' + data.dominio;
							component.setState({
								redirectTo: redirectTo
							});
						});
					},
					rowCallback: function(row, data) {
						if (data.marca) {
							if (data.marca.foto) {
								let photoUrl = Config.get('apiUrlBase') + '/marcas/foto/' + data.marca.foto;
								fetch(photoUrl, {
									method: 'GET',
									headers: {
										'Authorization-Token': localStorage.getItem("token")
									}
								}).then(response => {
									if (response.status === 200) {
										return response.blob();
									} else {
										$('#avatar', row).addClass('dt-user-avatar');
										$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
									}
								}).then(imgBlob => {
									$('#avatar', row).addClass('dt-user-avatar-image');
									$('#avatar', row).css('background-image', 'url(' + URL.createObjectURL(imgBlob) + ')');
								}).catch(() => {
									$('#avatar', row).addClass('dt-user-avatar');
									$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
								});
							} else {
								$('#avatar', row).addClass('dt-user-avatar');
								$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
							}
						} else {
							$('#avatar', row).addClass('dt-user-avatar');
							$('#avatar', row).html('');
						}

						$('.input-km', row).change(function() {
							var input = this;
							var value = this.value;
							var maxDif = component.state.maxDifferenceKM;
							var kmActual = data.km;
							if(Math.abs(value - kmActual) > maxDif) {
								swal({
									title: component.props.intl.formatMessage({ id: 'noReportanGrid.modal_km_update.confirm_diferencia_de_Km_mayor_a', defaultMessage: 'Diferencia de Km mayor a ' }) + maxDif + component.props.intl.formatMessage({ id: 'noReportanGrid.modal_km_update.confirm_km_esta_seguro_que_desea_actualizar', defaultMessage: ' Km. Esta seguro que desea actualizar?' }),
									text: "",
									icon: "warning",
									showCancelButton: true,
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'noReportanGrid.modal_km_update.confirm_aceptar', defaultMessage: 'Aceptar' }),
											value: true,
											visible: true,
											className: "btn btn-success",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'noReportanGrid.modal_km_update.confirm_cancelar', defaultMessage: 'Cancelar' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: false,
										}
									}
								}).then(isConfirm => {
									if (isConfirm) {
										$.ajax({
											type: 'PUT',
											url: Config.get('apiUrlBase') + '/moviles/' + data.id + '/km',
											data: '{ "km": ' + value + '}',
											contentType: "application/json; charset=utf-8",
											headers: {
												'Authorization-Token': localStorage.getItem("token")
											},
											dataType: "json",
											success: function(result){
												component.table.cell(row,7).data(value);
												input.value = '';
												swal(component.props.intl.formatMessage({ id: 'noReportanGrid.modal_km_update.information_confirm_se_actualizo_con_exito', defaultMessage: 'Se actualizó con Éxito!' }), "", "success");
											}
										});
									} else {
										swal(component.props.intl.formatMessage({ id: 'noReportanGrid.modal_km_update.information_confirm_actualizacion_cancelada!', defaultMessage: 'Actualización Cancelada!' }), "", "error");
									}
									input.value = '';
								});
							} else {
								$.ajax({
									type: 'PUT',
									url: Config.get('apiUrlBase') + '/moviles/' + data.id + '/km',
									data: '{ "km": ' + value + '}',
									contentType: "application/json; charset=utf-8",
									headers: {
										'Authorization-Token': localStorage.getItem("token")
									},
									dataType: "json",
									success: function(result){
										component.table.cell(row,7).data(value);
										input.value = '';
										swal(component.props.intl.formatMessage({ id: 'noReportanGrid.modal_km_update.information_se_actualizo_con_exito', defaultMessage: 'Se actualizó con Éxito!' }), " ", "success");
									}
								});
							}
						});
					}
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					extend: 'print',
					name: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win) {
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						} else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css('font-size', '10pt')
							.prepend(`<div class="container">
												<div class="row mb-2">
													<div class="col-6">
														<img style="height: 40px" src="` + LogoCompany + `" />
													</div>
													<div class="col-6	text-right">
														<img style="height: 40px" src="` + LogoVecFleet + `" />
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12" style="border-bottom: 1px solid #000;">
														<h2>`+component.props.intl.formatMessage({ id: 'noReportanGrid.print_content.header_listado_de_moviles', defaultMessage: 'Listado de Móviles' })+`</h2>
													</div>
												</div>
											</div>`);

						$(win.document.body).find('table')
							.addClass('compact')
							.css('font-size', 'inherit');
					},
					exportOptions: {
						columns: component.columnsToPrint
					},
					titleAttr: component.props.intl.formatMessage({ id: 'noReportanGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'noReportanGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
				}, {
					name: 'exportExcel',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'noReportanGrid.tools_export.exportar_a_excel', defaultMessage: 'Exportar a Excel' }),
					action: function (e, dt, node, config) {
						swal({
							title: component.props.intl.formatMessage({ id: 'noReportanGrid.tools_export.modal_information_se_estan_exportando_los_datos', defaultMessage: 'Se están exportando los datos.' }),
							text: component.props.intl.formatMessage({ id: 'noReportanGrid.tools_export.modal_information_esta_accion_puede_demorar', defaultMessage: 'Esta acción puede demorar.' }),
							buttons: {}
						});

						component.ajaxHandler.fetch('/noreportan/exportar-excel', {
							method: 'GET'
						}).then(response => {
							if (response.status === 200) {
								return response.blob();
							}
						}).then(fileBlob => {
							let fileUrl = URL.createObjectURL(fileBlob);
							$("<a />", {
								href: fileUrl,
								download: "fleet-moviles-no-reportan.xlsx"
							}).appendTo("body").get(0).click();
						}).catch(() => {
						}).finally(() => {
							swal.close();
						});
					}
				},  ]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('print:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('columns:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('export:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('import:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('exportExcel:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();

			$('.buttons-collection').on("click",function(e){ e.stopPropagation();
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
	}

	getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}
}

export default injectIntl(NoReportanGrid);
