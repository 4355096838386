import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import Security from '../../../commons/security/Security';

const MovilesAbmBaseField = ({ formData, props, state, handleSelectChange }) => {
    const requiredSymbol = '*';
    return (
        <React.Fragment>
            {/* BASE */}
            <div className="col-md-6" >
                <div className="form-group row">
                    <label className="col-md-3 label-control col-form-label" htmlFor="base">
                        <FormattedMessage id="movilesAbm.render.administracion.label_base" defaultMessage="Base" />{requiredSymbol}:
                    </label>
                    <div className="col-md-9">
                        {props.action === 'VIEW' || (props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                            <div className="form-control-static col-form-label form-value">{formData.base && formData.base.descripcion ? formData.base.descripcion : ''}</div>
                        ) : (
                            <div>
                                <Select
                                    id="base"
                                    name="base"
                                    placeholder={!state.basesLoading ? props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_base', defaultMessage: 'Seleccione' }) : ''}
                                    options={state.bases}
                                    valueKey='value'
                                    labelKey='label'
                                    value={formData.base ? { value: formData.base.id, label: formData.base.descripcion } : null}
                                    onChange={handleSelectChange}  // Add this handler to catch changes
                                    isLoading={state.basesLoading}
                                    disabled={state.basesLoading}
                                />
                                <small className="text-danger">{state.validationErrors.base}</small>
                            </div>
                        )}
                    </div>
                </div>
            </div >
        </React.Fragment>
    );
};

export default MovilesAbmBaseField;