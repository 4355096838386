import React, { useState } from 'react';

const TicketEditaDetalle = ({ detalle, onSave, editable }) => {

    const [newDetalle, setNewDetalle] = useState(detalle);

    const handleChange = (e) => {    
      setNewDetalle(e.target.value);  ;
    };

    const handleSave = () => {
        onSave(newDetalle);
    };

    return (
        <>
            {editable ? (
                <>
                    <textarea
                        id='detalleTextArea'
                        value={newDetalle}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Ingrese el detalle"
                        rows={3} 
                    />
                    <button onClick={handleSave} className="btn btn-primary mt-2">
                        Guardar
                    </button>
                </>
            ) : (
                <div id='detalleTextArea' className="form-value-detalle">{detalle}</div>
            )}
        </>
    );
};

export default TicketEditaDetalle;
