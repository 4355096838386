import * as Constants from './constants.js'

export const atributosDinamicosRequeridosFaltantes = (atributos_dinamicos) => {
	return (atributos_dinamicos.filter(atributo => atributo.required && (Array.isArray(atributo.value) ? atributo.value.length === 0 : !atributo.value))).length
}

export const castValoresDinamicos = (atributos_dinamicos, valores_dinamicos, defaults=true) => {
	let valores_a_devolver = []
	atributos_dinamicos.forEach(atributo => {
		let valor_dinamico = Object.assign({}, atributo)
		delete valor_dinamico.id
		delete valor_dinamico.modelo_dinamico_id
		delete valor_dinamico.modelo_dinamico_type
		valor_dinamico.atributo_dinamico_id = atributo.id
		let valorCorrespondiente = valores_dinamicos.find( valor => valor.atributo_dinamico_id === atributo.id );
		if (valorCorrespondiente) {
			valor_dinamico.id = valorCorrespondiente.id
			valor_dinamico.value = valorCorrespondiente.value
			valor_dinamico.estado = valorCorrespondiente.estado
			valor_dinamico.observaciones = valorCorrespondiente.observaciones
			valor_dinamico.adjunto_id = valorCorrespondiente.adjunto_id
			valor_dinamico.adjuntos = valorCorrespondiente.adjuntos;
      // TODO: adaptar a varios tickets
			valor_dinamico.ticket_id = valorCorrespondiente.ticket_id
			valor_dinamico.ticket = valorCorrespondiente.ticket
			valor_dinamico.tickets = valorCorrespondiente.tickets
      if (valorCorrespondiente.tickets != 0) {
        let estados = valorCorrespondiente.tickets.map(ticket =>  ticket.estadoColor);
        console.log(estados);
        if (estados.includes(Constants.ID_ESTADO_APROBACION_VALIDO)) {
          valor_dinamico.tickets_estado = Constants.ID_ESTADO_APROBACION_VALIDO
        } 
        
        if (estados.includes(Constants.ID_ESTADO_APROBACION_INVALIDO)) {
          valor_dinamico.tickets_estado = Constants.ID_ESTADO_APROBACION_INVALIDO
        }

        if (estados.includes(Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION)) {
          valor_dinamico.tickets_estado = Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION
        } 

        if (estados.includes(Constants.ID_ESTADO_APROBACION_PENDIENTE)) {
          valor_dinamico.tickets_estado = Constants.ID_ESTADO_APROBACION_PENDIENTE
        } 
      }
			valores_a_devolver.push(valor_dinamico)
		} else if (defaults) {
			valor_dinamico.value = atributo.valorDefault
			valores_a_devolver.push(valor_dinamico)
		}
	})
	return valores_a_devolver
}
