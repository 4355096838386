import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'

import Security from '../../commons/security/Security.js'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
//import dtConfig from '../../commons/datatables/DatatablesConfig.js'
import dtConfig from './PanolGrid.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'

import { getAlmacenes, getAlmacenesList, createAlmacenes, updateAlmacenes, deleteAlmacenes } from './MetodosPanol.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class AlmacenConsolidado extends Component {

	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			loading: true,
			grid: {},
			almacenes: [],
			create: null,
			update: null,
			delete: null
		}
	}
	async componentDidMount() {

    	let component = this;
    	let filters = null;
    	this.setState({isLoading: true});
			let getGrid = await getAlmacenes().then(res=>{
	        this.setState({ almacenes: res.data });
	       	return this.state.almacenes
	    }).then(result => {
					this.setState({isLoading: false})
					console.log("termina la  carga: "+ this.state.isLoading);
					this.forceUpdate();
	    })
	    const config = {
	    		headers: {
		    		'Content-Type': 'application/json',
						'Authorization-Token': localStorage.getItem("token")
	    	 	}
			};
     	let table = $(this.refs.main).DataTable(
			Object.assign({
			//dom: '<"data-table-wrapper">',
				stateSave: true,
				stateSaveCallback: function(settings,data) {
						localStorage.setItem( 'DataTables_AlmacenConsolidado', JSON.stringify(data) );
				},
				stateLoadCallback: function(settings) {
						return JSON.parse( localStorage.getItem( 'DataTables_AlmacenConsolidado') );
				},
				ajax: {
						cache: false,
						type: 'GET',
						url: Config.get('apiUrlBase') + '/panol/almacenes/consolidado',
						headers: {
								'Content-Type': 'application/json',
								'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(res){
								return res.items;
						},
						// error: function (xhr, error, thrown) {
						// },
				},
				initComplete: function (settings) {
					  // SOLO PARA FILTROS
						// RECORDAR
						// filterType: "input"
						// Numero de columnas y class en <table>
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_AlmacenConsolidado')).columns;
						var sets = settings;
						var index = 0;
						this.api().columns().every( function () {
							var column = this;
							if(sets.aoColumns[index].filterType){
								if(sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'almacenConsolidado.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo( $(column.footer()).empty() )
										.on( 'change', function () {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search( val ? val : '', true, false )
												.draw();
										});
									if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="'+e.value+'">'+e.label+'</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each( function ( d, j ) {
											select.append( '<option value="'+d+'">'+d+'</option>' );
										});
									}
									if(stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if(sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'almacenConsolidado.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on( 'keyup change', function () {
										if ( column.search() !== this.value ) {
											column
											.search( this.value )
											.draw()
											.ajax.reload(null, false);
										}
									});
								}
							}
							index++;
							return '';
						});
						$('tfoot tr').appendTo('thead');
			},
			columns: [
				{
						title: component.props.intl.formatMessage({ id: 'almacenConsolidado.column_id.label_id', defaultMessage: 'id' }),
						className: "text-center",
						visible: false,
						data: "id",
				},
				{
						title: component.props.intl.formatMessage({ id: 'almacenConsolidado.column_item.label_item', defaultMessage: 'Item' }),
						className: "text-center",
						data: "item.descripcion",
						// solo para el filter que permite buscar
						filterType: "input"
				},
				{
						title: component.props.intl.formatMessage({ id: 'almacenConsolidado.column_subcategory.label_subcategoria', defaultMessage: 'subcategoria' }),
						className: "text-center",
						data: "item.subcategoria.nombre",
						filterType: "input"
				},
				{
						title: component.props.intl.formatMessage({ id: 'almacenConsolidado.column_amount.label_cantidad', defaultMessage: 'Cantidad' }),
						className: "text-center",
						data: "cantidad"
				},
				{
						title: component.props.intl.formatMessage({ id: 'almacenConsolidado.column_cost.label_costo', defaultMessage: 'Costo' }),
						className: "text-center",
						data: "costo"
				},
				{
						title: component.props.intl.formatMessage({ id: 'almacenConsolidado.column_total.label_total', defaultMessage: 'Total' }),
						className: "text-center",
						data: "total"
				},
				{
						title: component.props.intl.formatMessage({ id: 'almacenConsolidado.column_unit.label_unidad', defaultMessage: 'Unidad' }),
						className: "text-center",
						data: "item.unidad.descripcion"
				},
    	],
			fixedHeader: true,
    	sDom: 'lrtip',
			//dtInit
			}, dtConfig)
		) //fin dt

		$(document).find("#example thead th").css("height","43px");
		$(document).find("#example thead th").css("padding-left","20px");
		$(document).find("#example tbody td").css("padding-left","20px");
		$('[data-toggle="tooltip"]').tooltip();

		new $.fn.dataTable.Buttons( table, {
			buttons: [
				{
          extend: 'print',
          text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win)
					{
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						}	else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css( 'font-size', '10pt' )
							.prepend(`<div class="container">
													<div class="row mb-2">
														<div class="col-6">
															<img style="height: 35px" src="`+LogoCompany+`" />
														</div>
														<div class="col-6	text-right">
															<img style="height: 40px" src="`+LogoVecFleet+`" />
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-12" style="border-bottom: 1px solid #000;">
															<h2>`+component.props.intl.formatMessage({ id: 'almacenConsolidado.print_content.header_listado_de_regiones', defaultMessage: 'Listado de Regiones' })+`</h2>
														</div>
													</div>
												</div>`);

						$(win.document.body).find( 'table' )
							.addClass( 'compact' )
							.css( 'font-size', 'inherit' );
			 		},
          exportOptions: {
							columns: [ 3,4,5,6 ]
          },
					titleAttr: component.props.intl.formatMessage({ id: 'almacenConsolidado.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
        },
        {
          extend: 'colvis',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
        },
        {
          extend: 'collection',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'almacenConsolidado.tools_export.title_Exportar', defaultMessage: 'Exportar' }),
          buttons: [
          {
            extend: 'copyHtml5',
            exportOptions: {
              columns: ':visible'
            },
						text: component.props.intl.formatMessage({ id: 'almacenConsolidado.tools_export.select_text_copiar', defaultMessage: 'Copiar' }),
						titleAttr: component.props.intl.formatMessage({ id: 'almacenConsolidado.tools_export.select_copiar', defaultMessage: 'Copiar' })
          },
          {
            extend: 'excelHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'almacenConsolidado.tools_export.select_exportar_excel', defaultMessage: 'Exportar a Excel' })
          },
          {
            extend: 'csvHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'almacenConsolidado.tools_export.select_exportar_formato_csv', defaultMessage: 'Exportar en formato .csv' })
          },
          {
            extend: 'pdfHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'almacenConsolidado.tools_export.select_exportar_PDF', defaultMessage: 'Exportar a PDF' })
					}
					]
				},

			]
		});

		table.buttons( 0, null ).container().appendTo('#buttons');


		/*
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
		*/

		$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
			if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
				$('.dt-button-collection>.dropdown-menu').css('display','block');
		});
    }
    componentWillUnmount(){
       $('.data-table-wrapper')
       .find('table')
       .DataTable()
       .destroy(true);
    }
    shouldComponentUpdate() {
        return false;
    }

    onChange = (e) => {
    	this.props.history.push(`/panol/stock/almacen/${e.target.value}`);
  	}
  	onIngresoClick = (e) => {
  		let redirectTo;
		redirectTo = '/panol/stock/ingreso';
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
  	}
  	onEgresoClick = (e) => {
  		let redirectTo;
		redirectTo = '/panol/stock/egreso';
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
  	}
  	onTransferClick = (e) => {
  		let redirectTo;
		redirectTo = '/panol/stock/transferencia';
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
  	}
  	onAjusteClick = (e) => {
  		let redirectTo;
		redirectTo = '/panol/stock/ajuste';
		this.setState({redirectTo: redirectTo});
		this.forceUpdate();
  	}
	onModalEditSubmit = (e) => {
	  		e.preventDefault();
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();
	  		updateAlmacenes(e).then(res =>{

				$(this).find('form').trigger('reset');
				$("#editAlmacenesModal").modal("hide");
				swal(component.props.intl.formatMessage({ id: 'almacenConsolidado.modal_edit.information_modificado', defaultMessage: 'Modificado!' }), "", "success");
				let getGrid2 = getAlmacenes().then(res2=>{
	    		let dataR2 = res2.data;
			    component.setState({ grid: dataR2 });
			    component.setState({ almacenes: [] });
	        	component.setState({ almacenes: component.state.almacenes.concat(dataR2) });
				table.ajax.reload();
			    console.log("actualiza grid:");
			    console.log(component.state.grid);
			    this.forceUpdate();
			    })
			})
	  	}
	  	onModalNewSubmit = (e) => {
	  		e.preventDefault();
	  		let component = this;
	  		let table = $(this.refs.main).DataTable();
	  		createAlmacenes(e).then(res =>{

				    $("#newAlmacenesModal").find('form').trigger('reset');
				    $("#newAlmacenesModal").modal("hide");

				swal(component.props.intl.formatMessage({ id: 'almacenConsolidado.modal_add.information_agregado', defaultMessage: 'Agregado!' }), "", "success");

				let getGrid2 = getAlmacenes().then(res2=>{
	    		let dataR2 = res2.data;
			        component.setState({ grid: dataR2 });
			        component.setState({ almacenes: [] });
	        		component.setState({ almacenes: this.state.almacenes.concat(dataR2) });
				table.ajax.reload();
			    console.log("actualiza grid:");
			    console.log(component.state.grid);
			    this.forceUpdate();
			    })

			})

	  	}

    render() {
         if (this.state.isLoading) return <div>Cargando...</div>;
	      return (
        	<React.Fragment>
        	{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
    		<div className="row mt-2">
				<div className="col-12">
					<div className="card">
						<div className="card-header">
							<h1><FormattedMessage id="almacenConsolidado.render.header_seleccione_un_almacen" defaultMessage="Seleccione un Almacen"/></h1>
						</div>
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">

				               <select className="form-control form-control-lg" onChange={this.onChange}>
								  <option defaultValue value="">Consolidado</option>
								  	{this.state.almacenes.map((value) => {
				                       return <option key={value.id} value={value.id}>{value.nombre}</option>
				                    })}
								</select>
				            </div>
			            </div>
					</div>
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
										<div className="col-6">
                                            {Security.renderIfHasPermission('PANOL_ALMACEN_CREAR', (
											<div
												className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'almacenConsolidado.render.button_add.title_nuevo_almacen', defaultMessage: 'Nuevo Almacen' })}
												data-toggle="modal"
												data-target="#newAlmacenesModal"
												>
												<i className="ft-plus"></i>
											</div>))}
                                            {Security.renderIfHasPermission('PANOL_ALMACEN_INGRESAR_ITEM', (
											<div
												className="btn btn-secondary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'almacenConsolidado.render.button_add.title_ingreso_item', defaultMessage: 'Ingreso Item' })}
												onClick={this.onIngresoClick}
												>
												<i className="fas fa-file-import"></i>
											</div>))}
                                            {Security.renderIfHasPermission('PANOL_ALMACEN_EGRESAR_ITEM', (
											<div
												className="btn btn-secondary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'almacenConsolidado.render.button_add.title_egreso_item', defaultMessage: 'Egreso Item' })}
												onClick={this.onEgresoClick}
												>
												<i className="fas fa-file-export"></i>
											</div>))}
                                            {Security.renderIfHasPermission('PANOL_ALMACEN_TRANSFERIR', (
											<div
												className="btn btn-secondary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'almacenConsolidado.render.button_add.title_transferencia', defaultMessage: 'Transferencia' })}
												onClick={this.onTransferClick}
												>
												<i className="fas fa-exchange-alt"></i>
											</div>))}
                                            {Security.renderIfHasPermission('PANOL_ALMACEN_AJUSTAR_STOCK', (
											<div
												className="btn btn-secondary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
												data-toggle="tooltip"
												data-placement="right"
												title={this.props.intl.formatMessage({ id: 'almacenConsolidado.render.button_add.title_ajuste_de_stock', defaultMessage: 'Ajuste de stock' })}
												onClick={this.onAjusteClick}
												>
												<i className="fas fa-edit"></i>
											</div>))}
										</div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div>
				               	<div>
				               		<table id="example" ref="main" className="nowrap table-hover" width="100%">
															<tfoot>
																<tr style={{backgroundColor: '#fff'}}>
																	<th className=""></th>
																	<th className="dt-search-header"><FormattedMessage id="almacenConsolidado.render.table_column_item" defaultMessage="Item"/></th>
																	<th className="dt-search-header"><FormattedMessage id="almacenConsolidado.render.table_column_subcategoria" defaultMessage="subcategoria"/></th>
																	<th className=""></th>
																	<th className=""></th>
																	<th className=""></th>
																	<th className=""></th>
																</tr>
															</tfoot>
													</table>
					            </div>

				            </div>
			            </div>
					</div>
				</div>
			{/*---Modal nuevo Almacen---*/}
				<div className="modal fade" id="newAlmacenesModal" tabIndex="-1" role="dialog" aria-labelledby="newAlmacenesModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="newAlmacenesModalLabel"><FormattedMessage id="almacenConsolidado.render.modal_add_warehouse.header_nuevo_almacen" defaultMessage="Nuevo Almacen"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <form onSubmit={this.onModalNewSubmit}>
					      <div className="modal-body">
					        <div className="row">
						        <div className="col-md-12">
						          <div className="form-group">
							           	<label htmlFor="nombreAlmacen" className="col-form-label"><FormattedMessage id="almacenConsolidado.render.modal_add_warehouse.label_nombre_del_almacen" defaultMessage="Nombre del Almacen:"/></label>
							            <input
							            	type="text"
							            	className="form-control"
							            	name="nombre"
							            	id="newNombreAlmacen"
							            	placeholder={this.props.intl.formatMessage({ id: 'almacenConsolidado.render.modal_add_warehouse.placeholder_nombre_del_almacen', defaultMessage: 'Ej: Almacen 2' })}
							            />
						          </div>
						          </div>
						        </div>
				          	</div>
					      <div className="modal-footer">
					        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="almacenConsolidado.modal_add_warehouse.finish_button.cancelar" defaultMessage="Cancelar"/></button>
					        <button type="submit" className="btn btn-primary"><FormattedMessage id="almacenConsolidado.modal_add_warehouse.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
					      </div>
				      </form>
				    </div>
				  </div>
				</div>

				{/*---Modal edit Almacen---*/}
				<div className="modal fade" id="editAlmacenesModal" tabIndex="-1" role="dialog" aria-labelledby="editAlmacenesModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="editAlmacenesModalLabel"><FormattedMessage id="almacenConsolidado.render.modal_edit_warehouse.header_editar_almacen" defaultMessage="Nombre del Almacen:"/></h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				    	<form onSubmit={this.onModalEditSubmit}>
					      <div className="modal-body">
					        <div className="row">
						        <div className="col-md-12">
						          <div className="form-group">
						          		<input
							            	type="hidden"
							            	className="form-control"
							            	name="id"
							            	id="editIdAlmacen"
							            />
						           		<label htmlFor="nombreAlmacen" className="col-form-label"><FormattedMessage id="almacenConsolidado.render.modal_edit_warehouse.label_nombre_de_la_almacen" defaultMessage="Nombre de la Almacen:"/></label>
							            <input
							            	type="text"
							            	className="form-control"
							            	name="nombre"
							            	id="editNombreAlmacen"
							            />
						          </div>
						        </div>
				          		</div>
						      </div>
						      <div className="modal-footer">
						        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="almacenConsolidado.modal_edit_warehouse.finish_button.cancelar" defaultMessage="Cancelar"/>Cancelar</button>
						        <button type="submit" className="btn btn-primary"><FormattedMessage id="almacenConsolidado.modal_add_warehouse.finish_button.guardar_datos" defaultMessage="Guardar datos"/></button>
						      </div>
				        </form>
				    </div>
				  </div>
				</div>

			</div>
		</React.Fragment>
            );
    }
}

export default injectIntl(AlmacenConsolidado);
