import React, { useState, useEffect } from "react"
import { useIntl, FormattedMessage } from 'react-intl'
import * as H from '../../commons/utils/Helpers'
import $ from 'jquery'
import Select from 'react-select'

const ServicioItems = ({ itemList, servicioItems, ticketTipo, saveCallback }) => {
	const intl = useIntl()
	const [optionItems, setOptionItems] = useState([])
	const [selectedItems, setSelectedItems] = useState([])
	const [currentItem, setCurrentItem] = useState('')

	useEffect(() => {
		if (!H.isEmptyArray(servicioItems)) setSelectedItems([...servicioItems])

		if (!H.isEmptyArray(itemList)) setOptionItems(itemList)

	}, [servicioItems, itemList])

	useEffect(() => { disableAdd() }, [currentItem]);

	useEffect(() => {
		if (!H.isEmptyArray(optionItems)) updateDisableItems()
	}, [selectedItems])

	const updateDisableItems = () => {
		let selectedValues = selectedItems.map(H.pluck('value'))
		let checkedOptionItems = optionItems.map(option => {

			let isDisabled = selectedValues.includes(option.value) ? true : false

			return { "value": option.value, "label": option.label, "cantidad": option.cantidad, "disabled": isDisabled }
		})

		setOptionItems(checkedOptionItems)
	}

	const updateCantidad = (index, cantidad) => {
		if (!(cantidad == 0 && ticketTipo === 'PREVENTIVO')) {

			let selItems = [...selectedItems]
			selItems[index]['cantidad'] = cantidad
			setSelectedItems(selItems)

		}
	}

	const addItem = () => {
		setSelectedItems([...selectedItems, currentItem])
		setCurrentItem('')

	}

	const removeItem = (index) => {
		let items = [...selectedItems]
		items.splice(index, 1)
		setSelectedItems([...items])
	}

	const closeModal = () => {
		setCurrentItem('')
		setSelectedItems([...servicioItems])
		$('#repuestos_modal').modal('toggle')
	}

	const disableAdd = () => {
		if (ticketTipo !== 'PREVENTIVO' && !currentItem.label) return true

		if (ticketTipo === 'PREVENTIVO' && !currentItem) return true

		if (ticketTipo === 'PREVENTIVO' && (currentItem.cantidad == '' || currentItem.cantidad == 0 || currentItem.cantidad == null)) return true

		return false
	}



	return (
		<>
			<div className="modal fade" tabIndex="-1" id="repuestos_modal" role="dialog" aria-labelledby="myModalLabel" style={{ overflow: 'visible' }} aria-hidden="true" data-backdrop="static" data-keyboard="false">
				<div className="modal-md modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="myModalLabel">
								<FormattedMessage id="serviciosAbm.repuestos.modal_title" defaultMessage=" Editar Repuestos" />
							</h4>
							<button type="button" className="close" onClick={() => closeModal()}><span>&times;</span></button>
						</div>
						<div className="modal-body modal-grey" style={{ overflowY: 'visible' }}>
							<div className="row" style={{overflowY: 'scroll', maxHeight: '60vh'}}>
								<div className="col">
									{selectedItems && selectedItems.map((item, index) => {
										return (
											<div className="form-group row" key={index}>
												<div className="col-md-8">
													<input
														type="text"
														className="form-control"
														value={item.label}
														disabled
													/>
												</div>
												<div className='col-md-4' style={{ display: 'flex' }}>
													<input
														type="number"
														name={item.label}
														className="form-control"
														value={item.cantidad}
														min={ticketTipo === 'PREVENTIVO' ? "1" : "0"}
														step="1"
														onChange={(e) => updateCantidad(index, e.target.value)}
														onKeyDown={(e) => H.preventSymbolsOnNumerInput(e)}
													/>
													<button
														className="btn btn-sm btn-icon btn-dt-grid text-danger ps-1"
														onClick={() => removeItem(index)}
													>
														<i className="fa fa-trash fa-xs"></i>
													</button>
												</div>
											</div>

										)
									})
									}
								</div>
							</div>
							<div className="form-group row">
								<div className="col-md-10">
									<label className="col-md-12 col-form-label" htmlFor="tareasServicio">
										<FormattedMessage id="serviciosAbm.repuestos.modal.add_repuesto" defaultMessage="Agregar Repuestos" />:
									</label>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-md-7">
									<div>
										<Select
											clearable={false}
											id="item"
											name="item"
											placeholder={intl.formatMessage({ id: "serviciosAbm.repuestos.modal.add_repuesto.placeholder", defaultMessage: "Repuesto", })}
											options={optionItems}
											valueKey="value"
											labelKey="label"
											closeOnSelect={true}
											value={currentItem}
											onChange={(value) => setCurrentItem(value)}
											backspaceRemoves={false}
											deleteRemoves={false}
										/>
									</div>
								</div>
								<div className="col-md-3">
									<input
										type="number"
										id='itemTempCantidad'
										name='itemTempCantidad'
										className="form-control"
										min="0"
										step="1"
										value={currentItem.cantidad || ''}
										onChange={(e) => setCurrentItem({ ...currentItem, cantidad: e.target.value })}
										disabled={!currentItem.label}
										onKeyDown={(e) => H.preventSymbolsOnNumerInput(e)}
									/>
								</div>
								<div className="col-md-2">
									<button
										className="btn btn-icon-add text-center text-fleet p-0"
										role="button"
										onClick={addItem}
										style={{ backgroundColor: "transparent" }}
										disabled={disableAdd()}
									>
										<i className="ft-plus-circle"></i>
									</button>
								</div>
							</div>
						</div>
						<div className="modal-footer modal-grey">
							<button
								type="submit"
								className="btn btn-primary mr-1"
								onClick={() => {
									saveCallback(selectedItems);
									setCurrentItem('');
								}} >
								<i className="fa fa-check-circle"></i>
								<FormattedMessage id="serviciosAbm.repuestos.modal_guardar" defaultMessage=" Guardar" />
							</button>
							<button
								type="button"
								className="btn btn-danger"
								onClick={() => closeModal()}>
								<i className="fa fa-times-circle">
								</i> <FormattedMessage id='serviciosAbm.repuestos.modal_cancelar' defaultMessage='Cancelar' />
							</button>
						</div>
					</div>
				</div>
			</div >
		</>
	)
}

export default ServicioItems