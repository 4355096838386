import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import TicketsGrid from './TicketsGrid.jsx'
import TicketsAbm from './TicketsAbm'
import {FormattedMessage, injectIntl} from 'react-intl';
import * as Multilanguage from '../../commons/utils/Multilanguage'
import Loading from '../ui/Loading';

class Tickets extends Component {

	constructor(props) {
		super(props);
	
		this.state = {
			ticketTipo: localStorage.getItem('ticketsTipoStrings') ? JSON.parse(localStorage.getItem('ticketsTipoStrings')) : [],
			ticketEstado: localStorage.getItem('ticketsEstadoStrings') ? JSON.parse(localStorage.getItem('ticketsEstadoStrings')) : []
		};
	  }

	  async componentDidMount() {
		let ticketTipo = localStorage.getItem('ticketsTipoStrings')
		let ticketEstado = localStorage.getItem('ticketsEstadoStrings')

		let ticketTipoML = Multilanguage.setTicketTipoStringsToLocalStorage(this.props.intl)
		let ticketEstadoML = await Multilanguage.setTicketEstadoStringsToLocalStorage(this.props.intl)

		if (!ticketTipo || JSON.parse(ticketTipo) !== ticketTipoML) {
			localStorage.setItem('ticketsTipoStrings', JSON.stringify(ticketTipoML))
			this.setState({ ticketTipo: ticketTipoML})
		}

		if (!ticketEstado || JSON.parse(ticketEstado) !== ticketEstadoML) {
			localStorage.setItem('ticketsEstadoStrings', JSON.stringify(ticketEstadoML))
			this.setState({ ticketEstado: ticketEstadoML})
		}
	  }


	render() {
	    return (
		<>
		{ this.state.ticketEstado.length == 0 ? ( <Loading /> ) : ( 
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
						<h3 className="content-header-title"><i className="la la-clipboard ml-1 mr-1 align-middle"></i><FormattedMessage id="Gerenciadores.header_administracion_de_tickets" defaultMessage="Administración de Tickets"/></h3>
					</div>
				</div>
				<div className="content-body">
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={TicketsGrid} />
						<Route exact path={`${this.props.match.url}/:id/edit`} component={(props) => <TicketsAbm {...props} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} component={(props) => <TicketsAbm {...props} action="VIEW" />} />
					</Switch>
				</div>
			</div> 
		)}
		</>
	    );
  	}
}

export default injectIntl(Tickets);