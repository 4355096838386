import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import AlmacenConsolidado from './AlmacenConsolidado'
import ConfigAbm from './ConfigAbm.js'
import MedidasAbm from './ConfigAbm.js'
import MarcasAbm from './MarcasAbm.js'
import CategoriasAbm from './CategoriasAbm.js'
import SubCatAbm from './SubCatAbm.js'
import ItemsAbm from './ItemAbm.js'
import PlantillasAbm from './PlantillasAbm.js'
import PlantillasDetalle from './PlantillasDetalle.js'
import MovimientoStockOperaciones from './MovimientoStockOperaciones.js'
import MovimientoStockProductos from './MovimientoStockProductos.js'
import MovimientoStockNew from './MovStockNew.js'
import MovimientoStockEgreso from './MovStockEgreso.js'
import MovimientoStockTransfer from './MovStockTransferencia.js'
import MovimientoStockAjuste from './MovStockAjuste.js'
import MovStockView from './MovStockView.js'
import {FormattedMessage, injectIntl} from 'react-intl';

class Panol extends Component {

	componentDidMount() {
		//$(document).find('#main-menu-navigation .panol').addClass('open');
		let menuOpen = document.getElementsByClassName('panol')[0].classList.add("open");
	}

	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      					<h3 className="content-header-title"><FormattedMessage id="panol.header_administracion de Panol" defaultMessage="Administración de Pañol"/></h3>
      				</div>
				</div>
				<div className="content-body">
					<Switch>
						<Route exact path={`${this.props.match.url}`} component={AlmacenConsolidado} />
						<Route exact path={`${this.props.match.url}/stock`} component={AlmacenConsolidado} />
						<Route exact path={`${this.props.match.url}/stock/almacen/:id`} render={(props) => ( <MovimientoStockProductos key={props.match.params.id} {...props} />)} />
						<Route exact path={`${this.props.match.url}/stock/almacen/:id/operaciones`} render={(props) => <MovimientoStockOperaciones {...props} action="VIEW" />} />
						<Route exact path={`${this.props.match.url}/stock/ingreso`} component={MovimientoStockNew} />
						<Route exact path={`${this.props.match.url}/stock/egreso`} component={MovimientoStockEgreso} />
						<Route exact path={`${this.props.match.url}/stock/transferencia`} component={MovimientoStockTransfer} />
						<Route exact path={`${this.props.match.url}/stock/ajuste`} component={MovimientoStockAjuste} />
						<Route exact path={`${this.props.match.url}/operaciones/:id`} component={MovStockView} />
						<Route exact path={`${this.props.match.url}/plantillas`} component={PlantillasAbm} />
						<Route exact path={`${this.props.match.url}/plantillas/:id`} render={(props) => <PlantillasDetalle {...props} action="VIEW" />}/>
						<Route exact path={`${this.props.match.url}/config`} component={ConfigAbm} />
						<Route exact path={`${this.props.match.url}/config/marcas`} component={MarcasAbm} />
						<Route exact path={`${this.props.match.url}/config/categorias`} component={CategoriasAbm} />
						<Route exact path={`${this.props.match.url}/config/subcategorias`} component={SubCatAbm} />
						<Route exact path={`${this.props.match.url}/config/items`} component={ItemsAbm} />
					</Switch>
				</div>
			</div>
	    );
  	}
}

export default Panol;
