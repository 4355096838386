import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import * as Utils from "../../commons/utils/Utils";
import Security from '../../commons/security/Security'

const ClasesAbm = (props) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [loading, setLoading] = useState(null);
  const formContainer = useRef("form");
  const [formData, setFormData] = useState({ nombre: "", dias_ultima_inspeccion: 0 });

  useEffect(() => {

    if (Security.hasPermission('MOVILES_LISTAR')) {
      props.action === "VIEW" || props.action === "EDIT" ? loadFormData() : initBlank();
    } else {
      setRedirectTo('/error');
    }

  }, []);
  const initBlank = () => {};
  const loadFormData = () => {
    Utils.getData("/llanta-modelo-clases/" + props.match.params.id).then((res) => {
      setFormData(res);
    });
  };

  const handleFormChange = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy[e.target.name] = e.target.value;
    setFormData(formDataCopy);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Utils.sendData(
      "/llanta-modelo-clases" + (props.action === "EDIT" ? "/" + props.match.params.id : ""),
      props.action === "EDIT" ? "PUT" : "POST",
      formData
    ).then((res) => {
      setRedirectTo("/clases");
    });
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <form className="form form-horizontal" ref={formContainer}>
        <div className="form-body">
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body">
                <h4 className="form-section">
                  <i className="icon-home"></i>
                  <FormattedMessage
                    id="clasesAbm.render.general_data.header_datos_generales"
                    defaultMessage=" Datos Generales "
                  />
                  <div className="float-right" style={{ fontSize: "14px" }}>
                    <FormattedMessage
                      id="clasesAbm.render.general_data.campos_requeridos"
                      defaultMessage="* campos requeridos"
                    />
                  </div>
                </h4>
                {/* Nombre */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                        <FormattedMessage id="clasesAbm.render.general_data.labelnombre" defaultMessage="Nombre *:" />
                      </label>
                      <div className="col-md-9">
                        {props.action === "VIEW" ? (
                          <div className="form-control-static col-form-label form-value">{formData.nombre}</div>
                        ) : (
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              id="nombre"
                              name="nombre"
                              placeholder={props.intl.formatMessage({
                                id: "clasesAbm.render.general_data.placeholder_nombre",
                                defaultMessage: "Nombre",
                              })}
                              value={formData.nombre}
                              onChange={handleFormChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Dias Ultima Inspeccion */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-3 label-control col-form-label" htmlFor="dias_ultima_inspeccion">
                        <FormattedMessage
                          id="clasesAbm.render.general_data.label_dias_ultima_inspeccion"
                          defaultMessage="Dias Ultima Inspeccion *:"
                        />
                      </label>
                      <div className="col-md-9">
                        {props.action === "VIEW" ? (
                          <div className="form-control-static col-form-label form-value">
                            {formData.dias_ultima_inspeccion}
                          </div>
                        ) : (
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              id="dias_ultima_inspeccion"
                              name="dias_ultima_inspeccion"
                              placeholder={props.intl.formatMessage({
                                id: "clasesAbm.render.general_data.placeholder_dias_ultima_inspeccion",
                                defaultMessage: "Dias Ultima Inspeccion",
                              })}
                              value={formData.dias_ultima_inspeccion}
                              onChange={handleFormChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body">
                <div className="text-cd text-right">
                  {props.action !== "VIEW" && (
                    <button className="btn btn-primary mr-1" onClick={handleSubmit}>
                      <i className="fa fa-check-circle"></i>
                      <FormattedMessage id="modelosAbm.finish_button.guardar" defaultMessage=" Guardar" />
                    </button>
                  )}
                  <button type="button" className="btn btn-danger" onClick={() => setRedirectTo("/clases")}>
                    <i className="fa fa-times-circle"></i>{" "}
                    {props.action === "VIEW"
                      ? props.intl.formatMessage({
                          id: "modelosAbm.finish_button.volver",
                          defaultMessage: "Volver",
                        })
                      : props.intl.formatMessage({
                          id: "modelosAbm.finish_button.cancelar",
                          defaultMessage: "Cancelar",
                        })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default injectIntl(ClasesAbm);
