import React, { useState, useEffect} from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import {
	Collapse,
	TextField,
	Grid,
	Box,
} from '@material-ui/core';
import Switch from "react-switch";

const FILTER_STATE_INIT = {
	nombre: '',
	showInactivos: false
}

const handleInitFilters = (props) => {
	var filterInit = FILTER_STATE_INIT;
	return filterInit;
}

const MotivosGridFiltros = (props) => {
	const intl = useIntl();
	const [init,setInit] = useState(false)
	const [filtersLoading, setFiltersLoading] = useState(false)
	const [filtersState, setFiltersState] = useState(handleInitFilters(props))

	useEffect(() => {
		if(props.filterResetInputs){
			setFiltersState(FILTER_STATE_INIT);
		}
	},[props.filterResetInputs])
	
	useEffect(() => {
		!props.dropdownActive && props.setFilterResetInputs(false)
		!props.dropdownActive && setFiltersLoading(false)
	}, [props.dropdownActive]);

	const handleChange = (name, object) => {
		props.handleFilterChange();
		setFiltersLoading(true);
		let query = {}
		query = JSON.parse(JSON.stringify(filtersState));

		if(query['nombre'] !== ''){
			props.setSearchActive(true);
		}else{
			props.setSearchActive(false);
		}

		query[name] = object
		
		setInit(true)
		
		props.setFilterQuery(query);
		setFiltersLoading(false)
		return;
	};

	const handleChangeInactivosSwich = (value) => {
		setFiltersState({...filtersState, showInactivos : value});
	};
	
	useEffect(() => {
		handleChange('showInactivos', filtersState.showInactivos);
	},[filtersState.showInactivos])

	const handleOnEnter = (e, name, value) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			handleChange(name, value);
		}
	};

	return (
		<>
			{init &&
				<Collapse in={props.dropdownActive}>
					<Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<TextField
									label={intl.formatMessage({ id: "Motivo", defaultMessage: "Motivo" })}
									placeholder={intl.formatMessage({ id: "Motivo", defaultMessage: "Motivo" })}
									name="nombre"
									value={filtersState.nombre}
									onChange={(e) => setFiltersState({...filtersState, nombre: e.target.value})}
									onKeyDown={(e) => handleOnEnter(e, 'nombre', filtersState.nombre)}
									onBlur={(e) => handleChange("nombre", filtersState.nombre)}
									disabled={filtersLoading}
									className="col-12"
								/>
							</form>
						</Grid>
						<Grid component={Box} item xs={3} className="align-self-end">
							<form noValidate autoComplete="off">
								<div className="d-flex inline justify-content-around align-items-center">
									<FormattedMessage
										id="formulariosGridFilters.render.filtros.ver_inactivos"
										defaultMessage="Ver Inactivos"
									/>
									<Switch
										onChange={(value) => handleChangeInactivosSwich(value)}
										checked={filtersState.showInactivos}
										disabled={filtersLoading}
										offColor="#FF4961"
										onColor="#28D094"
									/>
								</div>
							</form>
						</Grid>
					</Grid>
				</Collapse>
			}
		</>
	)
}

export default MotivosGridFiltros
