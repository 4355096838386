import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Sort from '../../assets/images/llantas/table/sort.svg';
import SortAsc from '../../assets/images/llantas/table/sort-asc.svg';
import SortDesc from '../../assets/images/llantas/table/sort-desc.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import Loading from '../ui/Loading.js';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { StyledTableCell, StyledTooltip, rowsPerPage } from '../../commons/utils/TableStyles';
import * as Constants from '../atributoDinamico/constants.js';
import * as Utils from '../../commons/utils/Utils';
import { FormattedMessage, useIntl } from 'react-intl';
import PanolGridRow from './PanolGridRow.jsx';
import PanolGridFiltros from './PanolGridFiltros.jsx';
import Security from '../../commons/security/Security';
import swal from 'sweetalert2';

const PAGINATION_INIT = {
  page: 0,
  count: 0,
  perPage: 10,
};

const FILTER_QUERY_INIT = {
  id: '',
  almacen: 'consolidado',
  item: '',
  subcategoria: '',
  cantidad: '',
  costo: '',
  total: '',
  unidad: '',
  tipo: '',
  numeroParte: '',
};

const SORT_QUERY_INIT = {
  id: 'desc',
  item: '',
  subcategoria: '',
  cantidad: '',
  costo: '',
  total: '',
  unidad: '',
  tipo: '',
  numeroParte: '',
};

const PanolGrid = (props) => {
  const intl = useIntl();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ ...PAGINATION_INIT });
  const [paginationCount, setPaginationCount] = useState(0);
  const [sortQuery, setSortQuery] = useState({ ...SORT_QUERY_INIT });
  const [filterQuery, setFilterQuery] = useState({ ...FILTER_QUERY_INIT });

  const [dropdownActive, setDropdownActive] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  let [isConsolidado, setIsConsolidado] = useState(true);

  useEffect(() => {
    console.log('fq', filterQuery)
    setLoading(true);
    fetchItems();
  }, [pagination, filterQuery]);

  const fetchItems = () => {
    setIsConsolidado(filterQuery.almacen === "consolidado");
    Utils.getData(`/panol2/grid${getQuery()}`)
      .then((data) => {
        setItems(data.items);
        console.log('data', data.items);
        setLoading(false);
        setPaginationCount(data.pagination.count);
        setLoading(false);
        // setFilterQueryLast(filterQuery);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

 


  const getQuery = () => {
    console.log("armando query", filterQuery)
    let query = '?page=' + pagination.page;
    query += '&perPage=' + pagination.perPage;

    query += `&almacen=${filterQuery.almacen}`;
    query += `&item=${filterQuery.item}`;
    query += `&subcategoria=${filterQuery.subcategoria}`;
    query += `&cantidad=${filterQuery.cantidad}`;
    query += `&costo=${filterQuery.costo}`;
    query += `&total=${filterQuery.total}`;
    query += `&unidad=${filterQuery.unidad}`;
    query += `&tipo=${filterQuery.tipo}`;
    query += `&numeroParte=${filterQuery.numeroParte}`;

    return query;
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}

      <div className="card">
        <div className="card-content">
          <div className="card-body card-dashboard">
            <div className="container-fluid mb-1">
              <div className="col">
                <div
                  className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                  style={{ height: 52 }}
                  onClick={() => setDropdownActive(!dropdownActive)}
                >
                  {dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
                </div>
              </div>
            </div>
            <PanolGridFiltros
              dropdownActive={dropdownActive}
              setDropdownActive={setDropdownActive}
              filterQuery={filterQuery}
              setFilterQuery={setFilterQuery}
              // searchActive={searchActive}
              // setSearchActive={setSearchActive}
              pagination={pagination}
              loading={loading}
            
            />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">{/* ID */}</StyledTableCell>

                    <StyledTableCell
                      align="left"
                      style={{ cursor: 'pointer' }}
                    // onClick={() => handleSort('item')}
                    >
                      <FormattedMessage
                        id="panolGrid.render.table_column_item"
                        defaultMessage="Item"
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ cursor: 'pointer' }}
                    // onClick={() => handleSort('subcategoria')}
                    >
                      <FormattedMessage
                        id="panolGrid.render.table_column_subcategoria"
                        defaultMessage="SubCategoria"
                      />
                    </StyledTableCell>

                    <StyledTableCell align="left" style={{ cursor: 'pointer' }}>
                      <FormattedMessage
                        id="panolGrid.render.table_column_cantidad"
                        defaultMessage="Cantidad"
                      />
                    </StyledTableCell>

                    <StyledTableCell align="left" style={{ cursor: 'pointer' }}>
                      <FormattedMessage
                        id="panolGrid.render.table_column_costo"
                        defaultMessage="Costo"
                      />
                    </StyledTableCell>

                    {isConsolidado &&
                      <StyledTableCell align="left" style={{ cursor: 'pointer' }}>
                        <FormattedMessage
                          id="panolGrid.render.table_column_total"
                          defaultMessage="Total"
                        />
                      </StyledTableCell>
                    }

                    <StyledTableCell align="left" style={{ cursor: 'pointer' }}>
                      <FormattedMessage
                        id="panolGrid.render.table_column_unidad"
                        defaultMessage="Unidad"
                      />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <StyledTableCell colSpan={11} align="center">
                        <FormattedMessage id="Cargando" defaultMessage="Cargando" />
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    items &&
                    items.map((item) => (
                      <PanolGridRow
                        key={'panolGridRow_' + item.id}
                        baseUrl={props.match.url}
                        item={item}
                        isConsolidado={isConsolidado}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              count={paginationCount}
              rowsPerPage={pagination.perPage}
              page={pagination.page}
              // onChangePage={handleChangePage}
              // onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Mostrar"
              labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PanolGrid;
