class ConfigBusiness {
	static get(configKey) {
        try {
            let configData = JSON.parse(localStorage.getItem("configBusiness"));            
            return this.index(configData, configKey);         
        } catch (error) {
            // Manejar cualquier error de sintaxis JSON aquí
            console.error("Error al analizar el JSON:", error);
            return { json: "error" };
        }
    }    

	static index(obj, is, value) {
	    if (typeof is === 'string') {
	        return this.index(obj,is.split('.'), value); }
	    else if (is.length===1 && value!==undefined)
	        return obj[is[0]] = value;
	    else if (is.length===0)
	        return obj;
	    else {
			if(obj===null) return null;
	    	else {
				if(!obj[is[0]] && value!==undefined) obj[is[0]] = {};
				return this.index(obj[is[0]],is.slice(1), value);
			}
		}
	}
}

export default ConfigBusiness;
