import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import CombustiblesGridNew from './CombustiblesGridNew.js'
import CombustiblesAbm from './CombustiblesAbm.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'

class Combustibles extends Component {

    constructor(props) {
		super(props);
		this.ajaxHandler = new AjaxHandler();
        this.props = props;
    }

    componentDidMount(){
        this.ajaxHandler.subscribe(this);
    }

    componentWillUnmount(){
        this.ajaxHandler.unsubscribe(this);
    }

	render() {
	    return (
			<div className="content-wrapper">
				<div className="content-header row">
					<div className="content-header-left col-md-6 col-12 mb-2">
      					<h3 className="content-header-title"><FormattedMessage id="combustible.titulo.administracion_cargas_combustible" defaultMessage="Administración de Cargas de Combustible"/></h3>
      				</div>
				</div>
				<div className="content-body">								
					<Switch>
						<Route exact path={`${this.props.match.url}`} render={(props) => <CombustiblesGridNew {...this.props} ajaxHandler={this.ajaxHandler} />}/>
						<Route exact path={`${this.props.match.url}/add`} render={(props) => <CombustiblesAbm {...props} action="ADD" />} />
						<Route exact path={`${this.props.match.url}/:id/edit`} render={(props) => <CombustiblesAbm {...props} action="EDIT" />} />
						<Route path={`${this.props.match.url}/:id`} render={(props) => <CombustiblesAbm {...props} action="VIEW" />} />
					</Switch>										
				</div>
			</div>
	    );
  	}
}

export default injectIntl(Combustibles);
