import React, { Component } from 'react'
import Validator from '../../commons/validation/Validator.js'
import Loading from '../ui/Loading.js'
import moment from 'moment'
import 'moment/min/locales'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import AtributoDinamicoInstanciaViewPDF from '../atributoDinamico/AtributoDinamicoInstanciaViewPDF.js'
import 'react-select/dist/react-select.css'
import { castValoresDinamicos } from '../atributoDinamico/helpers.js'
import {FormattedMessage} from 'react-intl';

class FormularioViewPDF extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");
    this.state = {
      redirectTo: null,
      props: this.props,
      errors: [],
      loading: false,
      isChoferValid: true,
      tareas: [],
      entidad: null,
    };

    this.handleChangeAtributoDinamico = this.handleChangeAtributoDinamico.bind(this);
  }
  initForm() {

  }
  componentWillMount() {

    this.ajaxHandler.subscribe(this);
    if ((Security.hasPermission('CHECKLIST_CREAR_HISTORICO') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('CHECKLIST_EDITAR_HISTORICO') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('CHECKLIST_VISUALIZAR_HISTORICO') && this.state.props.action === 'VIEW')) {
            this.initForm();
            this.getLocation();
		} else {
			this.setState({
				redirectTo: '/error'
			});
        }
  }
  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  handleChangeAtributoDinamico(index, key, value) {
    this.props.valores_dinamicos[index][key] = value;
    this.setState({
      valores_dinamicos: this.props.valores_dinamicos
    });
	}

  exit() {
    this.setState({
      redirectTo: "/formulario",
    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((event) => this.showPosition(event));
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  showPosition(position) {
    this.setState({
      latitudForm: position.coords.latitude,
      longitudForm: position.coords.longitude,
    });
  }

  getMovilAtributo(movilAtributoId){
    if(movilAtributoId === "modelo")
      return this.props.movil.modelo ? this.props.movil.modelo.nombre : '';
    else if (movilAtributoId === 'marca')
      return this.props.movil.modelo ? (this.props.movil.modelo.marca ? this.props.movil.modelo.marca.nombre : '') : '';
    else if (movilAtributoId === 'titular')
      return this.props.movil.titular_eloquent ? this.props.movil.titular_eloquent.nombre : '';
    else if (movilAtributoId === 'plan_preventivo')
      return this.props.movil.plan_preventivo_eloquent ? this.props.movil.plan_preventivo_eloquent.descripcion : (this.props.movil.planPreventivo ? this.props.movil.planPreventivo.descripcion : '' );
    else if (movilAtributoId === 'centro_costos')
      return this.props.movil.cecos ? this.props.movil.cecos.nombre : (this.props.movil.centroCostos ? this.props.movil.centroCostos.nombre : '');
    else if (movilAtributoId === 'proveedor_gps')
      return this.props.movil.proveedorGps ? this.props.movil.proveedorGps.razon_social : '';
    else
      return this.props.movil[movilAtributoId];
  }

  render() {
    let state = this.state;
    let formData = this.props.formData;

    return (
      <React.Fragment>
        {this.state.loading && <Loading />}
        <div className="row content-body">
          <div className="col-12">
            <form className="form form-horizontal" ref="form">
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i>
                        <FormattedMessage
                          id="formularioAbm.general_data.label_datos_generales"
                          defaultMessage=" Datos Generales "
                        />
                      </h4>

                      <div className="row">
                        {/* Tipo Formulario */}
                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-3 label-control col-form-label" htmlFor="identificador">
                              <FormattedMessage
                                id="formularioAbm.general_data.label_tipo_formulario"
                                defaultMessage="Tipo de Formulario *:"
                              />
                            </label>
                            <div className="col-8">
                              <div className="form-control-static col-form-label form-value">
                                {this.props.formData.tipo_formulario ? this.props.formData.tipo_formulario.nombre : ''}
                              </div>
                            </div>
                            <label className="col-1">
                              <i
                                className={
                                  this.props.formData.tipo_formulario
                                    ? this.props.formData.tipo_formulario.icono + " fa-2x"
                                    : ""
                                }
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.props.formData.tipo_formulario && (
                        <div className="row">
                          {/* Descripción */}
                          <div className="col-6">
                            <div className="form-group row">
                              <label className="col-3 label-control col-form-label" htmlFor="nombre">
                                <FormattedMessage
                                  id="formularioAbm.general_data.label_descripcion"
                                  defaultMessage="Descripción:"
                                />
                              </label>
                              <div className="col-9">
                                <p className="col-form-label">{formData.tipo_formulario.descripcion} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {this.props.formData.usuario_validacion_id && (
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="fa fa-thumbs-up "></i> Validación Manual
                        </h4>
                        <div className="row">
                          {/* Tipo Formulario */}
                          <div className="col-6">
                            <div className="form-group row">
                              <label className="col-3 label-control col-form-label" htmlFor="identificador">
                                Usuario:
                              </label>
                              <div className="col-9">
                                <p className="col-form-label">{this.props.formData.usuario_validacion.label}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* Tipo Formulario */}
                          <div className="col-6">
                            <div className="form-group row">
                              <label className="col-3 label-control col-form-label" htmlFor="identificador">
                                Fecha:
                              </label>
                              <div className="col-9">
                                <p className="col-form-label">{this.props.formData.validated_at}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.props.formData.tipo_formulario && (
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage
                            id="formularioAbm.form_to_complete.label_formulario_completar"
                            defaultMessage=" Formulario a Completar "
                          />
                        </h4>
                        {this.props.formData.tipo_formulario.con_persona ? (
                          <div className="row">
                            {/* Persona */}
                            <div className="col-6">
                              <div className="form-group row">
                                <label className="col-3 label-control col-form-label" htmlFor="movil">
                                  <FormattedMessage
                                    id="formularioAbm.form_to_complete.label_persona"
                                    defaultMessage="Persona *:"
                                  />
                                </label>
                                <div className="col-9">
                                  <div className="form-control-static col-form-label form-value">
                                    {this.props.formData.persona ? this.props.formData.persona.label : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.props.formData.tipo_formulario.con_movil ? (
                          <div className="row">
                            {/* Movil */}
                            <div className="col-6">
                              <div className="form-group row">
                                <label className="col-3 label-control col-form-label" htmlFor="movil">
                                  <FormattedMessage
                                    id="formularioAbm.form_to_complete.label_movil"
                                    defaultMessage="Móvil *:"
                                  />
                                </label>
                                <div className="col-9">
                                  <div className="form-control-static col-form-label form-value">
                                    {this.props.formData.movil ? this.props.formData.movil.dominio : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.props.movil && this.props.movil_atributos_mostrar.map((element, index) => {
                              return (
                                <div key={index} className="col-6">
                                  <div className="form-group row">
                                    <label className="col-3 label-control col-form-label" htmlFor="movil">
                                      {element.nombre} :
                                    </label>
                                    <div className="col-9">
                                      <div className="form-control-static col-form-label form-value">
                                        {this.getMovilAtributo(element.id)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                );
                              })
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        {this.props.formData.tipo_formulario.con_chofer ? (
                          <div className="row">
                            {/* Chofer */}
                            <div className="col-6">
                              <div className="form-group row">
                                <label className="col-3 label-control col-form-label">Actualizar Chofer *:</label>
                                <div className="col-9">
                                  <div className="form-control-static col-form-label form-value">
                                    {this.props.formData.chofer ? this.props.formData.chofer.label : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.props.valores_dinamicos.map(
                          (atributoDinamico, index) =>
                            atributoDinamico &&
                            atributoDinamico.activo && (
                              <div className="row" key={index}>
                                <div className="col-6">
                                  <div className="form-group row">
                                    <AtributoDinamicoInstanciaViewPDF
                                      movil={this.props.movil ? this.props.movil.id : null}
                                      className=""
                                      disabled={true}
                                      handleChange={(name, value) =>
                                        this.handleChangeAtributoDinamico(index, name, value)
                                      }
                                      atributo={atributoDinamico}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FormularioViewPDF;
