// Reintentos.js
import React from 'react';

const Reintentos = ({ countdown }) => (
    <div className="app-content content">
        <div className="content-wrapper">
            <div className="content-header row"></div>
            <div className="content-body">
                <section className="flexbox-container">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="col-md-4 col-10 box-shadow-2 p-0">
                            <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                                <div id="loginLogo" className="card-header border-0 text-center" style={{ padding: 0 }}>
                                    <img src={process.env.PUBLIC_URL + "/images/company/logo-login-app.png"} alt="" className="rounded-circle img-fluid center-block" />
                                </div>
                                <div className="card-content">
                                    <div className="card-body" style={{ paddingTop: 0 }}>
                                        <div className="text-center">
                                            <div>
                                                Error en la configuración de negocio. Reintentando en {countdown} segundos...
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-6">
                                                <img src={process.env.PUBLIC_URL + "/images/company/logo-login-company.png"} alt="" className="img-fluid center-block" />
                                            </div>
                                            <div className="col-6 text-right">
                                                <img src={process.env.PUBLIC_URL + "/images/company/logo-login-powered-by.png"} alt="" className="img-fluid center-block" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <h5 className="login-version">v{process.env.REACT_APP_VERSION}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
);

export default Reintentos;
