module.exports = function(intl) {
	return {
		moveOnSelect: false,
		moveAllLabel: intl.formatMessage({ id: 'duallisboxConfig.title_mover_todos', defaultMessage: 'Mover todos' }),
		removeAllLabel: intl.formatMessage({ id: 'duallisboxConfig.title_remover_todos', defaultMessage: 'Remover todos' }),
		moveSelectedLabel: intl.formatMessage({ id: 'duallisboxConfig.title_mover_seleccionados', defaultMessage: 'Mover seleccionados' }),
		removeSelectedLabel: intl.formatMessage({ id: 'duallisboxConfig.title_remover_seleccionados', defaultMessage: 'Remover seleccionados' }),
		infoText: false,
		filterPlaceHolder: intl.formatMessage({ id: 'duallisboxConfig.placeHolder_filtros', defaultMessage: 'Filtros' }),
		nonSelectedListLabel: intl.formatMessage({ id: 'duallisboxConfig.label_permisos_disponibles', defaultMessage: 'Permisos Disponibles' }),
		selectedListLabel: intl.formatMessage({ id: 'duallisboxConfig.label_permisos_asignados', defaultMessage: 'Permisos Asignados' })
	}
}