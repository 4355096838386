import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import DatePicker from 'react-datepicker'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import swal from 'sweetalert'
import Select from 'react-select'
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone'
import Dialog from '../../commons/dialog/Dialog.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import OTAdjuntos from './OTAdjuntos'

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class OTAbm extends Component {
	constructor(props) {
		super(props);

		moment.locale('es');

		this.ajaxHandler = new AjaxHandler();

		this.state = {
			props: this.props,
			formData: {
				servicio: null,
				gestor: null,
				escribano: null,
				fecha: null,
				selectedTickets: [],
				estado: '',
				fechaVencimiento: null
			},
			//usuarioGerenciador: false,
			seleccionarTodos: false,
			seleccionarMultipleCierre: false,
			necesitaFormulario: false,
			necesitaGestoria: false,
			certificaEscribania: false,
			estadoProvisorio: false,
			gestoriaVencimiento: false,
			servicios: null,
			gestores: null,
			escribanos: null,
			enableGestor: false,
			enableEscribano: false,
			escribanosLoading: false,
			gestoresLoading: false,
			formStep: 0,
			redirectTo: null,
			errors: [],
			multipleSelectIds: '',
			actions: [],
			modalDatosCierreVencimientoKey: 0,
			fechaVencimientoValida: true,
			displayAdjuntos: false,
			displayTicketAdjuntos: null
		}
		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleFormChangeSelectObject = this.handleFormChangeSelectObject.bind(this);
		this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
		this.handleDatePickerFormRawChange = this.handleDatePickerFormRawChange.bind(this);
		this.handleCancelModal = this.handleCancelModal.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDisplayAdjuntos = this.handleDisplayAdjuntos.bind(this);

		this.formValidation = new FormValidation({
			component: this,
			validators: {
			/*	'formData.mes': (value) => Validator.notEmpty(value),
				'formData.anio': (value) => {
					let valid = value === null ? true : /^\d{4}$/.test(value);
					return {
						valid: valid,
						message: !valid ? 'Año inválido' : ''
					}
				},
				'formData.quincena': (value) => Validator.notEmpty(value),
				'formData.importe': (value) => {
					let empty = value === null ? true : !/\S/.test(value);
					let valid = this.state.props.action === 'VIEW' || parseFloat(value) === parseFloat(this.state.formData.selectedTickets.map((e) => e.importe).reduce((a, b) => a + b, 0).toFixed(2));
					return {
						valid: empty || valid,
						message: !empty && !valid ? 'Debe coincidir con la suma de los importes de los tickets seleccionados' : ''
					}
                }*/
			}
        });

		this.localCurrencyFormat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' });
	}

	componentDidMount(nextProps, nextState) {
		if ((Security.hasPermission('OT_CREAR') && this.state.props.action === 'ADD') ||
	       (Security.hasPermission('OT_MODIFICAR') && this.state.props.action === 'EDIT') ||
	       (Security.hasPermission('OT_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
		}
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
	}

	handleFormChangeSelectObject(name, object) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = object ? { id: object.id } : null;
		this.setState({
			formData: formDataCopy
		});
	}

	handleDatePickerFormChange(name, event) {
		let date = event ? event.format() : null;
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = date;
        if(name === "fechaVencimiento" && date){
            this.setState({
                fechaVencimientoValida: true
            });
        }
		this.setState({
			formData: formDataCopy
		});
	}

	handleDatePickerFormRawChange(name, date) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = date === '' ? null : date;
        if(name === "fechaVencimiento" && date !== ''){
            this.setState({
                fechaVencimientoValida: true
            });
        }
		this.setState({
			formData: formDataCopy
		});
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/ordenestrabajo'
    });
	}

	handleMultipleSelectClose() {
		$('#multiple_select').modal('toggle');
	}



	handleMultipleSelectCierre() {
		let selectedIds = this.state.multipleSelectIds.replace(/[ ]{1,}/g,',').replace(/\n{1,}/g,',').split(/,|;|\.|\n|\r\n/);
		selectedIds = selectedIds.filter(e => e.length > 0);
		selectedIds = selectedIds.filter((item, pos) => selectedIds.indexOf(item) === pos);
		let errorsNotAvailable = [];
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		for(let i in selectedIds) {

			let ticketACerrar = formDataCopy.selectedTickets.filter(ticket => ticket.id == parseInt(selectedIds[i]))[0]
			if(ticketACerrar)
				ticketACerrar.seleccionado = true
			else
				errorsNotAvailable.push(selectedIds[i]);
		}

		this.handleMultipleSelectClose();
		if(errorsNotAvailable.length) {
			Dialog.alert(this.props.intl, {
				title: this.props.intl.formatMessage({ id: 'oTAbm.modal_multiple_ticket_selection_close.information_siguientes_tickets_son_invalidos', defaultMessage: 'Los siguientes números de tickets son inválidos' }),
				text: errorsNotAvailable.join(', ')
			});
		}
		else {
			this.setState({
				formData: formDataCopy
			});
		}
	}

	handleMultipleSelect() {
		let selectedIds = this.state.multipleSelectIds.replace(/[ ]{1,}/g,',').replace(/\n{1,}/g,',').split(/,|;|\.|\n|\r\n/);
		selectedIds = selectedIds.filter(e => e.length > 0);
		selectedIds = selectedIds.filter((item, pos) => selectedIds.indexOf(item) === pos);

		let errorsNotAvailable = [];

		for(let i in selectedIds) {
			if(!this.table.column(1).data().toArray().includes(parseInt(selectedIds[i]))) {
				errorsNotAvailable.push(selectedIds[i]);
			}
		}

		if(!errorsNotAvailable.length) {
			this.table.rows().every(function(rowIdx, tableLoop, rowLoop) {
				this.data().check = false;
				$(this.cell(rowIdx, 0).node()).find('input[type="checkbox"]').prop('checked', false);
				return true;
			});

			this.table.rows().every(function(rowIdx, tableLoop, rowLoop) {
				if(selectedIds.includes(this.data().id.toString())) {
					this.data().check = true;
					$(this.cell(rowIdx, 0).node()).find('input[type="checkbox"]').prop('checked', true);
				}
				return true;
			});
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.selectedTickets = this.table.data().toArray().filter((e) => e.check);
			this.setState({
				formData: formDataCopy
			});

			this.handleMultipleSelectClose();
		} else {
			Dialog.alert(this.props.intl, {
				title: this.props.intl.formatMessage({ id: 'oTAbm.modal_multiple_ticket_selection.information_siguientes_tickets_son_invalidos', defaultMessage: 'Los siguientes números de tickets son inválidos' }),
				text: errorsNotAvailable.join(', ')
			});
		}
	}

	handleSubmit(event) {
		let component = this;

		if((component.state.formData.servicio === null)) {
			component.setState({
				errors: ['oTAbm.errors.no_puede_dejar_datos_sin_completar']
			});
			window.scrollTo(0, 0);
		} else {
			if(component.state.formData.selectedTickets.length === 0) {
				component.setState({
					errors: ['oTAbm.errors.debe_seleccionar_al_menos_un_ticket']
				});
				window.scrollTo(0, 0);
			} else {

				component.setState({
					loading: true
				}, () => {
					component.ajaxHandler.fetch('/ordenestrabajo' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
						method: this.props.action === 'ADD' ? 'POST' : 'PUT',
						body: JSON.stringify({
							...component.state.formData
						}),
					}).then(response => {
						if (response.status !== 400) {
							component.exit();
						} else {
							response
							.json()
							.then(data => {
								component.setState({
									errors: data.detalle
								});
							});
						}
						window.scrollTo(0, 0);
					}).catch((error) => {
						component.ajaxHandler.handleError(error);
					}).finally(() => {
						component.setState({
							loading: false
						});
					});
				});
			}
		}
		event.preventDefault();
	}

	handleDisplayAdjuntos(e,ticket){

		this.setState({
			displayAdjuntos: !this.state.displayAdjuntos,
			displayTicketAdjuntos: ticket
		}, ()=>{

			$('#modificar_adjuntos_modal').modal({ backdrop: 'static' });
		});

	}

	handleAdjuntosUpdate(adjuntos) {

		let ticketActual = this.state.displayTicketAdjuntos.id;

		let ticketUpdate = this.state.formData.selectedTickets.findIndex(e => e.id === ticketActual);

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.selectedTickets[ticketUpdate].adjuntos = adjuntos;
		this.setState({
			formData: formDataCopy
		});

	}

	handleModificarAdjuntosClose(){
		this.setState({ displayAdjuntos: !this.state.displayAdjuntos });
		$('#modificar_adjuntos_modal').modal('toggle');
	}

	handleModificarAdjuntosSave(adjuntos){
		/*this.setState({
            adjuntos: adjuntos,
            tabSelected: adjuntos.length ? adjuntos[0].id : -1
        }, () => {
            this.props.callbackUpdate(this.state.adjuntos);
		});*/
		let ticketActual = this.state.displayTicketAdjuntos.id;

		let ticketUpdate = this.state.formData.selectedTickets.findIndex(e => e.id === ticketActual);

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.selectedTickets[ticketUpdate].adjuntos = adjuntos;
		formDataCopy.selectedTickets[ticketUpdate].adjuntoModificado = true;
		this.setState({
			formData: formDataCopy,
			displayAdjuntos: !this.state.displayAdjuntos
		});

        $('#modificar_adjuntos_modal').modal('toggle');
	}

	initForm() {
    let component = this;

    if(this.state.props.action === 'ADD') {
			this.setState({
				serviciosLoading: true,
			});

			Promise.all([
				component.ajaxHandler.getJson('/servicios/tipo-ticket/GESTORIA/select')
			]).then((data) => {
				let servicios = data[0] ? data[0] : [];
				let gestores = data[1] ? data[1] : [];
				let escribanos = data[2] ? data[2] : [];

				component.setState({
					servicios: servicios,
					serviciosLoading: false
				});
			});
		} else {
			this.setState({
				errors: []
			});

			Promise.all([
				component.ajaxHandler.getJson('/ordenestrabajo/' + this.state.props.match.params.id),
				component.ajaxHandler.getJson('/tickets/OT/' + this.state.props.match.params.id),
				component.ajaxHandler.getJson('/ordenestrabajo/' + this.state.props.match.params.id + '/actions')
			]).then((data) => {
				let formData =  data[0] ? Object.assign(this.state.formData, data[0]) : null;
				formData.selectedTickets = data[1].map(ticket => {
					ticket.seleccionado = false;
					ticket.adjuntoModificado = false;
					return ticket
				});
				let actions = data[2];

				let component = this;

				Promise.all([
					component.ajaxHandler.getJson('/gerenciadores/tipo/2/select'),
					component.ajaxHandler.getJson('/gerenciadores/tipo/3/select'),
					component.ajaxHandler.getJson('/servicios/' + this.state.formData.servicio.id)
				]).then((data) => {
					let gestores = data[0] ? data[0] : [];
					let escribanos = data[1] ? data[1] : [];
					let servicio = data[2] ? data[2] : null;

					let enableGestor = (servicio.necesitaFormulario || servicio.necesitaGestoria);
					let enableEscribano = servicio.certificaEscribania;

					component.setState({
						enableGestor: enableGestor,
						enableEscribano: enableEscribano,
						gestores: gestores,
						escribanos: escribanos,
						necesitaFormulario: servicio.necesitaFormulario,
						necesitaGestoria: servicio.necesitaGestoria,
						certificaEscribania: servicio.certificaEscribania,
                        estadoProvisorio: servicio.estadoProvisorio,
                        gestoriaVencimiento: servicio.gestoriaVencimiento
					});
				});

				if(formData) component.setState({
					formData: formData,
					actions: actions
				});
			});
		}

		window.scrollTo(0, 0);
  }

	canDo(action) {
    return this.state.actions.indexOf(action) >= 0;
	}

	handleCheckBoxTodos() {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		let seleccionar = !this.state.seleccionarTodos;
		formDataCopy.selectedTickets = formDataCopy.selectedTickets.map(ticket => {
			if (ticket.estado != 'CERRADO')
					ticket.seleccionado = seleccionar;
			return ticket;
		})
		this.setState({
			seleccionarTodos: seleccionar,
			formData: formDataCopy
		})
	}

	handleCheckBoxTicket(ticket, index) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.selectedTickets[index].seleccionado = !ticket.seleccionado;
		this.setState({
			formData: formDataCopy
		})
	}

	handleServicioChange(object) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.servicio = null;
		formDataCopy.selectedTickets = [];
		this.setState({
			formData: formDataCopy
		}, () => {
			formDataCopy.servicio = object ? {
				id: object.value,
				label: object.label
			} : null;
			this.setState({
				formData: formDataCopy
			}, () => {

				this.setState({
					gestoresLoading: true,
					escribanosLoading:true
				});

				let component = this;

				Promise.all([
					component.ajaxHandler.getJson('/gerenciadores/tipo/2/select'),
					component.ajaxHandler.getJson('/gerenciadores/tipo/3/select'),
					component.ajaxHandler.getJson('/servicios/' + this.state.formData.servicio.id)
				]).then((data) => {
					let gestores = data[0] ? data[0] : [];
					let escribanos = data[1] ? data[1] : [];
					let servicio = data[2] ? data[2] : null;

					let enableGestor = servicio.necesitaFormulario || servicio.necesitaGestoria;
					let enableEscribano = servicio.certificaEscribania;

					component.setState({
						enableGestor: enableGestor,
						enableEscribano: enableEscribano,
						gestores: gestores,
						escribanos: escribanos,
						gestoresLoading: false,
						escribanosLoading:false,
						necesitaFormulario: servicio.necesitaFormulario,
						necesitaGestoria: servicio.necesitaGestoria,
						certificaEscribania: servicio.certificaEscribania,
                        estadoProvisorio: servicio.estadoProvisorio,
                        gestoriaVencimiento: servicio.gestoriaVencimiento
					});
				});

				this.initGrid();

			});
		});
	}

	handleGestorChange(object) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.gestor = null;
		this.setState({
			formData: formDataCopy
		}, () => {
			formDataCopy.gestor = object ? {
				id: object.value,
				label: object.label
			} : null;
			this.setState({
				formData: formDataCopy
			});
		});
	}

	handleEscribanoChange(object) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.escribano = null;
		this.setState({
			formData: formDataCopy
		}, () => {
			formDataCopy.escribano = object ? {
				id: object.value,
				label: object.label
			} : null;
			this.setState({
				formData: formDataCopy
			});
		});
	}

	initGrid() {
		let component = this;

		if(this.state.formData.servicio) {
			if(this.table) this.table.destroy();

				let filters = null

				this.table = $(this.refs.grid).DataTable(
					Object.assign({
						dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
						scrollY: "240px",
						scrollCollapse: true,
						paging: false,
						stateSave: false,
						processing: true,
						serverSide: false,
						deferRender: true,
                        order: [[ 3, "asc" ]],
                        autoWidth: true,
						language: {
							"sInfo": "_TOTAL_ tickets",
						},
						ajax: {
							type: 'GET',
							url: Config.get('apiUrlBase') + '/tickets/OT/grid/servicio/' + component.state.formData.servicio.id,
							headers: {
								'Authorization-Token': localStorage.getItem("token")
							},
							dataSrc: function(response) {
								return response;
							},
							error: function(xhr, error, thrown) {
								component.ajaxHandler.handleResponseErrorsIsValid(xhr);
							},
							cache: false
						},
						initComplete: function(settings) {
							let stateColumns = null;

							var sets = settings;
							var index = 0;

							this.api().columns().every(function() {
								var column = this;

								if (sets.aoColumns[index].filterType) {
									if (sets.aoColumns[index].filterType === 'select') {
										var select = $('<select class="btn-dt-filter" required><option value="" selected>Buscar...</option></select>')
											.appendTo($(column.footer()).empty())
											.on('change', function() {
												var val = $.fn.dataTable.util.escapeRegex($(this).val());
												column
													.search(val ? val : '', true, false)
													.draw();
											});
										if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
											filters[sets.aoColumns[index].name].map(e => {
												select.append('<option value="' + e.value + '">' + e.label + '</option>');
												return true;
											});
										} else {
											column.data().unique().sort().each(function(d, j) {
												select.append('<option value="' + d + '">' + d + '</option>');
											});
										}
										if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
									}
									if (sets.aoColumns[index].filterType === 'input') {
										var input = $('<input type="text" class="btn-dt-filter" placeholder="Buscar..." />');
										if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
										input.appendTo($(column.footer()).empty());
										input.on('keyup change', function() {
											if (column.search() !== this.value) {
												column
													.search(this.value)
													.draw()
													.ajax.reload(null, false);
											}
										});
									}
								}
								index++;
								return '';
							});

							// Check
							let check = $('<input type="checkbox" id="check-all" data-togle="tooltip" data-placement="top" title="Seleccionar todo" />');
							check.appendTo($(this.api().column(0).footer()).empty());
							check.tooltip();
							let table = this;
							check.on('click', function() {
								let checked = $(this).prop('checked');
								$(this).attr('data-original-title', checked ? 'Deseleccionar todo' : 'Seleccionar todo');
								$(this).tooltip('hide');
								table.api().rows().every(function(rowIdx, tableLoop, rowLoop) {
									this.data().check = checked;
									$(this.cell(rowIdx, 0).node()).find('input[type="checkbox"]').prop('checked', checked);
									return true;
								});
								let formDataCopy = JSON.parse(JSON.stringify(component.state.formData));
								formDataCopy.selectedTickets = component.table.data().toArray().filter((e) => e.check);
								component.setState({
									formData: formDataCopy
								});
							});

							$('div.dataTables_scrollFootInner > table.dataTable > tfoot > tr').appendTo('div.dataTables_scrollHeadInner > table.dataTable > thead');
						},
						columns: [{
							name: 'check',
							title: '',
							data: null,
							className: 'all',
							orderable: true,
							render: (data, type, full, meta) => {
								return '<input type="checkbox" id="check-' + data.id + '" ' + (data.check ? 'checked' : '') + ' />';
							},
							createdCell: (td, cellData, rowData, row, col) => {
								cellData.check = false;
								$(td).find('input[type="checkbox"]').on('click', () => {
									cellData.check = $(td).find('input[type="checkbox"]').prop('checked');

									let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
									formDataCopy.selectedTickets = component.table.data().toArray().filter((e) => e.check);
									component.setState({
										formData: formDataCopy
									});
								});
							},
						}, {
							title: 'Tkt',
                            name: 'id',
                            width: 3,
							className: 'all',
							data: 'id',
							orderable: true,
							filterType: 'input'
						}, {
							title: 'Creado',
							name: 'fechaHoraAlta',
							className: 'all',
							data: 'fechaHoraAlta',
							render: function(data, type, full, meta) {
								if (type === 'sort') {
									return data;
								} else {
									return data ? moment(data).format('L') : '';
								}
							},
							filterType: 'input'
						}, {
							title: 'Registro Automotor',
							name: 'dnrpa_seccional',
							className: 'all',
							data: 'movilPersona',
                            filterType: 'input',
                            render: function(data, type, row) {
								return data && data.dnrpa_seccional ? data.dnrpa_seccional : '';
							},
						},{
							title: 'Entidad',
							name: 'movilPersona',
							className: 'all',
							data: 'movilPersona',
							render: function(data, type, row) {
								return data && data.descripcion ? data.descripcion : '';
							},
							filterType: 'input'
						}, {
							title: 'Titular',
							name: 'titular',
							className: 'all',
							data: 'movilPersona',
                            filterType: 'input',
                            render: function(data, type, row) {
								return data && data.titular ? data.titular.nombre : '';
							},
						}],
						drawCallback: function() {
							$(this).find('.action').on('click', function() {
								let data = component.table.row($(this).parents('tr')).data();

								if ($(this).hasClass('approve')) {
									swal({
										title: 'Aprobar',
										text: '¿Confirma que desea aprobar la factura?',
										icon: "info",
										content: {
											element: "textarea",
											attributes: {
												placeholder: "Observaciones",
												id: 'confirm-observacionesCambioEstado'
											}
										},
										buttons: {
											confirm: {
												text: "Si",
												value: true,
												visible: true,
												className: "btn btn-primary",
												closeModal: false
											},
											cancel: {
												text: "No",
												value: null,
												visible: true,
												className: "btn btn-danger",
												closeModal: true,
											}
										}
									}).then((isConfirm) => {
										if (isConfirm) {
											component.ajaxHandler.fetch('/facturas/' + data.id + '/aprobar', {
												method: 'POST',
												body: JSON.stringify({
													observaciones: $('#confirm-observacionesCambioEstado').val()
												}),
												headers: {
													'Accept': 'application/json',
													'Content-Type': 'application/json',
													'Authorization-Token': localStorage.getItem("token")
												}
											}).then((response) => {
												if(response.status === 200) {
													component.table.ajax.reload(null, false);
													swal("Factura aprobada.", "", "success");
												}
											}).catch((error) => {
												component.ajaxHandler.handleError(error);
											});
										}
									});
								}

								if ($(this).hasClass('reject')) {
									swal({
										title: 'Rechazar',
										text: '¿Confirma que desea rechazar la factura?',
										icon: "warning",
										content: {
											element: "textarea",
											attributes: {
												placeholder: "Observaciones",
												id: 'confirm-observacionesCambioEstado'
											}
										},
										buttons: {
											confirm: {
												text: "Si",
												value: true,
												visible: true,
												className: "btn btn-primary",
												closeModal: false
											},
											cancel: {
												text: "No",
												value: null,
												visible: true,
												className: "btn btn-danger",
												closeModal: true,
											}
										}
									}).then((isConfirm) => {
										if (isConfirm) {
											component.ajaxHandler.fetch('/facturas/' + data.id + '/rechazar', {
												method: 'POST',
												body: JSON.stringify({
													observaciones: $('#confirm-observacionesCambioEstado').val()
												}),
												headers: {
													'Accept': 'application/json',
													'Content-Type': 'application/json',
													'Authorization-Token': localStorage.getItem("token")
												}
											}).then((response) => {
												if(response.status === 200) {
													component.table.ajax.reload(null, false);
													swal("Factura rechazada.", "", "success");
												}
											}).catch((error) => {
												component.ajaxHandler.handleError(error);
											});
										}
									});
								}
							});
						}
					}, datatablesConfig(this.props.intl))
				);

				new $.fn.dataTable.Buttons(this.table, {
					buttons: [{
						name: 'multipleSelect',
						text: '<i id="multipleSelect" class="ft-check-square"></i>',
						titleAttr: 'Seleccionar múltiples de tickets',
						action: function (e, dt, node, config) {
							component.setState({
								multipleSelectIds: ''
							});
							$('#multiple_select').modal({backdrop: 'static'});
						},
					},{
						name: 'Export',
						text: '<i id="Exportar" class="ft-upload"></i>',
						titleAttr: 'Exportación de tickets',
						action: function (e, dt, node, config) {
							swal({
								title: 'Se están exportando los datos.',
								text: 'Esta acción puede demorar.',
								buttons: {}
							});
							component.ajaxHandler.fetch('/facturas/exportar-tickets-excel/'+ component.state.formData.gerenciador.id, {
								method: 'GET'
							}).then(response => {
							if (response.status === 200) {
								return response.blob();
							}
							}).then(fileBlob => {
								let fileUrl = URL.createObjectURL(fileBlob);
								$("<a />", {
									href: fileUrl,
									download: "fleet-tickets-facturables_" + component.state.formData.gerenciador.label + ".xlsx"
								}).appendTo("body").get(0).click();
							}).catch(() => {
							}).finally(() => {
								swal.close();
							});
						}
					}]
				});

				this.table.buttons(0, null).container().appendTo('#buttons');

				this.table.button('multipleSelect:name')
					.nodes()
					.attr('data-toggle', 'tooltip')
					.attr('data-position', 'top');
				$('[data-toggle="tooltip"]').tooltip();
				this.table.button('Export:name')
					.nodes()
					.attr('data-toggle', 'tooltip')
					.attr('data-position', 'top');
				$('[data-toggle="tooltip"]').tooltip();
		}
	}

	handleMostrarSeleccionMultiple() {
			this.setState({
				seleccionarMultipleCierre: true
			})
			$('#multiple_select').modal({backdrop: 'static'});
	}

	handleComprarFormularios() {
		if((this.state.formData.gestor === null)) {
			this.setState({
				errors: ['Debe asignar un gestor.']
			});
			window.scrollTo(0, 0);
		} else {
			this.ajaxHandler.fetch('/ordenestrabajo/comprar-formularios/' + this.state.props.match.params.id, {
				method: 'POST',
				body: JSON.stringify({
					gestor: this.state.formData.gestor.id
				})
			}).then(response => {
				if(response.status === 204) {
					this.initForm();
				} else {
					response.json()
					.then(data => {
						this.setState({
							errors: data.detalle
						});
					});
				}
				window.scrollTo(0,0);
			}).catch((error) => {
				this.ajaxHandler.handleError(error);
			});
		}
	}

	handleEnviarEscribania() {
		if((this.state.formData.escribano === null)) {
			this.setState({
				errors: ['Debe asignar un escribano.']
			});
			window.scrollTo(0, 0);
		} else {
			this.ajaxHandler.fetch('/ordenestrabajo/enviar-escribania/' + this.state.props.match.params.id, {
				method: 'POST',
				body: JSON.stringify({
					escribano: this.state.formData.escribano.id
				})
			}).then(response => {
				if(response.status === 204) {
					this.initForm();
				} else {
					response.json()
					.then(data => {
						this.setState({
							errors: data.detalle
						});
					});
				}
				window.scrollTo(0,0);
			}).catch((error) => {
				this.ajaxHandler.handleError(error);
			});
		}
	}

	handleGestionar() {
		if((this.state.formData.gestor === null)) {
			this.setState({
				errors: ['Debe asignar un gestor.']
			});
			window.scrollTo(0, 0);
		} else {
			this.ajaxHandler.fetch('/ordenestrabajo/gestionar/' + this.state.props.match.params.id, {
				method: 'POST',
				body: JSON.stringify({
					gestor: this.state.formData.gestor.id
				})
			}).then(response => {
				if(response.status === 204) {
					this.initForm();
				} else {
					response.json()
					.then(data => {
						this.setState({
							errors: data.detalle
						});
					});
				}
				window.scrollTo(0,0);
			}).catch((error) => {
				this.ajaxHandler.handleError(error);
			});
		}
	}

	handlePasarProvisorio() {
		this.ajaxHandler.fetch('/ordenestrabajo/pasar-provisorio/' + this.state.props.match.params.id, {
      method: 'POST'
    }).then(response => {
      if(response.status === 204) {
        this.initForm();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      this.ajaxHandler.handleError(error);
    });
	}

	handleCancelar() {
		this.ajaxHandler.fetch('/ordenestrabajo/cancelar/' + this.state.props.match.params.id, {
      method: 'POST'
    }).then(response => {
      if(response.status === 204) {
        this.initForm();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      this.ajaxHandler.handleError(error);
    });
	}

	confirmarCierre(conVencimiento) {
		let ticketsACerrar = this.state.formData.selectedTickets.filter(ticket => ticket.seleccionado)
		let ticketsIds = ticketsACerrar.map(ticket => ticket.id)
		let textoTicket = ticketsIds.join(' , ')
		let primaryVisible = true

		let titulo = "¿Los siguientes tickets seleccionados?"
		if (!ticketsACerrar.length) {
			titulo = "No seleccionó ningún ticket"
			textoTicket = "Esta acción no tuvo ningún efecto"
			primaryVisible = false
		}
		swal({
				title: titulo,
				text: textoTicket,
				icon: "warning",
				buttons: {
					confirm: {
						text: "Confirmar",
						value: true,
						visible: primaryVisible,
						className: "btn btn-primary",
						closeModal: true
					},
					cancel: {
						text: "Cancelar",
						value: null,
						visible: true,
						className: "btn btn-danger",
						closeModal: true,
					}
				}
		})
		.then(isConfirm => {
				if (isConfirm) {
						let body = {
							tickets: ticketsIds
						}
						if (conVencimiento) {
								body.fechaVencimiento = this.state.formData.fechaVencimiento;
								$('#datos_vencimiento_modal').modal('toggle');
						}
						this.ajaxHandler.fetch('/ordenestrabajo/cerrar/' + this.state.props.match.params.id, {
							method: 'POST',
							body: JSON.stringify(body),
						})
						.then(response => {
								if(response.status === 204) {
										this.initForm();
								} else {
										response.json()
										.then(data => {
												this.setState({
														errors: data.detalle
												});
										});
								}
								window.scrollTo(0,0);
						})
						.catch((error) => {
								this.ajaxHandler.handleError(error);
						});
				}
		})
		.catch(error => {
			console.log(error);
		});
	}

	handleCerrar() {
		if(this.controlarAdjuntosObligatorios()){
			if (this.state.gestoriaVencimiento) {
				let modalDatosCierreVencimientoKey = this.state.modalDatosCierreVencimientoKey + 1;
				this.setState({
					modalDatosCierreVencimientoKey: modalDatosCierreVencimientoKey
				}, () => {
					$('#datos_vencimiento_modal').modal({ backdrop: 'static' });
				});
			}
			else
				this.confirmarCierre(false);
		}
	}

	controlarAdjuntosObligatorios() {

		let ticketsACerrar = this.state.formData.selectedTickets.filter(ticket => ticket.seleccionado)
		let ticketsIds = ticketsACerrar.map(ticket => {
				if(ticket.servicio.adjuntoObligatorio){
					let estaAdjunto = null;
					if(ticket.adjuntoModificado){
						estaAdjunto = ticket.adjuntos.findIndex((e) => e.id == ticket.servicio.categoria.id);
					} else{
						estaAdjunto = ticket.adjuntos.findIndex((e) => e.categoria && ticket.servicio.categoria && e.categoria.id == ticket.servicio.categoria.id);
					}

					if(estaAdjunto === -1){
						return "Ticket " + ticket.id + " adjunto " +ticket.servicio.categoria.nombre;
					} else {
						return null;
					}
				} else {
					return null;
				}			 
			});
		let textoTicket = ticketsIds.join(' \n ')

		if(ticketsIds.findIndex((e) => e !== null) !== -1){
			let titulo = "Faltan adjuntos obligatorios"
			if (!ticketsACerrar.length) {
				titulo = "No seleccionó ningún ticket"
				textoTicket = "Esta acción no tuvo ningún efecto"
			}
			swal({
					title: titulo,
					text: textoTicket,
					icon: "warning",
					buttons: {

						cancel: {
							text: "Volver",
							value: null,
							visible: true,
							className: "btn btn-danger",
							closeModal: true,
						}
					}
			}).then(data =>{
				return false;
			});
		} else{
			return true;
		}

	}

    handleDatosCierreVencimientoSave(obj) {
        if(!this.state.formData.fechaVencimiento)
            this.setState({fechaVencimientoValida: false});
        else
			this.confirmarCierre(true)
    }

    handleCancelModal() {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
				formDataCopy['fechaVencimiento'] = null;

        this.setState({
            formData: formDataCopy
            }, ()=>$('#datos_vencimiento_modal').modal('toggle'));
	}

	render() {

		//console.log('STATE: ' + JSON.stringify(this.state.formData));
		this.formValidation.validate();
		let state = this.state;
		let formData = state.formData;
        let validationState = this.formValidation.state;
        let requiredSymbol = ' *';

		let servicio = formData.servicio && state.servicios && state.servicios.length ? state.servicios.find(e => e.value === formData.servicio.id) : null;
		let gestor = formData.gestor && state.gestores && state.gestores.length ? state.gestores.find(e => e.value === formData.gestor.id) : null;
		let escribano = formData.escribano && state.escribanos && state.escribanos.length ? state.escribanos.find(e => e.value === formData.escribano.id) : null;

		//console.log(JSON.stringify(this.state.formData));
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row">
					<div className="col-12">
						<div className="alert alert-danger" role="alert" hidden={state.errors.length===0}>
							{state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
						<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit} noValidate>
							<div className="form-body" hidden={state.formStep !== 0}>
								{!state.usuarioGerenciador ? (
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<div className="row">
												{/* SERVICIO */}
												<div className="col-md-4">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="servicio">
                              <FormattedMessage id="tipoFormularioAbm.render.label_servicio" defaultMessage="Servicio:"/>
                            </label>
                            {this.props.action !== 'ADD' ? (
														  <div className="col-md-8 form-control-static col-form-label form-value">{formData.servicio ? formData.servicio.nombre : ''}</div>
														) : (
                              <div className="col-md-8 p-0">
                                <div>
                                  <Select
                                    clearable={false}
                                    id="servicio"
                                    name="servicio"
                                    placeholder={!state.serviciosLoading ? this.props.intl.formatMessage({ id: 'tipoFormularioAbm.render.placeholder_servicio', defaultMessage: 'Seleccione' }) : ''}
                                    options={state.servicios}
                                    valueKey='value'
                                    labelKey='label'
                                    value={servicio ? { value: servicio.id, label: servicio.label } : null}
                                    onChange={(e) => this.handleServicioChange(e)}
                                    isLoading={state.serviciosLoading}
                                    disabled={state.serviciosLoading}
                                  />
                                </div>
                              </div>
                              )}
                            </div>
                        </div>
												{/* ESTADO */}
												{this.props.action !== 'ADD' ? (
												<div className="col-md-4">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="servicio">
                              <FormattedMessage id="tipoFormularioAbm.render.label_estado" defaultMessage="Estado:"/>
                            </label>
														<div className="col-md-8 form-control-static col-form-label form-value">{formData.estado}</div>
                          </div>
                        </div>
												) : (
												<div className="col-md-4"></div>
												)}
												<div className="col-md-4">
													<div>
														<div className="row dt-icons" style={{display:  this.props.action === 'VIEW' ? '': 'none'}}>
															<div className="col-12 text-right pr-2">
																{this.canDo('COMPRAR_FORMULARIOS') && Security.hasPermission('OT_COMPRAR_FORMULARIOS') ? (
																<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" data-tooltip="tooltip" data-placement="top" title={this.props.intl.formatMessage({ id: 'tipoFormularioAbm.render.action_button.comprar_formularios', defaultMessage: 'Comprar formularios' })} onClick={this.handleComprarFormularios.bind(this)}>
																	<i className="ft-file-text"></i>
																</div>
																) : ''}
																{this.canDo('ENVIAR_ESCRIBANIA') && Security.hasPermission('OT_ENVIAR_ESCRIBANIA') ? (
																<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" data-tooltip="tooltip" data-placement="top" title={this.props.intl.formatMessage({ id: 'tipoFormularioAbm.render.action_button.enviar_a_escribania', defaultMessage: 'Enviar a escribanía' })} onClick={this.handleEnviarEscribania.bind(this)}>
																	<i className="ft-edit-2"></i>
																</div>
																) : ''}
																{this.canDo('GESTIONAR') && Security.hasPermission('OT_GESTIONAR') ? (
																<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" data-tooltip="tooltip" data-placement="top" title={this.props.intl.formatMessage({ id: 'tipoFormularioAbm.render.action_button.gestionar', defaultMessage: 'Gestionar' })} onClick={this.handleGestionar.bind(this)}>
																	<i className="ft-briefcase"></i>
																</div>
																) : ''}
																{this.canDo('PASAR_PROVISORIO') && Security.hasPermission('OT_PASAR_PROVISORIO') && this.state.estadoProvisorio ? (
																<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" data-tooltip="tooltip" data-placement="top" title={this.props.intl.formatMessage({ id: 'tipoFormularioAbm.render.action_button.pasara_a_provisorio', defaultMessage: 'Pasara a provisorio' })} onClick={this.handlePasarProvisorio.bind(this)}>
																	<i className="ft-clock"></i>
																</div>
																) : ''}
																{this.canDo('CANCELAR') && Security.hasPermission('OT_CANCELAR') ? (
																<div className="btn btn-danger box-shadow-2 btn-round btn-lg btn-dt-main round-icon" data-tooltip="tooltip" data-placement="top" title={this.props.intl.formatMessage({ id: 'tipoFormularioAbm.render.action_button.cancelar_ticket', defaultMessage: 'Cancelar Ticket' })} onClick={this.handleCancelar.bind(this)}>
																	<i className="ft-thumbs-down"></i>
																</div>
                                                                ) : ''}
                                                                {this.canDo('CERRAR') && Security.hasPermission('OT_CERRAR') ? (
																<div className="btn btn-danger box-shadow-2 btn-round btn-lg btn-dt-main round-icon" data-tooltip="tooltip" data-placement="top" title={this.props.intl.formatMessage({ id: 'tipoFormularioAbm.render.action_button.cerrar_ticket', defaultMessage: 'Cerrar Ticket' })} onClick={this.handleCerrar.bind(this)}>
																	<i className="ft-lock"></i>
																</div>
																) : ''}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												{/* GESTOR */}
												{this.state.enableGestor ? (
												<div className="col-md-4">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="gestor">
                              <FormattedMessage id="tipoFormularioAbm.render.label_gestor" defaultMessage="Gestor:"/>
                            </label>
                            {this.props.action === 'ADD' || this.canDo('COMPRAR_FORMULARIOS') || (!this.state.necesitaFormulario && this.canDo('GESTIONAR')) ? (
														  <div className="col-md-8 p-0">
																<div>
																	<Select
																		id="gestor"
																		name="gestor"
																		placeholder={!state.gestoresLoading ? this.props.intl.formatMessage({ id: 'tipoFormularioAbm.render.placeholder_gestor', defaultMessage: 'Seleccione' }) : ''}
																		options={state.gestores}
																		valueKey='value'
																		labelKey='label'
																		value={gestor ? { value: gestor.id, label: gestor.label } : null}
																		onChange={(e) => this.handleGestorChange(e)}
																		isLoading={state.gestoresLoading}
																		disabled={state.gestoresLoading}
																	/>
																</div>
															</div>
														) : (
															<div className="col-md-8 form-control-static col-form-label form-value">{formData.gestor ? formData.gestor.razonSocial : ''}</div>
                            )}
                          </div>
												 </div>
												 ) : ''}
												{/* ESCRIBANO */}
												{this.state.enableEscribano ? (
												<div className="col-md-4">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="escribano">
                              <FormattedMessage id="tipoFormularioAbm.render.label_escribano" defaultMessage="Escribano:"/>
                            </label>
                            {this.props.action === 'ADD' || this.canDo('ENVIAR_ESCRIBANIA') ? (
															<div className="col-md-8 p-0">
																<div>
																	<Select
																		id="escribano"
																		name="escribano"
																		placeholder={!state.escribanosLoading ? this.props.intl.formatMessage({ id: 'tipoFormularioAbm.render.placeholder_escribano', defaultMessage: 'Seleccione' }) : ''}
																		options={state.escribanos}
																		valueKey='value'
																		labelKey='label'
																		value={escribano ? { value: escribano.id, label: escribano.label } : null}
																		onChange={(e) => this.handleEscribanoChange(e)}
																		isLoading={state.escribanosLoading}
																		disabled={state.escribanosLoading}
																	/>
																</div>
															</div>
														) : (
															<div className="col-md-8 form-control-static col-form-label form-value">{formData.escribano ? formData.escribano.razonSocial : ''}</div>
                            )}
                          </div>
												</div>
												) : ''}
											</div>
										</div>
									</div>
								</div>
								) : ''}
								{state.props.action === 'ADD' && formData.servicio ? (
								<React.Fragment>
								<div className="card">
									<div className="card-content">
										<div className="card-body">
											<div className="container-fluid">
												<FormattedMessage id="tipoFormularioAbm.render.tickets_information.los_tickets_que_desea_agregar_a_la_orden_de_trabajo" defaultMessage="Seleccione los tickets que desea agregar a la Órden de Trabajo."/>
											</div>
											<div className="container-fluid">
												<div className="row dt-icons">
													<div className="col-6">
													</div>
													<div className="col-6" id="buttons"></div>
												</div>
											</div>
											<div className="table-responsive">
												<table id="dataTable" className="table nowrap table-hover" ref="grid" width="100%">
													<tfoot>
														<tr style={{backgroundColor: '#fff'}}>
															<th></th>
															<th className="dt-search-header"></th>
															<th className="dt-search-header"></th>
															<th className="dt-search-header"></th>
                                                            <th className="dt-search-header"></th>
															<th className="dt-search-header"></th>
														</tr>
													</tfoot>
												</table>
											</div>
											<hr />
											<div className="row">
												<div className="col-md-6">
													<div className="form-group row ml-2">
														<div className="form-control-static col-form-label"><strong>Tickets seleccionados: {state.formData.selectedTickets.length}</strong></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								</React.Fragment>
								) : ''}
								{state.props.action === 'VIEW' && formData.servicio ? (
								<React.Fragment>
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section mt-2">
												<i className="la la-ticket"></i> Tickets
											</h4>
											{state.props.action !== 'ADD' ? (
											<div className="row">
												<div className="col-md-12" style={{maxHeight: '300px', overflowY: 'auto'}}>
													<table className="table">
														<thead className="thead-fleet">
															<tr>
															{this.canDo('CERRAR') ? (
																<th scope="col">
																<input type="checkbox"
																	type="checkbox"
																	checked={this.state.seleccionarTodos}
																	data-togle="tooltip"
																	data-placement="top"
																	data-original-title="Seleccionar todo"
																	onChange={(e) => this.handleCheckBoxTodos()}/>
																<button className="btn btn-secondary btn-sm pull-right" onClick={this.handleMostrarSeleccionMultiple.bind(this)} type="button" title="Seleccionar múltiples de tickets" >
																	<span><i id="multipleSelect" className="ft-check-square"></i></span>
																</button>
																</th>
															) : ''}
																<th scope="col">Ticket</th>
																<th scope="col">Creado</th>
																<th scope="col">Estado</th>
                                								<th scope="col">Registro Automotor</th>
																<th scope="col">Entidad</th>
                                								<th scope="col">Titular</th>
																<th scope="col">Adjuntos</th>
															</tr>
														</thead>
														<tbody>
															{this.state.formData.selectedTickets.map((ticket, index)=>{
															return(
															<tr key={index}>
																{this.canDo('CERRAR') ? (
																	<td className="align-middle">
																		{ticket.estado != 'CERRADO' && ticket.estado != 'CANCELADO' ? (
																			<input type="checkbox"
														            type="checkbox"
														            checked={ticket.seleccionado}
																				onChange={(e) => this.handleCheckBoxTicket(ticket, index)}/>
																		) : ''}
																	</td>
																) : ''}
																	<td className="align-middle">{ticket.id}</td>
																	<td className="align-middle">{ moment(ticket.fechaHoraAlta).format('L') }</td>
																	<td className="align-middle">{ticket.estado}</td>
																	<td className="align-middle">{ticket.movilPersona.dnrpa_seccional}</td>
																	<td className="align-middle">{ticket.movilPersona.descripcion}</td>
	                                								<td className="align-middle">{ticket.movilPersona.titular ? ticket.movilPersona.titular.nombre : ''}</td>
																	<td className="align-middle">
																		{ticket.estado !== 'CERRADO' && ticket.estado !== 'CANCELADO' ? (
																			<button type="button" className="action btn btn-icon text-fleet btn-sm btn-dt-grid p-0 m-0" title="Ver Adjuntos" >
																				<i className="fa fa-paperclip fa-xs" onClick={ (e) => this.handleDisplayAdjuntos(e,ticket)}>
																				</i>
																			</button>) : '' }
																	</td>
															</tr>
															);
															})}
														</tbody>
													</table>
												</div>
											</div>
											) : ''}
											<div className="row">
												<div className="col-md-6">
													<div className="form-group row ml-2">
														<div className="form-control-static col-form-label"><strong>Tickets: {state.formData.selectedTickets.length}</strong></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								</React.Fragment>
								) : ''}
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<div className="text-cd text-right">
												{this.props.action !== 'VIEW' && (
												<button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
													<i className="fa fa-check-circle"></i> Guardar
												</button>
												)}
												<button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
													<i className="fa fa-times-circle"></i> Cancelar
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>

				<div className="modal fade" tabIndex="-1" id="multiple_select" role="dialog" aria-labelledby="myModalLabel3">
					<div className="modal-lg modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header bg-fleet">
								<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-check-square align-middle icon-modal-title"></i> Seleccionar múltiples de tickets</h4>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<div className="modal-body modal-grey modal-mh ovf-x-hidden">
								<div className="row">
									<div className="col-md-12">
										{/*<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
											{this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
										</div>*/}
										<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
											<div className="form-body">
												<div className="card">
													<div className="card-content">
														<div className="card-body">
															<h4 className="form-section">
																<i className="la la-ticket"></i> Tickets
															</h4>
															<div className="form-group">
																<div className="col-md-12">
																	<div className="" htmlFor="multipleSelectIds">
																		Ingrese los números de los tickets que desea seleccionar (separados por coma, espacio o salto de línea):
																	</div>
																</div>
																<div className="col-md-12">
																	<textarea id="multipleSelectIds" name="multipleSelectIds" style={{width: '100%', height: '150px'}} value={this.state.multipleSelectIds} onChange={e => this.setState({multipleSelectIds: e.target.value})} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="modal-footer modal-grey">
							{this.state.seleccionarMultipleCierre ?
								( <button type="button" className="btn btn-default btn-fleet" onClick={this.handleMultipleSelectCierre.bind(this)}><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-check-circle'}></i> Aceptar</button> ) :
								( <button type="button" className="btn btn-default btn-fleet" onClick={this.handleMultipleSelect.bind(this)}><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-check-circle'}></i> Aceptar</button> )
							}
								<button type="button" className="btn btn-danger" onClick={this.handleMultipleSelectClose}><i className="fa fa-times-circle"></i> Cancelar</button>
							</div>
						</div>
					</div>
				</div>

                <div className="modal fade" tabIndex="-1" id="datos_vencimiento_modal" role="dialog" aria-labelledby="myModalLabel133">
                    <div className="modal-xl modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-fleet">
                        <h4 className="modal-title text-white" id="myModalLabel133"><i className="la la-history align-middle icon-modal-title"></i>Cerrar Orden de Trabajo</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                        <form className="form form-horizontal" ref="form">
							<div className="form-body">
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section">
												<i className="la la-file-text"></i> Datos del Próximo Vencimiento
												<div className="float-right" style={{fontSize:'14px'}}>* campos requeridos</div>
											</h4>
											<div className="row">
												<div className="col-md-12">
													<div className="form-group row">
														{/* FECHA DE VENCIMIENTO */}
														<div className="col-md-4">
															<div className="form-group row">
																<label className="col-md-6 label-control col-form-label" htmlFor="fechaVencimiento">
																	Fecha de Vencimiento{requiredSymbol}:
																</label>
																<div className="col-md-6">
																	<div>
																		<DatePicker
																			id="fechaVencimiento"
																			name="fechaVencimiento"
																			className="form-control date-picker-placeholder"
																			placeholderText="DD/MM/AAAA"
																			selected={formData.fechaVencimiento === null || formData.fechaVencimiento === '0000-00-00' ? null : moment(formData.fechaVencimiento) }
																			onChange={(event) => this.handleDatePickerFormChange("fechaVencimiento", event)}
																			onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaVencimiento", event.target.value)}
																			minDate={moment.max([moment(), moment(moment(formData.fechaAlta)) ])}
																			openToDate={moment()}
																			showMonthDropdown
																			showYearDropdown
																			dropdownMode="select"
																			autoComplete="off"
																		/>
																		<div className="help-block text-danger field-message" hidden={this.state.fechaVencimientoValida}>Dato Requerido</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card pull-up">
								<div className="card-content">
									<div className="card-body">
										<div className="text-cd text-right">
											<button type="button" className="btn btn-primary mr-1" disabled={!validationState.form.valid} onClick={this.handleDatosCierreVencimientoSave.bind(this)}>
											<i className="fa fa-check-circle"></i> Guardar
											</button>
											<button type="button" className="btn btn-danger" onClick={this.handleCancelModal}>
											<i className="fa fa-times-circle"></i> Cancelar
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
                        </div>
                    </div>
                    </div>
                </div>
				{/* Modal Adjuntos */}
				{this.state.displayAdjuntos ?
				<div className="modal fade" tabIndex="-1" id="modificar_adjuntos_modal" role="dialog" aria-labelledby="myModalLabel1">
					<div className="modal-xl modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header bg-fleet">
								<h4 className="modal-title text-white" id="myModalLabel1"><i className="la la-history align-middle icon-modal-title"></i>{' Modificar adjuntos para el ticket: ' + this.state.displayTicketAdjuntos.id}</h4>
							</div>
							<div className="modal-body modal-grey modal-mh ovf-x-hidden">
								<OTAdjuntos onRef={ref => (this.child = ref)} ticket={this.state.displayTicketAdjuntos} callbackUpdate={this.handleAdjuntosUpdate.bind(this)} callbackClose={this.handleModificarAdjuntosClose.bind(this)} callbackSave={this.handleModificarAdjuntosSave.bind(this)} isModal="true"></OTAdjuntos>
							</div>
						</div>
					</div>
				</div>
				:''}
			</React.Fragment>
		);
	}
}

export default injectIntl(OTAbm);
